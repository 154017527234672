import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { FieldUpdate, SetFieldUpdate, FieldValue } from '../../shared/logik/models/field.model';
import { Store } from '../../shared/models/store.model';
import { WidgetInitState } from '../widget/Widget.slice';
import _ from 'lodash';
import { Prebuilt } from '../prebuilts/prebuilt.model';
import logger from '../../app/logger';

export const getNewConfigurationDefaultInput = (
    productId?: string,
    ic?: WidgetInitState['initialConfiguration'],
    store?: Store,
    prebuilt?: Prebuilt,
): (FieldUpdate | SetFieldUpdate)[] => {
    const updates = [
        {
            value: 'Customer',
            variableName: LogikFieldsEnum.userProfile,
        },
        {
            variableName: LogikFieldsEnum.availableDiscountTypes,
            value: [],
        },
    ] as (FieldUpdate | SetFieldUpdate)[];
    let initialSetup: { [name: string]: FieldValue } = {};

    if (ic) {
        if (productId && !prebuilt) initialSetup[LogikFieldsEnum.product] = ic[LogikFieldsEnum.product] || productId;

        initialSetup[LogikFieldsEnum.orderDateTimeUTC] = ic[LogikFieldsEnum.orderDateTimeUTC] || getUTCInISO();

        const icAllowedValues = _.uniq([
            LogikFieldsEnum.cusName,
            LogikFieldsEnum.cusEmail,
            LogikFieldsEnum.cusCompany,
            LogikFieldsEnum.cusPhone,
            LogikFieldsEnum.cusPhone3,
            LogikFieldsEnum.cusPAddress,
            LogikFieldsEnum.cusPCity,
            LogikFieldsEnum.cusPState,
            LogikFieldsEnum.cusPZipCode,
            LogikFieldsEnum.billing_address,
            LogikFieldsEnum.billing_city,
            LogikFieldsEnum.billing_country,
            LogikFieldsEnum.billing_state,
            LogikFieldsEnum.billing_zip,
            LogikFieldsEnum.billing_primary_phone,
            LogikFieldsEnum.billing_secondary_phone,
            LogikFieldsEnum.shipping_address,
            LogikFieldsEnum.shipping_city,
            LogikFieldsEnum.shipping_contact,
            LogikFieldsEnum.shipping_country,
            LogikFieldsEnum.shipping_state,
            LogikFieldsEnum.shipping_zip,
            LogikFieldsEnum.shipping_primary_phone,
            LogikFieldsEnum.dealId,
        ]);

        for (const defKey of icAllowedValues) {
            const defValue = ic[defKey];
            if (defValue && !initialSetup[defKey]) {
                initialSetup[defKey] = defValue;
            }
        }
    }

    if (store) {
        initialSetup = {
            ...initialSetup,
            ...getStoreUpdatesObject(store, prebuilt),
        };
    }

    return [...updates, ...objectToUpdatesArray(initialSetup)];
};

export const getOrderDateTimeUTCUpdate = (): FieldUpdate => {
    return {
        variableName: LogikFieldsEnum.orderDateTimeUTC,
        value: getUTCInISO(),
    };
};

export const getOrderDateUpdate = (dateTimeUTC: string, siteId: string): FieldUpdate => {
    const utcDate = new Date(dateTimeUTC);

    let timeZone;
    switch (siteId) {
        case 'UBBCLE': //Central Standard Time
            timeZone = 'CST';
            break;
        case 'MFGVA': //Eastern Standard Time
            timeZone = 'EST';
            break;
        case 'MFGCA': //Pacific Standard Time
            timeZone = 'PST';
            break;
        default:
            throw new Error(`Unknown site id: ${siteId}`);
    }

    logger.debug(`Changing time zone to: ${timeZone}`);

    //'YYYYMMDD'
    const dateParts = Intl.DateTimeFormat('en-US', {
        timeZone: timeZone,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).formatToParts(utcDate);

    return {
        variableName: LogikFieldsEnum.orderDateValue,
        value: [
            dateParts.find((p) => p.type === 'year'),
            dateParts.find((p) => p.type === 'month'),
            dateParts.find((p) => p.type === 'day'),
        ]
            .map((p) => p?.value)
            .join(''),
    };
};

export const getStoreUpdates = (store: Store, prebuilt?: Prebuilt): FieldUpdate[] => {
    return objectToUpdatesArray(getStoreUpdatesObject(store, prebuilt));
};

const getStoreUpdatesObject = (store: Store, prebuilt?: Prebuilt) => {
    const initialSetup: { [name: string]: FieldValue } = {};

    if (store.erpDepartment) {
        initialSetup[LogikFieldsEnum.dept] = store.erpDepartment;
        initialSetup[LogikFieldsEnum.deptDescr] = store.description;
        initialSetup[LogikFieldsEnum.storeMarket] = store.state;
    }

    if (prebuilt?.storeId) {
        initialSetup[LogikFieldsEnum.warehouse] = prebuilt.storeId;
    }

    return initialSetup;
};

const objectToUpdatesArray = (updates: Record<string, FieldValue>) => {
    return Object.entries(updates).map(([k, v]) => ({
        variableName: k,
        value: v,
    }));
};

export const getUTCInISO = (): string => new Date().toISOString();
