import { ServiceBase } from '../../shared/services/service-base';
import { ProductTemplate } from './product-template.model';
import { DataSet } from '../../shared/models/data-set.model';

class ProductsTemplatesAPIImpl extends ServiceBase {
    constructor() {
        super();
        this.http.defaults.timeout = 10000000;
    }
    async get(id: string, abortSignal: AbortSignal): Promise<ProductTemplate> {
        const prebuilt = await this.http.get<ProductTemplate>(`/api/pm/default-products/${id}`, {
            signal: abortSignal,
        });
        return prebuilt.data;
    }

    async list(abortSignal?: AbortSignal): Promise<ProductTemplate[]> {
        // const list = await this.http.get<DataSet<ProductTemplate>>('/api/pm/default-products/public/list', {
        //     signal: abortSignal,
        // });
        const list = await this.http.get<DataSet<ProductTemplate>>('/api/pm/default-products/public/list', {
            signal: abortSignal,
        });
        return list.data.data;
    }
}

export const ProductsTemplatesAPI = new ProductsTemplatesAPIImpl();
