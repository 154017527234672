import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { setLoading, setProductId, setStore } from '../widget/Widget.slice';
import { StoresSelect } from '../stores/StoresSelect';
import styles from './ProductsPage.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Store } from '../../shared/models/store.model';
import { FieldUpdate } from '../../shared/logik/models/field.model';
import { ROUTE_CONFIGURATOR } from '../wizard/wizard.router';
import { Form } from 'antd';
import { ProductInfo, productsList } from './product-list';
import { Product } from './Product';
import { ProductsTemplatesAPI } from '../products-templates/product-templates.service';
import { setProductsTemplates } from './Products.slice';

type ProductFormState = {
    productId: string;
    store: Store | null;
};

export function ProductsPage(): JSX.Element {
    const navigate = useNavigateExtended();
    const dispatch = useAppDispatch();

    const [form] = Form.useForm<ProductFormState>();
    const uuid = useAppSelector((state) => state.widget.uuid);
    const user = useAppSelector((state) => state.auth.user);
    const inOMS = useAppSelector((state) => state.widget.init?.src === 'oms');
    const productTemplates = useAppSelector((state) => state.products.productsDefaults);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const isLoading = useRef<boolean>(false);

    const products = useMemo(
        () =>
            productsList
                .sort((a, b) => a.order - b.order)
                .map((p) => ({
                    ...p,
                    image: productTemplates?.[p.id]?.images?.find((i) => i.isDefault)?.url ?? p.image,
                })),
        [productTemplates],
    );

    const navigateToConfigurator = async (): Promise<void> => {
        const idBarn = form.getFieldValue('productId');
        dispatch(setLoading(true));
        dispatch(setProductId(idBarn));

        if (uuid) {
            await dispatch(
                updateConfiguration({
                    uuid,
                    updates: [
                        {
                            variableName: LogikFieldsEnum.product,
                            value: idBarn,
                        },
                    ],
                }),
            );
        }
        dispatch(setLoading(false));
        navigate('../' + ROUTE_CONFIGURATOR, {
            state: {
                productId: idBarn,
            },
        });
    };

    const onStoreSelect = useCallback(
        async (store: Store) => {
            dispatch(setStore(store));

            if (uuid) {
                const updates: FieldUpdate[] = [
                    {
                        variableName: LogikFieldsEnum.dept,
                        value: store.erpDepartment,
                    },
                    {
                        variableName: LogikFieldsEnum.deptDescr,
                        value: store.description,
                    },
                    {
                        variableName: LogikFieldsEnum.storeMarket,
                        value: store.state,
                    },
                ];
                await dispatch(
                    updateConfiguration({
                        uuid,
                        updates,
                    }),
                );
            }
        },
        [dispatch, uuid],
    );

    useEffect(() => {
        if (!isLoading.current) {
            isLoading.current = true;
            dispatch(setLoading(true));
            ProductsTemplatesAPI.list()
                .then((pl) => {
                    dispatch(setProductsTemplates(pl));
                })
                .catch(() => {})
                .finally(() => {
                    dispatch(setLoading(false));
                    setIsLoaded(true);
                });
        }
    }, [dispatch]);

    return (
        <>
            {isLoaded && (
                <Form onFinish={navigateToConfigurator} form={form}>
                    {(inOMS || user) && (
                        <div className={styles['stores-container']}>
                            <StoresSelect storeSelected={onStoreSelect} />
                        </div>
                    )}
                    <div className={styles['products-container']}>
                        {products.map((p) => (
                            <Product
                                key={p.id}
                                product={p}
                                onClick={({ id }: ProductInfo) => {
                                    form.setFieldValue('productId', id);
                                    form.submit();
                                }}
                            />
                        ))}
                    </div>
                </Form>
            )}
        </>
    );
}
