import { PricingModal } from '../configurator/PricingModal';
import { useAppDispatch } from '../../app/hooks';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { updateTotalPrice } from '../configurator/thunks/updateTotalPrice';
import styles from './PricingPage.module.scss';
import { fetchPricingDetails } from '../widget/thunks/basic/fetchPricingDetails';

export const PricingPage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const fetchingPricing = useRef<boolean>(false);

    //const uuid = useAppSelector((state) => state.widget.uuid);
    //const total = useAppSelector((state) => state.widget.pricingUpdating);

    const fetchPricing = useCallback(async () => {
        if (!fetchingPricing.current) {
            fetchingPricing.current = true;
            await dispatch(fetchPricingDetails());
            fetchingPricing.current = false;

            setTimeout(fetchPricing, 1000);
        }
    }, [dispatch]);

    const update = useCallback(() => {
        dispatch(
            updateTotalPrice({
                forceFetchBom: true,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        fetchPricing().then(() => {});

        console.log('pricing channel', `Channel ID: ${window.opener.channelId}`);
    }, [fetchPricing, update]);

    useLayoutEffect(() => {
        console.log('pricing channel updating ');
    }, [dispatch, update]);

    return (
        <div className={styles['container']}>
            <PricingModal />
        </div>
    );
};
