import { useEffect, useState } from 'react';
import { Section, SectionProps } from '../../shared/components/layout/Section';
import { PricingTreeItem, usePrintablePricingDetailsAsTree } from '../../shared/logik/hooks/pricing.hooks';
import { ProductDetail } from '../../shared/logik/models/product.details.model';
import { PricingRows } from './PricingRows';
import './PricingSection.scss';

export type PricingSectionProps = SectionProps & {
    layoutType: 'single-row' | 'single-column' | 'multi-column';
    pricingType: 'configuration' | 'pricing';
    filter?: (item: ProductDetail) => boolean;
};
export const PricingSection = ({
    layoutType,
    pricingType,
    paddingSize,
    roundedBorders,
    filter,
    ...props
}: PricingSectionProps): JSX.Element => {
    const pricingDetails = usePrintablePricingDetailsAsTree();
    const [rows, setRows] = useState<PricingTreeItem[]>([]);

    useEffect(() => {
        let rows = pricingDetails.filter((pd) =>
            pricingType === 'pricing' ? pd.data?.uniqueIdentifier !== 'BP' : pd.data?.uniqueIdentifier === 'BP',
        );

        if (filter) {
            rows = rows.filter((r) => (r.data ? filter(r.data) : false));
        }

        setRows(rows);
    }, [filter, pricingDetails, pricingType]);

    return (
        <Section paddingSize={paddingSize ?? 'small'} roundedBorders={roundedBorders ?? true} {...props}>
            <div className={'pricing-rows' + ` pricing-rows-${layoutType}`}>
                <PricingRows items={rows} hideDivider={true} />
            </div>
        </Section>
    );
};
