import { fieldsLogic, onSaveQuoteT, propertyForLogicType } from '@ulrichlifestyle/configurator';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductConfigurator } from '../configurator/ProductConfigurator';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { App } from 'antd';
import { updateTotalPrice } from '../configurator/thunks/updateTotalPrice';
import { setThreekitId, setConfigurationFields, setSiteId } from '../widget/Widget.slice';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { ROUTE_SUMMARY } from '../wizard/wizard.router';
import { Field, getFieldValueFromArray } from '../../shared/logik/models/field.model';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { PricingModal } from '../configurator/PricingModal';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { setUserProfile } from '../widget/thunks/init/setUserProfile';
import { isError } from 'lodash';
import { Environment } from '../../app/environment';
import logger from '../../app/logger';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { EventBus } from '../../app/eventbus';
import { inIframe } from '../../shared/utils/document.utils';
import { useTaxUpdateCallback } from '../tax/tax.hooks';
import { savePreownedMasterConfiguration } from './thunks/savePreownedMasterConfiguration';
import { PreownedMasterNewEvent, PreownedMasterNewEventParams } from './preowned.utils';
import { saveAndUploadSnapshots } from '../configurator/thunks/snapshots';

const notificationDuration = 5;
const notificationPlacement: NotificationPlacement = 'bottomLeft';
const errorMessage = 'An unexpected error occurred';
const notificationKey = 'preowned-notification';

export const PreownedConfiguratorPage = (): JSX.Element => {
    const uuid = useAppSelector((state) => state.widget.uuid);
    const configurationFields = useAppSelector((state) => state.widget.configurationFields);
    const configurationMessages = useAppSelector((state) => state.widget.configurationMessages);
    const threeKitId = useAppSelector((state) => state.widget.threeKitId);
    const tooltips = useAppSelector((state) => state.widget.tooltips);
    const configurationFieldsConverted = useMemo(() => Object.values(configurationFields), [configurationFields]);

    const dispatch = useAppDispatch();
    const navigate = useNavigateExtended();
    const { modal, notification } = App.useApp();

    const [pricingModalHandle, setPricingModalHandle] = useState<FlexibleModalHandle<typeof PricingModal>>();
    const updateTax = useTaxUpdateCallback();

    useEffect(() => {
        const modal = FlexibleModal.createModal(PricingModal, {
            showCloseButton: true,
            destroyOnClose: true,
            //className: styles['pricing-modal'],
        });

        setPricingModalHandle(modal);
    }, []);

    const showError = useCallback(
        (error: Error) => {
            const message = Environment.isDevelopment ? `${error.message}.` : '';

            notification.error({
                message: errorMessage,
                description: `${message}Please try again later`,
                duration: notificationDuration,
                placement: notificationPlacement,
                key: notificationKey,
            });

            logger.error(error);
        },
        [notification],
    );

    const finishConfiguration: onSaveQuoteT = useCallback(
        async (uuid, conf, saveThreeKitConfig, getSnapshots) => {
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));
            dispatch(setConfigurationFields(conf as Field[]));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            await dispatch(updateTotalPrice({ forceFetchBom: true }));

            navigate('../' + ROUTE_SUMMARY);

            return {
                uuid: uuid,
                fields: conf,
                message: [],
                threekitId: shortId,
            };
        },
        [dispatch, navigate],
    );

    const showDetails = useCallback(async () => {
        await dispatch(
            updateTotalPrice({
                forceFetchBom: true,
            }),
        );
        pricingModalHandle?.open();
    }, [dispatch, pricingModalHandle]);

    const onZipChange = useCallback(
        async (_: string, fields: propertyForLogicType[]) => {
            const siteId = fields.find((f) => f.variableName === LogikFieldsEnum.siteId);
            if (uuid && siteId) {
                dispatch(setSiteId(siteId.value));

                try {
                    dispatch(setConfigurationFields(fields as Field[]));
                    await Promise.all([
                        dispatch(setUserProfile(uuid)),
                        updateTax().catch(() => {
                            notification.error({
                                message: 'Error getting tax rate for your location',
                                duration: 5,
                                placement: 'bottomLeft',
                            });
                        }),
                    ]);

                    return {
                        fields,
                    };
                } catch (err) {
                    showError(isError(err) ? err : new Error(err?.toString()));
                }
            }

            return {
                fields: fields,
            };
        },
        [dispatch, notification, showError, updateTax, uuid],
    );

    const save: onSaveQuoteT = useCallback(
        async (uuid, conf, saveThreeKitConfig, getSnapshots) => {
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));

            dispatch(setConfigurationFields(conf as Field[]));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            await dispatch(updateTotalPrice({ forceFetchBom: true }));

            const master = await dispatch(savePreownedMasterConfiguration()).unwrap();

            modal.confirm({
                title: 'Preowned has been successfully saved',
                content: 'Now you can close the window or go back and make some changes',
                icon: <></>,
                okText: 'Save and continue',
                cancelText: 'Save and close',
                okButtonProps: { size: 'small' },
                cancelButtonProps: { size: 'small' },
                onOk: () => dispatch(recreateConfiguration(uuid)),
                onCancel: async () => {
                    const siteId = getFieldValueFromArray<string>(conf as Field[], LogikFieldsEnum.siteId);

                    if (siteId) {
                        EventBus.dispatch<PreownedMasterNewEventParams>(PreownedMasterNewEvent, {
                            serial: master.serial,
                            siteId,
                        });

                        if (!inIframe()) {
                            await redirectToTransitionPage({
                                site: siteId,
                                redirectType: 'SavePreownedConfig',
                                serial: master.serial,
                            });
                        }
                    } else {
                        setTimeout(() => {
                            showError(new Error('SiteId is undefined'));
                        }, 0);
                    }
                },
            });

            return {
                uuid: uuid,
                fields: conf,
                message: [],
                threekitId: shortId,
            };
        },
        [dispatch, modal, showError],
    );

    return uuid ? (
        <ProductConfigurator
            logicConfig={{
                fields: configurationFieldsConverted as fieldsLogic,
                message: configurationMessages,
                uuid,
                hints: tooltips,
            }}
            threeKitId={threeKitId}
            onFinishConfig={finishConfiguration}
            onGetInfoConfiguration={showDetails}
            onChangeZipCode={onZipChange}
            saveButtonTitle='Save Preowned'
            saveButtonCallback={save}
            goBackProductList={undefined}
            containerClassName={''}
        />
    ) : (
        <WidgetAsyncFallback />
    );
};
