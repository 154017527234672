import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { FieldUpdate, SetFieldUpdate } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { fetchConfigurationEventDataFromFields, fetchProduct } from '../../../../shared/logik/utils/fields.utils';
import { ConfiguratorAPI } from '../../../../shared/services/configurator.service';
import { WidgetState, setSubTotal, setTotal } from '../../Widget.slice';
import { recreateConfiguration } from '../basic/recreateConfiguration';
import { updateConfiguration } from '../basic/updateConfiguration';

type saveAndRecreateConfigurationArgs = {
    updates?: (FieldUpdate | SetFieldUpdate)[];
    uuid: string;
    masterConfigurationId: string;
};
export const saveAndRecreateConfiguration = createAsyncThunk<
    Configuration,
    saveAndRecreateConfigurationArgs,
    {
        state: RootState;
    }
>(
    'configuration/saveAndRecreate',
    async ({ updates, uuid, masterConfigurationId }, thunkAPI): Promise<Configuration> => {
        const dispatch = thunkAPI.dispatch;
        const widgetState = thunkAPI.getState().widget;

        if (!uuid || !widgetState.productId || !widgetState.configurationFields)
            return Promise.reject('State is incomplete');

        if (updates) {
            await dispatch(
                updateConfiguration({
                    uuid: uuid,
                    updates,
                    skipRelatedUpdates: true,
                }),
            ).unwrap();
        }

        const salesman = thunkAPI.getState().auth.user?.email;
        const savedConfig = await logikApiService.saveConfiguration(uuid);
        const subtotal = savedConfig.products?.find((p) => p.id === 'Subtotal')?.extended?.extPrice;
        dispatch(setSubTotal(subtotal));
        dispatch(setTotal(savedConfig.total));
        const res = await Promise.all([
            ConfiguratorAPI.saveConfiguration({
                logikId: uuid,
                productId: widgetState.productId,
                threeKitId: widgetState.threeKitId ?? '',
                product: fetchProduct(widgetState.configurationFields),
                masterConfigurationId: masterConfigurationId,
                data: {
                    ...fetchConfigurationEventDataFromFields(
                        widgetState.configurationFields,
                        savedConfig.products ?? [],
                        undefined,
                        undefined,
                        salesman,
                        widgetState.siteId,
                        widgetState.init?.dealId,
                        subtotal,
                        false,
                        undefined,
                        widgetState.init?.actionType,
                    ),
                    images: widgetState.images3D,
                },
            }),
            dispatch(recreateConfiguration(uuid)).unwrap(),
        ]);

        return res[1];
    },
);

export const addSaveAndRecreateConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(saveAndRecreateConfiguration.pending, (state) => {
            state.saving = true;
        })
        .addCase(saveAndRecreateConfiguration.fulfilled, (state) => {
            state.saving = false;
        })
        .addCase(saveAndRecreateConfiguration.rejected, (state) => {
            state.saving = false;
        });
};
