import { Form, FormItemProps } from 'antd';
import { Rule } from 'antd/es/form';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { NamePath } from 'antd/es/form/interface';
import { debounce, isArray, isEqual, isError, isString, uniq } from 'lodash';
import React, { ReactNode, createContext, useState } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import logger from '../../../app/logger';
import {
    updateConfiguration,
    UpdateConfigurationThunkResponse,
} from '../../../features/widget/thunks/basic/updateConfiguration';
import { removeConfigurationMessagesForField } from '../../../features/widget/Widget.slice';
import { MASKED_INPUT_MASK_COMPLETE_EVENT } from '../../antd/components/MaskedInput';
import { FormState, FormStateValue } from '../../models/form.state.model';
import { Field, FieldUpdate, FieldValue } from '../models/field.model';
import { FieldMessage, isNotGlobalMessage } from '../models/message.model';
import { convertFromFormStateValue } from '../utils/form.utils';
import { LogikTooltip } from './LogikTooltip';
import { LogikFormItemErrors } from './LogikFormItemErrors';
import { LogikFormItemWarnings } from './LogikFormItemWarnings';

const DEFAULT_DEBOUNCE_TIME = 250;
type LogikFormItemBaseOwnProps = {
    name: NamePath;
    index?: number;
    setName?: string;
    fullName: NamePath;
    logikDataType?: Field['dataType'];
    fieldUpdateConverter: (value: FieldValue) => FieldUpdate;
    getMessageSelector: (message: FieldMessage) => boolean;
};

export type LogikFormItemContextType = {
    logikName: string;
    index?: number;
    setName?: string;
    setErrors?: (errors: ReactNode[]) => void;
    setWarnings?: (errors: ReactNode[]) => void;
};
export const LogikFormItemContext = createContext<LogikFormItemContextType | undefined>(undefined);
export type LogikFormItemBaseProps = FormItemProps<FormStateValue> & {
    fieldType: 'set' | 'field';
    logikName: string;
    fieldValueConverter?: (value: FormStateValue) => FieldValue;
    debounceTime?: number;
    withDebounce?: boolean;
    showAllStatusIcons?: boolean;
    showStatusIcons?: ValidateStatus[];
    direction?: 'horizontal' | 'vertical' | 'default';
    updateOnBlur?: boolean;
    updateOnMaskComplete?: boolean;
    tooltipFieldName?: string;
    tooltipContent?: string;
    hideTooltip?: boolean;
    onErrors?: (errors: React.ReactNode[]) => void;
    onWarnings?: (errors: React.ReactNode[]) => void;
    hideErrors?: boolean;
    hideWarnings?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLogikFormItemBase = () => {
    return function LogikFormItemBaseImpl({
        fieldType,
        fullName,
        index,
        setName,
        logikName,
        //logikValue,
        logikDataType,
        updateOnBlur,
        updateOnMaskComplete,
        // validateStatus,
        // status,
        validateTrigger,
        help,
        extra,
        hasFeedback,
        rules,
        messageVariables,
        withDebounce,
        debounceTime,
        showAllStatusIcons,
        showStatusIcons,
        direction,
        validateFirst,
        className,
        label,
        tooltipFieldName,
        tooltipContent,
        hideTooltip,
        fieldUpdateConverter,
        fieldValueConverter,
        getMessageSelector,
        children,
        htmlFor,
        onErrors,
        onWarnings,
        hideErrors,
        hideWarnings,
        ...props
    }: LogikFormItemBaseProps & LogikFormItemBaseOwnProps): JSX.Element {
        const dispatch = useAppDispatch();
        const form = Form.useFormInstance<FormState>();
        const fieldValue = Form.useWatch<FormStateValue>(fullName, form);
        const prevValue = useRef<FormStateValue>();
        const update$ = useRef<Promise<UpdateConfigurationThunkResponse>>();
        const [errors, setErrors] = useState<ReactNode[]>([]);
        const [warnings, setWarnings] = useState<ReactNode[]>([]);

        const messages = useMemo(
            () =>
                Object.assign(
                    {},
                    {
                        name: label?.toString() ?? logikName,
                    },
                    messageVariables,
                ),
            [logikName, messageVariables, label],
        );

        const validateTriggerMemoized = useMemo(
            () =>
                uniq(
                    ['onChange', 'onBlur'].concat(
                        !!validateTrigger
                            ? isString(validateTrigger)
                                ? [validateTrigger]
                                : isArray(validateTrigger)
                                ? validateTrigger
                                : []
                            : [],
                    ),
                ),
            [validateTrigger],
        );

        const classNameMemoized = useMemo(
            () =>
                (showAllStatusIcons || showStatusIcons?.length === 0
                    ? ''
                    : showStatusIcons && showStatusIcons.length > 0
                    ? showStatusIcons.map((s) => `hide-status-${s}`).join(' ')
                    : 'hide-status-success hide-status-warning') +
                ' ' +
                `direction-${direction ?? 'default'}` +
                ' ' +
                'hide-errors' +
                ' ' +
                (className ?? ''),
            [className, direction, showAllStatusIcons, showStatusIcons],
        );
        const update = useCallback(
            async (
                value: FieldValue,
                resolve: (res: UpdateConfigurationThunkResponse) => void,
                reject: (reason?: unknown) => void,
            ) => {
                try {
                    dispatch(removeConfigurationMessagesForField(logikName));
                    const res = await dispatch(
                        updateConfiguration({ updates: [fieldUpdateConverter(value)] }),
                    ).unwrap();

                    resolve(res);
                } catch (ex) {
                    logger.error(ex);
                    reject(isError(ex) ? ex.message : ex);
                }
            },
            [dispatch, fieldUpdateConverter, logikName],
        );

        const debounceUpdate = useMemo(
            () => debounce(update, debounceTime ?? DEFAULT_DEBOUNCE_TIME, { trailing: true }),
            [debounceTime, update],
        );

        const extendedRules: Rule[] = useMemo(
            () => [
                ...(rules ?? []),
                {
                    validator: async () => {
                        if (!form.isFieldTouched(fullName)) {
                            return Promise.resolve();
                        }

                        if (update$.current) {
                            try {
                                const res = await update$.current;
                                const errors = res.configuration?.messages
                                    .filter(getMessageSelector)
                                    .filter(isNotGlobalMessage)
                                    .filter((f) => f.type === 'error' || f.type === 'validation' || f.error === true)
                                    .map((m) => m.message.replace(logikName, messages.name));

                                return errors && errors.length > 0 ? Promise.reject(errors) : Promise.resolve();
                            } catch (err) {
                                const msg = 'Unknown error. Please try again later';
                                return Promise.reject(msg);
                            }
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
                {
                    validator: async () => {
                        if (update$.current) {
                            try {
                                const res = await update$.current;
                                const warnings = res.configuration?.messages
                                    .filter(getMessageSelector)
                                    .filter(isNotGlobalMessage)
                                    .filter(
                                        (m) =>
                                            (m.type === 'info' || m.type === 'warning') &&
                                            !m.error &&
                                            !m.message.includes('Recommended'),
                                    )
                                    .map((m) => m.message.replace(logikName, messages.name));

                                return warnings && warnings.length > 0 ? Promise.reject(warnings) : Promise.resolve();
                            } catch (err) {
                                const msg = 'Unknown error. Please try again later';
                                return Promise.reject(msg);
                            }
                        } else {
                            return Promise.resolve();
                        }
                    },
                    warningOnly: true,
                },
            ],
            [form, fullName, getMessageSelector, logikName, messages.name, rules],
        );

        const runUpdate = useCallback(
            (fieldValue: FormStateValue): Promise<UpdateConfigurationThunkResponse> | null => {
                if (!logikDataType) return null;
                if (!isEqual(fieldValue, prevValue.current)) {
                    prevValue.current = fieldValue;
                    update$.current = new Promise<UpdateConfigurationThunkResponse>((resolve, reject) => {
                        const value = convertFromFormStateValue(fieldValue, logikDataType, fieldValueConverter);
                        if (withDebounce) {
                            return debounceUpdate(value, resolve, reject);
                        } else {
                            return update(value, resolve, reject);
                        }
                    });
                    return update$.current;
                } else {
                    return null;
                }
            },
            [debounceUpdate, fieldValueConverter, logikDataType, update, withDebounce],
        );

        useEffect(() => {
            if (!updateOnBlur && form.isFieldTouched(fullName)) {
                runUpdate(fieldValue)?.catch((_) => logger.error('Unknown error occurred during field update'));
            }
        }, [updateOnBlur, fieldValue, form, fullName, runUpdate]);

        useEffect(() => {
            const field = form.getFieldInstance(fullName);

            if (field?.input) {
                const nativeInput = field.input as HTMLInputElement;

                if (nativeInput) {
                    const validateAndRunUpdate = (fieldValue: FormStateValue) => {
                        runUpdate(fieldValue)
                            ?.catch((_) => logger.error('Unknown error occurred during field update'))
                            ?.then(() => form.validateFields([fullName]))
                            .catch((_) => {});
                    };

                    const blur = () => validateAndRunUpdate(fieldValue);
                    const maskComplete = () => validateAndRunUpdate(fieldValue);
                    if (updateOnBlur) {
                        nativeInput.addEventListener('blur', blur);
                    }

                    if (updateOnMaskComplete) {
                        nativeInput.addEventListener(MASKED_INPUT_MASK_COMPLETE_EVENT, maskComplete);
                    }

                    return () => {
                        nativeInput?.removeEventListener('blur', blur);
                        nativeInput?.removeEventListener(MASKED_INPUT_MASK_COMPLETE_EVENT, maskComplete);
                    };
                }
            }
        }, [fieldValue, form, fullName, runUpdate, updateOnBlur, updateOnMaskComplete]);

        const labelContent = useMemo(
            () =>
                label &&
                (!hideTooltip ? (
                    <>
                        <label
                            style={{ display: 'contents' }}
                            htmlFor={
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                [form['__INTERNAL__'].name, ...fullName].join('_')
                            }>
                            {label}
                        </label>
                        <LogikTooltip fieldName={tooltipFieldName ?? logikName} helpContent={tooltipContent} />
                    </>
                ) : (
                    label
                )),
            [form, fullName, hideTooltip, label, logikName, tooltipContent, tooltipFieldName],
        );

        const labelProps = useMemo(
            () =>
                htmlFor
                    ? { htmlFor }
                    : !hideTooltip
                    ? {
                          htmlFor: undefined,
                      }
                    : {},
            [hideTooltip, htmlFor],
        );

        const formItemMemoized = useMemo(
            () => (
                <Form.Item
                    className={classNameMemoized}
                    hasFeedback={hasFeedback ?? true}
                    extra={''}
                    help={''}
                    rules={extendedRules}
                    messageVariables={messages}
                    validateFirst={true}
                    validateTrigger={validateTriggerMemoized}
                    label={labelContent}
                    {...props}
                    {...labelProps}>
                    {children}
                </Form.Item>
            ),
            [
                children,
                classNameMemoized,
                extendedRules,
                hasFeedback,
                labelContent,
                labelProps,
                messages,
                props,
                validateTriggerMemoized,
            ],
        );

        return (
            <>
                <LogikFormItemContext.Provider
                    value={{
                        logikName,
                        index,
                        setName,
                        setErrors: (errors) => {
                            onErrors?.(errors);
                            setErrors(errors);
                        },
                        setWarnings: (warnings) => {
                            onWarnings?.(warnings);
                            setWarnings(warnings);
                        },
                    }}>
                    {formItemMemoized}
                </LogikFormItemContext.Provider>
                {!hideErrors && <LogikFormItemErrors errors={errors} />}
                {!hideWarnings && <LogikFormItemWarnings warnings={warnings} />}
            </>
        );
    };
};
