import { FormListOperation } from 'antd';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { Option } from '../../../shared/logik/models/field.model';
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useFieldSet, useFieldOptions } from '../../../shared/logik/hooks/field.hooks';
import styles from './DiscountRow.module.scss';
import { DiscountRowContent } from './DiscountRowContent';

type DiscountRowImplProps = {
    index: number;
    remove: FormListOperation['remove'];
};

const DiscountRowImpl = ({ index, remove }: DiscountRowImplProps): JSX.Element => {
    const set = useFieldSet(LogikFieldsEnum.set_of_discounts);
    const availableDiscounts = useFieldOptions(LogikFieldsEnum.availableDiscountTypes);
    const [definition, setDefinition] = useState<Option>();

    useEffect(() => {
        setDefinition(
            availableDiscounts?.find(
                (d) =>
                    d.value ===
                    set?.find((s) => s.index === index)?.fields[LogikFieldsEnum.set_of_discounts_discount_type].value,
            ),
        );
    }, [availableDiscounts, index, set]);

    return definition ? (
        <div className={styles['discounts-row']}>
            <DiscountRowContent index={index} definition={definition} onRemove={() => remove(index)} />
        </div>
    ) : (
        <></>
    );
};

export const DiscountRow = React.memo(DiscountRowImpl, (prev, next) => isEqual(prev, next));
