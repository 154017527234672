import { RootState } from '../../app/store';
import { selectFields, selectMessages, selectSets } from '../../features/widget/Widget.slice';
import {
    Option,
    isFieldOptionSet,
    VisibilityState,
    FieldValue,
    FieldOptionSet,
    Field,
    SetField,
} from '../logik/models/field.model';
import { SetFieldItemGroup } from '../logik/models/field-set.details.model';
import {
    FieldMessage,
    isFieldMessage,
    isSetFieldMessage,
    SetFieldMessage,
    TYPE_GLOBAL_MESSAGE,
} from '../logik/models/message.model';

export const selectField =
    (logikFieldName: string) =>
    (state: RootState): Field | undefined =>
        selectFields(state)[logikFieldName];

export const selectSetField =
    (logikFieldName: string, index: number, setName?: string) =>
    (state: RootState): SetField | undefined => {
        return !setName
            ? Object.values(selectSets(state))
                  .map((s) => s.find((c) => c.index === index)?.fields[logikFieldName])
                  .find((f) => !!f)
            : selectSets(state)?.[setName]?.find((s) => s.index === index)?.fields[logikFieldName];
    };

export const selectFieldVisibility =
    (logikFieldName: string) =>
    (state: RootState): VisibilityState | undefined =>
        selectField(logikFieldName)(state)?.visibilityState;

// export const selectFieldProperty =
//     <TProperty extends keyof Field>(propertyName: TProperty, logikFieldName: string) =>
//     (state: RootState): Field[TProperty] | undefined =>
//         selectField(logikFieldName)(state)?.[propertyName];

export const selectFieldProperty =
    <TType extends Field, TFunc extends (f: TType) => unknown>(property: TFunc, logikFieldName: string) =>
    (state: RootState): ReturnType<typeof property> | undefined => {
        const field = selectField(logikFieldName)(state);

        return field ? (property(field as TType) as ReturnType<typeof property>) : undefined;
    };

export const selectSetFieldVisibility =
    (logikFieldName: string, index: number, setName?: string) =>
    (state: RootState): VisibilityState | undefined =>
        selectSetField(logikFieldName, index, setName)(state)?.visibilityState;

// export const selectSetFieldProperty =
//     <TProperty extends keyof SetField>(
//         propertyName: TProperty,
//         logikFieldName: string,
//         index: number,
//         setName?: string,
//     ) =>
//     (state: RootState): SetField[TProperty] | undefined =>
//         selectSetField(logikFieldName, index, setName)(state)?.[propertyName];

export const selectSetFieldProperty =
    <TType extends Field, TFunc extends (f: TType) => unknown>(
        property: TFunc,
        logikFieldName: string,
        index: number,
        setName?: string,
    ) =>
    (state: RootState): ReturnType<typeof property> | undefined => {
        const field = selectSetField(logikFieldName, index, setName)(state);
        return field ? (property(field as TType) as ReturnType<typeof property>) : undefined;
    };

export const selectFieldOptions =
    (logikFieldName: string) =>
    (state: RootState): Option[] | undefined => {
        const field = selectFields(state)[logikFieldName];
        if (field && isFieldOptionSet(field)) {
            return field.optionSet.options;
        }
    };
export const selectFieldValue =
    <T extends FieldValue = FieldValue>(logikFieldName: string) =>
    (state: RootState): T | null =>
        selectField(logikFieldName)(state)?.value as T;

export const selectSetFieldValue =
    <T extends FieldValue = FieldValue>(logikFieldName: string, index: number, setName?: string) =>
    (state: RootState): T | null =>
        selectSetField(logikFieldName, index, setName)(state)?.value as T;

export const selectFieldOptionValue =
    (logikFieldName: string) =>
    (state: RootState): FieldValue | null => {
        const field = selectField(logikFieldName)(state);
        if (!field) return null;

        return (field as FieldOptionSet).optionSet.options.find((f) => f.value == f.value)?.label ?? null;
    };

export const selectFieldSet =
    (logikSetName: string) =>
    (state: RootState): SetFieldItemGroup[] | undefined => {
        return selectSets(state)?.[logikSetName];
    };

export const selectFieldMessages =
    (logikFieldName: string, selector?: (message: FieldMessage) => boolean) =>
    (state: RootState): FieldMessage[] =>
        selectMessages(state)
            ?.filter(isFieldMessage)
            ?.filter((m) => m.targetType === 'field' && m.field !== TYPE_GLOBAL_MESSAGE)
            ?.filter((m) => m.field === logikFieldName)
            ?.filter(selector ? selector : () => true);
export const selectSetFieldMessages =
    (logikFieldName: string, index: number, setName?: string, selector?: (message: SetFieldMessage) => boolean) =>
    (state: RootState): SetFieldMessage[] =>
        selectMessages(state)
            ?.filter(isSetFieldMessage)
            ?.filter((m) => m.targetType === 'field' && m.field !== TYPE_GLOBAL_MESSAGE)
            ?.filter((m) => m.field === logikFieldName && m.index === index && (setName ? m.set === setName : true))
            ?.filter(selector ? selector : () => true);
export const selectFieldEditable =
    (logikFieldName: string) =>
    (state: RootState): Field['editable'] | undefined =>
        selectField(logikFieldName)(state)?.editable;
