import { createSelector } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../app/hooks';
import { selectPricingDetails } from '../../../features/widget/Widget.slice';
import { ProductDetail } from '../models/product.details.model';

export type PricingTree = {
    root: PricingTreeItem;
};
export type PricingTreeItem = {
    data: ProductDetail | null;
    products: PricingTreeItem[];
};

const fillTree = (items: ProductDetail[], parent: PricingTreeItem): void => {
    const children = items.filter((b) => b.parentProduct == parent?.data?.uniqueIdentifier);

    for (let i = 0; i < children.length; i++) {
        const child = { data: children[i], products: new Array<PricingTreeItem>() } as PricingTreeItem;
        parent.products.push(child);
        fillTree(items, child);
    }
};

const arrayToTree = (items: ProductDetail[]): PricingTree => {
    const root = { data: null, products: new Array<PricingTreeItem>() } as PricingTreeItem;
    fillTree(items, root);
    return { root };
};

const selectPrintablePricingDetails = createSelector(selectPricingDetails, (items) =>
    items
        .filter((pd) => pd.extended?.PrintPricingDet)
        .map((pd) => {
            return { ...pd, uniqueIdentifier: pd.uniqueIdentifier ?? pd.id };
        }),
);
const selectPrintablePricingDetailsAsTree = createSelector(
    selectPrintablePricingDetails,
    (items) => arrayToTree(items).root.products as PricingTreeItem[],
);
export const usePrintablePricingDetails = (): ProductDetail[] => useAppSelector(selectPrintablePricingDetails);
export const usePrintablePricingDetailsAsTree = (): PricingTreeItem[] =>
    useAppSelector(selectPrintablePricingDetailsAsTree);
