import { isArray, isString } from 'lodash';
import { useCallback } from 'react';
import { useNavigate, To, NavigateOptions, useLocation } from 'react-router-dom';
import { buildPath, BuildPathOptions } from '../utils/path.utils';
import { useLocationSearchParams } from './hooks';

export type ToExtended = To | string[];
export type NavigateOptionsExtended = NavigateOptions &
    BuildPathOptions & {
        preserveSearch?: boolean;
        pathParams?: Record<string, unknown>;
        intendedToGoBack?: boolean;
    };

export type NavigateRelativeFunctionExtended = (to: ToExtended, options?: NavigateOptionsExtended) => void;
export const useNavigateExtended = (): NavigateRelativeFunctionExtended => {
    const navigate = useNavigate();
    const searchParams = useLocationSearchParams();
    const currentLocation = useLocation();

    return useCallback(
        (to: ToExtended, options?: NavigateOptionsExtended) => {
            if (isString(to) || isArray(to)) {
                to = {
                    pathname: buildPath(to, options, options?.pathParams),
                };
            } else {
                if (to.pathname) to.pathname = buildPath(to.pathname, options, options?.pathParams);
            }

            if (options?.preserveSearch ?? true) {
                to.search = (to.search ?? '') + searchParams.toString();
            }

            if (options?.intendedToGoBack) {
                const goBackLocation = currentLocation.state?.['goBackLocation'] as Location;

                if (!!goBackLocation) {
                    to = {
                        pathname: goBackLocation.pathname,
                        search: options.preserveSearch ? goBackLocation.search : '',
                        hash: goBackLocation.hash,
                    };
                }
            }

            if (!options) options = { state: {} };
            options.state = {
                ...options.state,
                goBackLocation: currentLocation,
            };
            navigate(to, options);
        },
        [currentLocation, navigate, searchParams],
    );
};
