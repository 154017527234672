import React, { PropsWithChildren, useCallback, useImperativeHandle, useState } from 'react';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '../../../app/store';
import { DefaultConfigProvider } from '../../antd/DefaultConfigProvider';
import { FlexibleModalHandle } from './flexible-modal';
import { ModalBody, ModalBodyProps } from './ModalBody';

interface OwnProps extends Pick<ModalBodyProps, 'showCloseButton'> {
    rootSelector?: string;
    className?: string;
    destroyOnClose?: boolean;
    showCloseButton?: boolean;
    closeOnOutsideClick?: boolean;
    root?: Root | null;
    closeOnEscape?: boolean;
}

const defaultConfig: Required<OwnProps> = {
    rootSelector: 'body',
    className: '',
    destroyOnClose: true,
    showCloseButton: true,
    closeOnOutsideClick: true,
    root: null,
    closeOnEscape: true,
};

export interface ModalRefType {
    close: () => void;
    show: () => void;
    hide: () => void;
    isHidden: () => boolean;
}

type FlexibleModalImplStatic = {
    createModal?: typeof FlexibleModalHandle.createModal;
};

export type FlexibleModalProps = PropsWithChildren<OwnProps>;
export type OpenModalProps = Omit<FlexibleModalProps, 'children' | 'root'>;

const refComponent = React.forwardRef<ModalRefType, FlexibleModalProps>(function FlexibleModal(
    { children, root, ...ownProps },
    ref,
): JSX.Element {
    const config = { ...defaultConfig, ...ownProps };
    const container = useRef(document.querySelector(config.rootSelector)).current;
    const [hidden, setHidden] = useState<boolean>(false);

    const close = useCallback(
        (unmount?: boolean) => {
            if (config.destroyOnClose || unmount) {
                root?.unmount();
            } else {
                setHidden(true);
            }
        },
        [config.destroyOnClose, root],
    );

    useImperativeHandle(ref, () => ({
        close,
        hide: () => setHidden(true),
        show: () => setHidden(false),
        isHidden: () => hidden,
    }));

    useEffect(() => {
        return () => {
            close(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return container ? (
        ReactDOM.createPortal(
            <Provider store={store}>
                <DefaultConfigProvider>
                    <ModalBody
                        onRequestClose={() => close()}
                        showCloseButton={config.showCloseButton}
                        closeOnEscape={config.closeOnEscape}
                        closeOnOutsideClick={config.closeOnOutsideClick}
                        style={{ display: hidden ? 'none' : 'initial' }}
                        className={ownProps.className}>
                        {children}
                    </ModalBody>
                </DefaultConfigProvider>
            </Provider>,
            container,
        )
    ) : (
        <></>
    );
});

const FlexibleModalImpl: React.ForwardRefExoticComponent<
    OwnProps & {
        children?: React.ReactNode;
    } & React.RefAttributes<ModalRefType>
> &
    FlexibleModalImplStatic = refComponent;

FlexibleModalImpl.createModal = FlexibleModalHandle.createModal;
export const FlexibleModal = FlexibleModalImpl as React.ForwardRefExoticComponent<
    OwnProps & {
        children?: React.ReactNode;
    } & React.RefAttributes<ModalRefType>
> &
    Required<FlexibleModalImplStatic>;
