import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { selectFields, WidgetState } from '../../../features/widget/Widget.slice';
import {
    selectFieldVisibility,
    selectFieldOptions,
    selectFieldValue,
    selectFieldOptionValue,
    selectFieldSet,
    selectSetFieldVisibility,
    selectSetFieldValue,
    selectSetFieldMessages,
    selectField,
    selectSetField,
    selectFieldEditable,
    selectFieldProperty,
    selectSetFieldProperty,
} from '../../store/selectors';
import { Field, FieldValue, isSetFieldUpdate, Option } from '../models/field.model';
import { SetFieldItemGroup } from '../models/field-set.details.model';

export const useField = (fieldName: string): Field | undefined =>
    useAppSelector((state) => selectField(fieldName)(state), shallowEqual);
export const useFieldHidden = (fieldName: string): boolean =>
    useAppSelector((state) => selectFieldVisibility(fieldName)(state) === 'hidden', shallowEqual);
export const useSetFieldHidden = (fieldName: string, index: number, setName?: string): boolean =>
    useAppSelector((state) => selectSetFieldVisibility(fieldName, index, setName)(state) === 'hidden', shallowEqual);
export const useFieldVisible = (fieldName: string): boolean =>
    useAppSelector((state) => selectFieldVisibility(fieldName)(state) === 'visible', shallowEqual);
export const useSetFieldVisible = (fieldName: string, index: number, setName?: string): boolean =>
    useAppSelector((state) => selectSetFieldVisibility(fieldName, index, setName)(state) === 'visible', shallowEqual);
export const useFieldOptions = (fieldName: string): Option[] | undefined =>
    useAppSelector(selectFieldOptions(fieldName), shallowEqual);
export const useFieldSet = (setName: string): SetFieldItemGroup[] | undefined =>
    useAppSelector(selectFieldSet(setName), shallowEqual);
export const useFieldValue = <T extends FieldValue = FieldValue>(fieldName: string): T | null =>
    useAppSelector(selectFieldValue(fieldName), shallowEqual);
export const useSetField = (fieldName: string, index: number, setName?: string): Field | undefined =>
    useAppSelector((state) => selectSetField(fieldName, index, setName)(state), shallowEqual);
export const useSetFieldValue = <T extends FieldValue = FieldValue>(
    fieldName: string,
    index: number,
    setName?: string,
): T | null => useAppSelector(selectSetFieldValue(fieldName, index, setName), shallowEqual);
export const useFieldOptionValue = (fieldName: string): FieldValue | null =>
    useAppSelector(selectFieldOptionValue(fieldName), shallowEqual);

export const useFields = (): WidgetState['configurationFields'] => useAppSelector((state) => selectFields(state));

export const useFieldsWithMatch = (filterBy: (field: Field) => boolean): Field[] =>
    useAppSelector((state) => Array.from(Object.values(selectFields(state))).filter(filterBy), shallowEqual);

export const useFieldHasError = (fieldName: string): boolean =>
    useAppSelector((state) => {
        return !!state.widget?.configurationMessages?.find(
            (m) => m.field === fieldName && (m.type === 'error' || m.type === 'validation') && m.targetType === 'field',
        );
    }, shallowEqual);

export const useSetFieldHasError = (fieldName: string, index: number, setName?: string): boolean =>
    useAppSelector(
        (state) =>
            !!selectSetFieldMessages(
                fieldName,
                index,
                setName,
            )(state)?.find((m) => (m.type === 'error' || m.type === 'validation') && m.targetType === 'field'),
        shallowEqual,
    );

export const useFieldIsUpdating = (fieldName: string): boolean =>
    useAppSelector(
        (state) => !!state.widget.fieldsUpdating?.map((u) => u.variableName).includes(fieldName) ?? false,
        shallowEqual,
    );

export const useSetFieldIsUpdating = (fieldName: string, index: number, setName?: string): boolean =>
    useAppSelector(
        (state) =>
            !!state.widget.fieldsUpdating
                ?.filter(isSetFieldUpdate)
                ?.filter((u) => u.index === index && (setName ? u.set === setName : true))
                .map((u) => u.variableName)
                .includes(fieldName) ?? false,
        shallowEqual,
    );

export const useFieldOrSetIsUpdating = (fieldName: string, index?: number, setName?: string): boolean =>
    useAppSelector((state) => {
        return index && setName
            ? !!state.widget.fieldsUpdating
                  ?.filter(isSetFieldUpdate)
                  ?.filter((u) => u.index === index && (setName ? u.set === setName : true))
                  .map((u) => u.variableName)
                  .includes(fieldName) ?? false
            : !!state.widget.fieldsUpdating?.map((u) => u.variableName).includes(fieldName) ?? false;
    }, shallowEqual);
export const useFieldDataType = (fieldName: string): Field['dataType'] | undefined =>
    useAppSelector((state) => selectField(fieldName)(state)?.dataType, shallowEqual);

export const useSetFieldDataType = (
    fieldName: string,
    index: number,
    setName?: string,
): Field['dataType'] | undefined =>
    useAppSelector((state) => selectSetField(fieldName, index, setName)(state)?.dataType, shallowEqual);

export const useFieldIsEditable = (logikFieldName: string): boolean =>
    useAppSelector(selectFieldEditable(logikFieldName)) === 'true';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFieldProperty = <TType extends Field>() => {
    const stateSelector = useAppSelector;

    return <TFunc extends (f: TType) => unknown>(
        property: TFunc,
        logikFieldName?: string,
        index?: number,
        setName?: string,
    ): ReturnType<typeof property> | undefined => {
        if (!logikFieldName) return;

        const selector =
            index && setName
                ? selectSetFieldProperty<TType, TFunc>(property, logikFieldName, index, setName)
                : selectFieldProperty<TType, TFunc>(property, logikFieldName);
        const res = stateSelector(selector, shallowEqual);

        return res as ReturnType<typeof property> | undefined;
    };
};
