import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { LogikInput } from '../../../shared/logik/components/LogikInput';
import { LogikSwitch } from '../../../shared/logik/components/LogikSwitch';
import { useField, useFieldValue } from '../../../shared/logik/hooks/field.hooks';
import { Button } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AdjustDownPaymentAmountModal, formatMoney } from './AdjustDownPaymentAmountModal';
import { FlexibleModal } from '../../../shared/components/modal/FlexibleModal';
import styles from '../SummaryPage.module.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { updateConfiguration } from '../../widget/thunks/basic/updateConfiguration';
import { FlexibleModalHandle } from '../../../shared/components/modal/flexible-modal';
import { useFieldMessages } from '../../../shared/logik/hooks/messages.hooks';
import { LogikFormItemErrors } from '../../../shared/logik/components/LogikFormItemErrors';
import { LogikFormItemWarnings } from '../../../shared/logik/components/LogikFormItemWarnings';
import { MaskedInput, MaskedInputRef } from '../../../shared/antd/components/MaskedInput';
import { LogikSlider } from '../../../shared/logik/components/LogikSlider';

// type AdjFormState = {
//     [LogikFieldsEnum.adjustDownPmtDue]: string;
// };
export function SummaryOther(): JSX.Element {
    const adjustDownPmtDueField = useField(LogikFieldsEnum.adjustDownPmtDue);
    const [modalHandler, setModalHandler] = useState<FlexibleModalHandle<typeof AdjustDownPaymentAmountModal>>();
    const dispatch = useAppDispatch();
    const { setFieldValue, validateFields, setFieldsValue } = useFormInstance();
    const minAdjAmount = useFieldValue<number>(LogikFieldsEnum.minDownPmtDue);
    const maxAdjAmount = useFieldValue<number>(LogikFieldsEnum.maxDownPmtDue);
    const adjustDownPmtDue = useFieldValue<number>(LogikFieldsEnum.adjustDownPmtDue);
    const adjAmountRef = useRef<MaskedInputRef>(null);
    const pricingDetails = useAppSelector((state) => state.widget.pricingDetails);
    const orderTotal = useMemo(() => {
        return pricingDetails.find((pd) => pd.id === 'TotalOrder')?.extended?.extPrice;
    }, [pricingDetails]);
    console.log('orderTotal', orderTotal);
    const messages = useFieldMessages(LogikFieldsEnum.maxDownPmtDue);

    //const [adjForm] = useLogikForm<AdjFormState>();
    const errors = useMemo(() => messages.filter((m) => m.error).map((m) => m.message), [messages]);
    const warnings = useMemo(
        () => messages.filter((m) => (m.type === 'info' || m.type === 'warning') && !m.error).map((m) => m.message),
        [messages],
    );

    const updateAdj = useCallback(
        async (percent: number) => {
            await dispatch(
                updateConfiguration({
                    updates: [
                        {
                            variableName: LogikFieldsEnum.adjustDownPmtDue,
                            value: percent,
                        },
                    ],
                }),
            ).unwrap();

            setFieldsValue({
                [LogikFieldsEnum.adjustDownPmtDue]: percent,
                [LogikFieldsEnum.adjustDownPmtDueManual]: percent,
            });

            return validateFields([LogikFieldsEnum.adjustDownPmtDue, LogikFieldsEnum.adjustDownPmtDueManual], {
                validateOnly: true,
            }).catch((err) => {});
        },
        [dispatch, setFieldsValue, validateFields],
    );

    useEffect(() => {
        if (!modalHandler) {
            const modal: FlexibleModalHandle<typeof AdjustDownPaymentAmountModal> = FlexibleModal.createModal(
                AdjustDownPaymentAmountModal,
                {
                    onClose: (percent) => {
                        if (percent) {
                            updateAdj(percent)
                                .catch(() => {})
                                .finally(modal.close);
                        } else {
                            modal.close();
                        }
                    },
                },
                {
                    showCloseButton: false,
                    destroyOnClose: true,
                    className: styles['pricing-modal'],
                },
            );
            setModalHandler(modal);
        }
    }, [dispatch, modalHandler, setFieldValue, updateAdj, validateFields]);

    return (
        <>
            <LogikFormItem
                label='Include POP Sheets'
                direction='horizontal'
                logikName={LogikFieldsEnum.includePop}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label={<span style={{ whiteSpace: 'normal' }}>Order Contains Hand Written Options</span>}
                direction='horizontal'
                logikName={LogikFieldsEnum.handWrittenOption}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label='Quote Mode'
                direction='horizontal'
                logikName={LogikFieldsEnum.quoteMode}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label='Add Referral'
                direction='horizontal'
                logikName={LogikFieldsEnum.discReferral}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label='Referrer Name'
                logikName={LogikFieldsEnum.discReferralName}
                rules={[
                    {
                        required: true,
                        message: 'Referrer name is required',
                    },
                ]}
                withDebounce>
                <LogikInput placeholder='Enter Referrer Name' />
            </LogikFormItem>

            <LogikFormItem
                label="Referrer's Order #"
                logikName={LogikFieldsEnum.discReferralOrder}
                rules={[
                    {
                        required: true,
                        message: 'Order number is required',
                    },
                ]}
                withDebounce>
                <LogikInput placeholder="Enter Referrer's Order" />
            </LogikFormItem>

            {minAdjAmount && maxAdjAmount && orderTotal && (
                <div>
                    <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
                        <LogikFormItem
                            style={{ width: '100%' }}
                            label='Adjust Down Payment'
                            logikName={LogikFieldsEnum.adjustDownPmtDue}
                            rules={[
                                {
                                    required: true,
                                    message: 'Order number is required',
                                },
                            ]}
                            withDebounce
                            hideErrors
                            hideWarnings
                            dependencies={[LogikFieldsEnum.adjustDownPmtDueManual]}>
                            <LogikSlider
                                min={minAdjAmount}
                                max={maxAdjAmount}
                                step={1}
                                style={{ marginLeft: '15px' }}
                                tooltip={{
                                    formatter: (v) =>
                                        `${
                                            v
                                                ? formatMoney(
                                                      (orderTotal *
                                                          (!!adjAmountRef.current?.getUnMaskedValue()
                                                              ? Number.parseFloat(
                                                                    adjAmountRef.current.getUnMaskedValue()!,
                                                                )
                                                              : v)) /
                                                          100,
                                                  )
                                                : 0
                                        }`,
                                    open: true,
                                    placement: 'top',
                                }}
                                marks={{
                                    [minAdjAmount]: `${Math.round(minAdjAmount)}%`,
                                    [maxAdjAmount]: `${Math.round(maxAdjAmount)}%`,
                                    //...(adjustDownPmtDue ? { [adjustDownPmtDue]: `${adjustDownPmtDue}%` } : {}),
                                }}
                                onChange={(value) => {
                                    setFieldValue(LogikFieldsEnum.adjustDownPmtDueManual, value);
                                }}
                            />
                        </LogikFormItem>
                        {/*<LogikForm form={adjForm} name={'adj-form'}>*/}
                        <LogikFormItem
                            logikName={LogikFieldsEnum.adjustDownPmtDueManual}
                            style={{ width: '120px' }}
                            rules={[
                                {
                                    validator: () => {
                                        const adjStr = adjAmountRef.current?.getUnMaskedValue();
                                        const adj = !!adjStr ? Number.parseFloat(adjStr) : 0;

                                        return minAdjAmount &&
                                            maxAdjAmount &&
                                            adj >= minAdjAmount &&
                                            adj <= maxAdjAmount
                                            ? Promise.resolve()
                                            : Promise.reject();
                                    },
                                    message: `Down Payment Amount is invalid`,
                                },
                            ]}
                            hideErrors
                            hideWarnings
                            updateOnBlur>
                            <MaskedInput
                                maskOptions={{
                                    mask: '% money',
                                    lazy: false,
                                    blocks: {
                                        money: {
                                            mask: Number,
                                            signed: true,
                                            thousandsSeparator: '',
                                            radix: '.',
                                            mapToRadix: [','],
                                            scale: 99,
                                            normalizeZeros: false,
                                            padFractionalZeros: false,
                                        },
                                    },
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value;
                                    if (value.endsWith('.') || value.endsWith(',')) {
                                        e.target.value += '00';
                                    }

                                    updateAdj(Number(e.target.value.replace('% money'.replace('money', ''), '')))
                                        .then(() => {})
                                        .catch((err) => {});
                                }}
                                onChange={(e) => {
                                    setFieldValue(
                                        LogikFieldsEnum.adjustDownPmtDue,
                                        adjAmountRef.current?.getUnMaskedValue(),
                                    );
                                }}
                                ref={adjAmountRef}
                                value={adjustDownPmtDue ?? undefined}
                            />
                        </LogikFormItem>
                        {/*</LogikForm>*/}
                    </div>
                    <LogikFormItemErrors errors={errors} />
                    <LogikFormItemWarnings warnings={warnings} />
                </div>
            )}

            {adjustDownPmtDueField?.visibilityState === 'visible' && (
                <Button size={'small'} onClick={() => modalHandler?.open()}>
                    Calculate From Amount
                </Button>
            )}
            {/* <LogikFormItem
                label='Primary Phone'
                direction='vertical'
                logikName={LogikFieldsEnum.cusPhone1}
                rules={[
                    {
                        required: true,
                        message: 'Phone number is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput />
            </LogikFormItem> */}

            {/* <LogikFormItem
                label='Secondary Phone'
                direction='vertical'
                logikName={LogikFieldsEnum.cusPhone2}
                rules={[
                    {
                        required: !quoteMode,
                        message: 'Phone number is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput />
            </LogikFormItem> */}

            {/* <LogikFormItem
                label='Alternate Phone'
                direction='vertical'
                logikName={LogikFieldsEnum.cusPhone3}
                withDebounce={true}
                rules={[]}>
                <LogikInput />
            </LogikFormItem> */}
        </>
    );
}
