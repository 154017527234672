import React, { useEffect, useRef } from 'react';
import { Outlet, useAsyncValue } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import { LogikGlobalErrors } from '../../shared/logik/components/LogikGlobalErrors';
import { UnloadGuard } from '../unload-guard/UnloadGuard';
import { IdleHeartbeat } from '../idle/IdleHeartbeat';
import { createProductTemplate } from './thunks/createProductTemplate';
import { editProductTemplate } from './thunks/editProductTemplate';
import styles from '../wizard/Wizard.module.scss';
import { setInitialWidgetState, WidgetInitState } from '../widget/Widget.slice';
import { ProductTemplateLoadResult } from './products-templates.router';
import { WizardLoader } from '../wizard/WizardLoader';

export const ProductTemplateWizard = (): React.JSX.Element => {
    const productTemplate = useAsyncValue() as ProductTemplateLoadResult;

    const dispatchingInit = useRef<boolean>(false);
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);

    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (!dispatchingInit.current && productTemplate && !uuid) {
            dispatchingInit.current = true;

            if (!productTemplate.id) {
                dispatch(
                    setInitialWidgetState({
                        launchObjectId: productTemplate.launchObjectId,
                    } as Partial<WidgetInitState>),
                );

                dispatch(createProductTemplate({ productId: productTemplate.productId }))
                    .unwrap()
                    .catch(showBoundary);
            } else {
                dispatch(
                    setInitialWidgetState({
                        launchObjectId: productTemplate.launchObjectId,
                        productId: productTemplate.productId,
                        masterConfigurationId: productTemplate.masterConfigurationId,
                    } as Partial<WidgetInitState>),
                );
                if (productTemplate.masterConfigurationId) {
                    dispatch(editProductTemplate({ masterConfigurationId: productTemplate.masterConfigurationId }))
                        .unwrap()
                        .catch(showBoundary);
                }
            }
        }
    }, [dispatch, productTemplate, showBoundary, uuid]);

    return (
        <div className={styles['app-container-no-header']}>
            <WizardLoader />

            <UnloadGuard>
                <Outlet />
            </UnloadGuard>
            <LogikGlobalErrors />
            <IdleHeartbeat />
        </div>
    );
};
