import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { updateTotalPrice } from '../../configurator/thunks/updateTotalPrice';
import { WidgetState, setMasterConfigurationId } from '../../widget/Widget.slice';
import { SerialTypeEnum } from '../../../shared/logik/constants/fields.constant';
import { PrebuiltsActionTypeEnum } from '../prebuilts.utils';
import { fetchConfigurationEventDataFromFields } from '../../../shared/logik/utils/fields.utils';

export type savePrebuiltMasterConfigurationResult = {
    masterId: string;
    configuration: Configuration;
    serial: string;
};
export const savePrebuiltMasterConfiguration = createAsyncThunk<
    savePrebuiltMasterConfigurationResult,
    void,
    {
        state: RootState;
    }
>('prebuilts/save-master-configuration', async (_, thunkAPI): Promise<savePrebuiltMasterConfigurationResult> => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const prebuiltState = state.widget;

    const uuid = prebuiltState.uuid;
    const productId = prebuiltState.productId;
    const threeKitId = prebuiltState.threeKitId;

    if (!uuid || !productId || !state.prebuilt.details?.serialNumber)
        return Promise.reject("Can't save prebuilt: invalid configuration data");

    await dispatch(updateTotalPrice());
    const configuration = await logikApiService.saveConfiguration(uuid);

    const res = await ConfiguratorAPI.saveConfiguration({
        logikId: uuid,
        productId: productId,
        product: '',
        threeKitId: threeKitId ?? '',
        data: {
            ...fetchConfigurationEventDataFromFields(prebuiltState.configurationFields, configuration.products ?? []),
            createOrder: false,
            prebuiltSerialNum: state.prebuilt.details.serialNumber,
            prebuiltIsMasterConfig: true,
            siteId: prebuiltState.siteId,
            actionType: state.prebuilt.details.masterConfigurationId
                ? PrebuiltsActionTypeEnum.PrebuiltMasterEdit
                : PrebuiltsActionTypeEnum.PrebuiltMasterNew,
            serialType: SerialTypeEnum.prebuilt,
            images: state.widget.images3D,
        },
        masterConfigurationId: prebuiltState.masterConfigurationId,
    });

    if (!res.masterConfigurationId) throw new Error('Failed to save master configuration');

    dispatch(setMasterConfigurationId(res.masterConfigurationId));

    return {
        masterId: res.masterConfigurationId,
        configuration: configuration,
        serial: state.prebuilt.details.serialNumber,
    };
});

export const addSavePrebuiltConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(savePrebuiltMasterConfiguration.pending, (state) => {
            state.saving = true;
        })
        .addCase(savePrebuiltMasterConfiguration.fulfilled, (state) => {
            state.saving = false;
        })
        .addCase(savePrebuiltMasterConfiguration.rejected, (state) => {
            state.saving = false;
        });
};
