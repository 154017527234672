import { CloudFile } from '../models/cloud-file.model';
import { FileState, FileUploadModel } from '../models/file-upload.model';
import { ServiceBase } from './service-base';

class FilesAPIImpl extends ServiceBase {
    public async upload(model: FileUploadModel): Promise<CloudFile[]> {
        const formData = new FormData();
        model.files.forEach((f) => {
            formData.append('files', f, f.name);
        });
        formData.append('path', model.path);
        formData.append('keepFileNames', JSON.stringify(model.keepFileNames));
        formData.append('fileUploadOptions', model.fileUploadOptions);
        formData.append('isPublic', JSON.stringify(model.isPublic));
        formData.append('fileState', JSON.stringify(model.state ?? FileState.permanent));

        const v = await this.http.post<CloudFile[]>('/api/files/', formData);
        return v.data;
    }
}

export const FilesAPI = new FilesAPIImpl();
