import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { WidgetState, setUUID, setThreekitId } from '../../widget/Widget.slice';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { fetchSets } from '../../widget/thunks/basic/fetchSets';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { isAxiosError } from 'axios';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';

export type createStockRequestArgs = {
    productMasterConfigurationId: string;
    zip: string;
    allowVariants: boolean;
};
export type createStockRequestResult = {
    configuration: Configuration;
};
export const createStockRequest = createAsyncThunk<
    createStockRequestResult,
    createStockRequestArgs,
    {
        state: RootState;
    }
>(
    'stock-requests/create',
    async ({ productMasterConfigurationId, zip, allowVariants }, thunkAPI): Promise<createStockRequestResult> => {
        const pd = await ConfiguratorAPI.getConfigurationByMasterId(productMasterConfigurationId);

        try {
            const configuration = await logikApiService.copyConfiguration(pd.logikId, [
                {
                    variableName: LogikFieldsEnum.shipping_zip,
                    value: zip,
                },
                {
                    variableName: LogikFieldsEnum.prebuiltIsMasterConfig,
                    value: false,
                },
                {
                    variableName: LogikFieldsEnum.allowVariants,
                    value: allowVariants,
                },
            ]);
            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));

            await thunkAPI.dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
            const setsToFetch =
                configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [];

            await Promise.all([
                await thunkAPI.dispatch(
                    fetchSets({
                        uuid: configuration.uuid,
                        sets: setsToFetch,
                    }),
                ),
                await thunkAPI.dispatch(fetchPricingDetails(configuration.uuid)),
            ]);

            thunkAPI.dispatch(setUUID(configuration.uuid));
            thunkAPI.dispatch(setThreekitId(pd.threeKitId));

            return { configuration };
        } catch (error) {
            if (isAxiosError(error)) {
                throw new Error(error.response?.data.errorMessage);
            } else {
                throw error;
            }
        }
    },
);

export const addCreateStockRequestCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(createStockRequest.pending, (state) => {
            state.initialized = false;
            state.initializing = true;
        })
        .addCase(createStockRequest.fulfilled, (state) => {
            state.initializing = false;
            state.initialized = true;
        })
        .addCase(createStockRequest.rejected, (state) => {
            state.initializing = false;
            state.initialized = true;
        });
};
