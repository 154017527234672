import { LogEntry } from '../models/logentry';
import { LogProvider } from './logprovider';

export class ConsoleLogProvider extends LogProvider {
    public log(logEntry: LogEntry): Promise<void> {
        console.log(this.formatMessage(logEntry), ...this.getConsoleParameters(logEntry));
        return Promise.resolve();
    }

    public info(logEntry: LogEntry): Promise<void> {
        console.info(this.formatMessage(logEntry), ...this.getConsoleParameters(logEntry));
        return Promise.resolve();
    }

    public debug(logEntry: LogEntry): Promise<void> {
        console.debug(this.formatMessage(logEntry), ...this.getConsoleParameters(logEntry));

        return Promise.resolve();
    }

    public warn(logEntry: LogEntry): Promise<void> {
        console.warn(this.formatMessage(logEntry), ...this.getConsoleParameters(logEntry));

        return Promise.resolve();
    }

    public error(logEntry: LogEntry): Promise<void> {
        console.error(this.formatMessage(logEntry), ...this.getConsoleParameters(logEntry));

        return Promise.resolve();
    }

    private formatMessage(logEntry: LogEntry): string {
        return `Console Log Provider:\r\n ${logEntry.message}`;
    }

    private getConsoleParameters(logEntry: LogEntry): unknown[] {
        return [logEntry.data] ?? [];
    }
}
