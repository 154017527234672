import { ProductInfo } from './product-list';
import styles from './Product.module.scss';

export type ProductProps = {
    product: ProductInfo;
    onClick: (product: ProductInfo) => void;
};

export const Product = ({ product, onClick }: ProductProps): JSX.Element => {
    return (
        <div className={styles['product-container']} onClick={() => onClick(product)}>
            <div className={styles.product}>
                <div className={styles.image}>
                    <img src={product.image} alt={product.title} />
                </div>
                <div className={styles.title}>{product.title}</div>
            </div>
        </div>
    );
};
