import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import logger from '../../app/logger';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { useScreenChange } from '../../shared/hooks/screen';
import { Page } from '../../shared/components/layout/Page';
import { SummaryFormState, SummaryPageContent } from './SummaryPageContent';
import { ROUTE_REVIEW, ROUTE_CUSTOMER_INFO } from '../wizard/wizard.router';
import { useLogikForm } from '../../shared/logik/hooks/form.hooks';
import { useCallback } from 'react';

export function SummaryPage(): JSX.Element {
    const navigate = useNavigateExtended();
    const quoteMode = useFieldValue<boolean>(LogikFieldsEnum.quoteMode);
    const { device } = useScreenChange();
    const [form] = useLogikForm<SummaryFormState>();

    const onFinish = useCallback(async () => {
        try {
            navigate('../' + ROUTE_REVIEW, { replace: true });
        } catch (err: unknown) {
            logger.error(err);
        }
    }, [navigate]);

    return (
        <Page
            navProps={{
                onClickBack: () =>
                    navigate('../' + ROUTE_CUSTOMER_INFO, {
                        replace: true,
                    }),
                onClickNext: form.submit,
                nextButtonTitle: device === 'mobile' ? 'To Review & Payment' : 'Next to Review & Payment',
                hideNextButton: quoteMode ?? false,
                relatedForms: [],
            }}>
            <SummaryPageContent onFinish={onFinish} form={form} />
        </Page>
    );
}
