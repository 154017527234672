import { ConsoleLogProvider } from '../shared/providers/console.logprovider';
import { LogService } from '../shared/services/log-service';
import { Environment } from './environment';

let loggerProvider;
if (Environment.isDevelopment) {
    loggerProvider = new ConsoleLogProvider();
} else {
    //TODO: change to google logger for production
    loggerProvider = new ConsoleLogProvider();
}

const logger = new LogService(loggerProvider);

export default logger;
