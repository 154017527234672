import { Button, notification, Space, Steps } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logger from '../../app/logger';
import { Page } from '../../shared/components/layout/Page';
import { GLatLng } from '../../shared/google/models/GAddress';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { useScreenChange } from '../../shared/hooks/screen';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { useLogikForm } from '../../shared/logik/hooks/form.hooks';
import { FormState } from '../../shared/models/form.state.model';
import { fetchConfiguration } from '../widget/thunks/basic/fetchConfiguration';
import { setUserProfile } from '../widget/thunks/init/setUserProfile';
import { ROUTE_CONFIGURATOR, ROUTE_SUMMARY } from '../wizard/wizard.router';
import { AddressFormShipping } from './AddressFormShipping';
import { CustomInfoForm } from './CustomerInfoForm';
import styles from './CustomerPage.module.scss';
import { setSaving } from '../widget/Widget.slice';
import { AddressFormBilling } from './AddressFormBilling';
import { updateTaxRate } from '../tax/thunks/updateTaxRateThunk';
import { isError } from 'lodash';

export type CustomerFormState = FormState;

export const CustomerPage = (): JSX.Element => {
    const navigate = useNavigateExtended();
    const [customerInfoForm] = useLogikForm<CustomerFormState>();
    const [shippingAddressForm] = useLogikForm<CustomerFormState>();
    const [billingAddressForm] = useLogikForm<CustomerFormState>();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const billingSameAsDelivery = useFieldValue<boolean>(LogikFieldsEnum.billing_use_shipping);
    const { device } = useScreenChange();
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);
    const [deliveryLatLng, setDeliveryLatLng] = useState<GLatLng>();
    const [updating, setUpdating] = useState(false);

    const currentForm = useMemo(() => {
        switch (currentStep) {
            case 0:
                return customerInfoForm;
            case 1:
                return shippingAddressForm;
            case 2:
                return billingAddressForm;
            default:
                return;
        }
    }, [billingAddressForm, currentStep, customerInfoForm, shippingAddressForm]);

    const onNext = useCallback(() => {
        try {
            (currentStep === 1 ? shippingAddressForm : billingAddressForm)
                .validateFields()
                .then(async () => {
                    if (uuid) {
                        try {
                            dispatch(setSaving(true));
                            setUpdating(true);
                            await Promise.all([
                                dispatch(setUserProfile(uuid)),
                                dispatch(
                                    updateTaxRate({
                                        latLng: deliveryLatLng,
                                    }),
                                ).unwrap(),
                            ]);
                            await dispatch(fetchConfiguration(uuid)).unwrap();
                        } catch (err) {
                            notification.error({
                                message: isError(err) ? err.message : 'Unknown error occurred',
                                duration: 5,
                                placement: 'bottomLeft',
                            });
                        } finally {
                            setUpdating(false);
                            dispatch(setSaving(false));
                        }
                    }
                    navigate('../' + ROUTE_SUMMARY, { replace: true });
                })
                .catch((errorInfo: ValidateErrorEntity<CustomerFormState>) => {
                    billingAddressForm.scrollToField(errorInfo.errorFields[0].name[0]);
                });
        } catch (err: unknown) {
            logger.error(err);
        }
    }, [billingAddressForm, currentStep, deliveryLatLng, dispatch, navigate, shippingAddressForm, uuid]);

    const onNextStep = useCallback(() => {
        if (currentForm) {
            currentForm
                .validateFields()
                .then(() => setCurrentStep(currentStep + 1))
                .catch((errorInfo: ValidateErrorEntity<CustomerFormState>) => {
                    currentForm?.scrollToField(errorInfo.errorFields[0].name[0]);
                });
        }
    }, [currentForm, currentStep]);

    return (
        <Page
            navProps={{
                onClickBack: () => navigate('../' + ROUTE_CONFIGURATOR, { replace: true }),
                onClickNext: onNext,
                disableNext: currentStep === 0 || (currentStep === 1 && !billingSameAsDelivery) || updating,
                nextButtonTitle: device === 'mobile' ? 'To Purchase Opt.' : 'Next to Purchase Options',
                relatedForms: currentForm ? [currentForm] : [],
            }}>
            <div className={styles['customer-page-content']}>
                <Steps
                    size={device === 'mobile' ? 'small' : 'default'}
                    items={[
                        {
                            title: 'Customer Info',
                            status: currentStep == 0 ? 'process' : 'wait',
                        },
                        {
                            title: <span style={{ whiteSpace: 'nowrap' }}>Shipping Information</span>,
                            status: currentStep === 1 ? 'process' : 'wait',
                        },
                        {
                            title: <span style={{ whiteSpace: 'nowrap' }}>Billing Information</span>,
                            status: currentStep === 2 ? 'process' : 'wait',
                        },
                    ]}
                    direction='horizontal'
                    current={currentStep}
                    labelPlacement='vertical'
                    responsive={false}></Steps>

                {currentStep === 0 && <CustomInfoForm form={customerInfoForm} device={device} />}
                {currentStep === 1 && (
                    <AddressFormShipping
                        form={shippingAddressForm}
                        device={device}
                        onSaveShippingCoordinates={setDeliveryLatLng}
                    />
                )}
                {currentStep === 2 && <AddressFormBilling form={billingAddressForm} device={device} />}

                <Space
                    size='middle'
                    align='center'
                    direction={device == 'mobile' ? 'vertical' : 'horizontal'}
                    style={{ width: '100%', justifyContent: 'center' }}>
                    {currentStep > 0 && (
                        <Button
                            onClick={() => setCurrentStep(currentStep - 1)}
                            type='default'
                            style={{
                                borderRadius: '21px',
                                boxShadow: 'none',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}>
                            Back to {currentStep === 1 ? 'Customer Info' : 'Shipping Info'}
                        </Button>
                    )}

                    {currentStep < 2 && (currentStep === 1 ? !billingSameAsDelivery : true) ? (
                        <Button onClick={onNextStep} type='primary'>
                            Next Step
                        </Button>
                    ) : (
                        <></>
                    )}
                </Space>
            </div>
        </Page>
    );
};
