/* eslint-disable @typescript-eslint/no-unused-vars */
import { App, ConfigProvider } from 'antd';
import { PropsWithChildren, useEffect, useState } from 'react';
import variables from '../../styles/variables.module.scss';
import { useScreenChange } from '../hooks/screen';

export const DefaultConfigProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const { device } = useScreenChange();
    return (
        <ConfigProvider
            componentSize={device === 'mobile' ? 'small' : 'middle'}
            theme={{
                components: {
                    Steps: {
                        colorPrimary: variables.brandingColor,
                        controlHeight: Number(variables.stepsHeight),
                        fontSize: Number(variables.stepsFontSize),
                        fontFamily: variables.stepsFontFamily,
                        colorTextLabel: variables.stepsIconTextColor,
                        colorText: variables.stepsDescriptionColor,
                        colorFillContent: variables.stepsIconColor,
                        colorTextDescription: variables.stepsDescriptionInactiveColor,
                        controlHeightSM: Number(variables.stepsHeightSm),
                        fontSizeSM: Number(variables.stepsFontSizeSm),
                        lineHeight: 1.3,
                        lineHeightSM: 1.3,
                    },
                    Button: {
                        colorPrimary: variables.buttonBackgroundColor,
                        colorPrimaryHover: variables.buttonHoverBackgroundColor,
                        colorPrimaryActive: variables.buttonHoverBackgroundColor,
                        colorBgContainerDisabled: variables.buttonColorDisabled,
                        colorTextDisabled: variables.buttonTextColor,
                        colorText: variables.buttonBackgroundColor,
                        borderRadius: Number(variables.buttonBorderRadius),
                        controlHeight: Number(variables.buttonHeight),
                        fontSize: Number(variables.buttonFontSize),
                        fontFamily: variables.buttonFontFamily,
                        paddingContentHorizontal: Number(variables.buttonPaddingHorizontal),
                        controlHeightSM: Number(variables.buttonHeightSm),
                        fontSizeSM: Number(variables.buttonFontSizeSm),
                        borderRadiusSM: Number(variables.buttonBorderRadiusSm),
                        paddingContentHorizontalSM: Number(variables.buttonPaddingHorizontalSm),
                        lineHeightSM: Number(variables.buttonLineHeightSm),
                    },
                    Switch: {
                        colorPrimary: variables.switchOnColor,
                        colorBgBase: variables.switchOffColor,
                        controlHeight: Number(variables.switchHeight),
                        controlHeightSM: Number(variables.switchHeightSm),
                        colorPrimaryHover: '#425d76BB',
                    },
                    Form: {
                        colorTextLabel: variables.formLabelColor,
                        fontSize: Number(variables.formFontSize),
                        fontSizeSM: Number(variables.formFontSizeSm),
                        fontFamily: variables.formFontFamily,
                        controlHeight: Number(variables.formLabelHeight),
                        controlHeightSM: Number(variables.formLabelHeightSm),
                        colorText: variables.formTextColor,
                        colorError: variables.formErrorColor,
                        colorPrimary: variables.formTextColor,
                    },
                    Input: {
                        fontSize: Number(variables.formInputFontSize),
                        fontSizeSM: Number(variables.formInputFontSizeSm),
                        colorBorderSecondary: variables.formInputBorderColor,
                        borderRadius: Number(variables.formInputBorderRadius),
                        borderRadiusSM: Number(variables.formInputBorderRadiusSm),
                        controlHeight: Number(variables.formInputHeight),
                        controlHeightSM: Number(variables.formInputHeightSm),
                        colorTextPlaceholder: variables.formInputPlaceholderColor,
                        colorText: variables.formInputColor,
                        colorError: variables.formErrorColor,
                        controlPaddingHorizontalSM: Number(variables.formInputPaddingHorizontalSm),
                    },
                    Select: {
                        fontSize: Number(variables.formInputFontSize),
                        fontSizeSM: Number(variables.formInputFontSizeSm),
                        colorBorderSecondary: variables.formInputBorderColor,
                        controlHeight: Number(variables.formInputHeight),
                        controlHeightSM: Number(variables.formInputHeightSm),
                        borderRadius: Number(variables.formInputBorderRadius),
                        borderRadiusSM: Number(variables.formInputBorderRadiusSm),
                        colorTextPlaceholder: variables.formInputPlaceholderColor,
                        colorText: variables.formInputColor,
                    },
                    Radio: {
                        colorPrimary: variables.radiosColor,
                        fontSize: Number(variables.radiosFontSize),
                        colorText: variables.formInputColor,
                        fontFamily: variables.radiosFontFamily,
                    },
                    InputNumber: {
                        fontSize: Number(variables.formInputFontSize),
                        colorBorderSecondary: variables.formInputBorderColor,
                        borderRadius: Number(variables.formInputBorderRadius),
                        controlHeight: Number(variables.formInputHeight),
                        colorTextPlaceholder: variables.formInputPlaceholderColor,
                        colorText: variables.formInputColor,
                    },
                    Spin: {
                        colorPrimary: variables.textColor,
                    },
                    FloatButton: {
                        colorPrimary: variables.brandingColor,
                    },
                    Modal: {
                        colorPrimary: variables.brandingColor,
                    },
                    Slider: {
                        colorPrimary: variables.darkBlueColor,
                        handleColor: variables.lightBlueColor,
                        trackBg: variables.lightBlueColor,
                        trackHoverBg: variables.darkBlueColor,
                        handleActiveColor: variables.darkBlueColor,
                        colorPrimaryBorderHover: variables.darkBlueColor,
                        dotActiveBorderColor: variables.lightBlueColor,
                    },
                },
                token: {
                    colorError: variables.formErrorColor,
                    colorPrimaryHover: variables.hoverColor,
                    controlOutline: 'none',
                },
            }}>
            <App style={{ height: '100%' }}>{children}</App>
        </ConfigProvider>
    );
};
