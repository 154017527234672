export const formatPriceValue = (
    value: string | number | undefined,
    includeCurrencySign?: boolean,
    options?: Intl.NumberFormatOptions,
): string => {
    if (!options) options = {};
    options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
    };

    let num = Number(value ?? '0').toLocaleString('en-US', options);

    if (includeCurrencySign) {
        num = '$ ' + num;
    }

    return num;
};
