import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { updateConfiguration } from '../../widget/thunks/basic/updateConfiguration';
import { WidgetState } from '../../widget/Widget.slice';

type UpdateTotalPriceParams = {
    forceFetchBom?: boolean;
    uuid?: string;
};
export const updateTotalPrice = createAsyncThunk<
    void,
    UpdateTotalPriceParams | undefined,
    {
        state: RootState;
    }
>('logik/updateTotalPrice', async (args, thunkAPI): Promise<void> => {
    const widgetState = thunkAPI.getState().widget;
    const dispatch = thunkAPI.dispatch;

    let total = widgetState.total;
    if (!total || args?.forceFetchBom) {
        total = (await dispatch(fetchPricingDetails(widgetState.uuid)).unwrap()).total;
    }

    if (total) {
        await dispatch(
            updateConfiguration({
                uuid: args?.uuid ?? widgetState.uuid,
                updates: [
                    {
                        variableName: LogikFieldsEnum.totalPriceBeforeDiscounts,
                        value: total,
                    },
                ],
            }),
        );
    }
});

export const addUpdateTotalPriceCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder;
    // .addCase(updateTotalPrice.pending, (state) => {})
    // .addCase(updateTotalPrice.fulfilled, (state, action) => {})
    // .addCase(updateTotalPrice.rejected, (state) => {});
};
