import { User } from '@auth0/auth0-react';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { UserWithCustomClaims } from './user.model';

export interface AuthState {
    user?: User;
    authFinished: boolean;
    authToken?: string;
}

export const AuthInitialState: AuthState = {
    authFinished: false,
};

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState: AuthInitialState,
    reducers: {
        setUser: (state, value) => {
            state.user = value.payload;
        },
        setAuthFinished: (state, value) => {
            state.authFinished = value.payload;
        },
        setAuthToken: (state, value) => {
            state.authToken = value.payload;
        },
    },
});

export const { setUser, setAuthFinished, setAuthToken } = AuthSlice.actions;
export const selectUser = (state: RootState): UserWithCustomClaims | undefined =>
    state.auth.user ? new UserWithCustomClaims(state.auth.user) : undefined;
export default AuthSlice.reducer;
