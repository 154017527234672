export enum LogikFieldsEnum {
    cusName = 'CusName',
    cusEmail = 'CusEmail',
    cusCompany = 'CusCompany',
    billing_address = 'CusAddress',
    billing_city = 'CusCity',
    billing_state = 'CusState',
    billing_zip = 'CusZipCode',
    billing_country = 'CusCountry',
    billing_primary_phone = 'CusPhone1',
    billing_secondary_phone = 'CusPhone2',
    billing_use_shipping = 'ShipAddrEqualBilling',
    shipping_contact = 'ShipContact',
    shipping_address = 'ShipAddress',
    shipping_city = 'ShipCity',
    shipping_state = 'ShipState',
    shipping_zip = 'ShipZipCode',
    shipping_country = 'ShipCountry',
    shipping_primary_phone = 'ShipPhone',
    purchaseOpt = 'PurchaseOpt',
    taxExempt = 'Tax_Exempt',
    taxExemptID = 'Tax_ExemptID',
    taxExemptReason = 'Tax_ExemptReason',
    additionalCharges = 'AddCharge',
    additionalChargesAmount = 'AddChargeAmt',
    upgRushOrder = 'UPG_RushOrder',
    rtoTerm = 'RTO_Term',
    rtoXtrDown = 'RTO_AddXtrDown',
    rtoXtrDownAmt = 'RTO_AddXtrDownAmt',
    rtoDamageToProperty = 'RTO_DamageToProperty',
    rtoUse = 'RTO_Use',
    rtoSAC = 'RTO_SAC',
    rtoAutopay = 'RTO_Autopay',
    ldw = 'RTO_LDW',
    bankName = 'FinanceBank',
    financeOption = 'FinancePmtOpt',
    approvedAmount = 'FinanceAprvAmt',
    additionalDownpayment = 'FinanceXtrDownAmt',
    includePop = 'IncludePOP',
    discReferral = 'Disc_Referral',
    discReferralName = 'Disc_ReferralName',
    discReferralOrder = 'Disc_ReferralOrder',
    handWrittenOption = 'HandWrittenOption',
    quoteMode = 'QuoteMode',
    cusPhone3 = 'CusPhone3',
    cusPhone = 'CusPhone',
    rtoYrsAddrs = 'RTO_YrsAddrs',
    cusPAddress = 'CusPAddress',
    cusPCity = 'CusPCity',
    cusPState = 'CusPState',
    cusPZipCode = 'CusPZipCode',
    rto2Cus = 'RTO_2Cus',
    rto2CusName = 'RTO_2CusName',
    rtoRef1Name = 'RTO_Ref1Name',
    rtoRef1Phone = 'RTO_Ref1Phone',
    rtoRef1Relation = 'RTO_Ref1Relation',
    rtoRef2Name = 'RTO_Ref2Name',
    rtoRef2Phone = 'RTO_Ref2Phone',
    rtoRef2Relation = 'RTO_Ref2Relation',
    //
    siteId = 'siteId',
    orderSubtotal = 'OrderSubtotal',
    width = 'Width',
    length = 'Length',
    wallHeight = 'WallHeight',
    shipVia = 'ShipVia',
    product = 'Product',
    market = 'Market',
    set_of_discounts = 'setOfDiscount',
    set_of_discounts_discount_type = 'Set_DiscountType',
    set_of_discounts_discount_amt = 'Set_DiscAmt',
    set_of_discounts_discount_input = 'Set_DiscInput',
    set_of_discounts_discount_id = 'Set_DiscountId',
    availableDiscountTypes = 'Disc_AvailableTypes',
    userProfile = 'UserProfile',
    salesperson = 'Salesperson',
    orderDateValue = 'orderDateValue',
    orderDateTimeUTC = 'orderDateTimeUTC',
    image3DBldg = 'image3DBldg',
    image2DPlan = 'image2DPlan',
    imageSideA = 'imageSideA',
    imageSideB = 'imageSideB',
    imageSideC = 'imageSideC',
    imageSideD = 'imageSideD',
    imageSideAD = 'imageAD',
    imageSideCB = 'imageCB',
    checkedOut = 'CheckedOut',
    downPmtDue = 'DownPmtDue',
    finalDownPmtDue = 'FinalDownPmtDue',
    totalPriceBeforeDiscounts = 'TotalPriceBeforeDiscounts',
    useDropship = 'UseDropship',
    territory = 'Territory',
    totalOfPayments = 'TotalOfPayments',
    taxStoreCode = 'Tax_StoreCode',
    taxApiRate = 'taxApiRate',
    taxApiCode = 'taxApiCode',
    dept = 'Dept',
    deptDescr = 'DeptDescr',
    warehouse = 'Warehouse',
    storeMarket = 'StoreMarket',
    prebuiltSerialNumber = 'PrebuiltSerialNumber',
    prebuiltIsMasterConfig = 'IsPrebuiltMasterConfig',
    serialType = 'SerialType',
    roofMaterial = 'RoofMaterial',
    prebuiltSiteId = 'PrebuiltSiteId',
    prebuiltMasterConfigUUID = 'PrebuiltMasterConfigUUID',
    dealId = 'DealId',
    permits = 'Permits',
    allowVariants = 'allowVariants',
    adjustDownPmtDue = 'AdjustDownPmtDue',
    adjustDownPmtDueManual = 'AdjustDownPmtDueManual',
    minDownPmtDue = 'MinDownPmtDue',
    maxDownPmtDue = 'MaxDownPmtDue',
    minDownPmtDueAmt = 'MinDownPmtDueAmt',
    maxDownPmtDueAmt = 'MaxDownPmtDueAmt',
}

export enum DiscountTypes {
    discCash = 'Disc_Cash',
    discCoupon = 'Disc_Coupon',
    discFreeUPG = 'Disc_FreeUPG',
    discMgr = 'Disc_Mgr',
    discMgrAmt = 'Disc_MgrAmt',
    discMultiUnit = 'Disc_MultiUnit',
    discPayInFull = 'Disc_PayInFull',
    discClearance = 'Disc_Clearance',
    discAdj = 'Disc_Adj',
    discPromo = 'Disc_Promo',
}

export type LogikFieldsEnumKeys = keyof typeof LogikFieldsEnum;

export enum SerialTypeEnum {
    prebuilt = 'Prebuilt',
    preowned = 'Preowned',
}

export enum RoofMaterialEnum {
    metal = 'Metal',
    shingle = 'Shingle',
}

function getLogikFieldName(localFieldName: string): string | undefined {
    return Object.entries<string>(LogikFieldsEnum).find((e) => e[0] === localFieldName)?.[1];
}

function getLogikFieldNameAndRequire(localFieldName: string): string {
    const name = getLogikFieldName(localFieldName);
    if (!name) throw new Error(`Logik field ${localFieldName} not found`);

    return name;
}

function getLocalFieldName(logikFieldName: string): string | undefined {
    return Object.entries<string>(LogikFieldsEnum).find((e) => e[1] == logikFieldName)?.[0];
}

export const LogikFields = {
    getLogikFieldName,
    getLogikFieldNameAndRequire,
    getLocalFieldName,
    fields: Object.entries<string>(LogikFieldsEnum).map((e) => {
        return { localFieldName: e[0], logikFieldName: e[1] };
    }),
};
