export type GAddress = {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    county?: string;
    country?: string;
    longCountry?: string;
    coordinates?: GLatLng;
};

export type GLatLng = {
    lat: number;
    lng: number;
};

export const formatGAddress = (address: GAddress, useLongCountry = false): string => {
    return [
        address.address,
        address.city,
        [address.state, address.zip].filter((v) => !!v).join(' '),
        useLongCountry ? address.longCountry : address.country,
    ]
        .filter((v) => !!v)
        .join(', ');
};
