import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useUserAuthenticated } from '../../../features/auth0/auth0.hooks';
import { PermissionsClaim } from '../../../features/auth0/user.model';
import { Environment } from '../../../app/environment';
import { rootLoaderResult } from '../../../app/app-router';

export type ProtectedRouteProps = PropsWithChildren<{
    permissions: string[];
    routeId: string;
}>;
export const ProtectedRoute = ({ permissions, children }: ProtectedRouteProps): ReactElement | null => {
    const user = useUserAuthenticated();
    const { siteId } = useRouteLoaderData('root') as rootLoaderResult;
    const allow = useMemo((): boolean => {
        if (!permissions || permissions.length === 0) return true;

        const permissionsClaim = user?.getCustomClaimWithNamespace<PermissionsClaim[]>(
            'permissions',
            Environment.auth0OOMSNamespace ?? '',
        );

        const allPermissionsForSite =
            permissionsClaim?.filter((up) => up.sites.includes(siteId ?? '-1')).flatMap((up) => up.operations) ?? [];

        for (const p of permissions) {
            if (!allPermissionsForSite.includes(p)) {
                return false;
            }
        }

        return true;
    }, [permissions, siteId, user]);

    return allow ? <>{children}</> : null;
};
