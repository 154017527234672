import React, { useEffect, useRef } from 'react';
import { Outlet, useAsyncValue } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { LogikGlobalErrors } from '../../shared/logik/components/LogikGlobalErrors';
import { UnloadGuard } from '../unload-guard/UnloadGuard';
import { IdleHeartbeat } from '../idle/IdleHeartbeat';
import { createBuildingPackage } from './thunks/createBuildingPackage';
import { editBuildingPackage } from './thunks/editBuildingPackage';
import styles from '../wizard/Wizard.module.scss';
import { BuildingPackageLoadResult } from './building-packages.router';
import { duplicateBuildingPackage } from './thunks/duplicateBuildingPackage';
import { setInitialWidgetState } from '../widget/Widget.slice';
import { useErrorBoundary } from 'react-error-boundary';
import { WizardLoader } from '../wizard/WizardLoader';

export const BuildingPackageWizard = (): React.JSX.Element => {
    const buildingPackage = useAsyncValue() as BuildingPackageLoadResult;

    const dispatchingInit = useRef<boolean>(false);
    const dispatch = useAppDispatch();

    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        if (!dispatchingInit.current && buildingPackage) {
            dispatchingInit.current = true;
            dispatch(
                setInitialWidgetState({
                    action: buildingPackage.action,
                    launchObjectId: buildingPackage.launchObjectId,
                }),
            );

            if (buildingPackage.action === 'new') {
                dispatch(createBuildingPackage(buildingPackage)).unwrap().catch(showBoundary);
            } else if (buildingPackage.action === 'edit') {
                if (buildingPackage.productMasterConfigurationId) {
                    dispatch(
                        editBuildingPackage({
                            productMasterConfigurationId: buildingPackage.productMasterConfigurationId,
                        }),
                    )
                        .unwrap()
                        .catch(showBoundary);
                } else {
                    showBoundary(new Error('Master config id param is empty'));
                }
            } else if (buildingPackage.action === 'copy') {
                if (buildingPackage.productMasterConfigurationId) {
                    dispatch(
                        duplicateBuildingPackage({
                            productMasterConfigurationId: buildingPackage.productMasterConfigurationId,
                        }),
                    )
                        .unwrap()
                        .catch(showBoundary);
                } else {
                    showBoundary(new Error('Master config id param is empty'));
                }
            }
        }
    }, [dispatch, buildingPackage, showBoundary]);

    return (
        <div className={styles['app-container-no-header']}>
            <WizardLoader />

            <UnloadGuard>
                <Outlet />
            </UnloadGuard>
            <LogikGlobalErrors />
            <IdleHeartbeat />
        </div>
    );
};
