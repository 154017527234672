import React from 'react';
import { PropsWithChildren } from 'react';
import { Await, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { WidgetAsyncError } from './WidgetAsyncError';
import { WidgetAsyncFallback } from './WidgetAsyncFallback';

export type WidgetAsyncLoaderProps<TData> = PropsWithChildren<{
    resolverSelector: (data: TData) => unknown;
    loader?: JSX.Element;
    routeId?: string;
}>;

export const WidgetAsyncLoader = <TData,>({
    children,
    resolverSelector: resolvedSelector,
    loader,
    routeId,
}: WidgetAsyncLoaderProps<TData>): JSX.Element => {
    const currentRouteLoaderData = useLoaderData() as TData;
    const idRouteLoaderData = useRouteLoaderData(routeId ?? '') as TData;

    return (
        <React.Suspense fallback={loader ?? <WidgetAsyncFallback />}>
            <Await
                resolve={resolvedSelector(routeId ? idRouteLoaderData : currentRouteLoaderData)}
                errorElement={<WidgetAsyncError />}>
                {children}
            </Await>
        </React.Suspense>
    );
};
