import { Button, Form, Space } from 'antd';
import { getMoneyInputValidators, MoneyInput, MoneyInputRef } from '../../../shared/antd/components/MoneyInput';
import { useCallback, useMemo, useRef } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useFieldValue } from '../../../shared/logik/hooks/field.hooks';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';

export const formatMoney = (amount: number): string =>
    Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount);

export type AdjustDownPaymentAmountModalProps = {
    onClose?: (value?: number) => void;
};

export const AdjustDownPaymentAmountModal = ({ onClose }: AdjustDownPaymentAmountModalProps): JSX.Element => {
    const moneyRef = useRef<MoneyInputRef>(null);
    const pricingDetails = useAppSelector((state) => state.widget.pricingDetails);
    const orderTotal = useMemo(() => {
        return pricingDetails.find((pd) => pd.id === 'TotalOrder')?.extended?.extPrice;
    }, [pricingDetails]);

    const minAdjAmount = useFieldValue<number>(LogikFieldsEnum.minDownPmtDueAmt);
    const maxAdjAmount = useFieldValue<number>(LogikFieldsEnum.maxDownPmtDueAmt);

    const onCalculate = useCallback(() => {
        console.log('amount', { adj: moneyRef.current?.getUnMaskedValue(), orderTotal });
        const adjStr = moneyRef.current?.getUnMaskedValue();
        const adj = !!adjStr ? Number.parseFloat(adjStr) : 0;
        const percent = orderTotal ? (adj / orderTotal) * 100 : 0;

        onClose?.(percent);
    }, [onClose, orderTotal]);

    return (
        <div
            style={{
                padding: '0px 20px 10px 20px',
                marginTop: '-25px',
                color: '#6A6A63',
                width: '500px',
            }}>
            <h2>Calculate from amount</h2>
            <Form onFinish={onCalculate} autoComplete={'off'}>
                <Form.Item
                    name={'adjDownOPaymentAmount'}
                    label={'Down Payment Amount'}
                    messageVariables={{ name: `Down Payment Amount` }}
                    rules={[
                        ...getMoneyInputValidators(moneyRef),
                        {
                            validator: () => {
                                const adjStr = moneyRef.current?.getUnMaskedValue();
                                const adj = !!adjStr ? Number.parseFloat(adjStr) : 0;

                                return minAdjAmount && maxAdjAmount && adj >= minAdjAmount && adj <= maxAdjAmount
                                    ? Promise.resolve()
                                    : Promise.reject();
                            },
                            message: `Down Payment Amount is invalid`,
                        },
                    ]}
                    labelAlign={'left'}
                    validateFirst
                    validateTrigger={'onBlur'}>
                    <MoneyInput placeholder={'Label'} ref={moneyRef} />
                </Form.Item>

                {minAdjAmount && maxAdjAmount && (
                    <p>
                        {`Down Payment Amount must be between ${formatMoney(minAdjAmount)} and ${formatMoney(
                            maxAdjAmount,
                        )}`}
                    </p>
                )}

                <Space>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' size={'small'}>
                            Ok
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType='button' onClick={() => onClose?.()} size={'small'}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </div>
    );
};
