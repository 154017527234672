import { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectField, selectFieldVisibility } from '../../store/selectors';
import { FieldUpdate } from '../models/field.model';
import { FieldMessage } from '../models/message.model';
import { getLogikFormItemBase, LogikFormItemBaseProps } from './LogikFormItemBase';
interface LogikFormItemProps extends Omit<LogikFormItemBaseProps, 'fieldType'> {}

const LogikFormItemBase = getLogikFormItemBase();
export const LogikFormItem = ({ logikName, name, fieldValueConverter, ...props }: LogikFormItemProps): JSX.Element => {
    const fieldVisibilityState = useAppSelector((state) => selectFieldVisibility(logikName)(state));
    const fieldDataType = useAppSelector((state) => selectField(logikName)(state)?.dataType);
    //const fieldName = useMemo(() => name ?? logikName, [logikName, name]);

    const getFieldUpdate = useCallback(
        (value: unknown) => {
            return {
                variableName: logikName,
                value,
            } as FieldUpdate;
        },
        [logikName],
    );
    const getMessageSelector = useCallback((message: FieldMessage) => message.field === logikName, [logikName]);

    const fullName = useMemo(() => (name ? name : [logikName]), [logikName, name]);

    return fieldVisibilityState !== 'hidden' ? (
        <LogikFormItemBase
            fieldType='field'
            name={fullName}
            fullName={fullName}
            logikName={logikName}
            logikDataType={fieldDataType}
            fieldUpdateConverter={getFieldUpdate}
            getMessageSelector={getMessageSelector}
            {...props}
        />
    ) : (
        <></>
    );
};
