import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import { updateTotalPrice } from '../../configurator/thunks/updateTotalPrice';
import { CheckoutState } from '../Checkout.slice';
import { saveConfiguration } from '../../widget/thunks/actions/saveConfiguration';
import { setActionType } from '../../widget/Widget.slice';

export const saveOrder = createAsyncThunk<
    Configuration,
    void,
    {
        state: RootState;
    }
>('orders/save', async (_, thunkAPI): Promise<Configuration> => {
    const dispatch = thunkAPI.dispatch;
    const widgetState = thunkAPI.getState().widget;

    if (!widgetState.uuid) {
        return Promise.reject('UUID is invalid');
    }

    dispatch(setActionType(widgetState.masterConfigurationId ? 'OrderEdit' : 'OrderNew'));
    await dispatch(updateTotalPrice({ forceFetchBom: true }));
    return (await dispatch(saveConfiguration()).unwrap()).configuration;
});

export const addSaveOrderCases = (
    builder: ActionReducerMapBuilder<CheckoutState>,
): ActionReducerMapBuilder<CheckoutState> => {
    return builder
        .addCase(saveOrder.pending, (state) => {
            state.orderProcessing = true;
        })
        .addCase(saveOrder.fulfilled, (state) => {
            state.orderProcessing = false;
        })
        .addCase(saveOrder.rejected, (state) => {
            state.orderProcessing = false;
        });
};
