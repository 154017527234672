import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { BomDetails } from '../../../../shared/logik/models/bom.detals.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { setPricingDetails, setSubTotal, setTotal, WidgetState } from '../../Widget.slice';
import { updateTotalPrice } from '../../../configurator/thunks/updateTotalPrice';

export const fetchPricingDetails = createAsyncThunk<
    BomDetails,
    string | undefined | void,
    {
        state: RootState;
    }
>('configuration/fetchPricingDetails', async (uuid, thunkAPI): Promise<BomDetails> => {
    const widgetState = thunkAPI.getState().widget;
    const dispatch = thunkAPI.dispatch;
    if (!uuid) uuid = widgetState.uuid;
    if (!uuid) return Promise.reject('Invalid UUID');
    const oldTotal = widgetState.total;

    const res = await logikApiService.fetchBOMSales(uuid);
    dispatch(setSubTotal(res.products.find((p) => p.id === 'Subtotal')?.extended?.extPrice));
    dispatch(setTotal(res.total));
    dispatch(setPricingDetails(res.products));
    if (res.total !== oldTotal) await dispatch(updateTotalPrice({ uuid }));

    return res;
});

export const addFetchPricingDetailsCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(fetchPricingDetails.pending, (state) => {
            state.pricingUpdating = true;
        })
        .addCase(fetchPricingDetails.fulfilled, (state) => {
            state.pricingUpdating = false;
        })
        .addCase(fetchPricingDetails.rejected, (state) => {
            state.pricingUpdating = false;
        });
};
