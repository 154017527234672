import { ServiceBase } from '../../shared/services/service-base';
import { StockRequest } from './stock-requests.model';

class StockRequestsAPIImpl extends ServiceBase {
    constructor() {
        super();
        this.http.defaults.timeout = 10000000;
    }
    async get(site: string, id: string): Promise<StockRequest> {
        const prebuilt = await this.http.get<StockRequest>(`/api/pm/${site}/stock-requests/${id}`);
        return prebuilt.data;
    }
}

export const StockRequestsAPI = new StockRequestsAPIImpl();
