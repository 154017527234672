import { useRef } from 'react';
import { getMoneyInputValidators, MoneyInput, MoneyInputRef } from '../../../shared/antd/components/MoneyInput';
import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikRadioGroup } from '../../../shared/logik/components/LogikRadioGroup';
import { LogikSelect } from '../../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { LogikSwitch } from '../../../shared/logik/components/LogikSwitch';
import { LogikTextArea } from '../../../shared/logik/components/LogikTextArea';

export function SummaryRTO(): JSX.Element {
    const moneyRef = useRef<MoneyInputRef>(null);

    return (
        <>
            <LogikFormItem
                logikName={LogikFieldsEnum.rtoTerm}
                label='Term'
                rules={[
                    {
                        required: true,
                        message: 'RTO Term is required',
                    },
                ]}>
                <LogikSelect fieldName={LogikFieldsEnum.rtoTerm} showSearch searchByLabel placeholder='Select Term' />
            </LogikFormItem>

            <LogikFormItem
                label='Extra Payment Deposit'
                direction='horizontal'
                logikName={LogikFieldsEnum.rtoXtrDown}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                logikName={LogikFieldsEnum.rtoDamageToProperty}
                label={
                    <span>
                        Damage to Rental
                        <br />
                        Property
                    </span>
                }
                direction='horizontal'
                withDebounce>
                <LogikTextArea />
            </LogikFormItem>

            <LogikFormItem
                label='Amount'
                logikName={LogikFieldsEnum.rtoXtrDownAmt}
                withDebounce={true}
                rules={getMoneyInputValidators(moneyRef)}>
                <MoneyInput placeholder='Enter Amount' ref={moneyRef} />
            </LogikFormItem>

            {/* <LogikFormItem
                label='Damage to Rental Property'
                logikName={LogikFieldsEnum.rtoDamageToProperty}
                rules={[
                    {
                        required: true,
                        message: 'Select damage to rental property',
                    },
                ]}
                withDebounce={true}>
                <LogikInput.TextArea />
            </LogikFormItem> */}

            <LogikFormItem
                label='Use Purpose'
                labelCol={{ span: 'auto' }}
                direction='horizontal'
                logikName={LogikFieldsEnum.rtoUse}
                rules={[
                    {
                        required: true,
                        message: 'RTO Use is required',
                    },
                ]}
                hasFeedback={false}>
                <LogikRadioGroup fieldName={LogikFieldsEnum.rtoUse} style={{ whiteSpace: 'nowrap' }} />
            </LogikFormItem>

            <LogikFormItem
                label='Same as Cash Voucher'
                direction='horizontal'
                logikName={LogikFieldsEnum.rtoSAC}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label='Auto-Pay'
                direction='horizontal'
                logikName={LogikFieldsEnum.rtoAutopay}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>
        </>
    );
}
