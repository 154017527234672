import { FormInstance } from 'antd';
import { DeviceType } from '../../shared/hooks/screen';
import { LogikForm } from '../../shared/logik/components/LogikForm';
import { LogikFormItem } from '../../shared/logik/components/LogikFormItem';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { CustomerFormState } from './CustomerPage';
import { LogikInput } from '../../shared/logik/components/LogikInput';

type CustomInfoFormProps = {
    form: FormInstance<CustomerFormState>;
    device: DeviceType;
};
export const CustomInfoForm = ({ form, device }: CustomInfoFormProps): JSX.Element => {
    return (
        <LogikForm
            name='customer-form'
            form={form}
            layout={device === 'mobile' || device == 'tablet' ? 'vertical' : 'horizontal'}
            labelCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 8 }}
            wrapperCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 16 }}
            labelAlign='left'
            requiredMark={true}
            autoComplete='on'>
            <LogikFormItem
                logikName={LogikFieldsEnum.cusName}
                label='Customer Name'
                rules={[
                    {
                        required: true,
                        message: 'Customer name is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter your name' autoFocus autoComplete='name' />
            </LogikFormItem>

            <LogikFormItem
                logikName={LogikFieldsEnum.cusEmail}
                label='Email address'
                rules={[
                    {
                        required: true,
                        message: 'Email is required',
                    },
                    {
                        type: 'email',
                        message: 'Email is has incorrect format',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter your email' autoComplete='email' />
            </LogikFormItem>

            <LogikFormItem logikName={LogikFieldsEnum.cusCompany} label='Company Name' withDebounce={true}>
                <LogikInput autoComplete='organization' placeholder='Optional Company Name' />
            </LogikFormItem>
        </LogikForm>
    );
};
