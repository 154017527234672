import { Button, Form } from 'antd';
import { useCallback } from 'react';
import { moneyToNumber } from '../../shared/antd/components/MoneyInput';
import { PaymentFormProps } from './payment-form.props';
import { PaymentAmountInput } from './PaymentAmountInput';
import { PaymentCommentInput } from './PaymentCommentInput';
import { PaymentType } from './payments.model';
import { ReceiptUpload, getFilesFromUploads } from './ReceiptUpload';
import { UploadFile } from 'antd/es/upload';
import { MakePaymentArgs } from './thunk/makePayment';
import { setOrderProcessing } from './Checkout.slice';
import { useAppDispatch } from '../../app/hooks';

type WirePaymentFormState = {
    amount: string;
    comment: string;
    receipts: UploadFile[];
};

export type WirePaymentProps = PaymentFormProps;
export const WirePaymentForm = ({ onCancel, onFinish, amountDue, processing }: WirePaymentProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<WirePaymentFormState>();

    const submitPayment = useCallback(
        async (formData: WirePaymentFormState) => {
            dispatch(setOrderProcessing(true));

            const payload = {
                amount: moneyToNumber(formData.amount),
                comment: formData.comment ?? '',
                paymentType: PaymentType.Wire,
                attachments: getFilesFromUploads(formData.receipts),
            } as MakePaymentArgs;

            onFinish(payload);
        },
        [dispatch, onFinish],
    );

    return (
        <Form
            form={form}
            labelAlign='left'
            layout='vertical'
            requiredMark={true}
            onFinish={submitPayment}
            colon={false}>
            <ReceiptUpload />
            <PaymentAmountInput defaultValue={amountDue} />
            <PaymentCommentInput />

            <Button type='primary' htmlType='submit' loading={processing} disabled={processing}>
                Confirm Order
            </Button>
        </Form>
    );
};
