import { isAxiosError } from 'axios';
import { defer, LoaderFunction } from 'react-router-dom';
import { PrebuiltsAPI } from './prebuilts.service';

export const loadPrebuiltById: LoaderFunction = async ({ params }) => {
    const siteId = params['siteId'];
    const id = params['id'];

    if (!siteId || !id) throw new Response('Not Found', { status: 404 });

    const prebuilt = PrebuiltsAPI.getPrebuiltById(siteId, id);
    return defer({
        prebuilt,
    });
};

export const loadPrebuiltBySerial: LoaderFunction = async ({ params, request }) => {
    const siteId = params['siteId'];
    const serial = params['serial'];
    if (!siteId || !serial) throw new Response('Not Found', { status: 404 });

    const prebuilt = PrebuiltsAPI.getPrebuiltBySerial(siteId, serial)
        .then((p) => {
            if (!p) throw new Error(`Prebuilt with serial number '${serial}' was not found`);
            return p;
        })
        .catch((r) => {
            if (isAxiosError(r)) {
                if (r.response?.status === 404)
                    throw new Response(`Prebuilt with serial number '${serial}' was not found`, { status: 404 });
            }
            return r;
        });

    return defer({
        prebuilt,
    });
};
