import { saveThreekit } from '@ulrichlifestyle/configurator';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { isString } from 'lodash';
import { Environment } from '../../../app/environment';
import logger from '../../../app/logger';
import { RootState } from '../../../app/store';
import { setThreekitId, WidgetState } from '../../widget/Widget.slice';

export const saveThreeKitConfiguration = createAsyncThunk<
    string | undefined,
    void,
    {
        state: RootState;
    }
>('threekit/save', async (_, thunkAPI): Promise<string | undefined> => {
    try {
        const shortId = await saveThreekit({
            authToken: Environment.threekitApiToken,
            orgId: Environment.threekitOrgId,
            threekitUrl: Environment.threekitUrl,
        });

        if (isString(shortId)) {
            thunkAPI.dispatch(setThreekitId(shortId));
            return Promise.resolve(shortId);
        } else {
            return Promise.resolve(undefined);
        }
    } catch (err) {
        logger.warn(err);
        return Promise.resolve(undefined);
    }
});

export const addSaveThreeKitConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder;
    // .addCase(saveThreeKitConfiguration.pending, (state) => {})
    // .addCase(saveThreeKitConfiguration.fulfilled, (state, action) => {})
    // .addCase(saveThreeKitConfiguration.rejected, (state) => {});
};
