import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { updateTotalPrice } from '../../configurator/thunks/updateTotalPrice';
import { setMasterConfigurationId, WidgetState } from '../../widget/Widget.slice';
import { PreownedActionTypeEnum } from '../preowned.utils';
import { SerialTypeEnum } from '../../../shared/logik/constants/fields.constant';
import { fetchConfigurationEventDataFromFields } from '../../../shared/logik/utils/fields.utils';

export type savePreownedMasterConfigurationResult = {
    masterId: string;
    configuration: Configuration;
    serial: string;
};
export const savePreownedMasterConfiguration = createAsyncThunk<
    savePreownedMasterConfigurationResult,
    void,
    {
        state: RootState;
    }
>('preowned/save-master-configuration', async (_, thunkAPI): Promise<savePreownedMasterConfigurationResult> => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const widgetState = state.widget;

    const uuid = widgetState.uuid;
    const productId = widgetState.productId;
    const threeKitId = widgetState.threeKitId;

    if (!uuid || !productId || !state.preowned.details?.serialNumber)
        return Promise.reject("Can't save preowned: invalid configuration data");

    await dispatch(updateTotalPrice());
    const configuration = await logikApiService.saveConfiguration(uuid);

    const res = await ConfiguratorAPI.saveConfiguration({
        logikId: uuid,
        productId: productId,
        product: '',
        threeKitId: threeKitId ?? '',
        data: {
            ...fetchConfigurationEventDataFromFields(widgetState.configurationFields, configuration.products ?? []),
            createOrder: false,
            prebuiltSerialNum: state.preowned.details.serialNumber,
            prebuiltIsMasterConfig: true,
            siteId: widgetState.siteId,
            actionType: state.preowned.details.masterConfigurationId
                ? PreownedActionTypeEnum.PreownedMasterEdit
                : PreownedActionTypeEnum.PreownedMasterNew,
            serialType: SerialTypeEnum.preowned,
            images: state.widget.images3D,
        },
        masterConfigurationId: widgetState.masterConfigurationId,
    });

    if (!res.masterConfigurationId) throw new Error('Failed to save master configuration');

    dispatch(setMasterConfigurationId(res.masterConfigurationId));

    return {
        masterId: res.masterConfigurationId,
        configuration: configuration,
        serial: state.preowned.details.serialNumber,
    };
});

export const addSavePreownedConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(savePreownedMasterConfiguration.pending, (state) => {
            state.saving = true;
        })
        .addCase(savePreownedMasterConfiguration.fulfilled, (state) => {
            state.saving = false;
        })
        .addCase(savePreownedMasterConfiguration.rejected, (state) => {
            state.saving = false;
        });
};
