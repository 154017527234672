import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { DataSet } from '../../../shared/models/data-set.model';
import { CheckoutState, setPayments } from '../Checkout.slice';
import { PaymentsAPI } from '../payments.api';
import { Payment } from '../payments.model';

export const listPayments = createAsyncThunk<
    DataSet<Payment>,
    string,
    {
        state: RootState;
    }
>('payments/list', async (masterId, thunkAPI): Promise<DataSet<Payment>> => {
    const payments = await PaymentsAPI.listPayments(masterId);
    thunkAPI.dispatch(setPayments(payments.data));

    return payments;
});

export const addListPaymentsCases = (
    builder: ActionReducerMapBuilder<CheckoutState>,
): ActionReducerMapBuilder<CheckoutState> => {
    return builder;
    //.addCase(makePayment.pending, (state) => {})
    //.addCase(makePayment.fulfilled, (state, action) => {})
    //.addCase(makePayment.rejected, (state) => {});
};
