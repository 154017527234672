import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { GLatLng } from '../../shared/google/models/GAddress';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { FieldUpdate } from '../../shared/logik/models/field.model';
import { TaxCodeRate } from '../../shared/models/tax-code-rate.model';
import { TaxRatesService } from '../../shared/services/tax-rates.service';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';

export const useTaxUpdateCallback = (): ((latLng?: GLatLng) => Promise<void>) => {
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);
    const shippingZip = useFieldValue<string>(LogikFieldsEnum.shipping_zip);
    const shippingAddress = useFieldValue<string>(LogikFieldsEnum.shipping_address);
    const shippingCity = useFieldValue<string>(LogikFieldsEnum.shipping_city);
    const siteId = useFieldValue<string>(LogikFieldsEnum.siteId);
    const store = useAppSelector((state) => state.widget.store);
    const isCA = siteId === 'MFGCA';
    const useAddress = isCA || !store;
    const useStoreId = !isCA && !!store;

    const updateTaxRate = useCallback(
        async (rate: TaxCodeRate, updateUsingStore: boolean) => {
            const updates = [] as FieldUpdate[];

            if (updateUsingStore) {
                updates.push({
                    variableName: LogikFieldsEnum.taxStoreCode,
                    value: rate.taxCode,
                });
            } else {
                updates.push({
                    variableName: LogikFieldsEnum.taxApiCode,
                    value: rate.taxCode,
                });

                updates.push({
                    variableName: LogikFieldsEnum.taxApiRate,
                    value: rate.taxRate,
                });
            }

            await dispatch(
                updateConfiguration({
                    uuid,
                    updates,
                    skipRelatedUpdates: true,
                }),
            );
        },
        [dispatch, uuid],
    );

    return useCallback(
        async (latLng?: GLatLng) => {
            if (siteId) {
                if (useStoreId) {
                    const r = await TaxRatesService.getByStoreId(siteId, store.id);
                    await updateTaxRate(r, true);
                } else if (useAddress) {
                    if (shippingAddress && shippingCity && shippingZip) {
                        let r;
                        if (shippingAddress.match(/-?\d+\.\d+,-?\d+\.\d+/g)) {
                            const parts = shippingAddress.split(',');

                            r = await TaxRatesService.getByLatLng(siteId, parseFloat(parts[0]), parseFloat(parts[1]));
                        } else {
                            r = await TaxRatesService.getByAddress(
                                siteId,
                                shippingAddress,
                                shippingCity,
                                shippingZip,
                                latLng,
                            );
                        }
                        await updateTaxRate(r, false);
                    }
                }
            }
        },
        [shippingAddress, shippingCity, shippingZip, siteId, store?.id, updateTaxRate, useAddress, useStoreId],
    );
};
