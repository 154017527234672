import { Button, Form, UploadFile } from 'antd';
import { useCallback, useRef } from 'react';
import { MaskedInput, MaskedInputRef } from '../../shared/antd/components/MaskedInput';
import { moneyToNumber } from '../../shared/antd/components/MoneyInput';
import { PaymentFormProps } from './payment-form.props';
import { PaymentAmountInput } from './PaymentAmountInput';
import { PaymentCommentInput } from './PaymentCommentInput';
import { PaymentType } from './payments.model';
import { ReceiptUpload, getFilesFromUploads } from './ReceiptUpload';
import { MakePaymentArgs } from './thunk/makePayment';
import { setOrderProcessing } from './Checkout.slice';
import { useAppDispatch } from '../../app/hooks';

export interface CheckPaymentFormState {
    check_number: string;
    amount: string;
    comment: string;
    receipts: UploadFile[];
}

export type CheckPaymentProps = PaymentFormProps;
export const CheckPaymentForm = ({ onCancel, onFinish, amountDue, processing }: CheckPaymentProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const checkNumberRef = useRef<MaskedInputRef>(null);

    const [form] = Form.useForm<CheckPaymentFormState>();

    const submitPayment = useCallback(
        async (formData: CheckPaymentFormState) => {
            dispatch(setOrderProcessing(true));

            const payload = {
                amount: moneyToNumber(formData.amount),
                comment: formData.comment ?? '',
                paymentType: PaymentType.Check,
                checkNumber: formData.check_number,
                attachments: getFilesFromUploads(formData.receipts),
            } as unknown as MakePaymentArgs;

            onFinish(payload);
        },
        [dispatch, onFinish],
    );

    return (
        <Form
            form={form}
            labelAlign='left'
            layout='vertical'
            requiredMark={true}
            onFinish={submitPayment}
            colon={false}>
            <ReceiptUpload />
            <Form.Item
                label='Check number'
                name='check_number'
                rules={[
                    {
                        required: true,
                        message: 'Check number is required',
                    },
                    {
                        validator() {
                            const value = checkNumberRef.current?.getUnMaskedValue();
                            if (value) {
                                const int = Number.parseInt(value);
                                return int >= 0 && int <= 2147483647 ? Promise.resolve() : Promise.reject();
                            } else {
                                return Promise.resolve();
                            }
                        },
                        message: 'Check number is incorrect',
                    },
                ]}
                validateFirst>
                <MaskedInput
                    placeholder='Enter Check Number'
                    maskOptions={{ mask: '0000000000' }}
                    ref={checkNumberRef}
                />
            </Form.Item>

            <PaymentAmountInput defaultValue={amountDue} />
            <PaymentCommentInput />

            <Button htmlType='submit' type='primary' loading={processing} disabled={processing}>
                Confirm Order
            </Button>
        </Form>
    );
};
