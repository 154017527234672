import { PropsWithChildren, ReactElement } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../auth0/Auth0.slice';
import { userHasPermissions, userHasRoles } from '../auth0/auth0.utils';

export type AdminSectionProps = PropsWithChildren<{
    permissions?: string[];
    role?: string;
    siteId?: string;
}>;
export const AdminSection = ({ children, permissions, siteId, role }: AdminSectionProps): ReactElement | null => {
    const user = useAppSelector(selectUser);
    const authFinished = useAppSelector((state) => state.auth.authFinished);

    if (
        !authFinished ||
        !user ||
        (authFinished && siteId && role && !userHasRoles(user, siteId, role)) ||
        (authFinished && siteId && permissions && !userHasPermissions(user, siteId, permissions))
    )
        return null;
    return <>{children}</>;
};
