import { FormListOperation, Form } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOption, LogikSelect } from '../../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { useFieldSet, useFieldValue } from '../../../shared/logik/hooks/field.hooks';
import { LogikTooltip } from '../../../shared/logik/components/LogikTooltip';
import { useFieldMessages } from '../../../shared/logik/hooks/messages.hooks';
import { LogikFormItemErrors } from '../../../shared/logik/components/LogikFormItemErrors';
import { LogikFormItemWarnings } from '../../../shared/logik/components/LogikFormItemWarnings';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

export type AddDiscountButtonProps = FormListOperation['add'];

const AddDiscountButtonImpl = ({ add }: { add: FormListOperation['add'] }): JSX.Element => {
    const [selectedValue, setSelectedValue] = useState(null);
    const set = useFieldSet(LogikFieldsEnum.set_of_discounts);
    const messages = useFieldMessages(LogikFieldsEnum.availableDiscountTypes);
    const errors = useMemo(() => messages.filter((m) => m.error).map((m) => m.message), [messages]);
    const warnings = useMemo(
        () => messages.filter((m) => (m.type === 'info' || m.type === 'warning') && !m.error).map((m) => m.message),
        [messages],
    );
    const form = useFormInstance();
    const userProfile = useFieldValue<string>(LogikFieldsEnum.userProfile);

    useEffect(() => {
        form.validateFields([[LogikFieldsEnum.set_of_discounts, LogikFieldsEnum.availableDiscountTypes]]);
    }, [form, errors]);

    const addNewDiscount = (value: string, option: SelectOption, add: FormListOperation['add']): void => {
        add({
            [LogikFieldsEnum.set_of_discounts_discount_type]: option.data.value,
        });
        setSelectedValue(null);
    };

    const filter = useCallback(
        (option: SelectOption): boolean => {
            const alreadySelected = set?.flatMap((s) => s.fields[LogikFieldsEnum.set_of_discounts_discount_type].value);
            return (
                (option.data.extended?.allowType === 'Multiple' ||
                    !alreadySelected?.includes(option.value as string)) &&
                option.data.extended?.[`${userProfile}_HideOption`] !== 'TRUE'
            );
        },
        [set, userProfile],
    );

    return (
        <>
            <Form.Item
                label={
                    <>
                        {'Add Discount'}
                        <LogikTooltip fieldName={LogikFieldsEnum.availableDiscountTypes} />
                    </>
                }
                labelCol={{ span: 8 }}
                name={LogikFieldsEnum.availableDiscountTypes}
                extra={''}
                help={''}
                rules={[
                    {
                        validator: () => {
                            return errors && errors.length > 0 ? Promise.reject(errors) : Promise.resolve();
                        },
                    },
                ]}>
                <LogikSelect
                    value={selectedValue}
                    placeholder='+ Add Discount'
                    fieldName={LogikFieldsEnum.availableDiscountTypes}
                    onSelect={(val: string, opt: SelectOption) => addNewDiscount(val, opt, add)}
                    filter={filter}
                    showSearch
                    searchByLabel
                />
            </Form.Item>
            <LogikFormItemErrors errors={errors} />
            <LogikFormItemWarnings warnings={warnings} />
        </>
    );
};

export const AddDiscountButton = React.memo(AddDiscountButtonImpl);
