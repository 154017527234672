export const LogikFormItemWarnings = ({ warnings }: { warnings: React.ReactNode[] }): JSX.Element => {
    return (
        <>
            {warnings.map((w, i) => (
                <div className='antd-custom-form-item-info' key={i}>
                    {w}
                </div>
            ))}
        </>
    );
};
