import { Button, Divider } from 'antd';
import { usePrintablePricingDetailsAsTree } from '../../shared/logik/hooks/pricing.hooks';
import { PricingRows } from '../pricing/PricingRows';
import styles from './PricingModal.module.scss';
import { PrinterOutlined } from '@ant-design/icons';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
export type PricingModalProps = {
    image3DBldg?: string;
    image2DPlan?: string;
};
export const PricingModal = ({ image3DBldg, image2DPlan }: PricingModalProps): JSX.Element => {
    const pricingDetails = usePrintablePricingDetailsAsTree();
    const zipCode = useFieldValue<string>(LogikFieldsEnum.shipping_zip);

    return (
        <div className={styles['pricing-modal-container']}>
            <div className={styles['title']}>
                <div>Price Details</div>
                <div>
                    <Button icon={<PrinterOutlined />} type='default' onClick={() => window.print()}>
                        Print
                    </Button>
                </div>
            </div>
            <Divider className={styles['divider']} />
            <div className={styles['pricing-container']}>
                <PricingRows items={pricingDetails} hideDivider={false} />
            </div>
            <Divider className={styles['divider']} />
            <div className={styles['pricing-warning']}>
                This is for quoting purposes only. Prices are subject to change without notice. Price and Sales tax is
                shown for ZIP code <b>{zipCode}</b>. This information does not construe a contract with Ulrich Lifestyle
                Structures or any of its affiliates. Rent-to-Own is available on many of our products.
                <br />
                <div style={{ textAlign: 'end' }}>
                    {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                </div>
            </div>
        </div>
    );
};
