import { isArray } from 'lodash';
import { generatePath } from 'react-router-dom';

export const absolutePath = (path: string): string => (path.startsWith('/') ? path : `/${path}`);
export type BuildPathOptions = {
    absolute?: boolean;
};
export const buildPath = (
    path: string | string[],
    options?: BuildPathOptions,
    params?: Record<string, unknown>,
): string => {
    let p = isArray(path) ? path.map((p) => p.replace(/^\/|\/$/g, '')).join('/') : path;

    if (options?.absolute) p = absolutePath(p);

    if (params) p = generatePath(p, params);

    return p;
};
