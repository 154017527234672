import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import logger from '../../../app/logger';
import { RootState } from '../../../app/store';
import miniPlayerService, { MiniPlayerConfiguration } from '../mini-player.service';
import { MiniPlayerState } from '../MiniPlayer.slice';

export const setMiniPlayer = createAsyncThunk<
    MiniPlayerConfiguration,
    string,
    {
        state: RootState;
    }
>('miniPlayer/load', async (shortId): Promise<MiniPlayerConfiguration> => {
    if (!shortId) {
        logger.error("Can\t load threekit configuration because 'shortId' is empty");
    }

    return miniPlayerService.getFullConfiguration(shortId);
});

export const addSetMiniPlayerCases = (builder: ActionReducerMapBuilder<MiniPlayerState>): void => {
    builder
        .addCase(setMiniPlayer.pending, (state, action) => {
            state.shortId = action.meta.arg;
            state.loading = true;
        })
        .addCase(setMiniPlayer.fulfilled, (state, action) => {
            state.configuration = action.payload;
            state.loading = false;
        })
        .addCase(setMiniPlayer.rejected, (state) => {
            state.loading = false;
        });
};
