import axios, { AxiosInstance } from 'axios';
import { Environment } from '../../app/environment';
import { auth0AxiosInterceptor } from '../interceptors/auth0.interceptor';

export abstract class ServiceBase {
    protected readonly http: AxiosInstance;
    constructor() {
        this.http = axios.create({
            baseURL: Environment.apiGatewayUrl,
            timeout: 50000,
        });
        auth0AxiosInterceptor(this.http);
    }
}
