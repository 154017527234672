import { useEffect, useRef } from 'react';
import { Outlet, useAsyncValue } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import { LogikGlobalErrors } from '../../shared/logik/components/LogikGlobalErrors';
import { UnloadGuard } from '../unload-guard/UnloadGuard';
import { IdleHeartbeat } from '../idle/IdleHeartbeat';
import { createStockRequest } from './thunks/createStockRequest';
import { editStockRequest } from './thunks/editStockRequest';
import styles from '../wizard/Wizard.module.scss';
import { StockRequestsLoadResult } from './stock-requests.router';
import { WizardLoader } from '../wizard/WizardLoader';
import { setInitialWidgetState } from '../widget/Widget.slice';

export const StockRequestWizard = (): JSX.Element => {
    const stockRequest = useAsyncValue() as StockRequestsLoadResult;

    const dispatchingInit = useRef<boolean>(false);
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);

    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (!dispatchingInit.current && stockRequest && !uuid) {
            dispatchingInit.current = true;

            if (!stockRequest.id) {
                if (!stockRequest.zip) throw new Error('Zip is missing');

                dispatch(
                    setInitialWidgetState({
                        action: 'new',
                        launchObjectId: stockRequest.launchObjectId,
                        siteId: stockRequest.site,
                    }),
                );
                dispatch(
                    createStockRequest({
                        zip: stockRequest.zip,
                        productMasterConfigurationId: stockRequest.productMasterConfigurationId,
                        allowVariants: stockRequest.allowVariants,
                    }),
                )
                    .unwrap()
                    .catch(showBoundary);
            } else {
                dispatch(
                    setInitialWidgetState({
                        action: 'edit',
                        launchObjectId: stockRequest.launchObjectId,
                    }),
                );
                dispatch(editStockRequest(stockRequest)).unwrap().catch(showBoundary);
            }
        }
    }, [dispatch, stockRequest, showBoundary, uuid]);

    return (
        <div className={styles['app-container-no-header']}>
            <WizardLoader />

            <UnloadGuard>
                <Outlet />
            </UnloadGuard>
            <LogikGlobalErrors />
            <IdleHeartbeat />
        </div>
    );
};
