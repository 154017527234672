import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { Outlet, useAsyncValue } from 'react-router-dom';
import { Environment } from '../../app/environment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LogikGlobalErrors } from '../../shared/logik/components/LogikGlobalErrors';
import { AdminOverlay } from '../admin-overlay/AdminOverlay';
import Header from '../header/Header';
import { UnloadGuard } from '../unload-guard/UnloadGuard';
import { initWidget } from '../widget/thunks/init/initWidget';
import { WidgetInitState } from '../widget/Widget.slice';
import styles from './Wizard.module.scss';
import { WizardAdminSection } from './WizardAdminSection';
import { WizardLoader } from './WizardLoader';
import { useErrorBoundary } from 'react-error-boundary';
import { WizardLoaderResult } from './wizard.router';
import { IdleHeartbeat } from '../idle/IdleHeartbeat';

export interface WizardProps {}
export function Wizard(props: PropsWithChildren<WizardProps>): JSX.Element {
    const dispatch = useAppDispatch();
    const {
        masterConfig,
        initialParams,
        prebuilt,
        dealId,
        action,
        siteId,
        actionType,
        sourceMasterConfigId,
        changedDescriptionId,
        allowVariants,
        store,
        preowned,
    } = useAsyncValue() as WizardLoaderResult;

    const startedInit = useRef<boolean>(false);
    const configurationSiteId = useAppSelector((state) => state.widget.siteId);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (startedInit.current === false) {
            startedInit.current = true;

            let src: WidgetInitState['src'];
            if (
                location.ancestorOrigins?.contains(Environment.omsOrigin) ??
                document.referrer === Environment.omsOrigin
            ) {
                src = 'oms';
            } else {
                src = 'public';
            }

            const data = masterConfig;
            if (data && data.logikId) {
                dispatch(
                    initWidget({
                        uuid: data.logikId,
                        productId: data.productId,
                        threeKitId: data.threeKitId,
                        action,
                        masterConfigurationId: data.masterConfigurationId,
                        initialConfiguration: initialParams,
                        src,
                        siteId: siteId ?? undefined,
                        actionType: actionType ?? undefined,
                        sourceMasterConfigId: sourceMasterConfigId ?? undefined,
                        changedDescriptionId: changedDescriptionId ?? undefined,
                        allowVariants,
                        store,
                    }),
                )
                    .unwrap()
                    .catch(showBoundary);
            } else {
                dispatch(
                    initWidget({
                        action,
                        initialConfiguration: initialParams,
                        src,
                        productId: data?.productId,
                        prebuilt,
                        preowned,
                        threeKitId: data?.threeKitId,
                        dealId: dealId ?? undefined,
                        siteId: siteId ?? undefined,
                        actionType: actionType ?? undefined,
                        sourceMasterConfigId: sourceMasterConfigId ?? undefined,
                        changedDescriptionId: changedDescriptionId ?? undefined,
                        allowVariants,
                        store,
                    }),
                )
                    .unwrap()
                    .catch(showBoundary);
            }
        }
    }, [
        action,
        actionType,
        dealId,
        dispatch,
        initialParams,
        masterConfig,
        prebuilt,
        preowned,
        siteId,
        changedDescriptionId,
        sourceMasterConfigId,
        showBoundary,
        allowVariants,
        store,
    ]);

    const memoized = useMemo(
        () => (
            <UnloadGuard>
                <div className={styles['app-header']}>
                    <Header />
                </div>
                <div className={styles['app-container']}>
                    <Outlet />

                    <IdleHeartbeat />
                    <WizardLoader />
                    <LogikGlobalErrors />
                    <AdminOverlay siteId={siteId ?? configurationSiteId} role={'admin'}>
                        <WizardAdminSection />
                    </AdminOverlay>
                    {/* <WizardURLTrackerImpl /> */}
                </div>
            </UnloadGuard>
        ),
        [configurationSiteId, siteId],
    );

    return memoized;
}
