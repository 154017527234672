export const isResponse = (obj: unknown): obj is Response => {
    if (!obj || typeof obj !== 'object') return false;

    const ownProps = Object.getOwnPropertyNames(obj);
    const prototype = Object.getPrototypeOf(obj);
    const prototypeProps = Object.getOwnPropertyNames(prototype);
    const allProps = ownProps.concat(prototypeProps);

    return ['ok', 'status'].every((p) => allProps.includes(p));
};
