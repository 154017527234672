import { AuditModel } from '../../shared/models/audit.model';
import { IDModel } from '../../shared/models/id.model';
import { Warehouse } from '../../shared/models/warehouse.model';
import { SerialIDOStatus } from '../../shared/models/serial-ido-status.model';

export enum PrebuiltStatus {
    Available = 'available',
    ReservedForDisplay = 'reservedForDisplay',
    Sold = 'sold',
}
export interface PrebuiltImage {
    fileId: string;
    imageUrl: string;
}
export interface Prebuilt extends IDModel, AuditModel {
    serialNumber?: string;
    storeId: string;
    storeDescription?: string;
    lot?: string;
    erpStatusId?: string;
    erpStatusDescription?: string;

    statusId?: PrebuiltStatus;
    statusDescription?: string;

    erpStatus: SerialIDOStatus;

    product?: string;
    productId?: string;
    siteId?: string;

    width: number;
    length: number;
    wallHeight: number;

    warehouse: Warehouse;

    description?: string;
    masterConfigurationId?: string;

    images?: PrebuiltImage[];
    quotes?: PrebuiltQuote[];
    orders?: PrebuiltOrder[];
}

export interface PrebuiltQuote {
    masterId: string;
    quoteId?: string;
}

export interface PrebuiltOrder {
    masterId: string;
    orderNum?: string;
}
