import { Form, FormItemProps } from 'antd';
import { FormStateValue } from '../../models/form.state.model';
import { LogikFormItemErrors } from './LogikFormItemErrors';
import { LogikFormItemWarnings } from './LogikFormItemWarnings';
import React from 'react';

export type LogikComplexFormItem = FormItemProps<FormStateValue> & {
    errors?: React.ReactNode[];
    warnings?: React.ReactNode[];
};
export const LogikComplexFormItem = ({ children, errors, warnings, ...props }: LogikComplexFormItem): JSX.Element => {
    return (
        <>
            <Form.Item {...props}>{children}</Form.Item>
            <LogikFormItemErrors errors={errors ?? []} />
            <LogikFormItemWarnings warnings={warnings ?? []} />
        </>
    );
};
