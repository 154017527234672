import { defer, LoaderFunction } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { PreownedAPI } from './preowned.service';

export const loadPreownedBySerial: LoaderFunction = async ({ params, request }) => {
    const siteId = params['siteId'];
    const serial = params['serial'];
    if (!siteId || !serial) throw new Response('Not Found', { status: 404 });

    const preowned = PreownedAPI.getPreownedPublicInfoBySerial(siteId, serial)
        .then((p) => {
            if (!p) throw new Error(`Preowned with serial number '${serial}' was not found`);
            return p;
        })
        .catch((r) => {
            if (isAxiosError(r)) {
                if (r.response?.status === 404)
                    throw new Response(`Preowned with serial number '${serial}' was not found`, { status: 404 });
            }
            return r;
        });

    return defer({
        preowned,
    });
};
