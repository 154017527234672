import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';
import { ProductTemplateNewEventParams } from '../products-templates/product-templates.utils';

export enum StockRequestsActionTypeEnum {
    StockRequestNew = 'StockRequestNew',
    StockRequestEdit = 'StockRequestEdit',
}
export type StockRequestsActionType = keyof typeof StockRequestsActionTypeEnum;
export const StockRequestNewEvent = `${StockRequestsActionTypeEnum.StockRequestNew}Event`;
export const StockRequestEditEvent = `${StockRequestsActionTypeEnum.StockRequestEdit}Event`;
export type StockRequestNewEventParams = IFrameEventParams & ProductTemplateNewEventParams;

export type StockRequestEditEventParams = StockRequestNewEventParams;
