import { store } from '../../app/store';
import { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';
export const delayUntilAuthorized = (callback: LoaderFunction<unknown>): LoaderFunction<unknown> => {
    return async (args: LoaderFunctionArgs) => {
        let resolve: (v: unknown) => void;
        const waitUntilAuthorized = new Promise((res) => (resolve = res));

        const unsubscribe = store.subscribe(() => {
            if (store.getState().auth.authFinished) {
                unsubscribe();
                resolve(unsubscribe);
            }
        });

        await waitUntilAuthorized;
        return callback(args);
    };
};
