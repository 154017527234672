import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { addPayment, CheckoutState } from '../Checkout.slice';
import { PaymentsAPI } from '../payments.api';
import { CreatePayment, Payment, PaymentType, TransactionStatus, paymentIsCard } from '../payments.model';
import { saveAndRecreateConfiguration } from '../../widget/thunks/actions/saveAndRecreateConfiguration';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { FilesAPI } from '../../../shared/services/files.service';
import { FileState, FileUploadOptions } from '../../../shared/models/file-upload.model';
import { setActionType } from '../../widget/Widget.slice';

export type MakePaymentResult = {
    payment: Payment;
    total: number;
    payments: Partial<Payment>[];
    uuidBeforeUpdate: string;
};
export type MakePaymentArgs = Omit<CreatePayment, 'receipts'> & {
    attachments: File[];
};
export const makePayment = createAsyncThunk<
    MakePaymentResult,
    MakePaymentArgs,
    {
        state: RootState;
    }
>('payments/make', async (payload, thunkAPI): Promise<MakePaymentResult> => {
    const dispatch = thunkAPI.dispatch;

    let uploaded: string[] = [];

    if (payload.attachments?.length > 0) {
        try {
            uploaded = [
                ...uploaded,
                ...(
                    await FilesAPI.upload({
                        files: payload.attachments,
                        isPublic: false,
                        keepFileNames: true,
                        fileUploadOptions: FileUploadOptions.onDuplicateRename,
                        path: `cnf/${payload.masterId}/receipts`,
                        state: FileState.temporary,
                    })
                ).map((u) => u.id),
            ];
        } catch {
            return Promise.reject('Failed to upload attachments');
        }
    }

    const createPayment = {
        ...payload,
        attachments: uploaded.map((u) => ({
            fileId: u,
        })),
        receipts: undefined,
    } as CreatePayment;

    const payment = await PaymentsAPI.processPayment(createPayment);

    if (
        payment &&
        ((paymentIsCard(payment) &&
            (payment.status === TransactionStatus.Approved || payment.status === TransactionStatus.HeldForReview)) ||
            payment.type != PaymentType.Card)
    ) {
        dispatch(addPayment(payment));

        dispatch(setActionType('OrderEdit'));
        await dispatch(
            saveAndRecreateConfiguration({
                uuid: payload.logikId,
                masterConfigurationId: payload.masterId,
                updates: [
                    {
                        variableName: LogikFieldsEnum.totalOfPayments,
                        value: thunkAPI.getState().checkout.totalPaid,
                    },
                ],
            }),
        );
    } else if (!payment) {
        return Promise.reject(`Failed to process payment`);
    }

    return {
        payment,
        uuidBeforeUpdate: payload.logikId,
        total: thunkAPI.getState().checkout.totalPaid,
        payments: thunkAPI.getState().checkout.payments,
    };
});

export const addMakePaymentCases = (
    builder: ActionReducerMapBuilder<CheckoutState>,
): ActionReducerMapBuilder<CheckoutState> => {
    return builder
        .addCase(makePayment.pending, (state) => {
            state.orderProcessing = true;
        })
        .addCase(makePayment.fulfilled, (state) => {
            state.orderProcessing = false;
        })
        .addCase(makePayment.rejected, (state) => {
            state.orderProcessing = false;
        });
};
