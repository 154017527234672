import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { FieldUpdate, SetFieldUpdate } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { fetchConfigurationEventDataFromFields, fetchProduct } from '../../../../shared/logik/utils/fields.utils';
import { ConfiguratorAPI } from '../../../../shared/services/configurator.service';
import { setMasterConfigurationId, setSubTotal, setTotal, WidgetState } from '../../Widget.slice';
import { updateConfiguration } from '../basic/updateConfiguration';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { updateTotalPrice } from '../../../configurator/thunks/updateTotalPrice';

export type saveConfigurationArgs = {
    updates?: (FieldUpdate | SetFieldUpdate)[];
    silent?: boolean;
};
export type saveConfigurationResponse = {
    configuration: Configuration;
    masterId: string;
};

export const saveConfiguration = createAsyncThunk<
    saveConfigurationResponse,
    saveConfigurationArgs | undefined,
    {
        state: RootState;
    }
>('configuration/saveConfiguration', async (args, thunkAPI): Promise<saveConfigurationResponse> => {
    const dispatch = thunkAPI.dispatch;
    const widgetState = thunkAPI.getState().widget;

    if (!widgetState.uuid || !widgetState.productId || !widgetState.configurationFields)
        return Promise.reject('State is incomplete');

    if (args?.updates) {
        await thunkAPI
            .dispatch(
                updateConfiguration({
                    uuid: widgetState.uuid,
                    updates: args?.updates,
                    skipRelatedUpdates: true,
                }),
            )
            .unwrap();
    }

    await dispatch(updateTotalPrice({ forceFetchBom: true }));
    const salesman = thunkAPI.getState().auth.user?.email;
    const savedConfig = await logikApiService.saveConfiguration(widgetState.uuid);
    const subtotal = savedConfig.products?.find((p) => p.id === 'Subtotal')?.extended?.extPrice;
    dispatch(setSubTotal(subtotal));
    dispatch(setTotal(savedConfig.total));

    const master = await ConfiguratorAPI.saveConfiguration({
        logikId: widgetState.uuid,
        productId: widgetState.productId,
        threeKitId: widgetState.threeKitId ?? '',
        product: fetchProduct(widgetState.configurationFields),
        masterConfigurationId: widgetState.masterConfigurationId,
        data: {
            ...fetchConfigurationEventDataFromFields(
                widgetState.configurationFields,
                savedConfig.products ?? [],
                undefined,
                undefined,
                salesman,
                widgetState.siteId,
                widgetState.init?.dealId,
                subtotal,
                false,
                false,
                widgetState.init?.actionType,
                widgetState.init?.changedDescriptionId,
            ),
            images: widgetState.images3D,
        },
        silent: args?.silent,
        sourceMasterConfigurationId: widgetState.init?.sourceMasterConfigId,
    });

    if (!widgetState.masterConfigurationId) {
        dispatch(setMasterConfigurationId(master.masterConfigurationId));
    }

    return {
        configuration: savedConfig,
        masterId: thunkAPI.getState().widget.masterConfigurationId!,
    } as saveConfigurationResponse;
});

export const addSaveConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(saveConfiguration.pending, (state) => {
            state.saving = true;
        })
        .addCase(saveConfiguration.fulfilled, (state) => {
            state.saving = false;
        })
        .addCase(saveConfiguration.rejected, (state) => {
            state.saving = false;
        });
};
