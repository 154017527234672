import { Form, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Store } from '../../shared/models/store.model';
import { StoresService } from '../../shared/services/stores.service';
import { useAppSelector } from '../../app/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import { Environment } from '../../app/environment';

type Option = {
    label: string;
    value: string;
    store: Store;
};

export interface StoresSelectProps {
    storeSelected: (store: Store) => void;
}

export const StoresSelect = (props: StoresSelectProps): JSX.Element => {
    const siteId = useAppSelector((state) => state.widget.init?.siteId);
    const [stores, setStores] = useState<Store[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        setLoading(true);
        StoresService.fetchStores(null) // don't filter site in 3d this time
            //StoresService.fetchStores(siteId)
            .then((ds) => {
                setStores([...ds]);
            })
            .catch((err) => {
                if (Environment.isDevelopment) return;
                showBoundary(err);
            })
            .finally(() => setLoading(false));
    }, [showBoundary, siteId]);

    useEffect(() => {
        setOptions(stores.map((store) => ({ label: store.description, value: store.id, store: store })));
    }, [stores]);

    const selectStore = useCallback(
        (_: string, selected: Option) => {
            if (!!selected) {
                props.storeSelected(selected.store);
            }
        },
        [props],
    );

    const filterStores = useCallback((filter: string, selected?: object): boolean => {
        const option = selected as Option;
        return !!option ? option.label.toLowerCase().startsWith(filter.toLowerCase()) : true;
    }, []);

    return (
        <Form.Item rules={[{ required: options.length > 0 }]} name='Store'>
            <Select
                style={{ width: '100%' }}
                placeholder='Select your Store'
                onSelect={selectStore}
                options={options}
                filterOption={filterStores}
                allowClear={true}
                showSearch
                notFoundContent='No stores match search criteria.'
                loading={loading}></Select>
        </Form.Item>
    );
};
