import { TransitionPageRequest, TransitionPageResponse } from '../models/transition-page.model';
import { ServiceBase } from './service-base';

class TransitionPageServiceImpl extends ServiceBase {
    async getUrl(request: TransitionPageRequest): Promise<string> {
        return (await this.http.post<TransitionPageResponse>(`/api/oms-link`, request)).data.url;
    }
}

export const TransitionPageService = new TransitionPageServiceImpl();
