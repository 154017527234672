import { isObjectLike } from 'lodash';
import { DomainError } from '../errors/domain.error';
import { SerializedError } from '@reduxjs/toolkit';

export const isErrorLike = (value: unknown): value is Error => {
    return !!value && isObjectLike(value) && Object.getOwnPropertyNames(value).includes('message');
};

export const isDomainError = (error: unknown): error is DomainError => {
    const ownFields = Object.getOwnPropertyNames(error);

    return !!error && isObjectLike(error) && ownFields.includes('_type') && ownFields.includes('_originalError');
};

export const serializeDomainError = (error: DomainError): SerializedError => {
    return {
        name: DomainError.prototype.constructor.name,
        message: error.message,
        code: error.type,
        stack: error.originalError?.stack,
    };
};

export const isSerializedDomainError = (error: unknown): error is SerializedError => {
    const ownFields = Object.getOwnPropertyNames(error);

    return (
        !!error &&
        isObjectLike(error) &&
        ownFields.includes('name') &&
        (error as unknown as Record<string, string>)['name'] === DomainError.prototype.constructor.name
    );
};
