import { Navigate, RouteObject, defer, LoaderFunctionArgs } from 'react-router-dom';
import { ROUTE_CONFIGURATOR, ROUTE_SUMMARY } from '../wizard/wizard.router';
import { WidgetAsyncError } from '../widget/WidgetAsyncError';
import { BuildingPackageWizard } from './BuildingPackageWizard';
import { BuildingPackageSummaryPage } from './BuildingPackageSummaryPage';
import { isAxiosError } from 'axios';
import { BuildingPackagesAPI } from './building-packages.service';
import { BuildingPackageConfiguratorPage } from './BuildingPackageConfiguratorPage';
import { WidgetInitState } from '../widget/Widget.slice';
import { WidgetAsyncLoader } from '../widget/WidgetAsyncLoader';
import { RouteProtected } from '../../shared/models/route-protected.model';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { delayUntilAuthorized } from '../../shared/utils/delayUntilAuthorized';

const routes: RouteObject[] = [
    {
        index: true,
        element: <Navigate to={ROUTE_CONFIGURATOR} replace={true} />,
    },
    {
        path: ROUTE_CONFIGURATOR,
        element: <BuildingPackageConfiguratorPage />,
    },
    {
        path: ROUTE_SUMMARY,
        element: <BuildingPackageSummaryPage />,
    },
];

export type BuildingPackageLoadResult = {
    id?: string;
    launchObjectId?: string;
    productMasterConfigurationId?: string;
    productId?: string;
    action: WidgetInitState['action'];
};

export const buildingPackagesRoutes: RouteProtected = {
    path: 'building-packages',
    errorElement: <WidgetAsyncError />,
    children: [
        {
            loader: delayUntilAuthorized(async ({ params, request }: LoaderFunctionArgs): Promise<unknown> => {
                const id = params['id'];

                const url = new URL(request.url);
                const masterConfigurationId = url.searchParams.get('masterConfigurationId');
                const productId = url.searchParams.get('productId');
                if (!(masterConfigurationId || productId) && !id)
                    throw new Response('Product not found', { status: 404 });

                let action: WidgetInitState['action'];
                const launchObjectId = url.searchParams.get('launchObjectId');

                if (id) {
                    switch (params['action']) {
                        case 'edit':
                        case undefined:
                            action = 'edit';
                            break;
                        case 'duplicate':
                            action = 'copy';
                            break;
                        default:
                            throw new Response('Not Found', {
                                status: 404,
                            });
                    }

                    const bp = BuildingPackagesAPI.get(id)
                        .then((p) => {
                            if (!p) throw new Error(`Building package with id '${id}' was not found`);
                            return {
                                productMasterConfigurationId: p.masterId,
                                action: action,
                                id: p.id,
                                launchObjectId: launchObjectId ?? p.id,
                            } as BuildingPackageLoadResult;
                        })
                        .catch((r) => {
                            if (isAxiosError(r)) {
                                if (r.response?.status === 404) {
                                    return new Response(`Building package with id '${id}' was not found`, {
                                        status: 404,
                                    });
                                }
                            }

                            throw r;
                        });

                    return defer({
                        bp,
                    });
                } else {
                    action = 'new';
                    if (!launchObjectId) throw new Response('LaunchObjectId param is missing', { status: 500 });
                    return defer({
                        bp: {
                            launchObjectId: url.searchParams.get('launchObjectId'),
                            productMasterConfigurationId: masterConfigurationId,
                            productId: productId,
                            action: action,
                        } as BuildingPackageLoadResult,
                    });
                }
            }),
            shouldRevalidate: () => false,
            element: (
                <WidgetAsyncLoader<{ [key: string]: unknown }> resolverSelector={(data) => data.bp}>
                    {/*http://localhost:3000/building-packages/new?launchObjectId=1010&masterConfigurationId=e5a3d05d-2d26-401b-94c0-84ad7ed02e00&cacheDate=1700471768197*/}
                    <ErrorBoundary>
                        <BuildingPackageWizard />
                    </ErrorBoundary>
                </WidgetAsyncLoader>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={'new'} replace={true} />,
                },
                {
                    path: 'new',
                    children: [...routes],
                },
                {
                    path: ':id',
                    children: [
                        {
                            index: true,
                            element: <Navigate to={'edit'} replace={true} />,
                        },
                        {
                            path: ':action',
                            children: [...routes],
                        },
                    ],
                },
            ],
        },
    ],
};
