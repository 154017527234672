import { FormInstance } from 'antd';
import { useCallback } from 'react';
import { focusNext } from '../../utils/document.utils';

type FocusNextOnEnterFunction = (e: React.KeyboardEvent<HTMLFormElement>) => void;

export const useFocusNextOnEnter = <TFormState>(form?: FormInstance<TFormState>): FocusNextOnEnterFunction => {
    return useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            if (!form) return;
            if (e.key === 'Enter') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const formId = (form as any)['__INTERNAL__'].name;
                if (formId) {
                    const formElement = document.getElementById(formId);
                    const activeElement = e.target as HTMLElement;
                    if (activeElement.tagName === 'TEXTAREA') return;
                    e.preventDefault();

                    let elementName = activeElement.getAttribute('name') || activeElement.id;

                    if (formElement) {
                        if (elementName) {
                            elementName = elementName.replace(`${formId}_`, '');
                            const fieldInstance = form.getFieldInstance(elementName);

                            if (fieldInstance) {
                                form.validateFields([elementName])
                                    .then(() => {
                                        focusNext(formElement, activeElement);
                                    })
                                    .catch((err) => {
                                        console.log('err', err);
                                    });
                            } else {
                                focusNext(formElement, activeElement);
                            }
                        } else {
                            focusNext(formElement, activeElement);
                        }
                    }
                } else {
                }
            }
        },
        [form],
    );
};
