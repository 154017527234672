import { InputProps } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import React, { RefObject } from 'react';
import { getMaskedInputValidators, MaskedInput, MaskedInputRef } from './MaskedInput';

export const ZIP_MASK = '00000';
export type ZipCodeInputRef = MaskedInputRef & {
    validators: Rule[];
};
export type ZipCodeInputProps = InputProps & React.RefAttributes<ZipCodeInputRef>;
export const getZipCodeInputValidators = (ref: RefObject<ZipCodeInputRef>, message?: string): Rule[] => [
    ...getMaskedInputValidators(ref, 'Incorrect Zip Code'),
];
export const ZipCodeInput = React.forwardRef<Partial<ZipCodeInputRef>, ZipCodeInputProps>(function ZipCodeInput(
    { ...props }: ZipCodeInputProps,
    innerRef,
): JSX.Element {
    return <MaskedInput maskOptions={{ mask: ZIP_MASK, lazy: true }} {...props} ref={innerRef} />;
});
