import { useAppSelector } from '../../app/hooks';
import { formatPriceValue } from '../../shared/utils/numbers.utils';
import styles from './PaymentsBlock.module.scss';
// eslint-disable-next-line @typescript-eslint/ban-types
export type PaymentsBlockProps = {};
export const PaymentsBlock = ({}: PaymentsBlockProps): JSX.Element => {
    const payments = useAppSelector((state) => state.checkout.payments);

    return (
        <div className={styles['payments-block']}>
            <div className={`${styles['payments-block-row']} ${styles['payments-block-row-header']}`}>
                <div
                    className={`${styles['payments-block-item']} ${styles['payments-block-item-header']}`}
                    key='head-1'>
                    Payment Date
                </div>
                <div
                    className={`${styles['payments-block-item']} ${styles['payments-block-item-header']}`}
                    key='head-2'>
                    Method
                </div>
                <div
                    className={`${styles['payments-block-item']} ${styles['payments-block-item-header']}`}
                    key='head-3'>
                    Amount
                </div>
                <div
                    className={`${styles['payments-block-item']} ${styles['payments-block-item-header']}`}
                    key='head-4'>
                    Comment
                </div>
            </div>
            {payments.map((p, i) => (
                <div className={styles['payments-block-row']} key={i}>
                    <div className={styles['payments-block-item']} key={`item-${i * 4}`}>
                        {p.paymentDate ? new Date(p.paymentDate).toLocaleDateString() : ''}
                    </div>
                    <div className={styles['payments-block-item']} key={`item-${i * 4 + 1}`}>
                        {p.type}
                    </div>
                    <div className={styles['payments-block-item']} key={`item-${i * 4 + 2}`}>
                        {formatPriceValue(p.amount, true, {
                            minimumFractionDigits: 2,
                        })}
                    </div>
                    <div className={styles['payments-block-item']} key={`item-${i * 4 + 3}`} title={p.comment}>
                        {p.comment}
                    </div>
                </div>
            ))}
        </div>
    );
};
