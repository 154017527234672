import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import {
    setInitialWidgetState,
    setMasterConfigurationId,
    setProductId,
    setSiteId,
    setStore,
    setThreekitId,
    setUUID,
    WidgetInitState,
    WidgetState,
} from '../../Widget.slice';
import { fetchInitConfiguration } from './fetchInitConfiguration';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { editInitConfiguration } from './editInitConfiguration';
import { Prebuilt } from '../../../prebuilts/prebuilt.model';
import { setPrebuilt } from '../../../prebuilts/prebuilts.slice';
import { copyInitConfiguration } from './copyInitConfiguration';
import { Store } from '../../../../shared/models/store.model';
import { setPreowned } from '../../../preowned/preowned.slice';
import { Preowned } from '../../../preowned/preowned.model';

export const initWidget = createAsyncThunk<
    unknown,
    Partial<
        WidgetInitState & { prebuilt: Partial<Prebuilt> | null; preowned: Partial<Preowned> | null; store?: Store }
    >,
    {
        state: RootState;
    }
>('init/widget', async ({ prebuilt, preowned, store, ...args }, thunkAPI): Promise<unknown> => {
    const dispatch = thunkAPI.dispatch;
    dispatch(setInitialWidgetState(args));

    const widgetInitState = thunkAPI.getState().widget.init;

    if (widgetInitState) {
        dispatch(setThreekitId(widgetInitState.threeKitId));
        dispatch(
            setProductId(widgetInitState.productId || widgetInitState.initialConfiguration?.[LogikFieldsEnum.product]),
        );
        dispatch(setUUID(widgetInitState.uuid));
        dispatch(setPrebuilt(prebuilt));
        dispatch(setPreowned(preowned));
        dispatch(setStore(store));

        if (widgetInitState?.action !== 'copy') {
            dispatch(setMasterConfigurationId(widgetInitState.masterConfigurationId));
        }

        dispatch(setSiteId(widgetInitState.siteId));
    }

    switch (widgetInitState?.action) {
        case 'edit':
            return dispatch(editInitConfiguration()).unwrap();
        case 'new':
            return Promise.resolve();
        case 'readonly':
            return dispatch(fetchInitConfiguration()).unwrap();
        case 'restore':
            return dispatch(fetchInitConfiguration()).unwrap();
        case 'copy':
            return dispatch(copyInitConfiguration()).unwrap();
    }
});

export const addInitWidgetCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(initWidget.pending, (state) => {
            state.initialized = false;
            state.initializing = true;
        })
        .addCase(initWidget.fulfilled, (state) => {
            state.initializing = false;
            state.initialized = true;
        })
        .addCase(initWidget.rejected, (state) => {
            state.initializing = false;
            state.initialized = true;
        });
};
