import { useAppDispatch } from '../../app/hooks';
import { useCallback, useEffect, useState } from 'react';

import { ConfigProvider, Modal, notification } from 'antd';
import theme from 'antd/es/theme';
import {
    fieldsLogic,
    IInitializationProvider,
    objSnaphotType,
    ProductConfiguration,
    propertyForLogicType,
    returnObjectApi,
    returnObjectThreekit,
} from '@ulrichlifestyle/configurator';
import { Environment } from '../../app/environment';
import logger from '../../app/logger';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { PricingModal } from '../configurator/PricingModal';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { updateTotalPrice } from '../configurator/thunks/updateTotalPrice';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { setConfigurationFields, setSiteId, setThreekitId } from '../widget/Widget.slice';
import { useTaxUpdateCallback } from '../tax/tax.hooks';
import { isError } from 'lodash';
import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { setUserProfile } from '../widget/thunks/init/setUserProfile';
import { Field, getFieldValueFromArray } from '../../shared/logik/models/field.model';
import React from 'react';
import { ROUTE_SUMMARY } from '../wizard/wizard.router';
import { savePrebuiltMasterConfiguration } from './thunks/savePrebuiltMasterConfiguration';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { inIframe } from '../../shared/utils/document.utils';
import { EventBus } from '../../app/eventbus';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { saveAndUploadSnapshots } from '../configurator/thunks/snapshots';
import { useOpenPricingWindowCallback } from '../../shared/hooks/pricing-window.hooks';

const notificationDuration = 5;
const notificationPlacement: NotificationPlacement = 'bottomLeft';
const errorMessage = 'An unexpected error occurred';
const notificationKey = 'prebuilt-notification';

export const SavePrebuiltMasterConfigEvent = 'SavePrebuiltMasterConfigEvent';
export type SavePrebuiltMasterConfigEventParams = {
    serial: string;
    siteId: string;
};
export type PrebuiltConfiguratorProps = {
    configuration: Partial<Omit<IInitializationProvider, 'logicConfig'>> & Pick<IInitializationProvider, 'logicConfig'>;
};
const PrebuiltConfiguratorImpl = ({ configuration }: PrebuiltConfiguratorProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const [pricingModalHandle, setPricingModalHandle] = useState<FlexibleModalHandle<typeof PricingModal>>();
    const updateTax = useTaxUpdateCallback();
    const navigate = useNavigateExtended();
    const openPricingInExternalWindow = useOpenPricingWindowCallback();

    useEffect(() => {
        const modal = FlexibleModal.createModal(PricingModal, {
            showCloseButton: true,
            destroyOnClose: true,
            //className: styles['pricing-modal'],
        });

        setPricingModalHandle(modal);
    }, []);

    const showError = useCallback((error: Error) => {
        const message = Environment.isDevelopment ? `${error.message}.` : '';

        notification.error({
            message: errorMessage,
            description: `${message}Please try again later`,
            duration: notificationDuration,
            placement: notificationPlacement,
            key: notificationKey,
        });

        logger.error(error);
    }, []);

    const finishConfiguration = useCallback(
        async (
            uuid: string,
            conf: fieldsLogic,
            saveThreeKitConfig: () => Promise<returnObjectThreekit>,
            getSnapshots: () => Promise<objSnaphotType[]>,
        ): Promise<returnObjectApi> => {
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));
            dispatch(setConfigurationFields(conf as Field[]));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            await dispatch(updateTotalPrice({ forceFetchBom: true }));

            navigate('../' + ROUTE_SUMMARY);

            return {
                uuid: uuid,
                fields: conf,
                message: [],
                threekitId: shortId,
            };
        },
        [dispatch, navigate],
    );

    const showDetails = useCallback(async (): Promise<void> => {
        await dispatch(
            updateTotalPrice({
                forceFetchBom: true,
            }),
        );
        pricingModalHandle?.open();
    }, [dispatch, pricingModalHandle]);

    const onZipChange = useCallback(
        async (_: string, fields: propertyForLogicType[]) => {
            const siteId = fields.find((f) => f.variableName === LogikFieldsEnum.siteId);
            if (configuration && siteId) {
                dispatch(setSiteId(siteId.value));

                try {
                    dispatch(setConfigurationFields(fields as Field[]));
                    await Promise.all([
                        dispatch(setUserProfile(configuration.logicConfig.uuid)),
                        updateTax().catch(() => {
                            notification.error({
                                message: 'Error getting tax rate for your location',
                                duration: 5,
                                placement: 'bottomLeft',
                            });
                        }),
                    ]);

                    return {
                        fields,
                    };
                } catch (err) {
                    showError(isError(err) ? err : new Error(err?.toString()));
                }
            }

            return {
                fields: fields,
            };
        },
        [configuration, dispatch, showError, updateTax],
    );

    const saveMasterConfig = useCallback(
        async (
            uuid: string,
            conf: fieldsLogic,
            saveThreeKitConfig: () => Promise<returnObjectThreekit>,
            getSnapshots: () => Promise<objSnaphotType[]>,
        ) => {
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));

            dispatch(setConfigurationFields(conf as Field[]));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            await dispatch(updateTotalPrice({ forceFetchBom: true }));

            const master = await dispatch(savePrebuiltMasterConfiguration()).unwrap();

            Modal.confirm({
                title: 'Prebuilt has been successfully saved',
                content: 'Now you can close the window or go back and make some changes',
                icon: <></>,
                okText: 'Save and continue',
                cancelText: 'Save and close',
                onOk: () => dispatch(recreateConfiguration(uuid)),
                onCancel: async () => {
                    const siteId = getFieldValueFromArray<string>(conf as Field[], LogikFieldsEnum.siteId);

                    if (siteId) {
                        EventBus.dispatch<SavePrebuiltMasterConfigEventParams>(SavePrebuiltMasterConfigEvent, {
                            serial: master.serial,
                            siteId,
                        });

                        if (!inIframe()) {
                            await redirectToTransitionPage({
                                site: siteId,
                                redirectType: 'SavePrebuiltConfig',
                                serial: master.serial,
                            });
                        }
                    } else {
                        setTimeout(() => {
                            showError(new Error('SiteId is undefined'));
                        }, 0);
                    }
                },
            });

            return {
                uuid: uuid,
                fields: conf,
                message: [],
                threekitId: shortId,
            };
        },
        [dispatch, showError],
    );

    return (
        <ConfigProvider theme={theme.defaultConfig}>
            {configuration ? (
                <ConfigProvider theme={theme.defaultConfig}>
                    <ProductConfiguration
                        {...configuration}
                        initializationThreekit={{
                            threekitUrl: Environment.threekitUrl,
                            authToken: Environment.threekitApiToken,
                            assetId: Environment.threekitAssetId,
                            apiToken: `Bearer ${Environment.logikApiToken}`,
                            apiUrl: Environment.logikApiUrl,
                            orgId: Environment.threekitOrgId,
                            eighthWallApiKey: Environment.appEighthWallApiKey,
                        }}
                        onFinishConfig={finishConfiguration}
                        onError={showError}
                        onGetInfoConfiguration={showDetails}
                        onChangeZipCode={onZipChange}
                        saveButtonTitle='Save Prebuilt'
                        saveButtonCallback={saveMasterConfig}
                        goBackProductList={undefined}
                        openPricingInExternalWindow={openPricingInExternalWindow}
                    />
                </ConfigProvider>
            ) : (
                <WidgetAsyncFallback />
            )}
        </ConfigProvider>
    );
};

export const PrebuiltConfigurator = React.memo(
    PrebuiltConfiguratorImpl,
    (oldProps, newProps) => oldProps.configuration.logicConfig.uuid === newProps.configuration.logicConfig.uuid,
);
