import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { Environment } from './app/environment';
import ga4 from 'react-ga4';

if (Environment.gaTrackingId) {
    try {
        ga4.initialize(Environment.gaTrackingId);
    } catch (err) {
        console.error(err);
    }
}

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
