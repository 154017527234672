import logger from '../../app/logger';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { Page } from '../../shared/components/layout/Page';
import { SummaryPageContent, SummaryFormState } from '../summary/SummaryPageContent';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { App } from 'antd';
import { useLogikForm } from '../../shared/logik/hooks/form.hooks';
import { saveStockRequest } from './thunks/saveStockRequest';
import { publishEventAndRedirect } from '../../shared/utils/location.utils';
import {
    StockRequestEditEvent,
    StockRequestEditEventParams,
    StockRequestNewEvent,
    StockRequestNewEventParams,
} from './stock-requests.utils';
import { useCallback } from 'react';

export function StockRequestSummaryPage(): JSX.Element {
    const navigate = useNavigateExtended();
    const dispatch = useAppDispatch();
    const [form] = useLogikForm<SummaryFormState>();
    const { modal } = App.useApp();

    const launchObjectId = useAppSelector((state) => state.widget.init?.launchObjectId);
    const masterConfigurationId = useAppSelector((state) => state.widget.masterConfigurationId);
    const site = useAppSelector((state) => state.widget.siteId);

    const onFinish = useCallback(
        async (values: unknown) => {
            try {
                const res = await dispatch(saveStockRequest()).unwrap();
                modal.confirm({
                    title: 'Stock request has been successfully saved',
                    content: 'Now you can close the window or go back and make some changes',
                    icon: <></>,
                    okText: 'Save and continue',
                    cancelText: 'Save and close',
                    okButtonProps: { size: 'small' },
                    cancelButtonProps: { size: 'small' },
                    onOk: async () => await dispatch(recreateConfiguration(res.configuration.uuid)),
                    onCancel: async () => {
                        if (launchObjectId && site) {
                            if (!masterConfigurationId) {
                                await publishEventAndRedirect<StockRequestNewEventParams>(StockRequestNewEvent, {
                                    launchObjectId,
                                    site,
                                });
                            } else {
                                await publishEventAndRedirect<StockRequestEditEventParams>(StockRequestEditEvent, {
                                    launchObjectId,
                                    site,
                                });
                            }
                        }
                    },
                });
            } catch (err: unknown) {
                logger.error(err);
            }
        },
        [dispatch, launchObjectId, masterConfigurationId, modal, site],
    );

    return (
        <Page
            navProps={{
                onClickBack: () => navigate('..', { preserveSearch: true }),
                onClickNext: () => form.submit(),
                nextButtonTitle: 'Finish',
                hideSaveButton: true,
            }}>
            <SummaryPageContent onFinish={onFinish} form={form} />
        </Page>
    );
}
