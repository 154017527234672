import { Provider } from 'react-redux';
import './App.scss';
import { Environment } from './app/environment';
import { store } from './app/store';
import { Auth0 } from './features/auth0/Auth0';
import { Widget } from './features/widget/Widget';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { DefaultConfigProvider } from './shared/antd/DefaultConfigProvider';
import { EventBus } from './app/eventbus';
import { ReactElement, useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { IFrameProxy } from './features/iframe-proxy/IFrameProxy';
import { ErrorBoundary } from './features/error/ErrorBoundary';
import logger from './app/logger';
import { LogEntry } from './shared/models/logentry';

console.debug('configurator package version', process.env.REACT_APP_CONFIGURATOR_PACKAGE_VERSION);

function App(): ReactElement {
    useEffect(() => {
        if (Environment.isDevelopment) {
            const { promise, unsubscribe } = EventBus.subscribeAsync('set-initial-data');
            promise.then((ev) => {
                logger.debug(new LogEntry('message in iframe', ev));
            });
            EventBus.dispatch('TestEvent', { value: 'TestValue' });

            return () => {
                unsubscribe();
            };
        }
    }, []);

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <Auth0Provider
                    domain={Environment.auth0Domain}
                    clientId={Environment.auth0ClientId}
                    audience={Environment.auth0Audience}
                    redirectUri={window.location.origin}>
                    <Auth0>
                        <HelmetProvider>
                            {!Environment.isProduction && (
                                <Helmet>
                                    <meta name='robots' content='noindex' />
                                </Helmet>
                            )}
                            {Environment.isProduction && (
                                <Helmet
                                    script={[
                                        {
                                            type: 'text/javascript',
                                            innerHTML: `(function(c,l,a,r,i,t,y){
                                                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                                        })(window, document, "clarity", "script", "h7zhunoky8");`,
                                        },
                                    ]}></Helmet>
                            )}
                            <DefaultConfigProvider>
                                <IFrameProxy>
                                    <Widget />
                                </IFrameProxy>
                            </DefaultConfigProvider>
                        </HelmetProvider>
                    </Auth0>
                </Auth0Provider>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
