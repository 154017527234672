import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikSelect } from '../../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { LogikInput } from '../../../shared/logik/components/LogikInput';
import { LogikSwitch } from '../../../shared/logik/components/LogikSwitch';

export function SummaryNA(): JSX.Element {
    return (
        <>
            {/* <SummarySectionHeader>Additional options</SummarySectionHeader> */}
            {/* <LogikFormItem
                label='Payment Method'
                direction='vertical'
                logikName={LogikFieldsEnum.purchaseOpt}
                rules={[
                    {
                        required: true,
                        message: 'Select purchase option',
                    },
                ]}
                hasFeedback={false}>
                <LogikRadioGroup fieldName={LogikFieldsEnum.purchaseOpt} />
            </LogikFormItem> */}

            <LogikFormItem
                label='Tax Exempt'
                direction='horizontal'
                logikName={LogikFieldsEnum.taxExempt}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label='Tax ID Number'
                logikName={LogikFieldsEnum.taxExemptID}
                rules={[
                    {
                        required: true,
                        message: 'Tax ID Number is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Tax ID' />
            </LogikFormItem>

            <LogikFormItem
                label='Select Exemption Reason'
                logikName={LogikFieldsEnum.taxExemptReason}
                rules={[
                    {
                        required: true,
                        message: 'Exemption reason is required',
                    },
                ]}
                hasFeedback={false}>
                <LogikSelect fieldName={LogikFieldsEnum.taxExemptReason} placeholder='Select Reason' />
            </LogikFormItem>
        </>
    );
}
