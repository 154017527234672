import { useRef } from 'react';
import { getZipCodeInputValidators, ZipCodeInput, ZipCodeInputRef } from '../../../shared/antd/components/ZipCodeInput';
import { useScreenChange } from '../../../shared/hooks/screen';
import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikRadioGroup } from '../../../shared/logik/components/LogikRadioGroup';
import { LogikSelect } from '../../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { LogikInput } from '../../../shared/logik/components/LogikInput';
import { LogikSwitch } from '../../../shared/logik/components/LogikSwitch';

export function SummaryRTOPrimary(): JSX.Element {
    const { size } = useScreenChange();
    const labelColSpan = size === 'small' ? 12 : 10;
    const zipInputRef = useRef<ZipCodeInputRef>(null);

    return (
        <>
            <LogikFormItem
                label='Customer Name'
                logikName={LogikFieldsEnum.cusName}
                labelCol={{ span: labelColSpan }}
                rules={[
                    {
                        required: true,
                        message: 'Customer name is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Name' />
            </LogikFormItem>

            {/* <LogikFormItem
                label='Company'
                logikName={LogikFieldsEnum.cusCompany}
                rules={[
                    {
                        required: true,
                        message: 'Company is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Company' />
            </LogikFormItem> */}

            <LogikFormItem
                logikName={LogikFieldsEnum.rtoYrsAddrs}
                direction='horizontal'
                label={
                    <span style={{ whiteSpace: 'normal' }}>
                        Years at current
                        <br />
                        address
                    </span>
                }
                labelCol={{ span: labelColSpan }}
                rules={[
                    {
                        required: true,
                        message: 'Select years at current address',
                    },
                ]}
                hasFeedback={false}>
                <LogikRadioGroup fieldName={LogikFieldsEnum.rtoYrsAddrs} />
            </LogikFormItem>

            <LogikFormItem
                label='Street Address'
                labelCol={{ span: labelColSpan }}
                logikName={LogikFieldsEnum.cusPAddress}
                rules={[
                    {
                        required: true,
                        message: 'Street address is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Street Address' />
            </LogikFormItem>

            <LogikFormItem
                label='City'
                labelCol={{ span: labelColSpan }}
                logikName={LogikFieldsEnum.cusPCity}
                rules={[
                    {
                        required: true,
                        message: 'City is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter City' />
            </LogikFormItem>

            <LogikFormItem
                logikName={LogikFieldsEnum.cusPState}
                label='State'
                labelCol={{ span: labelColSpan }}
                rules={[
                    {
                        required: true,
                        message: 'State is required',
                    },
                ]}
                hasFeedback={false}>
                <LogikSelect
                    fieldName={LogikFieldsEnum.cusPState}
                    showSearch
                    searchByValueAndLabel
                    placeholder='Enter State'
                />
            </LogikFormItem>

            <LogikFormItem
                label='Zip Code'
                labelCol={{ span: labelColSpan }}
                logikName={LogikFieldsEnum.cusPZipCode}
                rules={[
                    {
                        required: true,
                        message: 'Zip Code is required',
                    },
                    ...getZipCodeInputValidators(zipInputRef),
                ]}
                updateOnBlur
                updateOnMaskComplete>
                <ZipCodeInput placeholder='Enter Zip Code' ref={zipInputRef} />
            </LogikFormItem>

            <LogikFormItem
                label='Add second Buyer'
                labelCol={{ span: labelColSpan }}
                direction='horizontal'
                logikName={LogikFieldsEnum.rto2Cus}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>
        </>
    );
}
