import { Form, Skeleton } from 'antd';
import React from 'react';
import styles from './SkeletonRow.module.scss';

const SkeletonRowImpl = (): JSX.Element => {
    return (
        <Form.Item hasFeedback={true}>
            <div className={styles['skeleton-row']}>
                {/* <Skeleton.Button active={true} size={'small'} shape={'circle'} className={styles['skeleton-btn']} />
                <Skeleton.Input active={true} size='small' block={true} /> */}
                <Skeleton paragraph={false} title={{ width: '100%' }} avatar={{ size: 'small', shape: 'circle' }} />
            </div>
        </Form.Item>
    );
};

export const SkeletonRow = React.memo(SkeletonRowImpl);
