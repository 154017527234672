import { Button, Result } from 'antd';
import { PropsWithChildren, ReactElement } from 'react';
import { Environment } from '../../app/environment';
import { LogService } from '../../shared/services/log-service';
import logger from '../../app/logger';
import { ErrorBoundary as ErrorB } from 'react-error-boundary';

type ErrorBoundaryProps = PropsWithChildren & {
    logger?: LogService;
    nonBlockingBehavior?: boolean;
};

export const ErrorBoundary = ({ children }: ErrorBoundaryProps): ReactElement => {
    return (
        <ErrorB
            FallbackComponent={({ error }) => (
                <Result
                    status='500'
                    title='500'
                    subTitle={
                        <>Sorry, something went wrong. {Environment.isDevelopment ? JSON.stringify(error) : ''}</>
                    }
                    extra={
                        <Button type='primary' href='/'>
                            Back Home
                        </Button>
                    }
                />
            )}
            onError={(error, info) => {
                error.stack = (error.stack ?? '') + info.componentStack;
                logger.error(error);
            }}>
            {children}
        </ErrorB>
    );
};
