import { DataSet } from '../../shared/models/data-set.model';
import { ServiceBase } from '../../shared/services/service-base';
import { CreatePayment, Merchant, Payment, PaymentFormSettings, PaymentFormSettingsRequest } from './payments.model';

class PaymentsAPIImpl extends ServiceBase {
    private static readonly PAYMENTS_API_URL = `/api/payments`;
    private static readonly MERCHANTS_API_URL = `/api/merchants`;

    constructor() {
        super();
        this.http.defaults.timeout = 10000000;
    }

    public async processPayment(payload: CreatePayment): Promise<Payment | null> {
        const r = await this.http.post<Payment | null>(`${PaymentsAPIImpl.PAYMENTS_API_URL}/make-payment`, payload);
        return r.data;
    }

    public async getHostedPaymentFormToken(request: PaymentFormSettingsRequest): Promise<PaymentFormSettings> {
        const r = await this.http.post<PaymentFormSettings>(
            `${PaymentsAPIImpl.PAYMENTS_API_URL}/payment-form-settings`,
            request,
        );
        return r.data;
    }

    public async getMerchantForSiteId(siteId: string): Promise<Merchant> {
        const r = await this.http.get<Merchant>(`${PaymentsAPIImpl.MERCHANTS_API_URL}/sites/${siteId}`);
        return r.data;
    }

    public async listPayments(masterId: string): Promise<DataSet<Payment>> {
        const r = await this.http.get<DataSet<Payment>>(
            `${PaymentsAPIImpl.PAYMENTS_API_URL}/configurations/master/${masterId}`,
        );
        return r.data;
    }
}

export const PaymentsAPI = new PaymentsAPIImpl();
