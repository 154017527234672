import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { WidgetState, setMasterConfigurationId, setThreekitId, setUUID } from '../../widget/Widget.slice';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { fetchSets } from '../../widget/thunks/basic/fetchSets';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { setUserProfile } from '../../widget/thunks/init/setUserProfile';

export type editStockRequestArgs = {
    productMasterConfigurationId: string;
};
export type editStockRequestResult = {
    configuration: Configuration;
};
export const editStockRequest = createAsyncThunk<
    editStockRequestResult,
    editStockRequestArgs,
    {
        state: RootState;
    }
>('stock-requests/edit', async ({ productMasterConfigurationId }, thunkAPI): Promise<editStockRequestResult> => {
    const dispatch = thunkAPI.dispatch;

    const c = await ConfiguratorAPI.getConfigurationByMasterId(productMasterConfigurationId);

    const uuid = c.logikId;

    if (uuid) {
        const configuration = await logikApiService.copyConfiguration(uuid);
        const layoutUrl = getDefaultLayout(configuration)?.url;
        if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
        await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
        await dispatch(setUserProfile(configuration.uuid));

        await Promise.all([
            dispatch(
                fetchSets({
                    uuid: configuration.uuid,
                    sets: configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
                }),
            ),
            dispatch(fetchPricingDetails(configuration.uuid)),
        ]);

        dispatch(setUUID(configuration.uuid));
        dispatch(setThreekitId(c.threeKitId));
        dispatch(setMasterConfigurationId(productMasterConfigurationId));

        return { configuration };
    } else {
        return Promise.reject('Invalid UUID');
    }
});

export const addEditStockRequestCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(editStockRequest.pending, (state) => {
            state.initialized = false;
            state.initializing = true;
        })
        .addCase(editStockRequest.fulfilled, (state) => {
            state.initializing = false;
            state.initialized = true;
        })
        .addCase(editStockRequest.rejected, (state) => {
            state.initializing = false;
            state.initialized = true;
        });
};
