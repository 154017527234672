import { ServiceBase } from '../../shared/services/service-base';
import { Preowned } from './preowned.model';

class PreownedAPIImpl extends ServiceBase {
    constructor() {
        super();
        this.http.defaults.timeout = 10000000;
    }
    async getPreownedById(siteId: string, id: string): Promise<Preowned> {
        const prebuilt = await this.http.get<Preowned>(`api/${siteId}/preowned/${id}`);
        return prebuilt.data;
    }

    async getPreownedBySerial(siteId: string, serial: string): Promise<Preowned> {
        const prebuilt = await this.http.get<Preowned>(`api/${siteId}/preowned/public/serials/${serial}`);
        return prebuilt.data;
    }

    async getPreownedPublicInfoByMasterId(masterId: string): Promise<Partial<Preowned> | null> {
        const prebuilt = await this.http.get<Partial<Preowned> | null>(`api/preowned/masters/${masterId}`);
        return prebuilt.data;
    }

    async getPreownedPublicInfoBySerial(siteId: string, serial: string): Promise<Partial<Preowned> | null> {
        const prebuilt = await this.http.get<Partial<Preowned> | null>(
            `api/${siteId}/preowned/public/serials/${serial}`,
        );
        return prebuilt.data;
    }
}

export const PreownedAPI = new PreownedAPIImpl();
