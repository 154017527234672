import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { WidgetState } from '../../Widget.slice';
import { editInitConfiguration } from './editInitConfiguration';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { updateConfiguration } from '../basic/updateConfiguration';
import { FieldUpdate } from '../../../../shared/logik/models/field.model';

export const copyInitConfiguration = createAsyncThunk<
    Configuration,
    void,
    {
        state: RootState;
    }
>(
    'init/copy',
    async (_, thunkAPI): Promise<Configuration> => {
        const state = thunkAPI.getState();
        const actionType = state.widget.init?.actionType;
        const isCR = actionType === 'NewCR' || actionType === 'EditCR';
        let updates: FieldUpdate[] = !isCR
            ? [
                  {
                      variableName: LogikFieldsEnum.checkedOut,
                      value: false,
                  },
              ]
            : [];

        if (state.widget.store) {
            updates = [
                ...updates,
                {
                    variableName: LogikFieldsEnum.dept,
                    value: state.widget.store.erpDepartment,
                },
                {
                    variableName: LogikFieldsEnum.deptDescr,
                    value: state.widget.store.description,
                },
                {
                    variableName: LogikFieldsEnum.storeMarket,
                    value: state.widget.store.state,
                },
            ];
        }

        if (state.widget.init?.allowVariants !== undefined) {
            updates = [
                {
                    variableName: LogikFieldsEnum.allowVariants,
                    value: state.widget.init.allowVariants,
                },
                {
                    variableName: LogikFieldsEnum.shipping_zip,
                    value: '',
                },
                ...updates,
            ];
        }
        const config = thunkAPI
            .dispatch(
                editInitConfiguration({
                    updates,
                }),
            )
            .unwrap();

        if (!isCR) {
            await thunkAPI.dispatch(
                updateConfiguration({
                    uuid: (await config).uuid,
                    updates: [
                        {
                            variableName: `set.${LogikFieldsEnum.set_of_discounts}.size`,
                            set: LogikFieldsEnum.set_of_discounts,
                            value: [],
                        },
                    ],
                }),
            );
        }

        return config;
    },
    {
        condition: (_, { getState }) => !!getState().widget.uuid,
    },
);

export const addCopyInitConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(copyInitConfiguration.pending, (state) => {
            state.loading = true;
        })
        .addCase(copyInitConfiguration.fulfilled, (state) => {
            state.loading = false;
        })
        .addCase(copyInitConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
