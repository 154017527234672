import { Input, InputProps, InputRef } from 'antd';
import { RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import IMask from 'imask';
import React from 'react';
import { InputFocusOptions } from 'antd/es/input/Input';
import { Rule } from 'antd/es/form';
import { LogikInputPassword } from '../../logik/components/LogikInputPassword';
import { LogikInput } from '../../logik/components/LogikInput';

export const MASKED_INPUT_MASK_COMPLETE_EVENT = 'MASKED_INPUT_MASK_COMPLETE_EVENT';
export type MaskedInputProps = InputProps &
    React.RefAttributes<InputRef> & {
        maskOptions: IMask.AnyMaskedOptions;
        inputType?: 'password' | 'input';
        useNativeInput?: boolean;
    };

export type MaskedInputRef = InputRef & {
    getMaskedValue: () => string | undefined;
    getUnMaskedValue: () => string | undefined;
    validate: () => boolean;
    isComplete: () => boolean;
};

export const getMaskedInputValidators = (ref: RefObject<MaskedInputRef>, message?: string): Rule[] => [
    {
        validator: (_, val) => {
            const valid = (val as string)?.length > 0 ? ref.current?.isComplete() : true;
            return valid ? Promise.resolve() : Promise.reject();
        },
        validateTrigger: 'onBlur',
        message: message,
    },
];

export const MaskedInput = React.forwardRef<Partial<MaskedInputRef>, MaskedInputProps>(function MaskedInput(
    // eslint-disable-next-line react/prop-types
    { value, maskOptions, inputType, onChange, placeholder, useNativeInput, ...props }: MaskedInputProps,
    antdRef,
): JSX.Element {
    const masked = useRef<IMask.InputMask<IMask.AnyMaskedOptions>>();
    const [inputValue, setInputValue] = useState<string>();
    const [maskedPlaceholder, setMaskedPlaceholder] = useState<string>();
    const innerRef = React.useRef<InputRef>(null);

    useImperativeHandle(
        antdRef,
        () => {
            return {
                input: innerRef.current?.input ?? null,
                blur: () => innerRef.current?.blur(),
                focus: (options?: InputFocusOptions | undefined) => innerRef.current?.focus(options),
                select: () => innerRef.current?.select(),
                setSelectionRange: (
                    start: number,
                    end: number,
                    direction?: 'forward' | 'backward' | 'none' | undefined,
                ) => innerRef.current?.setSelectionRange(start, end, direction),
                getMaskedValue: () => masked.current?.value,
                getUnMaskedValue: () => masked.current?.unmaskedValue,
                validate: () => {
                    const result = masked.current?.masked.doValidate({}) ?? false;

                    return result;
                },
                isComplete: () => {
                    const result = masked.current?.masked.isComplete ?? false;

                    return result;
                },
            };
        },
        [],
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler = (e: any) => {
            if (e) {
                onChange?.(e);
            }
        };

        const handler2 = () => {
            window.setTimeout(() => {
                if (innerRef.current?.input)
                    innerRef.current.input.dispatchEvent(new Event(MASKED_INPUT_MASK_COMPLETE_EVENT));
            }, 0);
        };

        if (innerRef.current?.input) {
            if (!masked.current) {
                const m = IMask(innerRef.current.input, {
                    ...maskOptions,
                });

                m.on('accept', handler);
                m.on('complete', handler2);
                masked.current = m;
            }
        } else {
            if (masked.current) {
                masked.current.off('accept', handler);
                masked.current.off('complete', handler2);
                masked.current.destroy();
                masked.current = undefined;
            }
        }
    }, [innerRef.current?.input, maskOptions, onChange]);

    useEffect(() => {
        setMaskedPlaceholder(IMask.createPipe({ ...maskOptions, lazy: false } as IMask.AnyMaskedOptions)(''));

        if (maskOptions && masked.current) {
            masked.current.updateOptions({ ...maskOptions });
            if (innerRef.current?.input) innerRef.current.input.value = masked.current.value;
            masked.current.updateValue();
        }
    }, [maskOptions, onChange]);

    // const updateValue = useCallback(() => {
    //     if (masked.current) {
    //         masked.current.value = value?.toString() ?? '';
    //         masked.current.updateValue();
    //
    //         if (masked.current.value !== inputValue) {
    //             setInputValue(masked.current.value);
    //         }
    //     }
    // }, [inputValue, value]);

    useEffect(() => {
        if (masked.current) {
            masked.current.value = value?.toString() ?? '';
            masked.current.updateValue();

            if (masked.current.value !== inputValue) {
                setInputValue(masked.current.value);
            }
        }
    }, [inputValue, value]);

    return !inputType || inputType === 'input' ? (
        useNativeInput ? (
            <Input {...props} value={inputValue} placeholder={placeholder ?? maskedPlaceholder} ref={innerRef} />
        ) : (
            <LogikInput {...props} value={inputValue} placeholder={placeholder ?? maskedPlaceholder} ref={innerRef} />
        )
    ) : useNativeInput ? (
        <Input.Password
            {...props}
            value={inputValue}
            placeholder={placeholder ?? maskedPlaceholder}
            ref={innerRef}
            onChange={(e) => setInputValue(e.target.value)}
        />
    ) : (
        <LogikInputPassword
            {...props}
            value={inputValue}
            placeholder={placeholder ?? maskedPlaceholder}
            ref={innerRef}
        />
    );
});
