import { ServiceBase } from '../../shared/services/service-base';
import { BuildingPackage } from './building-packages.model';

class BuildingPackagesAPIImpl extends ServiceBase {
    constructor() {
        super();
        this.http.defaults.timeout = 10000000;
    }
    async get(id: string): Promise<BuildingPackage> {
        const prebuilt = await this.http.get<BuildingPackage>(`/api/pm/building-packages/${id}`);
        return prebuilt.data;
    }
}

export const BuildingPackagesAPI = new BuildingPackagesAPIImpl();
