let lastMs = 0;

type ChannelState = {
    channels: Window[];
    messageCallback: ((message: unknown) => void) | null;
    channelId: string;
    eventListener: (msg: MessageEvent) => void;
};

export const WindowMessageBusBroadcastMethod = {
    postMessage(channelState: ChannelState, message: object): Promise<unknown> {
        try {
            channelState.channels.forEach((w) => {
                try {
                    w.postMessage({
                        ...message,
                        channelId: channelState.channelId,
                    });
                } catch {}
            });
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    },
    onMessage(channelState: ChannelState, callback: (args: unknown) => void): void {
        channelState.messageCallback = callback;
    },
    create(channelName: string): Promise<ChannelState> | ChannelState {
        lastMs = Date.now() * 1000;
        const state: ChannelState = {
            channels: [self],
            messageCallback: null,
            channelId: channelName,
            eventListener: (msg: MessageEvent) => {
                if (state.channelId === msg.data.channelId) state.messageCallback?.(msg.data);
            },
        };

        if (!!self.opener) state.channels.push(self.opener);
        if (self.frames.length > 0) {
            for (let i = 0; i < self.frames.length; i++) {
                state.channels.push(self.frames[i]);
            }
        }

        state.channels.forEach((w) => {
            try {
                w.addEventListener('message', state.eventListener);
            } catch {}
        });

        console.debug('BroadcastChannel: creating broadcast channel', channelName);
        return state;
    },
    close(channelState: ChannelState): void {
        channelState.channels.forEach((w) => {
            try {
                w.removeEventListener('message', channelState.eventListener);
            } catch {}
        });
    },
    canBeUsed(): boolean {
        return true;
    },
    type: 'WindowMessageBus',
    microSeconds(): number {
        let ret = Date.now() * 1000; // milliseconds to microseconds
        if (ret <= lastMs) {
            ret = lastMs + 1;
        }
        lastMs = ret;
        return ret;
    },
    averageResponseTime(): number {
        return 150;
    },
};
