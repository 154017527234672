import { InputProps } from 'antd';
import { Rule } from 'antd/es/form';
import React, { RefObject } from 'react';
import { MaskedInput, MaskedInputRef } from './MaskedInput';
import { useCustomFormItemErrorsAndWarnings } from '../../logik/hooks/form.hooks';

export const PERCENT_INPUT_MASK = '% money';

export type PercentInputRef = MaskedInputRef & {
    validators: Rule[];
};
export const isPercent = (value: string): boolean => {
    const reg = new RegExp(`^${PERCENT_INPUT_MASK.replace('money', '-?\\d+([.,]\\d+)?').replace('%', '\\%')}$`);
    return value ? reg.test(value.toString()) : false;
};
export const percentToNumber = (value: string): number => {
    return Number(value.toString().replace(PERCENT_INPUT_MASK.replace('money', ''), ''));
};
export type PercentInputProps = InputProps & React.RefAttributes<PercentInputRef>;
export const getPercentInputValidators = (ref: RefObject<PercentInputRef>, message?: string): Rule[] => [
    {
        validator: () => {
            // const value = ref.current?.getUnMaskedValue()?.trim();
            // const numberValue = !!value ? Number.parseFloat(value) : undefined;
            // const valid = numberValue ? numberValue >= -100 && numberValue <= 0 : false;

            const valid = (ref.current?.getUnMaskedValue()?.trim().length ?? 0) > 0;
            return valid ? Promise.resolve() : Promise.reject();
        },
        message: message ?? '${name} is required',
    },
];

export const PercentInput = React.forwardRef<Partial<PercentInputRef>, PercentInputProps>(function PercentInput(
    // eslint-disable-next-line react/prop-types
    { onBlur, ...props }: PercentInputProps,
    innerRef,
): JSX.Element {
    useCustomFormItemErrorsAndWarnings();

    return (
        <MaskedInput
            maskOptions={{
                mask: PERCENT_INPUT_MASK,
                lazy: false,
                blocks: {
                    money: {
                        mask: Number,
                        signed: true,
                        thousandsSeparator: '',
                        radix: '.',
                        mapToRadix: [','],
                        scale: 2,
                        normalizeZeros: false,
                        padFractionalZeros: false,
                    },
                },
            }}
            onBlur={(e) => {
                const value = e.target.value;
                if (value.endsWith('.') || value.endsWith(',')) {
                    e.target.value += '00';
                }
                onBlur?.(e);
            }}
            {...props}
            ref={innerRef}
        />
    );
});
