import { objSnaphotType } from '@ulrichlifestyle/configurator';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { FieldUpdate } from '../../../shared/logik/models/field.model';
import { CloudFile } from '../../../shared/models/cloud-file.model';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { FileUploadOptions } from '../../../shared/models/file-upload.model';
import { FilesAPI } from '../../../shared/services/files.service';
import { dataUrlToFile } from '../../../shared/utils/dataurl.utils';
import logger from '../../../app/logger';
import { LogEntry } from '../../../shared/models/logentry';
import { setImages } from '../../widget/Widget.slice';

export type saveAndUploadSnapshotsArgs = {
    uuid: string;
    getSnapshots: () => Promise<objSnaphotType[]>;
};

export type saveAndUploadSnapshotsResult = FieldUpdate[];

export const saveAndUploadSnapshots = createAsyncThunk<
    saveAndUploadSnapshotsResult,
    saveAndUploadSnapshotsArgs,
    {
        state: RootState;
    }
>('configurator/save-snapshots', async ({ uuid, getSnapshots }, thunkAPI): Promise<saveAndUploadSnapshotsResult> => {
    const dispatch = thunkAPI.dispatch;
    const snapshots = await getSnapshots();
    const files$: Record<string, Promise<CloudFile[]>> = {};

    snapshots.forEach((sn) => {
        let name: string;
        switch (sn.type) {
            case '2D Plan':
                name = LogikFieldsEnum.image2DPlan;
                break;
            case '3D Building':
                name = LogikFieldsEnum.image3DBldg;
                break;
            case 'ActiveCameraA_D':
                name = LogikFieldsEnum.imageSideAD;
                break;
            case 'ActiveCameraC_B':
                name = LogikFieldsEnum.imageSideCB;
                break;
            case 'Side A':
                name = LogikFieldsEnum.imageSideA;
                break;
            case 'Side B':
                name = LogikFieldsEnum.imageSideB;
                break;
            case 'Side C':
                name = LogikFieldsEnum.imageSideC;
                break;
            case 'Side D':
                name = LogikFieldsEnum.imageSideD;
                break;
            default:
                return;
        }

        const file = dataUrlToFile(sn.url, name);

        if (file) {
            files$[file.name] = FilesAPI.upload({
                files: [file],
                keepFileNames: true,
                path: `configurations/${uuid}`,
                fileUploadOptions: FileUploadOptions.onDuplicateReplace,
                isPublic: true,
            });
        }
    });

    try {
        const uploaded = (await Promise.all(Object.values(files$))).flatMap((f) => f);
        logger.debug(new LogEntry('uploaded', uploaded));

        dispatch(
            setImages(
                uploaded.map((u) => ({
                    fileId: u.id,
                    url: u.publicUrl,
                    name: u.name,
                })),
            ),
        );

        return uploaded
            .map((f) => ({
                variableName: f.name.split('/').pop()?.split('.').shift() ?? '',
                value: f.publicUrl ? `${f.publicUrl}?t=${Date.now()}` : '',
            }))
            .filter((v) => v.variableName !== '');
    } catch (err) {
        logger.error(err);
        return [];
    }
});
