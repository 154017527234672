import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { FieldUpdate, SetFieldUpdate } from '../../../../shared/logik/models/field.model';
import { FieldSetDetails } from '../../../../shared/logik/models/field-set.details.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { WidgetState } from '../../Widget.slice';

export type updateConfigurationThunkResponse = {
    updates: (FieldUpdate | SetFieldUpdate)[];
    configuration: Configuration;
};

export type UpdateSetThunkResult = {
    setName: string;
    set: FieldSetDetails;
};
export const fetchSets = createAsyncThunk<
    UpdateSetThunkResult[],
    { uuid: string; sets: string[] },
    {
        state: RootState;
    }
>('configuration/fetchSets', async (arg): Promise<UpdateSetThunkResult[]> => {
    const requiredSets: string[] = [LogikFieldsEnum.set_of_discounts];

    return await Promise.all(
        arg.sets
            .filter((ch) => requiredSets.includes(ch))
            .map((set) =>
                logikApiService.fetchSet(arg.uuid, set).then((res) => {
                    return {
                        setName: set,
                        set: res,
                    };
                }),
            ),
    );
});

export const addFetchSetsCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder.addCase(fetchSets.fulfilled, (state, action) => {
        action.payload.forEach((p) => (state.configurationSets[p.setName] = p.set.content));
    });
};
