import { Steps, Image, Button } from 'antd';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Page } from '../../shared/components/layout/Page';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { useScreenChange } from '../../shared/hooks/screen';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { CheckoutPage } from '../checkout/CheckoutPage';
import { PricingSection } from '../pricing/PricingSection';
import { ReviewPage } from '../review/ReviewPage';
import { saveAndCreateOrder } from '../widget/thunks/actions/saveAndCreateOrder';
import { ROUTE_ORDER_CREATED, ROUTE_CHECKOUT, ROUTE_SUMMARY } from '../wizard/wizard.router';
import styles from './ReviewAndPay.module.scss';
import { useUserAuthenticated } from '../auth0/auth0.hooks';
import { EventBus } from '../../app/eventbus';
import { SaveOrderAndRedirectEvent, SaveOrderAndRedirectEventParams } from '../orders/OrderCreatedPage';
import { inIframe } from '../../shared/utils/document.utils';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';

export const REVIEW_AND_PAY_STEP_REVIEW = 0;
export const REVIEW_AND_PAY_STEP_PAY = 1;
export const REVIEW_AND_PAY_STEP_STATE_KEY = 'REVIEW_AND_PAY_STEP_STATE_KEY';
export const ReviewAndPay = (): JSX.Element => {
    const navigate = useNavigateExtended();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const [currentStep, setCurrentStep] = useState<number>(state?.['REVIEW_AND_PAY_STEP_STATE_KEY'] ?? 0);
    const user = useAppSelector((state) => state.auth.user);
    const { device } = useScreenChange();
    const imagePlan = useFieldValue<string>(LogikFieldsEnum.image2DPlan);
    const imageSideAD = useFieldValue<string>(LogikFieldsEnum.imageSideAD);
    const imageSideCB = useFieldValue<string>(LogikFieldsEnum.imageSideCB);
    const orderProcessing = useAppSelector((state) => state.checkout.orderProcessing);
    const checkedOut = useFieldValue<boolean>(LogikFieldsEnum.checkedOut);
    const userAuthenticated = useUserAuthenticated();
    const siteId = useFieldValue<string>(LogikFieldsEnum.siteId);
    const actionType = useAppSelector((state) => state.widget.init?.actionType);

    const createOrder = useCallback(async () => {
        const orderConfig = await dispatch(saveAndCreateOrder()).unwrap();
        if (userAuthenticated && siteId) {
            EventBus.dispatch<SaveOrderAndRedirectEventParams>(SaveOrderAndRedirectEvent, {
                masterId: orderConfig.orderMasterId,
                siteId,
                logikId: orderConfig.oldLogikId,
                newOrder: true,
            });

            if (!inIframe()) {
                await redirectToTransitionPage({
                    site: siteId,
                    redirectType: 'NewOrder',
                    masterId: orderConfig.orderMasterId,
                    logikId: orderConfig.oldLogikId,
                });
            }
        } else {
            navigate('../' + ROUTE_ORDER_CREATED, {
                replace: true,
            });
        }
    }, [dispatch, navigate, siteId, userAuthenticated]);

    return (
        <Page
            navProps={{
                nextButtonTitle: 'Proceed to payment',
                onClickNext: () => {
                    if (currentStep === REVIEW_AND_PAY_STEP_REVIEW) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        navigate('../' + ROUTE_CHECKOUT, { replace: true });
                    }
                },
                onClickBack: () => {
                    if (currentStep === 0) {
                        navigate('../' + ROUTE_SUMMARY, {
                            replace: true,
                        });
                    } else {
                        setCurrentStep(currentStep - 1);
                    }
                },
                hideNextButton:
                    currentStep == REVIEW_AND_PAY_STEP_PAY ||
                    actionType === 'NewCR' ||
                    actionType === 'EditCR' ||
                    checkedOut === true,
                hideSaveButton: currentStep == REVIEW_AND_PAY_STEP_PAY,
            }}
            navigationChildren={
                user && actionType !== 'NewCR' && actionType !== 'EditCR' && !checkedOut ? (
                    <Button type='primary' onClick={createOrder} disabled={orderProcessing}>
                        Create order w/o payment
                    </Button>
                ) : null
            }>
            <div
                className={
                    styles['container'] +
                    (currentStep === REVIEW_AND_PAY_STEP_PAY ? ' ' + styles['container-no-preview'] : '')
                }>
                <div className={styles['container-header']}>
                    <Steps
                        items={[
                            {
                                title: 'Review',
                                status: currentStep == REVIEW_AND_PAY_STEP_PAY ? 'wait' : 'process',
                            },
                            {
                                title: 'Payment',
                                status: currentStep >= REVIEW_AND_PAY_STEP_PAY ? 'process' : 'wait',
                            },
                        ]}
                        current={currentStep}
                        labelPlacement='vertical'
                        className={styles['container-content-steps']}
                        responsive={false}
                        size={device === 'mobile' ? 'small' : 'default'}></Steps>
                </div>

                <div className={styles['container-preview']}>
                    {imagePlan && <Image src={imagePlan} height='250' width='350' />}
                    {imageSideAD && <Image src={imageSideAD} height='250' width='350' />}
                    {imageSideCB && <Image src={imageSideCB} height='250' width='350' />}
                </div>

                {currentStep === REVIEW_AND_PAY_STEP_REVIEW && (
                    <div className={styles['container-customer-info']}>
                        <ReviewPage />
                    </div>
                )}

                {currentStep === REVIEW_AND_PAY_STEP_REVIEW && (
                    <div className={styles['container-pricing']}>
                        <div className={styles['pricing-configuration']}>
                            <PricingSection
                                titleElement='Configuration'
                                type='secondary'
                                pricingType='configuration'
                                paddingSize='big'
                                layoutType={device === 'tablet' ? 'multi-column' : 'single-column'}
                            />
                        </div>

                        <div className={styles['pricing-summary']}>
                            <PricingSection
                                titleElement='Order Summary'
                                type='primary'
                                pricingType='pricing'
                                filter={(pd) => !!pd.extended?.OrderSummary}
                                paddingSize='big'
                                layoutType={device === 'tablet' ? 'single-row' : 'single-column'}
                            />
                        </div>
                    </div>
                )}

                {currentStep === REVIEW_AND_PAY_STEP_PAY && (
                    <div className={styles['container-checkout']}>
                        <CheckoutPage />{' '}
                    </div>
                )}
            </div>
        </Page>
    );
};
