import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { FieldUpdate, SetFieldUpdate } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { fetchConfigurationEventDataFromFields, fetchProduct } from '../../../../shared/logik/utils/fields.utils';
import { ConfiguratorAPI } from '../../../../shared/services/configurator.service';
import { CheckoutState } from '../../../checkout/Checkout.slice';
import { notifyAboutOrder } from '../../../checkout/thunk/notifyAboutOrder';
import { recreateConfiguration } from '../basic/recreateConfiguration';
import { getOrderDateTimeUTCUpdate } from '../../../wizard/wizard.helpers';

export type SaveAndCreateOrderArgs = {
    notifyAboutNewOrder?: boolean;
    updates?: (FieldUpdate | SetFieldUpdate)[];
};

export type SaveAndCreateOrderResult = {
    orderMasterId: string;
    sourceMasterId?: string;
    oldLogikId: string;
    orderLogikId: string;
};

export const saveAndCreateOrder = createAsyncThunk<
    SaveAndCreateOrderResult,
    SaveAndCreateOrderArgs | undefined,
    {
        state: RootState;
    }
>('configuration/saveAndCreateOrder', async (args, thunkAPI): Promise<SaveAndCreateOrderResult> => {
    if (!args) {
        args = {};
    }

    if (args.notifyAboutNewOrder === undefined) {
        args.notifyAboutNewOrder = true;
    }

    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const widgetState = state.widget;
    const salesman = thunkAPI.getState().auth.user?.email;

    if (!widgetState.uuid || !widgetState.productId || !widgetState.configurationFields)
        return Promise.reject('State is incomplete');

    let uuid = widgetState.uuid;
    await logikApiService.saveConfiguration(uuid);

    const savedConfig = await ConfiguratorAPI.saveConfiguration({
        logikId: uuid,
        productId: widgetState.productId,
        threeKitId: widgetState.threeKitId ?? '',
        product: fetchProduct(widgetState.configurationFields),
        masterConfigurationId: widgetState.masterConfigurationId,
        data: {
            ...fetchConfigurationEventDataFromFields(
                widgetState.configurationFields,
                widgetState.pricingDetails,
                undefined,
                undefined,
                salesman,
                widgetState.siteId,
                widgetState.init?.dealId,
                widgetState.subTotal,
                false,
                false,
                widgetState.masterConfigurationId ? 'QuoteEdit' : 'QuoteNew',
            ),
            images: widgetState.images3D,
            createOrder: false,
        },
        silent: false,
    });

    const upd = [
        ...(args?.updates ?? []),
        {
            variableName: LogikFieldsEnum.checkedOut,
            value: true,
        },
        getOrderDateTimeUTCUpdate(),
    ];

    const copy = await logikApiService.copyConfiguration(uuid, upd);
    uuid = copy.uuid;
    await logikApiService.saveConfiguration(uuid);

    const res = await Promise.all([
        ConfiguratorAPI.createMasterConfig({
            logikId: uuid,
            productId: widgetState.productId,
            threeKitId: widgetState.threeKitId ?? '',
            product: fetchProduct(widgetState.configurationFields),
            masterConfigurationId: savedConfig.masterConfigurationId,
            data: {
                createOrder: false,
            },
        }),
        dispatch(recreateConfiguration(savedConfig.logikId)).unwrap(),
        await logikApiService.copyConfiguration(uuid),
    ]);

    if (!res[0].masterConfigurationId) return Promise.reject('Error while saving order');
    //dispatch(setMasterConfigurationId(res[0].masterConfigurationId));

    const result = {
        orderMasterId: res[0].masterConfigurationId,
        sourceMasterId: savedConfig.masterConfigurationId,
        oldLogikId: uuid,
        orderLogikId: res[2].uuid,
    } as SaveAndCreateOrderResult;

    if (args?.notifyAboutNewOrder) {
        await dispatch(notifyAboutOrder(result));
    }

    return result;
});

export const addSaveAndCreateOrderCases = (
    builder: ActionReducerMapBuilder<CheckoutState>,
): ActionReducerMapBuilder<CheckoutState> => {
    return builder
        .addCase(saveAndCreateOrder.pending, (state) => {
            state.orderProcessing = true;
        })
        .addCase(saveAndCreateOrder.fulfilled, (state) => {
            state.orderProcessing = false;
        })
        .addCase(saveAndCreateOrder.rejected, (state) => {
            state.orderProcessing = false;
        });
};
