import { ActionReducerMapBuilder, AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { FieldUpdate, getFieldValue } from '../../../shared/logik/models/field.model';
import { WidgetState } from '../../widget/Widget.slice';
import { GLatLng } from '../../../shared/google/models/GAddress';
import { TaxRatesService } from '../../../shared/services/tax-rates.service';
import { updateConfiguration } from '../../widget/thunks/basic/updateConfiguration';
import { TaxCodeRate } from '../../../shared/models/tax-code-rate.model';
import logger from '../../../app/logger';

const updateConfigurationWTaxRate = async (
    uuid: string,
    rate: TaxCodeRate,
    updateUsingStore: boolean,
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
) => {
    const updates = [] as FieldUpdate[];

    if (updateUsingStore) {
        updates.push({
            variableName: LogikFieldsEnum.taxStoreCode,
            value: rate.taxCode,
        });
    } else {
        updates.push({
            variableName: LogikFieldsEnum.taxApiCode,
            value: rate.taxCode,
        });

        updates.push({
            variableName: LogikFieldsEnum.taxApiRate,
            value: rate.taxRate,
        });
    }

    await dispatch(
        updateConfiguration({
            uuid,
            updates,
            skipRelatedUpdates: true,
        }),
    );
};

export type updateTaxRateArgs = {
    latLng?: GLatLng;
};
export const updateTaxRate = createAsyncThunk<
    void,
    updateTaxRateArgs,
    {
        state: RootState;
    }
>('taxes/update-rate', async ({ latLng }, thunkAPI): Promise<void> => {
    try {
        const dispatch = thunkAPI.dispatch;
        const widgetState = thunkAPI.getState().widget;
        const uuid = widgetState.uuid;
        const shippingZip = getFieldValue<string>(widgetState.configurationFields, LogikFieldsEnum.shipping_zip);
        const shippingAddress = getFieldValue<string>(
            widgetState.configurationFields,
            LogikFieldsEnum.shipping_address,
        );
        const shippingCity = getFieldValue<string>(widgetState.configurationFields, LogikFieldsEnum.shipping_city);
        const siteId = widgetState.siteId;
        const store = widgetState.store;
        const isCA = siteId === 'MFGCA';
        const useAddress = isCA || !store?.id;
        const useStoreId = !isCA && !!store?.id;

        if (uuid && siteId) {
            if (useStoreId) {
                const r = await TaxRatesService.getByStoreId(siteId, store.id);
                await updateConfigurationWTaxRate(uuid, r, true, dispatch);
            } else if (useAddress) {
                if (shippingAddress && shippingCity && shippingZip) {
                    let r;
                    if (isCA && shippingAddress.match(/-?\d+\.\d+,-?\d+\.\d+/g)) {
                        const parts = shippingAddress.split(',');

                        r = await TaxRatesService.getByLatLng(siteId, parseFloat(parts[0]), parseFloat(parts[1]));
                    } else {
                        r = await TaxRatesService.getByAddress(
                            siteId,
                            shippingAddress,
                            shippingCity,
                            shippingZip,
                            latLng,
                        );
                    }
                    await updateConfigurationWTaxRate(uuid, r, false, dispatch);
                }
            }
        }
    } catch (err: unknown) {
        logger.error(err);
        return Promise.reject('Error updating tax rate');
    }
});

export const addUpdateTaxRateCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(updateTaxRate.pending, (state) => {
            state.saving = true;
            state.loading = true;
        })
        .addCase(updateTaxRate.fulfilled, (state) => {
            state.saving = false;
            state.loading = false;
        })
        .addCase(updateTaxRate.rejected, (state) => {
            state.saving = false;
            state.loading = false;
        });
};
