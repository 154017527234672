import { Form, Input } from 'antd';
import { formatPriceValue } from '../../shared/utils/numbers.utils';

type PaymentAmountInputProps = {
    defaultValue: number;
};
export const PaymentAmountInput = ({ defaultValue }: PaymentAmountInputProps): JSX.Element => {
    return (
        <Form.Item label='Amount' name='amount' initialValue={defaultValue}>
            <Form.Item noStyle>
                <Input type='hidden' />
                <p>{formatPriceValue(defaultValue, true)}</p>
            </Form.Item>
        </Form.Item>
    );
};
