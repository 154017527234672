import { useEffect, useRef } from 'react';
import { useIsActiveUrl } from '../../shared/hooks/hooks';
import styles from './HeaderItem.module.scss';
export interface HeaderItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'ref'> {
    title: string;
    url: string;
    index: number;
}

export function HeaderItem({ title, url, index, ...props }: HeaderItemProps): JSX.Element {
    const isActive = useIsActiveUrl(url);
    const itemRef = useRef<HTMLDivElement>(null);
    const classes = [props.className, styles['menu-item'], styles['active']];

    const className = (): string => (isActive ? classes.join(' ') : [props.className, styles['menu-item']].join(' '));

    useEffect(() => {
        if (isActive && itemRef.current) {
            // window.scrollTo({
            //     behavior: 'smooth',
            //     left: itemRef.current.offsetLeft,
            // });

            itemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [isActive]);

    return (
        <div {...props} className={className()} ref={itemRef}>
            <div className={styles['icon']}>{index}</div>
            {title}
            <div className={styles['separator']}></div>
        </div>
    );
}
