import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { updateTotalPrice } from '../../configurator/thunks/updateTotalPrice';
import { recreateConfiguration } from '../../widget/thunks/basic/recreateConfiguration';
import { PrebuiltState } from '../prebuilts.slice';

export type overridePrebuiltMasterConfigurationResult = {
    masterId?: string;
    configurtion: Configuration;
};
export const overridePrebuiltMasterConfiguration = createAsyncThunk<
    overridePrebuiltMasterConfigurationResult,
    void,
    {
        state: RootState;
    }
>(
    'prebuilts/override-master-configuration',
    async (_, thunkAPI): Promise<overridePrebuiltMasterConfigurationResult> => {
        const dispatch = thunkAPI.dispatch;
        const state = thunkAPI.getState();
        const prebuiltState = state.widget;

        const uuid = prebuiltState.uuid;
        const productId = prebuiltState.productId;
        const threeKitId = prebuiltState.threeKitId;
        const prebuilt = state.prebuilt.details;

        if (!uuid || !productId || !prebuilt) return Promise.reject("Can't save prebuilt: invalid configuration data");

        await dispatch(updateTotalPrice());
        await logikApiService.updateConfiguration(uuid, [
            {
                variableName: LogikFieldsEnum.prebuiltMasterConfigUUID,
                value: uuid,
            },
        ]);
        const configuration = await logikApiService.saveConfiguration(uuid);

        const saveMasterConfig$ = ConfiguratorAPI.saveConfiguration({
            logikId: uuid,
            productId: productId,
            product: '',
            threeKitId: threeKitId ?? '',
            data: {
                createOrder: false,
                prebuiltSerialNum: state.prebuilt.details?.serialNumber,
                prebuiltIsMasterConfig: true,
                siteId: prebuiltState.siteId,
            },
            masterConfigurationId: prebuilt.masterConfigurationId,
        });
        const recreateConfig$ = dispatch(recreateConfiguration(configuration.uuid)).unwrap();
        const res = await Promise.all([saveMasterConfig$, recreateConfig$]);

        if (!res[0].masterConfigurationId) throw new Error('Failed to save master configuration');

        return {
            masterId: prebuiltState.masterConfigurationId,
            configurtion: res[1],
        };
    },
);

export const addOverridePrebuiltConfigurationCases = (
    builder: ActionReducerMapBuilder<PrebuiltState>,
): ActionReducerMapBuilder<PrebuiltState> => {
    return (
        builder
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(overridePrebuiltMasterConfiguration.pending, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(overridePrebuiltMasterConfiguration.fulfilled, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(overridePrebuiltMasterConfiguration.rejected, (state) => {})
    );
};
