import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { AddDiscountButton } from './AddDiscountButton';
import { LogikFormList } from '../../../shared/logik/components/LogikFormList';
import { DiscountRow } from './DiscountRow';
import { SkeletonRow } from './SkeletonRow';
import { Section } from '../../../shared/components/layout/Section';

export function SummaryDiscounts(): JSX.Element {
    return (
        <Section titleElement='Discounts'>
            <LogikFormList name={LogikFieldsEnum.set_of_discounts} setName={LogikFieldsEnum.set_of_discounts}>
                {(fields, { add, remove }, meta, isAddingRow, isRemovingRow) => {
                    return (
                        <>
                            <AddDiscountButton add={add} />

                            {fields.map(({ key, name, ...rest }) => {
                                if (!isRemovingRow) {
                                    return <DiscountRow index={name} remove={remove} key={name} />;
                                } else {
                                    return <SkeletonRow key={name} />;
                                }
                            })}
                            {isAddingRow && <SkeletonRow />}
                        </>
                    );
                }}
            </LogikFormList>
        </Section>
    );
}
