import { isObject, toLower } from 'lodash';
import moment from 'moment';
import { isMoney, moneyToNumber } from '../../antd/components/MoneyInput';
import { FormState, FormStateValue } from '../../models/form.state.model';
import { Field, FieldValue, isSetFieldSize } from '../models/field.model';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { isPercent, percentToNumber } from '../../antd/components/PercentInput';

export const getTypeFromLogikField = (logikDataType: Field['dataType']): 'string' | 'number' | 'boolean' | 'moment' => {
    switch (logikDataType) {
        case 'boolean':
            return 'boolean';

        case 'number':
            return 'number';

        case 'text':
            return 'string';
        default:
            return 'string';
    }
};
export const getFormInitialFormState = <TFormState extends FormState = FormState>(
    logikFields: Record<string, Field>,
    formState: FormState,
): FormState => {
    if (!formState) return {} as TFormState;
    const fs = formState;
    Object.keys(fs).forEach((name) => {
        const logikField = logikFields[name] || logikFields[`set.${name}.size`];
        if (!logikField) return;

        if (isSetFieldSize(logikField)) {
            const set = Array(logikField.value.length);

            for (let i = 0; i < set.length; i++) {
                const setFields = Object.entries(logikFields)
                    .filter(([k]) => k.startsWith(`${logikField.set}.${i}.`))
                    .map(([, v]) => v);

                if (!set[i]) set[i] = {};
                for (const f of setFields) {
                    set[i][f.variableName] = convertFromLogikValue(f.value, getTypeFromLogikField(f.dataType));
                }
            }

            fs[name] = set;
        } else {
            fs[name] = convertFromLogikValue(
                logikFields[name].value,
                getTypeFromLogikField(logikFields[name].dataType),
            );
        }
    });

    return fs;
};
export const convertFromFormStateValue = (
    value: FormStateValue,
    logikDataType: Field['dataType'],
    fieldValueConverter?: (value: FormStateValue) => FieldValue,
): FieldValue => {
    if (fieldValueConverter) {
        return fieldValueConverter(value);
    } else {
        switch (logikDataType) {
            case 'boolean':
                if (!value) return false;
                return toLower(value.toString()) === 'true';

            case 'number':
                if (value === undefined) return 0;
                const moneyStr = value.toString();
                if (isMoney(moneyStr)) {
                    return moneyToNumber(moneyStr);
                } else if (isPercent(moneyStr)) {
                    return percentToNumber(moneyStr);
                } else return Number(value);

            case 'text':
                if (moment.isMoment(value)) {
                    return (value as moment.Moment).toISOString();
                } else {
                    return value ? value.toString() : '';
                }
            default:
                return value ? value.toString() : '';
        }
    }
};

export const convertFromLogikValue = (
    value: FieldValue,
    fieldDataType: 'string' | 'number' | 'boolean' | 'moment',
    logikValueConverter?: (value: FieldValue | null) => FormStateValue,
): FormStateValue => {
    if (logikValueConverter) {
        return logikValueConverter(value);
    } else {
        if (value === null || value === '') return undefined;

        switch (fieldDataType) {
            case 'boolean':
                return toLower(value.toString()) === 'true';
            case 'number':
                return Number(value);
            case 'string':
                return value.toString();
            case 'moment':
                return moment(value.toString());
            default:
                return value.toString();
        }
    }
};

export const isValidateErrorEntity = (err: unknown): err is ValidateErrorEntity => {
    return isObject(err) && Object.getOwnPropertyNames(err).includes('errorFields');
};
