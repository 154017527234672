import { Environment } from '../../app/environment';
import { LogEntry } from '../models/logentry';
import { LogProvider } from '../providers/logprovider';
import { isResponse } from '../utils/typeguards';

enum LogType {
    Log = 0,
    Info = 1,
    Debug = 2,
    Warn = 3,
    Error = 4,
}

export type LogMessage = LogEntry | string | Error | unknown;
export class LogService {
    constructor(private readonly provider: LogProvider) {}

    protected async write(message: LogMessage, type: LogType): Promise<void> {
        let entry;

        if (message instanceof LogEntry) {
            entry = message;
        } else if (typeof message === 'string') {
            entry = new LogEntry(message);
        } else if (message instanceof Error) {
            entry = new LogEntry(message);
        } else if (typeof message === 'object' && message?.hasOwnProperty('message')) {
            entry = new LogEntry(Reflect.get(message, 'message'));
        } else if (isResponse(message)) {
            const errorText = await message.text();
            entry = new LogEntry(errorText, message);
        } else if (typeof message === 'object') {
            entry = new LogEntry('Unknown error occurred', JSON.stringify(message));
        } else {
            this.error(new Error("Couldn't log message. Unknown format"));
            return;
        }

        try {
            switch (type) {
                case LogType.Log:
                    await this.provider.log(entry);
                    break;

                case LogType.Info:
                    await this.provider.info(entry);
                    break;

                case LogType.Debug:
                    if (Environment.isDevelopment || Environment.isPilot) {
                        await this.provider.debug(entry);
                    }
                    break;

                case LogType.Warn:
                    await this.provider.log(entry);
                    break;

                case LogType.Error:
                    await this.provider.error(entry);
                    break;
            }
        } catch (internalError: unknown) {
            let error;
            if (internalError instanceof Error) {
                error = internalError;
            } else if (typeof internalError === 'string') {
                error = new Error(internalError);
            } else {
                return;
            }
            error.message = 'LogService failed: ' + error.message;
            this.error(error);

            const originalError = message instanceof Error ? message : new Error(entry.message);
            originalError.message = 'LogService original error: ' + originalError.message;
            this.error(originalError);
        }

        return Promise.resolve();
    }

    public log(error: LogMessage): void | Promise<void> {
        return this.write(error, LogType.Log);
    }

    public info(error: LogMessage): void | Promise<void> {
        return this.write(error, LogType.Info);
    }

    public debug(error: LogMessage): void | Promise<void> {
        return this.write(error, LogType.Debug);
    }

    public warn(error: LogMessage): void | Promise<void> {
        return this.write(error, LogType.Warn);
    }

    public error(error: LogMessage): void | Promise<void> {
        return this.write(error, LogType.Error);
    }
}
