import { PropsWithChildren, useEffect } from 'react';

const guard = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = 'If you leave, all the changes will be lost. Are you sure?';
};
export const UnloadGuard = ({ children }: PropsWithChildren): JSX.Element => {
    useEffect(() => {
        window.addEventListener('beforeunload', guard);

        return () => {
            UnloadGuard.disable();
        };
    }, []);
    return <>{children}</>;
};
UnloadGuard.disable = () => {
    window.removeEventListener('beforeunload', guard);
};
