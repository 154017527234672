import { GLatLng } from '../google/models/GAddress';
import { TaxCodeRate } from '../models/tax-code-rate.model';
import { ServiceBase } from './service-base';

class TaxRatesServiceImpl extends ServiceBase {
    public async getByLatLng(siteId: string, lat: number, lng: number): Promise<TaxCodeRate> {
        const payload = {
            longitude: lng,
            latitude: lat,
        };
        const response = await this.http.post<TaxCodeRate>(`api/${siteId}/taxes`, {
            coordinate: payload,
        });

        return response.data;
    }

    public async getByAddress(
        siteId: string,
        address: string,
        city: string,
        zip: string,
        latLng?: GLatLng,
    ): Promise<TaxCodeRate> {
        const payload = {
            address,
            city,
            zip,
            longitude: latLng?.lng,
            latitude: latLng?.lat,
        };

        const response = await this.http.post<TaxCodeRate>(`api/${siteId}/taxes`, {
            address: payload,
        });

        return response.data;
    }

    public async getByStoreId(siteId: string, storeId: string): Promise<TaxCodeRate> {
        const response = await this.http.post<TaxCodeRate>(`api/${siteId}/taxes`, {
            storeId,
        });

        return response.data;
    }
}

export const TaxRatesService = new TaxRatesServiceImpl();
