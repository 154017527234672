import { Form } from 'antd';
import { PropsWithChildren, useState } from 'react';
import { WizardButtons, WizardButtonsProps } from '../../../features/wizard/WizardButtons';
import './Page.scss';

export type PageProps = {
    navProps?: WizardButtonsProps;
    hideNavigationBar?: boolean;
    navigationChildren?: React.ReactNode;
};
export const Page = ({
    hideNavigationBar,
    children,
    navigationChildren,
    navProps,
}: PropsWithChildren<PageProps>): JSX.Element => {
    const [formInvalid, setFormInvalid] = useState(false);
    return (
        <Form.Provider
            onFormChange={(name, info) => {
                if (name !== 'wizard-buttons' && info.forms[name]) {
                    const formInvalid = info.forms[name].getFieldsError().some((f) => f.errors.length > 0);
                    setFormInvalid(formInvalid);
                }
            }}>
            <div className='page'>
                <div className='page-content'>{children}</div>
                {hideNavigationBar === true ? (
                    <></>
                ) : (
                    <div className='page-navigation'>
                        <WizardButtons {...navProps} formsInvalid={formInvalid}>
                            {navigationChildren}
                        </WizardButtons>
                    </div>
                )}
            </div>
        </Form.Provider>
    );
};
