export function isRequestNonceResponseSuccess(
    res: AuthorizeNet.RequestNonceResponse,
): res is AuthorizeNet.RequestNonceResponseSuccess {
    return res.messages.resultCode === 'Ok';
}

export function isRequestNonceResponseError(
    res: AuthorizeNet.RequestNonceResponse,
): res is AuthorizeNet.RequestNonceResponseError {
    return res.messages.resultCode === 'Error';
}
