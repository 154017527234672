import axios, { AxiosInstance } from 'axios';
import { Environment } from '../../app/environment';

interface MiniPlayerRawConfiguration {
    attachments: Record<string, unknown>;
    createdAt: string;
    customerId: string | null;
    id: string;
    identifier: string | null;
    metadata: Record<string, unknown>;
    orgId: string;
    productId: string;
    productVersion: string;
    scope: string | null;
    shortId: string;
    thumbnail: string;
    variant: Record<string, unknown>;
}

export interface MiniPlayerConfiguration extends MiniPlayerRawConfiguration {}

class MiniPlayerAPI {
    private get http(): AxiosInstance {
        return axios.create({
            baseURL: Environment.threekitUrl,
            timeout: 500000,
            headers: {
                Authorization: `Bearer ${Environment.threekitApiToken}`,
            },
        });
    }

    private convertResponse = (config: MiniPlayerRawConfiguration): MiniPlayerConfiguration => {
        return { ...config };
    };

    getFullConfiguration = async (shortId: string): Promise<MiniPlayerConfiguration> => {
        const res = await this.http.get<MiniPlayerRawConfiguration>(`api/configurations/${shortId}`, {
            params: {
                bearer_token: Environment.threekitApiToken,
            },
        });
        return res.data;
    };

    getThumbnail = async (shortId: string): Promise<File> => {
        const res = await this.http.get<File>(`api/configurations/${shortId}/thumbnail`, {
            params: {
                bearer_token: Environment.threekitApiToken,
            },
            responseType: 'blob',
        });
        return res.data;
    };
}

export default new MiniPlayerAPI();
