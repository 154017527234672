import { User } from '@auth0/auth0-react';

export class UserWithCustomClaims extends User {
    constructor(user: Partial<User>) {
        super();

        Object.assign(this, user);
    }

    getCustomClaim = <T>(claim: string): T | undefined => {
        return this[claim] as T;
    };

    getCustomClaimWithNamespace = <T>(claim: string, namespace: string): T | undefined => {
        return this[`${namespace}/${claim}`] as T;
    };
}

export type RoleClaim = {
    name: string;
    site: string;
};

export type PermissionsClaim = {
    operations: string[];
    sites: string[];
};

export type SalesmanClaim = {
    site: string;
    slsman: string;
};
