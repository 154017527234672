import { createSlice } from '@reduxjs/toolkit';
import { addCreateInitConfigurationCases } from './thunks/init/createInitConfiguration';
import { addInitWidgetCases } from './thunks/init/initWidget';
import { addPostProcessConfigurationCases } from './thunks/basic/postProcessConfiguration';
import { FieldSetDetails, SetFieldItemGroup } from '../../shared/logik/models/field-set.details.model';
import { addSaveAndRecreateConfigurationCases } from './thunks/actions/saveAndRecreateConfiguration';
import { addSaveAndSendQuoteAndRecreateConfigurationCases } from './thunks/actions/saveAndSendQuoteAndRecreateConfiguration';
import { addRecreateConfigurationCases } from './thunks/basic/recreateConfiguration';
import { addUpdateConfigurationCases } from './thunks/basic/updateConfiguration';
import { addFetchSetsCases } from './thunks/basic/fetchSets';
import { addFetchInitConfigurationCases } from './thunks/init/fetchInitConfiguration';
import { addEditInitConfigurationCases } from './thunks/init/editInitConfiguration';
import {
    Field,
    FieldUpdate,
    FieldValue,
    getFieldValue,
    isSetFieldUpdate,
    SetFieldUpdate,
    SetFieldVariable,
} from '../../shared/logik/models/field.model';
import { addSetUserProfileCases } from './thunks/init/setUserProfile';
import { union, isEqual } from 'lodash';
import { ConfigurationMessage } from '../../shared/logik/models/message.model';
import { RootState } from '../../app/store';
import { addFetchPricingDetailsCases } from './thunks/basic/fetchPricingDetails';
import { ProductDetail } from '../../shared/logik/models/product.details.model';
import { addCreateConfigurationCases } from './thunks/basic/createConfiguration';
import { addSaveThreeKitConfigurationCases } from '../configurator/thunks/saveThreeKitConfiguration';
import { addSaveConfigurationCases } from './thunks/actions/saveConfiguration';
import { Store } from '../../shared/models/store.model';
import { addUpdateTotalPriceCases } from '../configurator/thunks/updateTotalPrice';
import { Configuration } from '../../shared/logik/models/configuration.model';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { addSavePrebuiltConfigurationCases } from '../prebuilts/thunks/savePrebuiltMasterConfiguration';
import { addCopyInitConfigurationCases } from './thunks/init/copyInitConfiguration';
import { addFetchTooltipsCases } from './thunks/basic/fetchTooltips';
import { addUpdateTaxRateCases } from '../tax/thunks/updateTaxRateThunk';
import { addCreateProductTemplateCases } from '../products-templates/thunks/createProductTemplate';
import { addEditProductTemplateCases } from '../products-templates/thunks/editProductTemplate';
import { addSaveProductTemplateCases } from '../products-templates/thunks/saveProductTemplate';
import { addCreateBuildingPackageCases } from '../building-packages/thunks/createBuildingPackage';
import { addEditBuildingPackageCases } from '../building-packages/thunks/editBuildingPackage';
import { addSaveBuildingPackageCases } from '../building-packages/thunks/saveBuildingPackage';
import { addCreateStockRequestCases } from '../stock-requests/thunks/createStockRequest';
import { addEditStockRequestCases } from '../stock-requests/thunks/editStockRequest';
import { addSaveStockRequestCases } from '../stock-requests/thunks/saveStockRequest';
import { addDuplicateBuildingPackageCases } from '../building-packages/thunks/duplicateBuildingPackage';
import { addSavePreownedConfigurationCases } from '../preowned/thunks/savePreownedMasterConfiguration';
import { Image3D } from '../../shared/models/configuration-event-data.model';

export type WidgetAction = 'new' | 'edit' | 'readonly' | 'restore' | 'copy';
export interface WidgetInitState {
    action: WidgetAction;
    uuid?: string;
    threeKitId?: string;
    productId?: string;
    initialConfiguration?: { [name: string]: FieldValue };
    masterConfigurationId?: string;
    src: 'oms' | 'public' | 'private';
    dealId?: string;
    siteId?: string;
    actionType?:
        | 'NewCR'
        | 'EditCR'
        | 'QuoteNew'
        | 'QuoteEdit'
        | 'OrderNew'
        | 'OrderEdit'
        | 'PreownedMasterNew'
        | 'PreownedMasterEdit'
        | 'PrebuiltMasterNew'
        | 'PrebuiltMasterEdit';
    sourceMasterConfigId?: string;
    changedDescriptionId?: string;
    launchObjectId?: string;
    allowVariants?: boolean;
}

export interface WidgetState
    extends Omit<WidgetInitState, 'action' | 'mode' | 'initialConfiguration' | 'src' | 'dealId' | 'actionType'> {
    init?: WidgetInitState;
    loading?: boolean;
    saving?: boolean;
    updating?: boolean;
    updatingFields?: boolean;
    fieldsUpdating: (FieldUpdate | SetFieldUpdate)[];
    sendingQuote?: boolean;
    initializing?: boolean;
    initialized?: boolean;
    configurationSets: Record<string, SetFieldItemGroup[]>;
    siteId?: string;
    pricingDetails: ProductDetail[];
    configurationMessages: ConfigurationMessage[];
    configurationFields: Record<string, Field>;
    pricingUpdating: boolean;
    expired: boolean;
    total?: number;
    subTotal?: number;
    territory?: string;
    storeId?: string;
    store?: Store;
    tooltips: Record<string, string>;
    loadingTooltips: boolean;
    images3D?: Image3D[];
}

const initialState: WidgetState = {
    initializing: true,
    initialized: false,
    loading: false,
    saving: false,
    configurationSets: {},
    pricingDetails: [],
    configurationFields: {},
    configurationMessages: [],
    fieldsUpdating: [],
    pricingUpdating: false,
    expired: false,
    tooltips: {},
    loadingTooltips: false,
};

export const widgetSlice = createSlice({
    name: 'Widget',
    initialState,
    reducers: {
        setMasterConfigurationId: (state, action) => {
            state.masterConfigurationId = action.payload;
        },
        setUUID: (state, action) => {
            state.uuid = action.payload;
        },
        setSiteId: (state, action) => {
            state.siteId = action.payload;
        },
        setProductId: (state, action) => {
            state.productId = action.payload;
        },
        setThreekitId: (state, action) => {
            state.threeKitId = action.payload;
        },
        setWidgetState: (state, action) => {
            Object.assign(state, action.payload, { init: state.init });
        },
        setInitialWidgetState: (state, { payload }: { payload: Partial<WidgetInitState> }) => {
            if (!state.init) state.init = { action: 'new', src: 'public' };
            Object.assign(state.init, payload);
        },
        setInitializing: (state, action) => {
            state.initializing = action.payload;
        },
        setFieldsUpdating: (state, action) => {
            const n = union(state.fieldsUpdating ?? [], action.payload);
            if (!isEqual(state.fieldsUpdating, n)) {
                state.fieldsUpdating = [...n];
            }
        },
        setFieldsValueDuringUpdate: (state, action) => {
            (action.payload as FieldUpdate[]).forEach((fu) => {
                if (isSetFieldUpdate(fu)) {
                    if (fu.index && state.configurationSets[fu.set]?.[fu.index]?.fields?.[fu.variableName]) {
                        state.configurationSets[fu.set][fu.index].fields[fu.variableName] = {
                            ...state.configurationSets[fu.set][fu.index].fields[fu.variableName],
                            value: fu.value,
                        } as SetFieldVariable;
                    }
                } else {
                }

                state.configurationFields[fu.variableName] = {
                    ...state.configurationFields[fu.variableName],
                    value: fu.value,
                } as Field;
            });
        },
        setProducts: (state, action) => {
            state.pricingDetails = [...action.payload];
        },
        setExpired: (state, action) => {
            state.expired = action.payload;
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        },
        setSubTotal: (state, action) => {
            state.subTotal = action.payload;
        },
        setStoreId: (state, action) => {
            state.storeId = action.payload;
        },
        setStore: (state, action) => {
            state.store = action.payload ? { ...action.payload } : undefined;
        },
        setPricingDetails: (state, { payload }: { payload: ProductDetail[] }) => {
            state.pricingDetails = [...payload.filter((f) => f.bomType === 'SALES')];
        },
        setSetsFromFieldsList: (state, { payload }: { payload: SetFieldVariable[] }) => {
            const sets: WidgetState['configurationSets'] = {};

            payload.forEach((element) => {
                if (!sets[element.set]) sets[element.set] = [];
                const set = sets[element.set];
                if (!set[element.index])
                    set.push({
                        index: element.index,
                        fields: {},
                    });

                const item = set[element.index];
                item.fields[element.variableName] = element;
            });

            state.configurationSets = sets;
        },
        setSets: (state, { payload }: { payload: Record<string, FieldSetDetails> }) => {
            const sets: WidgetState['configurationSets'] = {};
            Object.entries(payload).forEach(([k, v]) => (sets[k] = v.content));
            state.configurationSets = { ...sets };
        },
        setConfigurationFields: (state, { payload }: { payload: Configuration['fields'] }) => {
            payload.forEach((f) => (state.configurationFields[f.uniqueName] = { ...f }));
        },
        setConfigurationMessages: (state, { payload }: { payload: Configuration['messages'] }) => {
            state.configurationMessages = [...payload];
        },
        setFromConfiguration: (state, { payload }: { payload: Configuration }) => {
            //payload.fields.forEach((f) => (state.configurationFields[f.variableName] = { ...f }));
            state.configurationFields = {
                ...state.configurationFields,
                ...Object.fromEntries(payload.fields.map((f) => [f.uniqueName, f])),
            };
            state.configurationMessages = [...payload.messages];
            state.siteId = getFieldValue<string>(state.configurationFields, LogikFieldsEnum.siteId) || state.siteId;
            state.productId =
                getFieldValue<string>(state.configurationFields, LogikFieldsEnum.product) || state.productId;
            state.territory =
                getFieldValue<string>(state.configurationFields, LogikFieldsEnum.territory) || state.territory;
            state.storeId = getFieldValue<string>(state.configurationFields, LogikFieldsEnum.dept) || state.storeId;
        },
        setLoading: (state, { payload }: { payload: boolean }) => {
            state.loading = payload;
        },
        removeConfigurationMessagesForField: (state, { payload }: { payload: string }) => {
            state.configurationMessages = [...state.configurationMessages.filter((cm) => cm.field !== payload)];
        },
        setSaving: (state, { payload }: { payload: boolean }) => {
            state.saving = payload;
        },
        setTooltips: (state, { payload }: { payload: WidgetState['tooltips'] }) => {
            state.tooltips = payload;
        },
        setActionType: (state, { payload }: { payload: WidgetInitState['actionType'] }) => {
            if (state.init) state.init.actionType = payload;
        },
        setImages: (state, { payload }: { payload: Image3D[] }) => {
            state.images3D = payload;
        },
    },
    extraReducers: (builder) => {
        addSetUserProfileCases(builder);
        addInitWidgetCases(builder);

        addFetchSetsCases(builder);
        addFetchPricingDetailsCases(builder);

        addPostProcessConfigurationCases(builder);
        addUpdateConfigurationCases(builder);
        addSaveAndRecreateConfigurationCases(builder);
        addRecreateConfigurationCases(builder);
        addSaveAndSendQuoteAndRecreateConfigurationCases(builder);
        addCreateConfigurationCases(builder);
        addSaveConfigurationCases(builder);

        addFetchInitConfigurationCases(builder);
        addEditInitConfigurationCases(builder);
        addCopyInitConfigurationCases(builder);
        addCreateInitConfigurationCases(builder);

        addSaveThreeKitConfigurationCases(builder);
        addUpdateTotalPriceCases(builder);

        addSavePrebuiltConfigurationCases(builder);
        addSavePreownedConfigurationCases(builder);

        addFetchTooltipsCases(builder);
        addUpdateTaxRateCases(builder);

        addCreateProductTemplateCases(builder);
        addEditProductTemplateCases(builder);
        addSaveProductTemplateCases(builder);

        addCreateBuildingPackageCases(builder);
        addEditBuildingPackageCases(builder);
        addDuplicateBuildingPackageCases(builder);
        addSaveBuildingPackageCases(builder);

        addCreateStockRequestCases(builder);
        addEditStockRequestCases(builder);
        addSaveStockRequestCases(builder);

        // builder.addMatcher(
        //     (action) => action.type.endsWith('/rejected'),
        //     (_, action) => {
        //         logger.error(action.error);
        //     },
        // );
    },
});

export const {
    setMasterConfigurationId,
    setUUID,
    setSiteId,
    setWidgetState,
    setInitialWidgetState,
    setInitializing,
    setFieldsUpdating,
    setFieldsValueDuringUpdate,
    setProductId,
    setThreekitId,
    setExpired,
    setTotal,
    setSubTotal,
    setStoreId,
    setStore,
    setPricingDetails,
    setSetsFromFieldsList,
    setSets,
    setConfigurationFields,
    setConfigurationMessages,
    setFromConfiguration,
    setLoading,
    removeConfigurationMessagesForField,
    setSaving,
    setTooltips,
    setActionType,
    setImages,
} = widgetSlice.actions;

export const selectFields = (state: RootState): WidgetState['configurationFields'] => state.widget.configurationFields;
export const selectSets = (state: RootState): WidgetState['configurationSets'] => state.widget.configurationSets;
export const selectMessages = (state: RootState): ConfigurationMessage[] => state.widget.configurationMessages;
export const selectPricingDetails = (state: RootState): ProductDetail[] => state.widget.pricingDetails;
export default widgetSlice.reducer;
