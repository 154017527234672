import React, { HTMLAttributes, PropsWithChildren, useEffect } from 'react';
export interface GMapProps extends HTMLAttributes<HTMLDivElement> {
    style?: { [key: string]: string };
    onMapClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    options?: google.maps.MapOptions;
}

export const GMap: React.FC<PropsWithChildren<GMapProps>> = ({
    onMapClick,
    onIdle,
    children,
    style,
    options,
    ...props
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [map]);

    useEffect(() => {
        if (map && options) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

            if (onMapClick) {
                map.addListener('click', onMapClick);
            }

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, [map, onMapClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} {...props} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map } as google.maps.MarkerOptions);
                }
            })}
        </>
    );
};
