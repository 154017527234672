import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { setUUID, WidgetState } from '../../Widget.slice';
import { fetchConfiguration } from '../basic/fetchConfiguration';

export const fetchInitConfiguration = createAsyncThunk<
    Configuration,
    void,
    {
        state: RootState;
    }
>('init/fetch', async (_, thunkAPI): Promise<Configuration> => {
    const widgetInitState = thunkAPI.getState().widget.init;

    if (!widgetInitState?.uuid) {
        throw thunkAPI.rejectWithValue("Can't open configuration. No UUID");
    } else {
        const configuration = await thunkAPI.dispatch(fetchConfiguration(widgetInitState?.uuid)).unwrap();
        thunkAPI.dispatch(setUUID(configuration.uuid));

        return configuration;
    }
});

export const addFetchInitConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(fetchInitConfiguration.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchInitConfiguration.fulfilled, (state, action) => {
            state.loading = false;
            state.productId = action.payload.fields.find((f) => f.variableName === LogikFieldsEnum.product)
                ?.value as string;
        })
        .addCase(fetchInitConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
