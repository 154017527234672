import { FloatButton } from 'antd';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { AdminSection } from '../admin-overlay/AdminSection';
import { MoreOutlined, SaveOutlined } from '@ant-design/icons';
import { useSavePrebuiltFunc } from './prebuilts.hooks';

export const PrebuiltsAdminSection = (): ReactElement => {
    const { siteId } = useParams();
    const savePrebuilt = useSavePrebuiltFunc();

    return (
        <AdminSection
            permissions={['prebuilts.update', 'prebuilts.create', 'prebuilts.read']}
            role='admin'
            siteId={siteId}>
            <FloatButton.Group trigger='click' type='primary' style={{ right: 24 }} icon={<MoreOutlined />}>
                <FloatButton icon={<SaveOutlined />} onClick={savePrebuilt} />
            </FloatButton.Group>
        </AdminSection>
    );
};
