// export const useFieldMessages = (fieldName: string): FieldMessage[] =>
//     useAppSelector((state) => fieldMessagesSelector(state, fieldName));

import { isEqual } from 'lodash';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { selectMessages } from '../../../features/widget/Widget.slice';
import { selectFieldMessages, selectSetFieldMessages } from '../../store/selectors';
import { FieldMessage, SetFieldMessage } from '../models/message.model';

// export const useSetFieldMessages = (fieldName: string, index: number, setName?: string): SetFieldMessage[] =>
//     useAppSelector((state) => setFieldMessagesSelector(state, fieldName, index, setName), shallowEqual);

export const useMessages = (): FieldMessage[] => useAppSelector(selectMessages, shallowEqual);
export const useFieldMessages = (fieldName: string): FieldMessage[] =>
    useAppSelector((state) => selectFieldMessages(fieldName)(state), isEqual);

export const useSetFieldMessages = (fieldName: string, index: number, setName?: string): SetFieldMessage[] =>
    useAppSelector((state) => selectSetFieldMessages(fieldName, index, setName)(state), isEqual);

export const useFieldOrSetMessages = (fieldName: string, index?: number, setName?: string): FieldMessage[] =>
    useAppSelector(
        (state) =>
            index ? selectSetFieldMessages(fieldName, index, setName)(state) : selectFieldMessages(fieldName)(state),
        isEqual,
    );
