import { Store } from '../models/store.model';
import { ServiceBase } from './service-base';

class StoresServiceImpl extends ServiceBase {
    public async fetchStores(siteId?: string | null): Promise<Store[]> {
        const r = await this.http.get<Store[]>(siteId ? `api/${siteId}/stores` : 'api/stores');
        return r.data;
    }

    public async fetchStore(storeId: string): Promise<Store> {
        const r = await this.http.get<Store>(`api/stores/${storeId}`);
        return r.data;
    }
}

export const StoresService = new StoresServiceImpl();
