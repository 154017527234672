import { useAppSelector } from '../../app/hooks';

import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';
import { PrebuiltConfigurator } from './PrebuiltConfigurator';
import { fieldsLogic } from '@ulrichlifestyle/configurator';

export const PrebuiltEditPage = (): JSX.Element => {
    const uuid = useAppSelector((state) => state.widget.uuid);
    const configurationFields = useAppSelector((state) => Object.values(state.widget.configurationFields));
    const configurationMessages = useAppSelector((state) => state.widget.configurationMessages);
    const threeKitId = useAppSelector((state) => state.widget.threeKitId);
    const tooltips = useAppSelector((state) => state.widget.tooltips);

    return uuid ? (
        <PrebuiltConfigurator
            configuration={{
                logicConfig: {
                    fields: configurationFields as fieldsLogic,
                    message: configurationMessages,
                    uuid,
                    hints: tooltips,
                },
                reconfigurationConfig: threeKitId ? { threeKitId } : undefined,
            }}
        />
    ) : (
        <WidgetAsyncFallback />
    );
};
