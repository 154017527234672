/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export class LogEntry {
    public readonly message?: string;
    public readonly data?: any;

    public constructor(message?: string | Error, data?: any) {
        if (message instanceof Error) {
            this.message = this.formatError(message);
        } else {
            this.message = message;
        }

        this.data = data;
    }

    private formatError(error: Error): string {
        return `${error.stack}`;
    }
}
