export type ProductInfo = {
    title: string;
    image: string;
    id: string;
    roofType: string;
    order: number;
};

export const productsList: ProductInfo[] = [
    {
        title: 'The Texan',
        image: '/products-images/Texan.png',
        id: 'FG-UL-SL-21-BSH',
        roofType: 'Loften',
        order: 2,
    },
    {
        title: 'The Rancher',
        image: '/products-images/Rancher.png',
        id: 'FG-UL-SL-21-CSB',
        roofType: 'Loften',
        order: 6,
    },

    {
        title: 'The Heritage',
        image: '/products-images/Heritage.png',
        id: 'FG-UL-SL-21-GSH',
        roofType: 'Gable',
        order: 1,
    },

    {
        title: 'The Retreat',
        image: '/products-images/Retreat.png',
        id: 'FG-UL-SL-21-CSG',
        roofType: 'Gable',
        order: 5,
    },

    {
        title: 'The Workshop',
        image: '/products-images/Workshop.png',
        id: 'FG-UL-SL-21-CSW',
        roofType: 'Gable',
        order: 7,
    },
    {
        title: 'The Garden Cottage',
        image: '/products-images/GardenCottage.png',
        id: 'FG-UL-SL-21-OSH',
        roofType: 'CottageShed',
        order: 3,
    },

    {
        title: 'The Modern Studio',
        image: '/products-images/ModernStudio.png',
        id: 'FG-UL-SL-21-MSH',
        roofType: 'ModernShed',
        order: 4,
    },
    {
        title: 'The Lean To',
        image: '/products-images/LeanTo.png',
        id: 'FG-UL-SL-21-LTS',
        roofType: 'LeanTo',
        order: 9,
    },
    {
        title: 'The Cabana',
        image: '/products-images/Cabana.png',
        id: 'FG-UL-SL-21-HRS',
        roofType: 'HipRoof',
        order: 8,
    },
    {
        title: 'The Byler',
        image: '/products-images/Byler.png',
        id: 'FG-UL-SL-21-LOW',
        roofType: 'Loften',
        order: 10,
    },
    {
        title: 'The Victorian Cottage',
        image: '/products-images/VictorianCottage.png',
        id: 'FG-UL-SL-21-VPL',
        roofType: 'VictorianCottage',
        order: 11,
    },
    {
        title: 'The Cape Cottage',
        image: '/products-images/CapeCottage.png',
        id: 'FG-UL-SL-21-CPL',
        roofType: 'CapeCottage',
        order: 12,
    },
    {
        title: 'The Hideaway',
        image: '/products-images/Hideaway.png',
        id: 'FG-UL-SL-21-HPL',
        roofType: 'Hideaway',
        order: 13,
    },
];
