import { FloatButton, Modal } from 'antd';
import { useCallback, useMemo } from 'react';
import { AdminSection } from '../admin-overlay/AdminSection';
import { MoreOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PrebuiltStatus } from '../prebuilts/prebuilt.model';
import { overridePrebuiltMasterConfiguration } from '../prebuilts/thunks/overridePrebuiltMasterConfiguration';

const { confirm } = Modal;

export const WizardAdminSection = (): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const siteId = useAppSelector((state) => state.widget.siteId);
    const prebuilt = useAppSelector((state) => state.prebuilt.details);

    const saveMasterConfigAvailable = useMemo(() => prebuilt && prebuilt.statusId !== PrebuiltStatus.Sold, [prebuilt]);
    const overrideMasterConfig = useCallback(() => {
        confirm({
            title: 'Override master configuration',
            content: 'Are you sure you want to override master configuration? It will invalidate all existing quotes',
            onOk: async (close) => {
                try {
                    await dispatch(overridePrebuiltMasterConfiguration()).unwrap();
                } catch (err) {
                } finally {
                    close();
                }
            },
        });
    }, [dispatch]);

    return saveMasterConfigAvailable ? (
        <AdminSection
            permissions={['prebuilts.update', 'prebuilts.create', 'prebuilts.read']}
            role='admin'
            siteId={siteId}>
            <FloatButton.Group
                trigger='click'
                type='primary'
                style={{ right: 24 }}
                icon={<MoreOutlined />}
                tooltip={'Admin actions'}>
                {saveMasterConfigAvailable && (
                    <FloatButton
                        icon={<SaveOutlined />}
                        onClick={() => overrideMasterConfig()}
                        tooltip={"Update prebuilt's master config using current configuration"}
                    />
                )}
                {!prebuilt && <span>No actions available</span>}
            </FloatButton.Group>
        </AdminSection>
    ) : null;
};
