import { fieldsLogic, onSaveQuoteT, propertyForLogicType } from '@ulrichlifestyle/configurator';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductConfigurator } from '../configurator/ProductConfigurator';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { App } from 'antd';
import { updateTotalPrice } from '../configurator/thunks/updateTotalPrice';
import { setThreekitId, setConfigurationFields, setSaving } from '../widget/Widget.slice';
import { fetchConfiguration } from '../widget/thunks/basic/fetchConfiguration';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { ROUTE_SUMMARY } from '../wizard/wizard.router';
import { Field } from '../../shared/logik/models/field.model';
import { saveStockRequest } from './thunks/saveStockRequest';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { PricingModal } from '../configurator/PricingModal';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import styles from '../configurator/Configurator.module.scss';
import { publishEventAndRedirect } from '../../shared/utils/location.utils';
import {
    StockRequestEditEvent,
    StockRequestEditEventParams,
    StockRequestNewEvent,
    StockRequestNewEventParams,
} from './stock-requests.utils';
import { saveAndUploadSnapshots } from '../configurator/thunks/snapshots';

export const StockRequestConfiguratorPage = (): JSX.Element => {
    const uuid = useAppSelector((state) => state.widget.uuid);
    const configurationFields = useAppSelector((state) => state.widget.configurationFields);
    const configurationMessages = useAppSelector((state) => state.widget.configurationMessages);
    const threeKitId = useAppSelector((state) => state.widget.threeKitId);
    const tooltips = useAppSelector((state) => state.widget.tooltips);
    const configurationFieldsConverted = useMemo(() => Object.values(configurationFields), [configurationFields]);
    const [pricingModalHandle, setPricingModalHandle] = useState<FlexibleModalHandle<typeof PricingModal>>();

    const dispatch = useAppDispatch();
    const navigate = useNavigateExtended();
    const { modal } = App.useApp();

    const launchObjectId = useAppSelector((state) => state.widget.init?.launchObjectId);
    const masterConfigurationId = useAppSelector((state) => state.widget.masterConfigurationId);
    const site = useAppSelector((state) => state.widget.siteId);

    useEffect(() => {
        if (!pricingModalHandle) {
            const modal = FlexibleModal.createModal(PricingModal, {
                showCloseButton: true,
                destroyOnClose: true,
                className: styles['pricing-modal'],
            });
            setPricingModalHandle(modal);
        }
    }, [pricingModalHandle]);

    const finishConfiguration: onSaveQuoteT = useCallback(
        async (uuid, conf, saveThreeKitConfig, getSnapshots) => {
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));

            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            const { fields } = await dispatch(fetchConfiguration(uuid)).unwrap();
            await dispatch(updateTotalPrice());

            navigate('../' + ROUTE_SUMMARY);

            return Promise.resolve({
                uuid,
                fields: fields as fieldsLogic,
                message: [],
            });
        },
        [dispatch, navigate],
    );

    const showDetails = useCallback(async () => {
        await dispatch(
            updateTotalPrice({
                forceFetchBom: true,
            }),
        );
        pricingModalHandle?.open();
    }, [dispatch, pricingModalHandle]);

    const onZipChange = useCallback(async (_: string, fields: propertyForLogicType[]) => {
        return {
            fields: fields,
        };
    }, []);

    const save: onSaveQuoteT = useCallback(
        async (uuid, conf, saveThreeKitConfig, getSnapshots) => {
            dispatch(setSaving(true));
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));

            dispatch(setConfigurationFields(conf as Field[]));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            await dispatch(fetchConfiguration(uuid)).unwrap();
            await dispatch(updateTotalPrice());

            await dispatch(saveStockRequest()).unwrap();
            //TODO: redirect to transition page

            modal.confirm({
                title: 'Stock request has been successfully saved',
                content: 'Now you can close the window or go back and make some changes',
                icon: <></>,
                okText: 'Save and continue',
                cancelText: 'Save and close',
                okButtonProps: { size: 'small' },
                cancelButtonProps: { size: 'small' },
                onOk: async () => await dispatch(recreateConfiguration(uuid)),
                onCancel: async () => {
                    if (launchObjectId && site) {
                        if (!masterConfigurationId) {
                            await publishEventAndRedirect<StockRequestNewEventParams>(StockRequestNewEvent, {
                                launchObjectId,
                                site,
                            });
                        } else {
                            await publishEventAndRedirect<StockRequestEditEventParams>(StockRequestEditEvent, {
                                launchObjectId,
                                site,
                            });
                        }
                    }
                },
            });
            dispatch(setSaving(false));
            return Promise.resolve({
                uuid,
                fields: conf,
                message: [],
            });
        },
        [dispatch, launchObjectId, masterConfigurationId, modal, site],
    );

    return uuid ? (
        <ProductConfigurator
            logicConfig={{
                fields: configurationFieldsConverted as fieldsLogic,
                message: configurationMessages,
                uuid,
                hints: tooltips,
            }}
            threeKitId={threeKitId}
            onFinishConfig={finishConfiguration}
            onGetInfoConfiguration={showDetails}
            onChangeZipCode={onZipChange}
            saveButtonTitle='Save Stock Request'
            saveButtonCallback={save}
            goBackProductList={undefined}
            containerClassName={''}
            hideInstallationDialog
        />
    ) : (
        <WidgetAsyncFallback />
    );
};
