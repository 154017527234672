import {
    createSearchParams,
    NavigateOptions,
    To,
    useLocation,
    useMatch,
    useParams,
    useResolvedPath,
    useSearchParams,
} from 'react-router-dom';
import { FieldValue } from '../logik/models/field.model';
export type NavigateRelativeFunction = (to: To, options?: NavigateOptions) => void;

export const useLocationSearchParams = (): URLSearchParams => useSearchParams()[0];
export const useLocationSearchParamsNative = (): URLSearchParams => createSearchParams(window.location.search);
export const useLocationProductParam = (): string | null => useLocationSearchParams().get('product');
export const useLocationMasterConfigurationParam = (): string | null => useLocationSearchParams().get('uuid');
export const useLocationMasterConfigurationParamNative = (): string | null =>
    useLocationSearchParamsNative().get('uuid');

export const useLocationReconfigureIdParam = (): string | null => {
    const params = useLocationSearchParams();
    return params.get('reconfigureId') || params.get('configurationId');
};
export const useLocationThreekitIdParam = (): string | null => useLocationSearchParams().get('threeKitId');
export const useLocationInitialConfigurationParam = (): Record<string, FieldValue> =>
    Object.fromEntries(new URLSearchParams(decodeURIComponent(useLocationSearchParams().get('ic') ?? '')).entries());
export const useLocationInitialConfigurationParamNative = (): Record<string, FieldValue> =>
    Object.fromEntries(new URLSearchParams(window.atob(useLocationSearchParamsNative().get('ic') ?? '')).entries());
export const useLocationInReadOnlyMode = (): boolean => {
    const readonly = useLocationSearchParams().get('readonly');
    return readonly !== null && readonly !== '0' && readonly.toLowerCase() !== 'false';
};
export const useIsActiveUrl = (to: To): boolean => useMatch({ path: useResolvedPath(to).pathname, end: true }) != null;
export const useRoutePathPattern = (): string | undefined => {
    const routeParams = useParams();
    const routeMatch = useMatch({ path: useLocation().pathname, end: true });
    let route = routeMatch?.pattern.path;
    if (routeParams) {
        Object.entries(routeParams).forEach(([k, v]) => {
            if (v) {
                route = route?.replace(v, `:${k}`);
            }
        });
    }

    return route;
};
