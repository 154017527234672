import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { fetchSets } from '../../widget/thunks/basic/fetchSets';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { setUserProfile } from '../../widget/thunks/init/setUserProfile';
import { setMasterConfigurationId, setThreekitId, setUUID } from '../../widget/Widget.slice';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { Preowned } from '../preowned.model';
import { PreownedState, setPreowned } from '../preowned.slice';
import { LogikFieldsEnum, SerialTypeEnum } from '../../../shared/logik/constants/fields.constant';

export type editPreownedMasterConfigurationParams = {
    siteId: string;
    preowned: Preowned;
};
export type editPreownedMasterConfigurationResult = {
    configuration: Configuration;
};
export const editPreownedMasterConfiguration = createAsyncThunk<
    editPreownedMasterConfigurationResult,
    editPreownedMasterConfigurationParams,
    {
        state: RootState;
    }
>(
    'preowned/edit-master-configuration',
    async ({ siteId, preowned }, thunkAPI): Promise<editPreownedMasterConfigurationResult> => {
        if (!preowned.masterConfigurationId) throw new Error('Preowned has no master configuration');

        const dispatch = thunkAPI.dispatch;

        const c = await ConfiguratorAPI.getConfigurationByMasterId(preowned.masterConfigurationId);

        const uuid = c.logikId;

        if (uuid) {
            const configuration = await logikApiService.copyConfiguration(uuid, [
                {
                    variableName: LogikFieldsEnum.serialType,
                    value: SerialTypeEnum.preowned,
                },
            ]);
            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
            await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
            await dispatch(setUserProfile(configuration.uuid));

            await Promise.all([
                dispatch(
                    fetchSets({
                        uuid: configuration.uuid,
                        sets: configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
                    }),
                ),
                dispatch(fetchPricingDetails(configuration.uuid)),
            ]);

            dispatch(setUUID(configuration.uuid));
            dispatch(setThreekitId(c.threeKitId));
            dispatch(setMasterConfigurationId(preowned.masterConfigurationId));
            dispatch(setPreowned(preowned));

            return { configuration };
        } else {
            return Promise.reject('Invalid UUID');
        }
    },
    {
        condition: (_, { getState }) => !getState().widget.uuid,
    },
);

export const addEditPreownedMasterConfigurationCases = (
    builder: ActionReducerMapBuilder<PreownedState>,
): ActionReducerMapBuilder<PreownedState> => {
    return (
        builder
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(editPreownedMasterConfiguration.pending, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(editPreownedMasterConfiguration.fulfilled, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(editPreownedMasterConfiguration.rejected, (state) => {})
    );
};
