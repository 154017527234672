import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';

export enum ProductTemplatesActionTypeEnum {
    ProductTemplateNew = 'ProductTemplateNew',
    ProductTemplateEdit = 'ProductTemplateEdit',
}
export type ProductTemplatesActionType = keyof typeof ProductTemplatesActionTypeEnum;
export const ProductTemplateNewEvent = `${ProductTemplatesActionTypeEnum.ProductTemplateNew}Event`;
export const ProductTemplateEditEvent = `${ProductTemplatesActionTypeEnum.ProductTemplateEdit}Event`;
export type ProductTemplateNewEventParams = IFrameEventParams & {
    launchObjectId: string;
    site?: string;
};
export type ProductTemplateEditEventParams = ProductTemplateNewEventParams;
