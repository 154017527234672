import { Navigate, RouteObject, defer, LoaderFunctionArgs } from 'react-router-dom';
import { ROUTE_CONFIGURATOR, ROUTE_SUMMARY } from '../wizard/wizard.router';
import { WidgetAsyncError } from '../widget/WidgetAsyncError';
import { ProductTemplateWizard } from './ProductTemplateWizard';
import { ProductTemplateSummaryPage } from './ProductTemplateSummaryPage';
import { isAxiosError } from 'axios';
import { ProductsTemplatesAPI } from './product-templates.service';
import { ProductTemplateConfiguratorPage } from './ProductTemplateConfiguratorPage';
import { WidgetAsyncLoader } from '../widget/WidgetAsyncLoader';
import { RouteProtected } from '../../shared/models/route-protected.model';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { delayUntilAuthorized } from '../../shared/utils/delayUntilAuthorized';

const routes: RouteObject[] = [
    {
        index: true,
        element: <Navigate to={ROUTE_CONFIGURATOR} replace={true} />,
    },
    {
        path: ROUTE_CONFIGURATOR,
        element: <ProductTemplateConfiguratorPage />,
    },
    {
        path: ROUTE_SUMMARY,
        element: <ProductTemplateSummaryPage />,
    },
];

type ProductTemplateLoadResultBase = {
    launchObjectId?: string;
};

export type ProductTemplateLoadResult = ProductTemplateLoadResultBase & {
    id?: string;
    productId: string;
    launchObjectId?: string;
    masterConfigurationId?: string;
};

export const productsTemplatesRoutes: RouteProtected = {
    path: 'product-templates',
    errorElement: <WidgetAsyncError />,
    children: [
        {
            path: '',
            loader: delayUntilAuthorized(async ({ params, request }: LoaderFunctionArgs): Promise<unknown> => {
                const url = new URL(request.url);
                const productId = url.searchParams.get('productId') || url.searchParams.get('product');
                const id = params['id'] ?? '';

                if (!productId && !id) throw new Response('Product not found', { status: 404 });

                if (id) {
                    const template = ProductsTemplatesAPI.get(id, request.signal)
                        .then((p) => {
                            if (!p.product) throw new Error(`Product template with id '${id}' was not found`);
                            return {
                                productId: p.product.model,
                                masterConfigurationId: p.masterId,
                                id: p.id,
                                launchObjectId: p.id,
                            } as ProductTemplateLoadResult;
                        })
                        .catch((r) => {
                            if (isAxiosError(r)) {
                                if (r.response?.status === 404) {
                                    throw new Response(`Product template with id '${id}' was not found`, {
                                        status: 404,
                                    });
                                }
                            }
                            throw r;
                        });

                    return defer({
                        template,
                    });
                } else {
                    return defer({
                        template: {
                            launchObjectId: url.searchParams.get('launchObjectId'),
                            productId: productId,
                        },
                    });
                }
            }),
            shouldRevalidate: () => false,
            element: (
                <WidgetAsyncLoader<{ [key: string]: unknown }> resolverSelector={(data) => data.template}>
                    <ErrorBoundary>
                        <ProductTemplateWizard />
                    </ErrorBoundary>
                </WidgetAsyncLoader>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={'new'} replace={true} />,
                },
                {
                    path: 'new',
                    children: [...routes],
                },
                {
                    path: ':id',
                    children: [
                        {
                            index: true,
                            element: <Navigate to={'edit'} replace={true} />,
                        },
                        {
                            path: 'edit',
                            children: [...routes],
                        },
                    ],
                },
            ],
        },
    ],
};
