import axios, { AxiosInstance } from 'axios';
import { ConfigurationFactory } from '../configuration.factory';
import { Configuration } from '../models/configuration.model';
import { FieldUpdate, SetFieldUpdate } from '../models/field.model';
import { FieldSetDetails } from '../models/field-set.details.model';
import { BomDetails } from '../models/bom.detals.model';
import { Environment } from '../../../app/environment';
import { LayoutDetails } from '../models/layout-details.model';

class LogikAPI {
    private get http(): AxiosInstance {
        const http = axios.create({
            baseURL: Environment.logikApiUrl,
            timeout: 5000000,
        });

        http.defaults.headers.common['Authorization'] = `Bearer ${Environment.logikApiToken}`;

        return http;
    }

    public async fetchConfiguration(uuid: string): Promise<Configuration> {
        const res = await this.http.get(`/${uuid}`);
        return ConfigurationFactory.createConfigurationFromResponse(res.data);
    }

    public async updateConfiguration(uuid: string, fields: (FieldUpdate | SetFieldUpdate)[]): Promise<Configuration> {
        const res = await this.http.patch(`/${uuid}`, {
            fields,
        });
        return ConfigurationFactory.createConfigurationFromResponse(res.data);
    }

    public async saveConfiguration(uuid: string): Promise<Configuration> {
        const res = await this.http.patch(`/${uuid}?save=true`, {});

        return ConfigurationFactory.createConfigurationFromResponse(res.data);
    }

    public async createConfiguration(
        initialConfiguration: (FieldUpdate | SetFieldUpdate)[] = [],
    ): Promise<Configuration> {
        const res = await this.http.post('/', {
            sessionContext: {
                stateful: true,
            },
            partnerData: {
                product: {
                    configuredProductId: Environment.logikProductId,
                },
            },
            fields: initialConfiguration,
        });

        return ConfigurationFactory.createConfigurationFromResponse(res.data);
    }

    public async copyConfiguration(
        configurationId: string,
        updates: (FieldUpdate | SetFieldUpdate)[] = [],
    ): Promise<Configuration> {
        const res = await this.http.post('/', {
            sessionContext: {
                stateful: true,
            },
            partnerData: {
                product: {
                    configuredProductId: Environment.logikProductId,
                    configurationAttributes: {
                        LGK__ConfigurationId__c: configurationId,
                    },
                },
            },
            fields: updates,
        });

        return ConfigurationFactory.createConfigurationFromResponse(res.data);
    }

    public async fetchSet(configurationId: string, setName: string): Promise<FieldSetDetails> {
        return (await this.http.get(`/${configurationId}/sets/${setName}?size=1000`)).data;
    }

    public async fetchBOM(configurationId: string): Promise<BomDetails> {
        return (await this.http.get(`/${configurationId}/bom?size=1000`)).data;
    }

    public async fetchBOMSales(configurationId: string): Promise<BomDetails> {
        return (await this.http.get(`/${configurationId}/bom/sales?size=1000`)).data;
    }

    public async fetchBOMManufacturing(configurationId: string): Promise<BomDetails> {
        return (await this.http.get(`/${configurationId}/bom/manufacturing?size=1000`)).data;
    }

    public async fetchLayoutDetails(layoutUrl: string): Promise<LayoutDetails> {
        if (layoutUrl.indexOf('/') === 0) {
            layoutUrl = layoutUrl.substring(1);
        }
        return (await this.http.get(`/${layoutUrl}`)).data;
    }
}

export default new LogikAPI();
