import styles from './Header.module.scss';
import { HeaderItem, HeaderItemProps } from './HeaderItem';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import {
    ROUTE_PRODUCTS,
    ROUTE_CONFIGURATOR,
    ROUTE_CUSTOMER_INFO,
    ROUTE_SUMMARY,
    ROUTE_REVIEW,
} from '../wizard/wizard.router';

export default function Header(): JSX.Element {
    const TITLE_PRODUCTS = 'Products';
    const TITLE_CONFIGURATOR = 'Design';
    const TITLE_CUSTOMER_INFO = 'Customer Info';
    const TITLE_SUMMARY = 'Purchase Options';
    const TITLE_REVIEW = 'Review & Pay';
    //const TITLE_PAYMENT = 'Payment';

    const quoteMode = useFieldValue<boolean>(LogikFieldsEnum.quoteMode);

    const items: HeaderItemProps[] = [
        {
            title: TITLE_PRODUCTS,
            url: ROUTE_PRODUCTS,
            index: 1,
        },
        {
            title: TITLE_CONFIGURATOR,
            url: ROUTE_CONFIGURATOR,
            index: 2,
        },
        {
            title: TITLE_CUSTOMER_INFO,
            url: ROUTE_CUSTOMER_INFO,
            index: 3,
        },
        {
            title: TITLE_SUMMARY,
            url: ROUTE_SUMMARY,
            index: 4,
        },
        {
            title: TITLE_REVIEW,
            url: ROUTE_REVIEW,
            index: 5,
        },
        // {
        //     title: TITLE_PAYMENT,
        //     url: ROUTE_CHECKOUT,
        //     index: 6,
        // },
    ];

    return (
        <div className={styles['main-menu']}>
            {items
                .filter((i) => (i.index === 5 ? !quoteMode : true))
                .map((i) => {
                    return <HeaderItem {...i} key={i.title} />;
                })}
        </div>
    );
}
