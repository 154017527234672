import { Input } from 'antd';
import { useContext } from 'react';
import { useFieldProperty } from '../hooks/field.hooks';
import { LogikFormItemContext } from './LogikFormItemBase';
import React from 'react';
import { useCustomFormItemErrorsAndWarnings } from '../hooks/form.hooks';
import { TextAreaProps, TextAreaRef } from 'antd/es/input/TextArea';

export type LogikTextAreaProps = TextAreaProps;
export const LogikTextArea = React.forwardRef<TextAreaRef, LogikTextAreaProps>(function LogikInput(
    { disabled, ...props }: LogikTextAreaProps & React.RefAttributes<TextAreaRef>,
    ref,
): JSX.Element {
    useCustomFormItemErrorsAndWarnings();
    const logikFormItemContext = useContext(LogikFormItemContext);
    const isEditable = useFieldProperty()(
        (f) => f.editable,
        logikFormItemContext?.logikName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    return <Input.TextArea {...props} disabled={disabled || isEditable === 'false'} ref={ref} />;
});
