import { InputProps } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import React, { RefObject } from 'react';
import { getMaskedInputValidators, MaskedInput, MaskedInputRef } from './MaskedInput';

export const PHONE_MASK = '+1 (000) 000-0000';
export type PhoneInputRef = MaskedInputRef & {
    validators: Rule[];
};
export type PhoneInputProps = InputProps & React.RefAttributes<PhoneInputRef>;
export const getPhoneInputValidators = (ref: RefObject<PhoneInputRef>, message?: string): Rule[] => [
    ...getMaskedInputValidators(ref, 'Phone Number is not complete'),
];

export const PhoneInput = React.forwardRef<Partial<PhoneInputRef>, PhoneInputProps>(function PhoneInput(
    { ...props }: PhoneInputProps,
    innerRef,
): JSX.Element {
    return (
        <MaskedInput maskOptions={{ mask: PHONE_MASK, placeholderChar: '0', lazy: true }} {...props} ref={innerRef} />
    );
});
