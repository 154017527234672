import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import {
    fetchShipZipCode,
    fetchProduct,
    fetchConfigurationEventDataFromFields,
} from '../../../../shared/logik/utils/fields.utils';
import { ConfiguratorAPI } from '../../../../shared/services/configurator.service';
import { updateTotalPrice } from '../../../configurator/thunks/updateTotalPrice';
import { setMasterConfigurationId, setSubTotal, setTotal, WidgetState } from '../../Widget.slice';
import { recreateConfiguration } from '../basic/recreateConfiguration';
import { updateConfiguration } from '../basic/updateConfiguration';

export const saveAndSendQuoteAndRecreateConfiguration = createAsyncThunk<
    { configuration: Configuration; masterId: string; oldMasterId?: string; oldLogikId: string },
    {
        email: string;
        name: string;
        uuid?: string;
        threeKitId?: string;
        productId?: string;
        sendEmail?: boolean;
    },
    {
        state: RootState;
    }
>(
    'quote/saveAndSendQuoteAndRecreateConfiguration',
    async (
        args,
        thunkAPI,
    ): Promise<{ configuration: Configuration; masterId: string; oldMasterId?: string; oldLogikId: string }> => {
        const dispatch = thunkAPI.dispatch;
        const widgetState = thunkAPI.getState().widget;

        const uuid = args.uuid ?? widgetState.uuid;
        const productId = args.productId ?? widgetState.productId;
        const threeKitId = args.threeKitId ?? widgetState.threeKitId;

        if (!uuid || !productId) return Promise.reject("Can't send quote: invalid configuration data");

        const salesman = thunkAPI.getState().auth.user?.email;

        await dispatch(updateTotalPrice());
        await dispatch(
            updateConfiguration({
                updates: [
                    {
                        variableName: LogikFieldsEnum.cusEmail,
                        value: args.email,
                    },
                    {
                        variableName: LogikFieldsEnum.cusName,
                        value: args.name,
                    },
                ],
            }),
        );

        const savedConfig = await logikApiService.saveConfiguration(uuid);
        const subtotal = savedConfig.products?.find((p) => p.id === 'Subtotal')?.extended?.extPrice;
        dispatch(setSubTotal(subtotal));
        dispatch(setTotal(savedConfig.total));

        const res = await Promise.all([
            ConfiguratorAPI.saveQuote({
                logikId: uuid,
                productId: productId,
                email: args.email,
                name: args.name,
                zip: fetchShipZipCode(widgetState.configurationFields),
                product: fetchProduct(widgetState.configurationFields),
                threeKitId: threeKitId ?? '',
                data: {
                    ...fetchConfigurationEventDataFromFields(
                        widgetState.configurationFields,
                        savedConfig.products ?? [],
                        args.email,
                        args.name,
                        salesman,
                        widgetState.siteId,
                        widgetState.init?.dealId,
                        subtotal,
                        false,
                        args.sendEmail,
                        widgetState.masterConfigurationId ? 'QuoteEdit' : 'QuoteNew',
                    ),
                    images: widgetState.images3D,
                },
                masterConfigurationId: widgetState.masterConfigurationId,
            }),
            dispatch(recreateConfiguration(uuid)).unwrap(),
        ]);
        if (!res[0].masterConfigurationId) return Promise.reject('Failed to save a quote');
        dispatch(setMasterConfigurationId(res[0].masterConfigurationId));

        return {
            configuration: res[1],
            masterId: res[0].masterConfigurationId,
            oldMasterId: widgetState.masterConfigurationId,
            oldLogikId: uuid,
        };
    },
);

export const addSaveAndSendQuoteAndRecreateConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(saveAndSendQuoteAndRecreateConfiguration.pending, (state) => {
            state.sendingQuote = true;
            state.saving = true;
        })
        .addCase(saveAndSendQuoteAndRecreateConfiguration.fulfilled, (state) => {
            state.sendingQuote = false;
            state.saving = false;
        })
        .addCase(saveAndSendQuoteAndRecreateConfiguration.rejected, (state) => {
            state.sendingQuote = false;
            state.saving = false;
        });
};
