import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { isSetFieldVariable } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { setPricingDetails, setSetsFromFieldsList, setSubTotal, setTotal, WidgetState } from '../../Widget.slice';
import { postProcessConfiguration } from './postProcessConfiguration';
import { getDefaultLayout } from '../../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from './fetchTooltips';

export const fetchConfiguration = createAsyncThunk<
    Configuration,
    string,
    {
        state: RootState;
    }
>('configuration/fetch', async (uuid, thunkAPI): Promise<Configuration> => {
    const dispatch = thunkAPI.dispatch;
    if (!uuid) return Promise.reject('Invalid UUID');

    const configuration = await logikApiService.fetchConfiguration(uuid);
    if (configuration.products) dispatch(setPricingDetails(configuration.products));
    const setFields = configuration.fields.filter(isSetFieldVariable).sort((a, b) => a.index - b.index);
    dispatch(setSetsFromFieldsList(setFields));
    const subtotal = configuration.products?.find((p) => p.id === 'Subtotal')?.extended?.extPrice;
    dispatch(setSubTotal(subtotal));
    dispatch(setTotal(configuration.total));
    const layoutUrl = getDefaultLayout(configuration)?.url;
    if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
    await dispatch(postProcessConfiguration({ configuration }));

    return configuration;
});

export const addCreateConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(fetchConfiguration.pending, (state) => {
            state.loading = true;
            state.pricingUpdating = true;
        })
        .addCase(fetchConfiguration.fulfilled, (state) => {
            state.loading = false;
        })
        .addCase(fetchConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
