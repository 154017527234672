import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Environment } from '../../../../app/environment';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { FieldUpdate, isFieldOptionSet } from '../../../../shared/logik/models/field.model';
import { selectUser } from '../../../auth0/Auth0.slice';
import { RoleClaim, SalesmanClaim } from '../../../auth0/user.model';
import { WidgetState } from '../../Widget.slice';
import { updateConfiguration } from '../basic/updateConfiguration';
import logger from '../../../../app/logger';

export const setUserProfile = createAsyncThunk<
    void,
    string | undefined,
    {
        state: RootState;
    }
>(
    'init/setUserProfile',
    async (uuid, thunkAPI): Promise<void> => {
        try {
            const user = selectUser(thunkAPI.getState());
            const siteId = thunkAPI.getState().widget.siteId;

            const userProfileField = thunkAPI.getState().widget.configurationFields[LogikFieldsEnum.userProfile];

            let role: string | undefined;
            let salesman: string | undefined;
            const updates: FieldUpdate[] = [];

            if (user) {
                if (siteId) {
                    const namespace = Environment.auth0OOMSNamespace ?? '';

                    role = user
                        .getCustomClaimWithNamespace<RoleClaim[]>('roles', namespace)
                        ?.find((r) => r.site === siteId)?.name;

                    salesman = user
                        .getCustomClaimWithNamespace<SalesmanClaim[]>('salesmen', namespace)
                        ?.find((r) => r.site === siteId)?.slsman;

                    if (salesman) {
                        updates.push({
                            variableName: LogikFieldsEnum.salesperson,
                            value: salesman,
                        });
                    }
                }
            } else {
                role = 'Customer';
            }

            if (role && userProfileField && isFieldOptionSet(userProfileField)) {
                const q = role;
                const roleOption = userProfileField.optionSet.options.find((o) =>
                    o.value.toLowerCase().includes(q.toLowerCase()),
                );

                if (roleOption && userProfileField.value !== roleOption.value) {
                    updates.push({
                        value: roleOption.value,
                        variableName: LogikFieldsEnum.userProfile,
                    });
                }
            }

            if (updates.length > 0) {
                await thunkAPI.dispatch(
                    updateConfiguration({
                        uuid: uuid ?? thunkAPI.getState().widget.uuid,
                        updates: updates,
                        skipRelatedUpdates: true,
                    }),
                );
            }
        } catch (err) {
            logger.error(err);
            return Promise.reject('Error updating user profile');
        }
    },
    {
        condition: (uuid, thunkApi) => {
            let resolve: (value: boolean) => void;
            const continue$ = new Promise<boolean>((r) => (resolve = r));

            const clear = setInterval(() => {
                const state = thunkApi.getState();
                if (state.auth.authFinished) {
                    clearInterval(clear);
                    resolve(true);
                }
            }, 50);

            return continue$;
        },
    },
);

export const addSetUserProfileCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder;
};
