import { useEffect, useState } from 'react';
import variables from '../../styles/variables.module.scss';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export type DeviceType = 'mobile' | 'tablet' | 'desktop';
export const useScreenChange = (): {
    windowDimensions: { width: number; height: number };
    size: 'small' | 'middle';
    orientation: 'portrait' | 'landscape';
    device: DeviceType;
} => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        windowDimensions,
        size: windowDimensions.width <= Number(variables.mobileBreakpoint) ? 'small' : 'middle',
        orientation: windowDimensions.width < windowDimensions.height ? 'portrait' : 'landscape',
        device:
            windowDimensions.width <= Number(variables.mobileBreakpoint)
                ? 'mobile'
                : windowDimensions.width > Number(variables.mobileBreakpoint) &&
                  windowDimensions.width <= Number(variables.tabletBreakpoint)
                ? 'tablet'
                : 'desktop',
    };
};
