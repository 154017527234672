import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { DiscountTypes, LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { getFieldValueFromArray, SetField } from '../../../../shared/logik/models/field.model';
import { isArray } from 'lodash';
import { updateConfiguration } from '../basic/updateConfiguration';

export interface RemoveDeprecatedDiscountsArgs {
    configuration: Configuration;
}
export const removeDeprecatedDiscounts = createAsyncThunk<
    void,
    RemoveDeprecatedDiscountsArgs,
    {
        state: RootState;
    }
>('discounts/remove-deprecated', async ({ configuration }, thunkAPI): Promise<void> => {
    const dispatch = thunkAPI.dispatch;

    await dispatch(
        updateConfiguration({
            uuid: configuration.uuid,
            updates: [
                {
                    variableName: LogikFieldsEnum.availableDiscountTypes,
                    value: [],
                },
            ],
            forceUpdate: true,
        }),
    ).unwrap();

    const isOrder = getFieldValueFromArray(configuration.fields, LogikFieldsEnum.checkedOut) === true;
    if (isOrder) return;

    for (const discount of thunkAPI.getState().widget.configurationSets[LogikFieldsEnum.set_of_discounts]) {
        if (discount.fields[LogikFieldsEnum.set_of_discounts_discount_type].value === DiscountTypes.discCash) {
            const index = discount.fields[`set.${LogikFieldsEnum.set_of_discounts}.index`].value;
            let size: SetField['value'] | undefined = (
                configuration.fields.find(
                    (f) => f.variableName === `set.${LogikFieldsEnum.set_of_discounts}.size`,
                ) as SetField
            )?.value;

            if (size && isArray(size)) {
                size = [...size];
                size.splice(size.indexOf(String(index)), 1);
                let availableDiscounts = configuration.fields.find(
                    (f) => f.variableName === LogikFieldsEnum.availableDiscountTypes,
                )?.value as string[];

                if (availableDiscounts) {
                    availableDiscounts = [...availableDiscounts];
                    availableDiscounts.splice(availableDiscounts.indexOf(DiscountTypes.discCash), 1);
                }

                // const updatedConfig = await dispatch(
                //     updateConfiguration({
                //         uuid: configuration.uuid,
                //         updates: [
                //             {
                //                 variableName: `set.${LogikFieldsEnum.set_of_discounts}.size`,
                //                 value: size,
                //             },
                //         ],
                //         forceUpdate: true,
                //     }),
                // ).unwrap();
                //
                // if (!updatedConfig.configuration) throw new Error('Error while removing deprecated discounts');
            }
        }
    }
});
