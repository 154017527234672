import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';
import { ProductTemplateNewEventParams } from '../products-templates/product-templates.utils';

export enum BuildingPackagesActionTypeEnum {
    BuildingPackageNew = 'BuildingPackageNew',
    BuildingPackageEdit = 'BuildingPackageEdit',
    BuildingPackageDuplicate = 'BuildingPackageDuplicate',
}
export type BuildingPackagesActionType = keyof typeof BuildingPackagesActionTypeEnum;
export const BuildingPackageNewEvent = `${BuildingPackagesActionTypeEnum.BuildingPackageNew}Event`;
export const BuildingPackageEditEvent = `${BuildingPackagesActionTypeEnum.BuildingPackageEdit}Event`;
export const BuildingPackageDuplicateEvent = `${BuildingPackagesActionTypeEnum.BuildingPackageDuplicate}Event`;
export type BuildingPackageNewEventParams = IFrameEventParams & ProductTemplateNewEventParams;

export type BuildingPackageEditEventParams = BuildingPackageNewEventParams;
export type BuildingPackageDuplicateEventParams = BuildingPackageNewEventParams;
