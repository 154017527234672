import styles from './TerritoryChangeModal.module.scss';
import { Button, Space } from 'antd';

export type TerritoryChangeModalProps = {
    onRequestClose: () => void;
    onRequestCloseAndRedirect: () => void;
};
export const TerritoryChangeModal = ({
    onRequestClose,
    onRequestCloseAndRedirect,
}: TerritoryChangeModalProps): JSX.Element => {
    return (
        <div className={styles['content']}>
            <p className={styles['warning']}>
                Oops. The zip code that was entered at the &quot;Design&quot; stage in Customer Info does not match this
                one
            </p>
            <p className={styles['info']}>You can go back and change it or leave it as is</p>
            <div className={styles['buttons']}>
                <Space size={'small'} align='center'>
                    <Button onClick={onRequestCloseAndRedirect}>Go Back</Button>
                    <Button type='primary' onClick={onRequestClose}>
                        Close
                    </Button>
                </Space>
            </div>
        </div>
    );
};
