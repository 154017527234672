import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikSelect } from '../../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';

export function SummaryLDW(): JSX.Element {
    return (
        <>
            <LogikFormItem
                logikName={LogikFieldsEnum.ldw}
                label='LDW'
                rules={[
                    {
                        required: true,
                        message: 'LDW is required',
                    },
                ]}
                hasFeedback={false}>
                <LogikSelect
                    fieldName={LogikFieldsEnum.ldw}
                    showSearch
                    searchByLabel
                    placeholder='Accept Damage Waiver'
                />
            </LogikFormItem>
        </>
    );
}
