export const getKeyboardFocusableElements = (element: HTMLElement): HTMLElement[] => {
    return [
        ...element.querySelectorAll<HTMLElement>(
            'a[href]:not(.ignore-focus-on-enter), button:not(.ignore-focus-on-enter), input:not(.ignore-focus-on-enter):not([type=hidden]), textarea:not(.ignore-focus-on-enter), select:not(.ignore-focus-on-enter), details:not(.ignore-focus-on-enter),[tabindex]:not([tabindex="-1"]:not(.ignore-focus-on-enter))',
        ),
    ].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
};

export const focusNext = (container?: HTMLElement, activeElement?: HTMLElement): void => {
    const focusableElements = getKeyboardFocusableElements(container ?? document.body);

    const focusIndex = focusableElements.indexOf(activeElement ?? (document.activeElement as HTMLElement));
    const nextElement = focusableElements.length > focusIndex ? focusableElements[focusIndex + 1] : null;

    if (nextElement) nextElement.focus();
};

export const inIframe = (): boolean => window.self !== window.top;
