import { Outlet, useAsyncValue } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import styles from '../wizard/Wizard.module.scss';
import { UnloadGuard } from '../unload-guard/UnloadGuard';
import { LogikGlobalErrors } from '../../shared/logik/components/LogikGlobalErrors';
import { IdleHeartbeat } from '../idle/IdleHeartbeat';
import { Preowned } from './preowned.model';
import { createPreownedMasterConfiguration } from './thunks/createPreownedMasterConfiguration';
import { editPreownedMasterConfiguration } from './thunks/editPreownedMasterConfiguration';

export const PreownedWizard = (): JSX.Element => {
    //const { siteId } = useParams();
    const preowned = useAsyncValue() as Preowned;

    const dispatchingInit = useRef<boolean>(false);
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);

    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (!dispatchingInit.current && preowned && !uuid) {
            dispatchingInit.current = true;

            if (!preowned.masterConfigurationId) {
                dispatch(createPreownedMasterConfiguration({ preowned, siteId: preowned.siteId ?? '' }))
                    .unwrap()
                    .catch(showBoundary);
            } else {
                dispatch(editPreownedMasterConfiguration({ preowned, siteId: preowned.siteId ?? '' }))
                    .unwrap()
                    .catch(showBoundary);
            }
        }
    }, [dispatch, preowned, showBoundary, uuid]);

    return (
        <div className={styles['app-container-no-header']}>
            {/*<AdminOverlay siteId={siteId} role={'admin'}>*/}
            {/*    <PrebuiltsAdminSection />*/}
            {/*</AdminOverlay>*/}
            <UnloadGuard>
                <Outlet />
            </UnloadGuard>
            <LogikGlobalErrors />
            <IdleHeartbeat />
        </div>
    );
};
