import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { setUUID, WidgetState } from '../../Widget.slice';
import { postProcessConfiguration } from './postProcessConfiguration';
import { getDefaultLayout } from '../../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from './fetchTooltips';

export const recreateConfiguration = createAsyncThunk<
    Configuration,
    string,
    {
        state: RootState;
    }
>('configuration/recreate', async (uuid, thunkAPI): Promise<Configuration> => {
    const copy = await logikApiService.copyConfiguration(uuid);
    const layoutUrl = getDefaultLayout(copy)?.url;
    if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
    await thunkAPI.dispatch(postProcessConfiguration({ configuration: copy }));

    thunkAPI.dispatch(setUUID(copy.uuid));
    return copy;
});

export const addRecreateConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(recreateConfiguration.pending, (state) => {
            state.loading = true;
            state.pricingUpdating = true;
        })
        .addCase(recreateConfiguration.fulfilled, (state) => {
            state.loading = false;
        })
        .addCase(recreateConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
