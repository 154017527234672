import { UnloadGuard } from '../../features/unload-guard/UnloadGuard';
import { TransitionPageRequest } from '../models/transition-page.model';
import { TransitionPageService } from '../services/transition-page.service';
import { EventBus } from '../../app/eventbus';
import { inIframe } from './document.utils';

export const redirectToUrl = (url: string, disableUnloadGuard = true): void => {
    if (disableUnloadGuard) UnloadGuard.disable();
    window.location.href = url;
};

export const redirectToTransitionPage = async (
    request: TransitionPageRequest,
    disableUnloadGuard = true,
): Promise<void> => {
    const url = await TransitionPageService.getUrl(request);
    redirectToUrl(url, disableUnloadGuard);
};

export const publishEventAndRedirect = async <TParams>(
    event: string,
    eventParams: TParams,
    redirectData?: TransitionPageRequest,
    disableUnloadGuard = true,
): Promise<void> => {
    EventBus.dispatch<TParams>(event, eventParams);

    if (redirectData && !inIframe()) {
        await redirectToTransitionPage(redirectData, disableUnloadGuard);
    }
};
