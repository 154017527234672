import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { useRoutePathPattern } from '../../shared/hooks/hooks';
export const useGAPageView = (): ((url: string) => void) => {
    return (url: string) => {
        if (!ReactGA.isInitialized) return;
        ReactGA.send({
            hitType: 'pageview',
            page: url,
        });
    };
};

export const useGAEvent = (): ((event: UaEventOptions) => void) => {
    return (event: UaEventOptions) => {
        if (!ReactGA.isInitialized) return;
        ReactGA.event(event);
    };
};

export const useGATrackPageView = (): void => {
    const route = useRoutePathPattern();
    const gaSend = useGAPageView();

    useEffect(() => {
        if (route) {
            try {
                //gaSend(route);
            } catch (err) {
                console.error(err);
            }
        }
    }, [gaSend, route]);
};
