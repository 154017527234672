import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { FieldUpdate, SetFieldUpdate } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { setUUID, WidgetState } from '../../Widget.slice';
import { postProcessConfiguration } from './postProcessConfiguration';
import { getDefaultLayout } from '../../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from './fetchTooltips';

export const createConfiguration = createAsyncThunk<
    Configuration,
    (FieldUpdate | SetFieldUpdate)[],
    {
        state: RootState;
    }
>('configuration/create', async (initConfiguration, thunkAPI): Promise<Configuration> => {
    const configuration = await logikApiService.createConfiguration(initConfiguration);
    const layoutUrl = getDefaultLayout(configuration)?.url;
    if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
    await thunkAPI.dispatch(postProcessConfiguration({ configuration }));
    thunkAPI.dispatch(setUUID(configuration.uuid));
    return configuration;
});

export const addCreateConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(createConfiguration.pending, (state) => {
            state.loading = true;
        })
        .addCase(createConfiguration.fulfilled, (state) => {
            state.loading = false;
        })
        .addCase(createConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
