import logger from '../../app/logger';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { Page } from '../../shared/components/layout/Page';
import { SummaryPageContent, SummaryFormState } from '../summary/SummaryPageContent';

import { savePrebuiltMasterConfiguration } from './thunks/savePrebuiltMasterConfiguration';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { App } from 'antd';
import { useLogikForm } from '../../shared/logik/hooks/form.hooks';
import { EventBus } from '../../app/eventbus';
import { inIframe } from '../../shared/utils/document.utils';
import { SavePrebuiltMasterConfigEventParams, SavePrebuiltMasterConfigEvent } from './PrebuiltConfigurator';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';

const notificationDuration = 5;
const notificationPlacement: NotificationPlacement = 'bottomLeft';
const errorMessage = 'An unexpected error occurred';
const notificationKey = 'prebuilt-notification';

export function PrebuiltSummaryPage(): JSX.Element {
    const navigate = useNavigateExtended();
    const dispatch = useAppDispatch();
    const [form] = useLogikForm<SummaryFormState>();
    const siteId = useAppSelector((state) => state.widget.siteId);
    const { modal, notification } = App.useApp();
    const onFinish = async (values: unknown) => {
        try {
            const res = await dispatch(savePrebuiltMasterConfiguration()).unwrap();
            modal.confirm({
                title: 'Prebuilt has been successfully saved',
                content: 'Now you can close the window or go back and make some changes',
                icon: <></>,
                okText: 'Save and continue',
                cancelText: 'Save and close',
                onOk: () => dispatch(recreateConfiguration(res.configuration.uuid)),
                onCancel: async () => {
                    if (siteId) {
                        EventBus.dispatch<SavePrebuiltMasterConfigEventParams>(SavePrebuiltMasterConfigEvent, {
                            serial: res.serial,
                            siteId,
                        });

                        if (!inIframe()) {
                            await redirectToTransitionPage({
                                site: siteId,
                                redirectType: 'SavePrebuiltConfig',
                                serial: res.serial,
                            });
                        }
                    } else {
                        setTimeout(() => {
                            notification.error({
                                message: errorMessage,
                                description: `Please try again later`,
                                duration: notificationDuration,
                                placement: notificationPlacement,
                                key: notificationKey,
                            });

                            logger.error(new Error('SiteId is undefined'));
                        }, 0);
                    }
                },
            });
        } catch (err: unknown) {
            logger.error(err);
        }
    };

    return (
        <Page
            navProps={{
                onClickBack: () => navigate('..', { preserveSearch: true }),
                onClickNext: () => form.submit(),
                nextButtonTitle: 'Finish',
                hideSaveButton: true,
            }}>
            <SummaryPageContent onFinish={onFinish} form={form} />
        </Page>
    );
}
