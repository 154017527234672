import { ErrorBase } from './error-base';
import { IconMap } from 'antd/es/result';

export class DomainError extends ErrorBase {
    private readonly _type: keyof typeof IconMap;
    private readonly _originalError?: Error;

    get originalError(): Error | undefined {
        return this._originalError;
    }

    get type(): keyof typeof IconMap {
        return this._type;
    }

    constructor(message: string, type?: keyof typeof IconMap, originalError?: Error) {
        super(message);
        this._type = type ?? 'error';
        this._originalError = originalError;
    }
}
