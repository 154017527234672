export enum FileUploadOptions {
    onDuplicateIgnore = 'onDuplicateIgnore',
    onDuplicateRename = 'onDuplicateRename',
    onDuplicateReplace = 'onDuplicateReplace',
    onDuplicateError = 'onDuplicateError',
}

export enum FileState {
    temporary = 0,
    permanent = 1,
    deleted = 2,
}
export interface FileUploadModel {
    files: File[];
    path: string;
    keepFileNames: boolean;
    fileUploadOptions: FileUploadOptions;
    isPublic: boolean;
    state?: FileState;
}
