import { useEffect, useMemo } from 'react';
import { Environment } from './environment';

export type EventBusContext = Window;

export interface EventBusOptions {
    context: EventBusContext;
}

export interface EventBusSubscription {
    unsubscribe: () => void;
}

export type EventBusPromiseSubscription<T> = EventBusSubscription & {
    promise: Promise<EventBusMessage<T>>;
};

export interface EventBusMessage<T> {
    event: string;
    payload: T;
}

export const isEventBusMessage = <T>(ev: unknown): ev is EventBusMessage<T> => {
    const propsNames = Object.getOwnPropertyNames(ev);
    return propsNames.includes('event') && propsNames.includes('payload');
};

class EventBusImpl {
    constructor(private options: EventBusOptions) {}

    subscribe = <T>(event: string, callback: (event: EventBusMessage<T>) => void): EventBusSubscription => {
        const handler = (e: MessageEvent<unknown>) => {
            if (isEventBusMessage<T>(e.data) && e.data.event === event) {
                callback(e.data);
            }
        };

        this.options.context.addEventListener('message', handler);

        return {
            unsubscribe: () => this.options.context.removeEventListener('message', handler),
        };
    };

    subscribeAsync = <T>(event: string): EventBusPromiseSubscription<T> => {
        let resolver: (value: EventBusMessage<T>) => void;

        const promise = new Promise<EventBusMessage<T>>((resolve, reject) => {
            resolver = resolve;
        });

        const handler = (e: MessageEvent<unknown>) => {
            if (isEventBusMessage<T>(e.data) && e.data.event === event) {
                resolver(e.data);
            }
        };

        this.options.context.addEventListener('message', handler);

        return {
            promise: promise,
            unsubscribe: () => this.options.context.removeEventListener('message', handler),
        };
    };

    dispatch = <T>(event: string, payload: T): void => {
        this.options.context.parent.postMessage(
            {
                event,
                payload,
            },
            Environment.isDevelopment ? '*' : Environment.omsOrigin,
        );
    };
}
export const EventBus = new EventBusImpl({
    context: window,
});

export const useEventBus = (): EventBusImpl => {
    return useMemo(() => EventBus, []);
};
export const useEventBusSubscribe = <T = unknown>(
    event: string,
    handler: (message: EventBusMessage<T>) => void,
): void => {
    useEffect(() => {
        const { unsubscribe } = EventBus.subscribe(event, handler);

        return () => {
            unsubscribe();
        };
    }, [event, handler]);
};

export const useEventBusDispatch = (): EventBusImpl['dispatch'] => {
    return useMemo(() => EventBus.dispatch, []);
};
