import { Form, FormInstance, notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { FormState } from '../../models/form.state.model';
import { LogikFieldsEnum } from '../constants/fields.constant';
import { useConfigurationGlobalMessages } from './configuration.hooks';
import { convertFromLogikValue, getTypeFromLogikField } from '../utils/form.utils';
import useFormItemStatus from 'antd/es/form/hooks/useFormItemStatus';
import { LogikFormItemContext } from '../components/LogikFormItemBase';
import { useFieldOrSetMessages } from './messages.hooks';

export const useShowLogikErrors = (): void => {
    const messages = useConfigurationGlobalMessages();

    useEffect(() => {
        if (messages) {
            messages.forEach((m, i) => {
                const props: ArgsProps = {
                    message: m.message,
                    duration: 3,
                    placement: 'bottomLeft',
                    key: String(i),
                };

                switch (m.type) {
                    case 'info':
                        notification.info(props);
                        break;
                    case 'validation':
                    case 'error':
                        notification.error(props);
                        break;
                    case 'warning':
                        notification.warning(props);
                        break;
                    default:
                        notification.success(props);
                        break;
                }
            });
        }
    }, [messages]);
};

export const useLogikForm = <TFormState extends FormState>(): [FormInstance<TFormState>] => {
    //const configurationFields = useAppSelector(selectFields);
    const [form] = Form.useForm<TFormState>();
    //const inited = useRef<boolean>(false);

    // const setDefaultValues = useCallback(
    //     (form: FormInstance<TFormState>, configurationFields: WidgetState['configurationFields']) => {
    //         const formValues = form.getFieldsValue();
    //         const updatedState = getFormInitialFormState<TFormState>(configurationFields, formValues);
    //         // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //         form.setFieldsValue(updatedState as any);
    //     },
    //     [],
    // );

    // useEffect(() => {
    //     if (!inited.current && Object.keys(configurationFields).length > 0) {
    //         setDefaultValues(form, configurationFields);
    //         inited.current = true;
    //     }
    // }, [configurationFields, form, setDefaultValues]);

    return [form];
};

export const useLogikFormDefaultState = (): FormState => {
    const [formState, setFormState] = useState<FormState>({});
    const initialValues = useAppSelector((state) => state.widget.configurationFields);
    const formStateInited = useRef<boolean>(false);

    useLayoutEffect(() => {
        if (Object.values(initialValues).length > 0 && !formStateInited.current) {
            formStateInited.current = true;
            setFormState(
                Object.fromEntries(
                    Object.values(initialValues)
                        .filter((v) => Object.values(LogikFieldsEnum).includes(v.variableName as LogikFieldsEnum))
                        .map((v) => [
                            v.variableName,
                            convertFromLogikValue(v.value, getTypeFromLogikField(v.dataType)),
                        ]),
                ) as FormState,
            );
        }
    }, [initialValues]);

    return formState;
};

export const useCustomFormItemErrorsAndWarnings = (): void => {
    const { errors, warnings } = useFormItemStatus();
    const logikFormItemContext = useContext(LogikFormItemContext);
    const messages = useFieldOrSetMessages(
        logikFormItemContext?.logikName ?? '',
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    useLayoutEffect(() => {
        for (const m of messages ?? []) {
            if (m.error || m.type === 'error' || m.type === 'validation') {
                if (!errors.includes(m.message)) errors.push(m.message);
            }

            if (m.type === 'info' || m.type === 'warning') {
                if (!warnings.includes(m.message) && !m.message.includes('Recommended')) warnings.push(m.message);
            }
        }

        logikFormItemContext?.setErrors?.(errors);
        logikFormItemContext?.setWarnings?.(warnings);
    }, [errors, logikFormItemContext, messages, warnings]);
};
