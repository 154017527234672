import { PropsWithChildren, useCallback, useEffect } from 'react';
import { Environment } from '../../app/environment';
import { EventBusMessage } from '../../app/eventbus';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logger from '../../app/logger';
import { useEventBusSubscribe } from '../../shared/hooks/eventbus.hooks';
import { LogEntry } from '../../shared/models/logentry';
import { SaveConfigurationEvent, SaveConfigurationEventParams } from '../configurator/Configurator';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { SaveQuoteEvent, SaveQuoteEventParams } from '../wizard/SaveQuoteDialog';
import {
    IFrameProxyMessage,
    IFrameProxyMessageSetLogikField,
    IFrameProxyMessageSetLogikFieldBatch,
} from './iframe-proxy.mode';

export type IFrameEventParams = Record<string, unknown>;
export type IFrameProxyParams = PropsWithChildren;

export function IFrameProxy({ children }: IFrameProxyParams): JSX.Element {
    const onSaveConfiguration = useEventBusSubscribe<SaveConfigurationEventParams>(SaveConfigurationEvent);
    const onSaveQuote = useEventBusSubscribe<SaveQuoteEventParams>(SaveQuoteEvent);
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);

    const onMessage = useCallback(
        (e: MessageEvent<IFrameProxyMessage>) => {
            if (!Environment.frameAncestors.split('|').includes(e.origin)) return;
            logger.debug(new LogEntry('IFrameProxy MessageEvent', e));

            switch (e.data?.action) {
                case 'set-logik-field':
                    const msg = e.data as IFrameProxyMessageSetLogikField;
                    if (msg) {
                        dispatch(
                            updateConfiguration({
                                uuid: uuid,
                                updates: [
                                    {
                                        variableName: msg.payload.logikFieldName,
                                        value: msg.payload.logikValue,
                                    },
                                ],
                            }),
                        );
                    }
                    break;

                case 'set-logik-field-batch':
                    const batch = e.data as IFrameProxyMessageSetLogikFieldBatch;
                    if (batch) {
                        dispatch(
                            updateConfiguration({
                                uuid,
                                updates: batch.payload.map((b) => ({
                                    variableName: b.logikFieldName,
                                    value: b.logikValue,
                                })),
                            }),
                        );
                    }
                    break;
                default:
                    logger.warn(new LogEntry('Unknown message received', e.data));
            }
        },
        [dispatch, uuid],
    );

    useEffect(() => {
        const top = window.top;

        if (!!top) {
            const postMessage = (e: EventBusMessage<IFrameEventParams>) => top.postMessage(e, '*');
            onSaveConfiguration.then(postMessage);
            onSaveQuote.then(postMessage);
        }
        window.onmessage = (msg) => onMessage(msg);

        return () => {
            window.onmessage = null;
        };
    }, [onMessage, onSaveConfiguration, onSaveQuote]);

    return <>{children}</>;
}
