import { base64ToFile } from './base64.utils';

export interface MimeDetails {
    type?: string | null;
    subType?: string | null;
    attribute?: string;
    attributeValue?: string;
    fullMimeType: string;
}
export interface DataUrl {
    mimeType?: MimeDetails;
    data: string;
}

export const decodeDataUrl = (dataUrlEncoded: string): DataUrl | null => {
    const dataUrlRegex =
        /data:(?<mime>(?<type>[^;=/]+)\/(?<subtype>[^;=/]+)(?:;(?<attribute>[^;=]+)=(?<value>[^;=]+))?)?(?:;base64)?,(?<data>[a-zA-Z0-9\+\/=]+)/g;
    const matches = dataUrlRegex.exec(dataUrlEncoded);
    const data = matches?.groups?.['data'];

    if (data) {
        let mimeType: MimeDetails | undefined = undefined;
        const mimeFull = matches?.groups?.['mime'];
        if (matches && mimeFull) {
            mimeType = {
                fullMimeType: mimeFull,
                type: matches.groups?.['type'],
                subType: matches.groups?.['subtype'],
                attribute: matches.groups?.['attribute'],
                attributeValue: matches.groups?.['value'],
            };
        }
        return {
            data,
            mimeType,
        };
    } else {
        return null;
    }
};

export const dataUrlToFile = (dataUrlString: string, fileName: string): File | null => {
    const dataUrl = decodeDataUrl(dataUrlString);

    if (dataUrl) {
        return base64ToFile(dataUrl.data, `${fileName}.${dataUrl.mimeType?.subType}`, dataUrl.mimeType?.fullMimeType);
    } else {
        return null;
    }
};
