import { Form, Input, Space } from 'antd';
import { DiscountTypes, LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { FieldValue, Option } from '../../../shared/logik/models/field.model';
import { ReadOnlyAmtField } from './ReadOnlyAmtField';
import { LogikFormSetItem } from '../../../shared/logik/components/LogikFormSetItem';
import React, { useMemo, useRef, useState } from 'react';
import { isEqual, uniq } from 'lodash';
import { MinusCircleOutlined } from '@ant-design/icons';
import styles from './DiscountRowContent.module.scss';
import { getMoneyInputValidators, MoneyInput, MoneyInputRef } from '../../../shared/antd/components/MoneyInput';
import { LogikInput } from '../../../shared/logik/components/LogikInput';
import { useFieldValue } from '../../../shared/logik/hooks/field.hooks';
import { LogikComplexFormItem } from '../../../shared/logik/components/LogikComplexFormItem';
import { getPercentInputValidators, PercentInput } from '../../../shared/antd/components/PercentInput';

type DiscountRowImplProps = {
    index: number;
    definition: Option;
    onRemove: () => void;
};

const DiscountRowContentImpl = ({ index, definition, onRemove }: DiscountRowImplProps): JSX.Element => {
    const moneyRef = useRef<MoneyInputRef>(null);
    const userProfile = useFieldValue<string>(LogikFieldsEnum.userProfile);
    const readonly = useMemo(
        () => definition.extended?.[userProfile ?? 'Customer'] === 'Readonly',
        [definition.extended, userProfile],
    );

    const [labelErrors, setLabelErrors] = useState<React.ReactNode[]>([]);
    const [amountErrors, setAmountErrors] = useState<React.ReactNode[]>([]);

    const [labelWarnings, setLabelWarnings] = useState<React.ReactNode[]>([]);
    const [amountWarnings, setAmountWarnings] = useState<React.ReactNode[]>([]);

    const combinedErrors = uniq([...labelErrors, ...amountErrors]);
    const combinedWarnings = uniq([...labelWarnings, ...amountWarnings]);

    const tooltipFieldName = useMemo(
        () =>
            `set.${LogikFieldsEnum.set_of_discounts}.${LogikFieldsEnum.set_of_discounts_discount_type}.${definition.value}`,
        [definition.value],
    );

    const label = (
        <span className={styles['discount-label']}>
            <MinusCircleOutlined
                className={styles['discount-remove-btn']}
                onClick={definition.state === 'visible' || userProfile !== 'Customer' ? onRemove : () => {}}
            />
            <span className={styles['discount-label-text']}>{definition?.label}</span>
        </span>
    );

    return (
        <>
            <Form.Item name={[index, LogikFieldsEnum.set_of_discounts_discount_type]} hidden={true}>
                <Input type='hidden' />
            </Form.Item>

            {(definition.extended?.amtType === 'TextWLabel' || definition.extended?.amtType === 'MoneyWLabel') && (
                <>
                    <LogikComplexFormItem
                        label={label}
                        className={'direction-horizontal hide-errors hide-status-success hide-status-warning'}
                        extra={''}
                        help={''}
                        errors={combinedErrors}
                        warnings={combinedWarnings}>
                        <Space.Compact>
                            <LogikFormSetItem
                                hideErrors
                                hideWarnings
                                logikName={LogikFieldsEnum.set_of_discounts_discount_input}
                                index={index}
                                setName={LogikFieldsEnum.set_of_discounts}
                                messageVariables={{ name: definition.label }}
                                rules={[
                                    {
                                        required: true,
                                        message: `${definition.label} label is required`,
                                    },
                                ]}
                                updateOnBlur
                                tooltipContent={tooltipFieldName}
                                onErrors={setLabelErrors}
                                onWarnings={setLabelWarnings}
                                label={undefined}
                                hasFeedback={false}
                                className={'hide-errors'}>
                                <LogikInput placeholder={'Label'} autoFocus disabled={readonly} />
                            </LogikFormSetItem>

                            <LogikFormSetItem
                                hideErrors
                                hideWarnings
                                logikName={LogikFieldsEnum.set_of_discounts_discount_amt}
                                index={index}
                                setName={LogikFieldsEnum.set_of_discounts}
                                messageVariables={{ name: `${definition.label} amount` }}
                                rules={getMoneyInputValidators(moneyRef)}
                                updateOnBlur
                                tooltipContent={tooltipFieldName}
                                onErrors={setAmountErrors}
                                onWarnings={setAmountWarnings}
                                hasFeedback={false}>
                                <MoneyInput placeholder={'Label'} ref={moneyRef} disabled={readonly} />
                            </LogikFormSetItem>
                        </Space.Compact>
                    </LogikComplexFormItem>
                </>
            )}

            {(definition.extended?.amtType === 'Input' || definition.extended?.amtType === 'Auto') && (
                <>
                    <LogikFormSetItem
                        label={label}
                        direction='horizontal'
                        logikName={LogikFieldsEnum.set_of_discounts_discount_input}
                        index={index}
                        setName={LogikFieldsEnum.set_of_discounts}
                        messageVariables={{ name: definition.label }}
                        rules={[
                            {
                                required: definition?.value === DiscountTypes.discCoupon,
                            },
                            ({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    if (definition.value !== DiscountTypes.discCoupon) return Promise.resolve();

                                    const values: Record<string, FieldValue>[] = getFieldValue(
                                        LogikFieldsEnum.set_of_discounts,
                                    );

                                    if (
                                        !!value &&
                                        values.filter(
                                            (v) => v && v[LogikFieldsEnum.set_of_discounts_discount_input] === value,
                                        ).length > 1
                                    ) {
                                        return Promise.reject(new Error("You've already added this coupon!"));
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                        updateOnBlur
                        tooltipContent={tooltipFieldName}>
                        <LogikInput
                            placeholder={definition.label}
                            disabled={readonly}
                            suffix={
                                definition.extended?.amtType === 'Auto' && (
                                    <ReadOnlyAmtField
                                        prefix={' | '}
                                        logikName={LogikFieldsEnum.set_of_discounts_discount_amt}
                                        index={index}
                                        setName={LogikFieldsEnum.set_of_discounts}
                                    />
                                )
                            }
                            autoFocus
                        />
                    </LogikFormSetItem>

                    <LogikFormSetItem
                        label={label}
                        direction='horizontal'
                        logikName={LogikFieldsEnum.set_of_discounts_discount_amt}
                        index={index}
                        setName={LogikFieldsEnum.set_of_discounts}
                        rules={
                            definition.extended?.amtType === 'Input'
                                ? definition.extended.discInputType === 'money'
                                    ? getMoneyInputValidators(moneyRef)
                                    : getPercentInputValidators(moneyRef)
                                : []
                        }
                        messageVariables={{ name: definition.label }}
                        updateOnBlur
                        tooltipFieldName={tooltipFieldName}>
                        {definition.extended.discInputType === 'money' && (
                            <MoneyInput
                                placeholder='Amount'
                                ref={moneyRef}
                                autoFocus
                                disabled={readonly || definition.extended?.amtType !== 'Input'}
                            />
                        )}
                        {definition.extended.discInputType === 'percent' && (
                            <PercentInput
                                placeholder='Amount'
                                ref={moneyRef}
                                autoFocus
                                disabled={readonly || definition.extended?.amtType !== 'Input'}
                            />
                        )}
                    </LogikFormSetItem>
                </>
            )}
        </>
    );
};

export const DiscountRowContent = React.memo(DiscountRowContentImpl, (prev, next) => isEqual(prev, next));
