import { SliderSingleProps, Slider } from 'antd';
import React, { useContext } from 'react';
import { useFieldProperty } from '../hooks/field.hooks';
import { LogikFormItemContext } from './LogikFormItemBase';
import { useCustomFormItemErrorsAndWarnings } from '../hooks/form.hooks';

export type LogikSliderProps = SliderSingleProps;

export const LogikSlider = React.forwardRef<HTMLElement, LogikSliderProps>(function LogikSlider(
    { disabled, ...props }: LogikSliderProps & React.RefAttributes<HTMLElement>,
    ref,
): JSX.Element {
    useCustomFormItemErrorsAndWarnings();
    const logikFormItemContext = useContext(LogikFormItemContext);
    const isEditable = useFieldProperty()(
        (f) => f.editable,
        logikFormItemContext?.logikName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    return <Slider {...props} disabled={disabled || isEditable === 'false'} ref={ref} />;
});
