import { createSlice } from '@reduxjs/toolkit';
import { MiniPlayerConfiguration } from './mini-player.service';
import { addSetMiniPlayerCases } from './thunks/setMiniPlayer';

export interface MiniPlayerState {
    shortId?: string;
    configuration?: MiniPlayerConfiguration;
    loading: boolean;
}

const initialState: MiniPlayerState = {
    loading: false,
};

export const miniPLayerSlice = createSlice({
    name: 'MiniPlayer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        addSetMiniPlayerCases(builder);
    },
});

export const {} = miniPLayerSlice.actions;

export default miniPLayerSlice.reducer;
