import { Divider } from 'antd';
import React from 'react';
import { PricingTreeItem } from '../../shared/logik/hooks/pricing.hooks';
import { formatPriceValue } from '../../shared/utils/numbers.utils';

export const renderPricingRows = (items: PricingTreeItem[], props?: PricingRowProps): JSX.Element[] =>
    items
        .filter((e) => !!e.data)
        .sort((a, b) => {
            if (!a.data || !b.data) return 0;
            if (a.data.description && b.data.description) {
                const reg = /^(?<num>\d+):/;

                const aMatch = reg.exec(a.data.description)?.groups?.['num'];
                const bMatch = reg.exec(b.data.description)?.groups?.['num'];

                if (aMatch && bMatch) return Number(aMatch) - Number(bMatch);
            }

            return (
                (a.data.orderNumber ?? 0) -
                (b.data.orderNumber ?? 0) +
                a.data.uniqueIdentifier.localeCompare(b.data.uniqueIdentifier)
            );
        })
        .map((item, i) => <PricingRow key={`row-${i}`} data={item.data} products={item.products} {...props} />);

type PricingRowProps = {
    hideDivider?: boolean;
    className?: string;
};

const PricingRowImpl = ({ data, products, ...props }: PricingRowProps & PricingTreeItem): JSX.Element => {
    const hasChildren = products.length > 0;

    return data ? (
        <>
            <div className={'pricing-row' + (data.extended?.Highlight ? ' pricing-row-bold' : '')}>
                <div className='pricing-row-label'>
                    {data.description}
                    {hasChildren && ':'}
                </div>
                <div className={'pricing-row-value'}>
                    {data.extended?.SectionPricingDet === 'Footer'
                        ? data.extPrice || data.extended?.extPrice
                        : formatPriceValue(data.extPrice || data.extended?.extPrice, true, {
                              minimumFractionDigits: 2,
                          })}
                </div>
            </div>

            {hasChildren && (
                <>
                    {renderPricingRows(products, props)}
                    {data.level === 0 && props.hideDivider !== true && <Divider className='pricing-row-divider' />}
                </>
            )}
        </>
    ) : (
        <></>
    );
};

export type PricingRowsProps = PricingRowProps & {
    items: PricingTreeItem[];
};
const PricingRowsImpl = ({ items, ...props }: PricingRowsProps): JSX.Element => {
    return <>{renderPricingRows(items, props)}</>;
};
const PricingRow = React.memo(PricingRowImpl);
export const PricingRows = React.memo(PricingRowsImpl);
