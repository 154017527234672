import styles from './WidgetAsyncFallback.module.scss';
import { ReactElement } from 'react';
export const WidgetAsyncFallback = (): ReactElement => {
    return (
        <div className={styles['lds-container']}>
            <div className={styles['lds-ellipsis']}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
