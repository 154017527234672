import { useScreenChange } from '../../../shared/hooks/screen';
import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { LogikInput } from '../../../shared/logik/components/LogikInput';

export function SummaryRTOSecondary(): JSX.Element {
    const { size } = useScreenChange();
    const labelColSpan = size === 'small' ? 12 : 10;

    return (
        <>
            <LogikFormItem
                label='Second Customer Name'
                labelCol={{ span: labelColSpan }}
                logikName={LogikFieldsEnum.rto2CusName}
                rules={[
                    {
                        required: true,
                        message: 'Name is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Customer Name' />
            </LogikFormItem>
        </>
    );
}
