import { fieldsLogic, onSaveQuoteT, propertyForLogicType } from '@ulrichlifestyle/configurator';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';
import { useCallback, useMemo } from 'react';
import { ProductConfigurator } from '../configurator/ProductConfigurator';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { App } from 'antd';
import { updateTotalPrice } from '../configurator/thunks/updateTotalPrice';
import { setThreekitId, setConfigurationFields, setSaving } from '../widget/Widget.slice';
import { fetchConfiguration } from '../widget/thunks/basic/fetchConfiguration';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { ROUTE_SUMMARY } from '../wizard/wizard.router';
import { Field } from '../../shared/logik/models/field.model';
import { saveBuildingPackage } from './thunks/saveBuildingPackage';
import { publishEventAndRedirect } from '../../shared/utils/location.utils';

import {
    BuildingPackageDuplicateEvent,
    BuildingPackageDuplicateEventParams,
    BuildingPackageEditEvent,
    BuildingPackageEditEventParams,
    BuildingPackageNewEvent,
    BuildingPackageNewEventParams,
} from './building-packages.utils';
import { saveAndUploadSnapshots } from '../configurator/thunks/snapshots';

export const BuildingPackageConfiguratorPage = (): JSX.Element => {
    const uuid = useAppSelector((state) => state.widget.uuid);
    const configurationFields = useAppSelector((state) => state.widget.configurationFields);
    const configurationMessages = useAppSelector((state) => state.widget.configurationMessages);
    const threeKitId = useAppSelector((state) => state.widget.threeKitId);
    const tooltips = useAppSelector((state) => state.widget.tooltips);
    const configurationFieldsConverted = useMemo(() => Object.values(configurationFields), [configurationFields]);

    const dispatch = useAppDispatch();
    const navigate = useNavigateExtended();
    const { modal } = App.useApp();

    const launchObjectId = useAppSelector((state) => state.widget.init?.launchObjectId);
    const masterConfigurationId = useAppSelector((state) => state.widget.masterConfigurationId);
    const action = useAppSelector((state) => state.widget.init?.action);

    const finishConfiguration: onSaveQuoteT = useCallback(
        async (uuid, conf, saveThreeKitConfig, getSnapshots) => {
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));

            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            const updatedConfig = await dispatch(fetchConfiguration(uuid)).unwrap();
            await dispatch(updateTotalPrice());

            navigate('../' + ROUTE_SUMMARY);

            return Promise.resolve({
                uuid,
                fields: updatedConfig.fields as fieldsLogic,
                message: [],
            });
        },
        [dispatch, navigate],
    );

    const showDetails = useCallback(async () => {}, []);

    const onZipChange = useCallback(async (_: string, fields: propertyForLogicType[]) => {
        return {
            fields: fields,
        };
    }, []);

    const save: onSaveQuoteT = useCallback(
        async (uuid, conf, saveThreeKitConfig, getSnapshots) => {
            dispatch(setSaving(true));
            const shortId = await saveThreeKitConfig();

            dispatch(setThreekitId(shortId));

            dispatch(setConfigurationFields(conf as Field[]));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: [...snapshots], skipRelatedUpdates: true }));
            await dispatch(fetchConfiguration(uuid)).unwrap();

            await dispatch(saveBuildingPackage()).unwrap();
            //TODO: redirect to transition page

            modal.confirm({
                title: 'Building package has been successfully saved',
                content: 'Now you can close the window or go back and make some changes',
                icon: <></>,
                okText: 'Save and continue',
                cancelText: 'Save and close',
                okButtonProps: { size: 'small' },
                cancelButtonProps: { size: 'small' },
                onOk: async () => await dispatch(recreateConfiguration(uuid)),
                onCancel: async () => {
                    if (launchObjectId) {
                        if (!masterConfigurationId) {
                            await publishEventAndRedirect<BuildingPackageNewEventParams>(BuildingPackageNewEvent, {
                                launchObjectId,
                            });
                        } else {
                            if (action === 'copy') {
                                await publishEventAndRedirect<BuildingPackageDuplicateEventParams>(
                                    BuildingPackageDuplicateEvent,
                                    {
                                        launchObjectId,
                                    },
                                );
                            } else {
                                await publishEventAndRedirect<BuildingPackageEditEventParams>(
                                    BuildingPackageEditEvent,
                                    {
                                        launchObjectId,
                                    },
                                );
                            }
                        }
                    }
                },
            });
            dispatch(setSaving(false));
            return Promise.resolve({
                uuid,
                fields: conf,
                message: [],
            });
        },
        [action, dispatch, launchObjectId, masterConfigurationId, modal],
    );

    return uuid ? (
        <ProductConfigurator
            logicConfig={{
                fields: configurationFieldsConverted as fieldsLogic,
                message: configurationMessages,
                uuid,
                hints: tooltips,
            }}
            threeKitId={threeKitId}
            onFinishConfig={finishConfiguration}
            onGetInfoConfiguration={showDetails}
            onChangeZipCode={onZipChange}
            saveButtonTitle='Save Product'
            saveButtonCallback={save}
            goBackProductList={undefined}
            containerClassName={''}
            hideInstallationDialog
        />
    ) : (
        <WidgetAsyncFallback />
    );
};
