import { Button, FormInstance } from 'antd';
import { SummaryRTO } from './rto/SummaryRTO';
import { SummaryOther } from './other/SummaryOther';
import { SummaryRTOPrimary } from './rto-primary/SummaryRTOPrimary';
import { SummaryRTOSecondary } from './rto-secondary/SummaryRTOSecondary';
import { SummaryRTOReferences } from './rto-references/SummaryRTOReferences';
import styles from './SummaryPage.module.scss';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { SummaryNA } from './na/SummaryNA';
import { SummaryDiscounts } from './discounts/SummaryDiscounts';
import { SummaryFinanceOptions } from './finance-options/SummaryFinanceOptions';
import { SummaryLDW } from './ldw/SummaryLDW';
import { SummaryMiscellaneous } from './miscellaneous/SummaryMiscellaneous';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { FormState } from '../../shared/models/form.state.model';
import { LogikFormItem } from '../../shared/logik/components/LogikFormItem';
import { LogikRadioGroup } from '../../shared/logik/components/LogikRadioGroup';
import { useScreenChange } from '../../shared/hooks/screen';
import { PricingSection } from '../pricing/PricingSection';
import { Section } from '../../shared/components/layout/Section';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { LogikForm } from '../../shared/logik/components/LogikForm';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { PricingModal } from '../configurator/PricingModal';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';

export type SummaryFormState = FormState;
export type SummaryPageContentProps = {
    onFinish?: (values: SummaryFormState) => void;
    form?: FormInstance<SummaryFormState>;
};
export const SummaryPageContent = ({ onFinish, form }: SummaryPageContentProps): ReactElement => {
    const purchaseOpt = useFieldValue<string>(LogikFieldsEnum.purchaseOpt);
    const quoteMode = useFieldValue<boolean>(LogikFieldsEnum.quoteMode);
    const rtoCus2 = useFieldValue<boolean>(LogikFieldsEnum.rto2Cus);
    const [pricingModalHandle, setPricingModalHandle] = useState<FlexibleModalHandle<typeof PricingModal>>();

    const { device } = useScreenChange();

    const showPricingModal = useCallback(async () => {
        pricingModalHandle?.open();
    }, [pricingModalHandle]);

    const pricingBlock = useMemo(
        () => (
            <div className={styles['summary-pricing-block']}>
                <PricingSection
                    type='primary'
                    titleElement={
                        <span className={styles['summary-pricing-block-title']}>
                            Order Summary{' '}
                            <Button type='link' size='small' onClick={showPricingModal}>
                                (view details)
                            </Button>
                        </span>
                    }
                    titleAlign='center'
                    titleSize='bigger'
                    layoutType={device === 'desktop' ? 'single-row' : 'single-column'}
                    filter={(pd) => !!pd.extended?.OrderSummary}
                    pricingType='pricing'
                    paddingSize='small'
                />
            </div>
        ),
        [device, showPricingModal],
    );

    const formBlock = useMemo(
        () => (
            <LogikForm
                name='summary-form'
                form={form}
                layout='horizontal'
                labelAlign='left'
                labelCol={{ span: 12 }}
                onFinish={onFinish}
                autoComplete='off'
                colon={false}
                className={styles['summary-form']}>
                <div style={{ textAlign: 'center' }}>
                    <LogikFormItem
                        label='Purchase Option'
                        labelCol={{ span: 24 }}
                        direction={device === 'mobile' ? 'vertical' : 'horizontal'}
                        logikName={LogikFieldsEnum.purchaseOpt}
                        className={styles['purchase-opt']}
                        rules={[
                            {
                                required: true,
                                message: 'Select purchase option',
                            },
                            {
                                validator: (_, value) => {
                                    return value !== 'NA'
                                        ? Promise.resolve()
                                        : Promise.reject('Please select purchase method');
                                },
                            },
                        ]}
                        hasFeedback={false}
                        hideTooltip>
                        <LogikRadioGroup fieldName={LogikFieldsEnum.purchaseOpt} />
                    </LogikFormItem>
                </div>

                <div className={styles['summary-form-content']}>
                    <Section titleElement='Additional options'>
                        <SummaryNA />

                        <SummaryMiscellaneous />

                        <SummaryDiscounts />
                    </Section>

                    <Section titleElement='Other Options'>
                        <SummaryOther />
                    </Section>

                    {purchaseOpt === 'RTO' && (
                        <>
                            <Section titleElement='Rent to Own'>
                                <SummaryRTO />
                                <SummaryLDW />
                            </Section>
                        </>
                    )}

                    {purchaseOpt === 'Finance' && (
                        <Section titleElement='Finance Options'>
                            <SummaryFinanceOptions />
                        </Section>
                    )}
                </div>

                {purchaseOpt === 'RTO' && !quoteMode && (
                    <div className={styles['summary-form-footer']}>
                        <Section titleElement='Rental App - Primary Buyer'>
                            <SummaryRTOPrimary />

                            {rtoCus2 && <SummaryRTOSecondary />}
                        </Section>

                        <Section titleElement='Rental App - References #1'>
                            <SummaryRTOReferences referenceNumber={1} />
                        </Section>

                        <Section titleElement='Rental App - References #2'>
                            <SummaryRTOReferences referenceNumber={2} />
                        </Section>
                    </div>
                )}
            </LogikForm>
        ),
        [device, form, onFinish, purchaseOpt, quoteMode, rtoCus2],
    );

    useEffect(() => {
        if (!pricingModalHandle) {
            const modal = FlexibleModal.createModal(PricingModal, {
                showCloseButton: true,
                destroyOnClose: true,
                className: styles['pricing-modal'],
            });
            setPricingModalHandle(modal);
        }
    }, [pricingModalHandle]);

    return (
        <div className={styles['summary-page-content']}>
            {pricingBlock}
            {formBlock}
        </div>
    );
};
