import { AxiosResponse } from 'axios';
import { ConfigurationBaseModel } from '../models/configuration-base.model';
import { ConfigurationModel } from '../models/configuration.model';
import { SaveQuoteModel } from '../models/save-quote.model';
import { ServiceBase } from './service-base';

class ConfiguratorAPIImpl extends ServiceBase {
    public async saveConfiguration(model: ConfigurationModel): Promise<ConfigurationBaseModel> {
        const res = await this.http.post<ConfigurationBaseModel>('/api/configurations/', model);
        return res.data;
    }

    public async createMasterConfig(model: ConfigurationModel | null): Promise<ConfigurationBaseModel> {
        const res = await this.http.post<ConfigurationBaseModel>('/api/configurations/master', model, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        });
        return res.data;
    }

    public async notifyAboutOrder(
        masterId: string,
        model: Omit<ConfigurationModel, keyof ConfigurationBaseModel>,
    ): Promise<void> {
        const res = await this.http.post<void>('/api/configurations/master/' + masterId, model, {});

        return res.data;
    }

    public async saveQuote(model: SaveQuoteModel): Promise<ConfigurationBaseModel> {
        model.silent = false;
        const res = await this.http.post<ConfigurationBaseModel>('/api/configurations/', model);
        return res.data;
    }

    public async getConfigurationByMasterId(masterId: string): Promise<ConfigurationBaseModel> {
        const res = await this.http.get<ConfigurationBaseModel>('/api/configurations/master/' + masterId);
        return res.data;
    }

    public getConfigurationById(uuid: string): Promise<AxiosResponse<ConfigurationBaseModel>> {
        return this.http.get('/api/configurations/' + uuid);
    }

    public generateDocument(templateId: string, uuid: string): Promise<AxiosResponse<Blob>> {
        return this.http.post(
            `/api/templates/${templateId}/generate?download=true&upload=false`,
            {
                ConfigurationId: uuid,
            },
            {
                responseType: 'blob',
            },
        );
    }

    public async getLink(masterId: string): Promise<string> {
        const res = await this.http.get<string>(`/api/configurations/links?id=${masterId}&type=master`);
        return res.data;
    }
}

export const ConfiguratorAPI = new ConfiguratorAPIImpl();
