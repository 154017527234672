import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Prebuilt } from './prebuilt.model';
import { addCreatePrebuiltMasterConfigurationCases } from './thunks/createPrebuiltMasterConfiguration';
import { addEditPrebuiltMasterConfigurationCases } from './thunks/editPrebuiltMasterConfiguration';

export interface PrebuiltState {
    details?: Prebuilt;
}

export const PrebuiltsInitialState: PrebuiltState = {};

export const PrebuiltsSlice = createSlice({
    name: 'Prebuilts',
    initialState: PrebuiltsInitialState,
    reducers: {
        setPrebuilt: (state, value) => {
            state.details = value.payload;
        },
    },
    extraReducers: (builder) => {
        addCreatePrebuiltMasterConfigurationCases(builder);
        addEditPrebuiltMasterConfigurationCases(builder);

        // builder.addMatcher(
        //     (action) => action.type.endsWith('/rejected'),
        //     (_, action) => {
        //         logger.error(action.error);
        //     },
        // );
    },
});

export const { setPrebuilt } = PrebuiltsSlice.actions;
export const selectPrebuilt = (state: RootState): PrebuiltState => state.prebuilt;
export const selectPrebuiltDetails = (state: RootState): Prebuilt | undefined => state.prebuilt.details;
export default PrebuiltsSlice.reducer;
