/* eslint-disable @typescript-eslint/no-unused-vars */
import { Loader } from '@googlemaps/js-api-loader';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { AutoComplete, AutoCompleteProps, Input, InputProps, InputRef } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { ChangeEvent, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useImperativeHandle, useState } from 'react';
import { Environment } from '../../../app/environment';
import logger from '../../../app/logger';
import { LogEntry } from '../../models/logentry';

type Option = {
    key: string;
    label: string;
    value: string | React.ReactNode;
    prediction: google.maps.places.AutocompletePrediction;
} & DefaultOptionType;

export type AddressAutocompleteRefType = {
    setAutocompleteValue: (value: string) => void;
    blur: () => void;
};
type AddressAutocompleteProps = {
    onPlaceChanged?: (components: google.maps.places.PlaceResult) => void;
    map?: google.maps.Map;
    inputProps?: InputProps;
    standalone?: boolean;
    valueTransform?: (value: string, prediction: google.maps.places.AutocompletePrediction) => string;
} & PropsWithChildren<AutoCompleteProps>;

const AddressAutocomplete = React.forwardRef<AddressAutocompleteRefType, AddressAutocompleteProps>(
    function AddressAutocomplete(
        // eslint-disable-next-line react/prop-types
        { onPlaceChanged, map, standalone, valueTransform, value, inputProps, ...autocompleteProps },
        ref,
    ): JSX.Element {
        const placesDiv = useRef<HTMLDivElement>(null);
        const autocompleteRef = useRef<InputRef>(null);
        const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(
            null,
        );
        const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

        const [autocompleteValue, setAutocompleteValue] = useState<string>();
        const [options, setOptions] = useState<Option[]>([]);
        const [inFocus, setInFocus] = useState<boolean>(false);
        const [isOpen, setIsOpen] = useState<boolean>(autocompleteProps.defaultOpen ?? false);
        const [selectedOption, setSelectedOption] = useState<Option | null>(null);
        const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
        const [lastValidAddress, setLastValidAddress] = useState<string>();

        useImperativeHandle(ref, () => ({
            setAutocompleteValue,
            blur: () => {
                autocompleteRef.current?.blur();
            },
        }));

        useEffect(() => {
            setAutocompleteValue(value);
            if (!lastValidAddress) {
                setLastValidAddress(value);
            }
        }, [lastValidAddress, value]);

        const onGLibraryInit = useCallback(
            (status: Status, loader: Loader) => {
                if (status === Status.SUCCESS) {
                    setAutocompleteService(new google.maps.places.AutocompleteService());
                    const div = map || placesDiv.current;
                    if (div) setPlacesService(new google.maps.places.PlacesService(div));
                    setSessionToken(new google.maps.places.AutocompleteSessionToken());
                }
            },
            [map],
        );

        const handleSearch = useCallback(
            async (value: string): Promise<void> => {
                if (inFocus && !isOpen) {
                    setIsOpen(true);
                }
                try {
                    autocompleteService?.getPlacePredictions(
                        {
                            input: value,
                            componentRestrictions: { country: 'us' },
                            sessionToken: sessionToken,
                        } as google.maps.places.AutocompletionRequest,
                        (predictions, serviceStatus) => {
                            if (!!predictions) {
                                if (serviceStatus == google.maps.places.PlacesServiceStatus.OK) {
                                    setOptions(
                                        predictions.map((p) => {
                                            return {
                                                label: p.description,
                                                value: p.description,
                                                prediction: p,
                                            } as Option;
                                        }),
                                    );
                                } else {
                                    logger.error(
                                        new LogEntry(
                                            `Google Maps Places Service is unavailable. Service status: ${serviceStatus}`,
                                        ),
                                    );
                                }
                            }
                        },
                    );
                } catch (err) {
                    logger.error(new LogEntry(err as Error));
                }
            },
            [autocompleteService, inFocus, isOpen, sessionToken],
        );

        const handleSearchChange = useCallback(
            (value: string, selected: Option | Option[]): void => {
                if (value === autocompleteValue) return;

                setSelectedOption(null);

                if (!Array.isArray(selected) && !!selected.value) {
                    setSelectedOption(selected);
                    const transformedValue = !valueTransform
                        ? selected.prediction?.description
                        : valueTransform(selected.prediction?.description, selected.prediction);

                    setAutocompleteValue(transformedValue);
                    setLastValidAddress(transformedValue);

                    const option = selected;

                    if (!!option?.prediction) {
                        console.debug('sessionToken getDetails', sessionToken);
                        placesService?.getDetails(
                            {
                                fields: ['formatted_address', 'geometry.location', 'address_components'],
                                placeId: option.prediction.place_id,
                                sessionToken: sessionToken,
                            },
                            (
                                place: google.maps.places.PlaceResult | null,
                                serviceStatus: google.maps.places.PlacesServiceStatus,
                            ) => {
                                if (!!place) {
                                    switch (serviceStatus) {
                                        case google.maps.places.PlacesServiceStatus.OK:
                                            onPlaceChanged?.(place);

                                            break;

                                        case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                                            logger.info(
                                                new LogEntry(
                                                    `No places found for given address ${option.prediction?.description}`,
                                                ),
                                            );
                                            break;
                                    }
                                }

                                setSessionToken(new google.maps.places.AutocompleteSessionToken());
                            },
                        );
                    }

                    setIsOpen(false);
                    autocompleteProps?.onChange?.(transformedValue, selected);
                } else {
                    setAutocompleteValue(value);
                    autocompleteProps?.onChange?.(value, selected);
                }
            },
            [autocompleteProps, autocompleteValue, onPlaceChanged, placesService, sessionToken, valueTransform],
        );

        if (autocompleteRef.current?.input) {
            autocompleteRef.current.input.autocomplete = inputProps?.autoComplete ?? 'off';
        }

        useEffect(() => {
            setIsOpen(inFocus);
        }, [inFocus]);

        const handleBlur = useCallback(() => {
            setOptions([]);
            if (!selectedOption) {
                setAutocompleteValue(lastValidAddress);
            }
        }, [lastValidAddress, selectedOption]);

        return (
            <>
                <div ref={placesDiv}></div>
                <Wrapper apiKey={Environment.googleMapsApiKey} libraries={['places']} callback={onGLibraryInit}>
                    <AutoComplete
                        {...autocompleteProps}
                        options={options}
                        onChange={handleSearchChange}
                        onSearch={handleSearch}
                        //onSelect={handleSearchChange}
                        onBlur={handleBlur}
                        value={autocompleteValue}
                        style={{ width: '100%' }}
                        open={isOpen}>
                        <Input
                            {...inputProps}
                            ref={autocompleteRef}
                            onFocus={function (e) {
                                setInFocus(true);

                                if (e.relatedTarget == null && e.isTrusted) {
                                    if (!!autocompleteValue) {
                                        handleSearch(autocompleteValue);
                                    }
                                }
                            }}
                            onBlur={() => setInFocus(false)}
                            type='text'
                        />
                    </AutoComplete>
                </Wrapper>
            </>
        );
    },
);

export default AddressAutocomplete;
