import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { Environment } from './environment';
import authReducer from '../features/auth0/Auth0.slice';
import miniPlayerReducer from '../features/mini-player/MiniPlayer.slice';
import widgetReducer from '../features/widget/Widget.slice';
import checkoutReducer from '../features/checkout/Checkout.slice';
import prebuiltsReducer from '../features/prebuilts/prebuilts.slice';
import productsReducer from '../features/products/Products.slice';
import preownedReducer from '../features/preowned/preowned.slice';
import { Config, createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from 'redux-state-sync';
import { WindowMessageBusBroadcastMethod } from '../shared/utils/broadcast-methods/window-message-bus-broadcast-method';

let listenerChannelId;
try {
    listenerChannelId = window.opener?.channelId;
} catch {}

const isListener = !!listenerChannelId;
const uuid = listenerChannelId ?? crypto.randomUUID();
window.channelId = uuid;

const syncStoreConfig: Config = {
    channel: `configurator-channel-${uuid}`,
    broadcastChannelOption: {
        methods: WindowMessageBusBroadcastMethod,
    },
    predicate: (action) => {
        return !action.type.startsWith('configurator/save-snapshots');
    },
};
const rootReducer = {
    auth: authReducer,
    miniPlayer: miniPlayerReducer,
    widget: widgetReducer,
    checkout: checkoutReducer,
    prebuilt: prebuiltsReducer,
    preowned: preownedReducer,
    products: productsReducer,
};

const finalReducer = isListener ? withReduxStateSync(combineReducers(rootReducer)) : rootReducer;

export const store = configureStore({
    reducer: finalReducer,
    devTools: Environment.isDevelopment,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware(syncStoreConfig)),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

if (isListener) initStateWithPrevTab(store);
