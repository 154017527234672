import { Input, InputProps, InputRef } from 'antd';
import { useContext } from 'react';
import { useFieldProperty } from '../hooks/field.hooks';
import { LogikFormItemContext } from './LogikFormItemBase';
import React from 'react';
import { useCustomFormItemErrorsAndWarnings } from '../hooks/form.hooks';

export type LogikInputProps = InputProps;
export const LogikInput = React.forwardRef<InputRef, LogikInputProps>(function LogikInput(
    { disabled, ...props }: LogikInputProps & React.RefAttributes<InputRef>,
    ref,
): JSX.Element {
    useCustomFormItemErrorsAndWarnings();
    const logikFormItemContext = useContext(LogikFormItemContext);
    const isEditable = useFieldProperty()(
        (f) => f.editable,
        logikFormItemContext?.logikName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    return <Input {...props} disabled={disabled || isEditable === 'false'} ref={ref} />;
});
