import { defer, LoaderFunctionArgs, Navigate } from 'react-router-dom';
import {
    RedirectToConfigurator,
    ROUTE_CONFIGURATOR,
    ROUTE_SUMMARY,
    WizardChildRoutes,
    WizardLoaderDeferredResult,
} from '../wizard/wizard.router';
import { PrebuiltEditPage } from './PrebuiltEditPage';
import { loadPrebuiltBySerial } from './prebuilts.loader';
import { PrebuiltSummaryPage } from './PrebuiltSummaryPage';
import { PrebuiltsWizard } from './PrebuiltsWizard';
import { WidgetAsyncError } from '../widget/WidgetAsyncError';
import { WidgetAsyncLoader } from '../widget/WidgetAsyncLoader';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { RouteProtected } from '../../shared/models/route-protected.model';
import { Wizard } from '../wizard/Wizard';
import { Store } from '../../shared/models/store.model';
import { StoresService } from '../../shared/services/stores.service';
import { ConfigurationBaseModel } from '../../shared/models/configuration-base.model';
import { PrebuiltsAPI } from './prebuilts.service';

export const PREBUILT_ID_ROUTE_ID = 'prebuilt-id-route';
export const prebuiltRoutes: RouteProtected = {
    path: 'prebuilts',
    errorElement: <WidgetAsyncError />,
    children: [
        {
            path: ':siteId',
            children: [
                {
                    path: 'serials',
                    children: [
                        {
                            path: ':serial',
                            children: [
                                {
                                    path: '',
                                    loader: loadPrebuiltBySerial,
                                    shouldRevalidate: () => false,
                                    permissions: ['prebuilts.read', 'prebuilts.update-master', 'prebuilts.create'],
                                    element: (
                                        <WidgetAsyncLoader<{ [key: string]: unknown }>
                                            resolverSelector={(data) => data.prebuilt}>
                                            <ErrorBoundary>
                                                <PrebuiltsWizard />
                                            </ErrorBoundary>
                                        </WidgetAsyncLoader>
                                    ),
                                    children: [
                                        {
                                            index: true,
                                            element: <Navigate to={ROUTE_CONFIGURATOR} replace={true} />,
                                        },
                                        {
                                            path: ROUTE_CONFIGURATOR,
                                            element: <PrebuiltEditPage />,
                                        },
                                        {
                                            path: ROUTE_SUMMARY,
                                            element: <PrebuiltSummaryPage />,
                                        },
                                    ],
                                },
                                {
                                    path: 'quotes',
                                    loader: async ({ request, params }: LoaderFunctionArgs): Promise<unknown> => {
                                        const siteId = params['siteId'];
                                        const serialNumber = params['serial'];
                                        if (!siteId || !serialNumber)
                                            throw new Response('Input is not correct', { status: 404 });

                                        const url = new URL(request.url);
                                        const qs = url.searchParams;
                                        const dealId = qs.get('dealId');
                                        const storeId = qs.get('storeId');
                                        let store$: Promise<Store | null> = Promise.resolve(null);

                                        if (storeId) {
                                            store$ = StoresService.fetchStore(storeId);
                                        }

                                        const prebuilt = await PrebuiltsAPI.getPrebuiltPublicInfoBySerial(
                                            siteId,
                                            serialNumber,
                                        );
                                        if (!prebuilt) throw new Response('Preowned was not found', { status: 404 });

                                        let initialParams = {};

                                        if (qs.has('ic')) {
                                            const ic = qs.get('ic');
                                            if (ic) {
                                                const query = new URLSearchParams(window.atob(ic));

                                                initialParams = Object.fromEntries(query.entries());
                                            }
                                        }

                                        return defer({
                                            init: Promise.resolve({
                                                masterConfig: {
                                                    productId: prebuilt.productId,
                                                } as ConfigurationBaseModel,
                                                initialParams,
                                                prebuilt: prebuilt,
                                                preowned: null,
                                                dealId,
                                                action: 'new',
                                                siteId,
                                                actionType: 'QuoteNew',
                                                sourceMasterConfigId: null,
                                                changedDescriptionId: null,
                                                allowVariants: undefined,
                                                store: await store$,
                                            }),
                                        } as WizardLoaderDeferredResult);
                                    },
                                    shouldRevalidate: () => false,
                                    element: (
                                        <WidgetAsyncLoader<WizardLoaderDeferredResult>
                                            resolverSelector={({ init }) => init}>
                                            <ErrorBoundary>
                                                <Wizard />
                                            </ErrorBoundary>
                                        </WidgetAsyncLoader>
                                    ),
                                    children: [
                                        {
                                            path: 'new',
                                            children: [
                                                {
                                                    path: '',
                                                    element: <RedirectToConfigurator />,
                                                },
                                                ...WizardChildRoutes,
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
