import { AuditModel } from '../../shared/models/audit.model';
import { IDModel } from '../../shared/models/id.model';

export enum PaymentType {
    Card = 'Card',
    Check = 'Check',
    Cash = 'Cash',
    Manual = 'Manual',
    Wire = 'Wire',
}

export enum TransactionStatus {
    Unknown = 0,
    Approved = 1,
    Declined = 2,
    Error = 3,
    HeldForReview = 4,
}

interface PaymentBase {
    comment?: string;
    amount: number;
}

interface ReadPaymentBase extends PaymentBase, IDModel, AuditModel {
    type: PaymentType;
    reference: number;
    paymentDate: string;
}

export interface CashPayment extends ReadPaymentBase {}
export interface ManualPayment extends ReadPaymentBase {}

export interface CheckPayment extends ReadPaymentBase {}

export interface CreditCardPayment extends ReadPaymentBase {
    merchantId: string;
    accountNumber: string;
    accountType: string;
    transactionId: string;
    status: TransactionStatus;
    reason: string;
    reasonCode: string;
}

interface CreatePaymentBase {
    paymentType: PaymentType;
    amount: number;
    comment?: string;
    logikId: string;
    masterId: string;
    siteId: string;
    paymentDate: string;
    attachments?: Attachment[];
    receipts?: Receipt[];
}

interface Receipt {
    documentId: string;
    accessToken: string;
}

interface Attachment {
    fileId: string;
}

export interface CreateCashPayment extends CreatePaymentBase {}
export interface CreateManualPayment extends CreatePaymentBase {}

export interface CreateCheckPayment extends CreatePaymentBase {
    checkNumber: string;
}

export interface CreateCreditCardPayment extends CreatePaymentBase {
    opaqueData: AuthorizeNet.OpaqueData;
    merchantId?: string;
}
export interface CreateWirePayment extends CreatePaymentBase {}

export type Payment = CashPayment | CheckPayment | CreditCardPayment | ManualPayment;
export type CreatePayment =
    | CreateCashPayment
    | CreateCheckPayment
    | CreateCreditCardPayment
    | CreateManualPayment
    | CreateWirePayment;

export interface Merchant {
    id: string;
    displayName: string;
    isSandboxAccount: boolean;
    inTestMode: boolean;
    publicClientKey: string;
    apiLoginId: string;
}
export interface PaymentFormSettings {
    token: string;
    merchant: Merchant;
}

export interface PaymentFormSettingsRequest {
    siteId: string;
    amount: number | string;
}

export interface PaymentEventData {
    paymentId: string;
    configurationId: string;
    amount: number;
    date: string;
    useDropship: boolean;
    type: 'Cash' | 'Check' | 'Credit' | 'Manual';
    comment?: string;
    reference?: number;
}

export const paymentIsCard = (payment: Payment): payment is CreditCardPayment => {
    return payment.type === PaymentType.Card;
};
