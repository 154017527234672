import { ServiceBase } from '../../shared/services/service-base';
import { Prebuilt } from './prebuilt.model';

class PrebuiltsAPIImpl extends ServiceBase {
    constructor() {
        super();
        this.http.defaults.timeout = 10000000;
    }
    async getPrebuiltById(siteId: string, id: string): Promise<Prebuilt> {
        const prebuilt = await this.http.get<Prebuilt>(`api/${siteId}/prebuilts/${id}`);
        return prebuilt.data;
    }

    async getPrebuiltBySerial(siteId: string, serial: string): Promise<Prebuilt> {
        const prebuilt = await this.http.get<Prebuilt>(`api/${siteId}/prebuilts/public/serials/${serial}`);
        return prebuilt.data;
    }

    async getPrebuiltPublicInfoByMasterId(masterId: string): Promise<Partial<Prebuilt> | null> {
        const prebuilt = await this.http.get<Partial<Prebuilt> | null>(`api/prebuilts/masters/${masterId}`);
        return prebuilt.data;
    }

    async getPrebuiltPublicInfoBySerial(siteId: string, serial: string): Promise<Partial<Prebuilt> | null> {
        const prebuilt = await this.http.get<Partial<Prebuilt> | null>(
            `api/${siteId}/prebuilts/public/serials/${serial}`,
        );
        return prebuilt.data;
    }
}

export const PrebuiltsAPI = new PrebuiltsAPIImpl();
