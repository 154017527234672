import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../app/hooks';
import logger from '../../app/logger';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { Environment } from '../../app/environment';

export const IdleHeartbeat = (): JSX.Element => {
    const uuid = useAppSelector((state) => state.widget.uuid);
    const activity = useAppSelector((state) => state.widget.saving || state.widget.loading || state.widget.updating);
    const heartbeatWorker = useRef<Worker>();

    useEffect(() => {
        const w = new Worker('/workers/heartbeatWorker.js');
        w.onmessage = async (
            ev: MessageEvent<{
                type: 'success' | 'error' | 'logik-error' | 'not-found' | 'timer-stopped';
                message: string;
            }>,
        ) => {
            let message = '';
            if (ev.data.type === 'not-found') {
                message = 'Your session has expired. Please refresh the page to continue';
            } else if (ev.data.type === 'error' || ev.data.type === 'logik-error') {
                logger.error(ev.data);
                message = 'An unknown error occurred. Please refresh the page to continue';
            }
            if (message !== '') {
                FlexibleModal.createModal(<h3 style={{ height: 50 }}>{message}</h3>, {
                    closeOnEscape: false,
                    closeOnOutsideClick: false,
                    showCloseButton: false,
                    destroyOnClose: true,
                }).open();
            }
        };

        w.onerror = (err) => logger.error(err);
        heartbeatWorker.current = w;

        return () => {
            try {
                if (heartbeatWorker.current) {
                    heartbeatWorker.current.terminate();
                    heartbeatWorker.current = undefined;
                }
            } catch {}
        };
    }, []);

    useEffect(() => {
        if (!uuid) return;

        const headers = {
            Authorization: `Bearer ${Environment.logikApiToken}`,
            'Content-Type': 'application/json',
        };
        const init: RequestInit = {
            method: 'PATCH',
            body: JSON.stringify({ fields: [] }),
        };

        if ((activity || !activity) && heartbeatWorker.current) {
            heartbeatWorker.current.postMessage({
                interval: 60 * 1000,
                requestUrl: `${Environment.logikApiUrl}${uuid}`,
                requestInit: init,
                requestHeaders: headers,
            });
        }
    }, [activity, uuid]);
    return <></>;
};
