import { Result } from 'antd';
import { IconMap, ResultProps, ResultStatusType } from 'antd/es/result';
import { isAxiosError } from 'axios';
import { isError } from 'lodash';
import { useMemo } from 'react';
import { isRouteErrorResponse, useAsyncError, useRouteError } from 'react-router-dom';
import { isDomainError, isErrorLike, isSerializedDomainError } from '../../shared/utils/errors.utils';
import { isResponse } from '../../shared/utils/typeguards';

export const WidgetAsyncError = (): JSX.Element => {
    const error = useAsyncError();
    const routeError = useRouteError();

    const errorDetails = useMemo((): Partial<ResultProps> => {
        const err = error || routeError;
        if (isDomainError(err)) {
            return {
                status: err.type,
                subTitle: err.message,
            };
        } else if (isSerializedDomainError(err)) {
            return {
                status: (err.code as keyof typeof IconMap) ?? 'error',
                subTitle: err.message,
                title: null,
            };
        } else if (isResponse(err) && !err.ok && !err.bodyUsed) {
            err.text().then((t) => {
                return {
                    status: err.status?.toString() as ResultStatusType,
                    subTitle: t,
                };
            });
        } else if (isAxiosError(err)) {
            return {
                status: err.response?.status.toString() as ResultStatusType,
                subTitle: err.message,
            };
        } else if (isError(err) || isErrorLike(err)) {
            return {
                status: 'error',
                subTitle: err.message,
            };
        } else if (isRouteErrorResponse(err)) {
            return {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                status: err.status as any,
                subTitle: err.data,
            };
        }

        return {
            status: 'error',
            subTitle: 'An unknown error occurred. Please try again later',
        };
    }, [error, routeError]);

    return <Result title='Error' {...errorDetails} />;
};
