import { Select, SelectProps } from 'antd';
import { useFieldProperty } from '../hooks/field.hooks';
import { FieldOptionSet, Option } from '../../logik/models/field.model';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import { isFunction } from 'lodash';
import { LogikFormItemContext } from './LogikFormItemBase';
import { useCustomFormItemErrorsAndWarnings } from '../hooks/form.hooks';

export type SelectOption = DefaultOptionType & {
    data: Option;
};

export type LogikSelectProps = Omit<SelectProps<string, SelectOption>, 'open'> & {
    fieldName?: string;
    filter?: (option: SelectOption, optionIndex: number, allOptions: SelectOption[]) => boolean;
    searchByLabel?: boolean;
    searchByValueAndLabel?: boolean;
    autoComplete?: string;
};

export const LogikSelect = ({
    fieldName,
    filter,
    searchByLabel,
    searchByValueAndLabel: searchByValueAndLabel,
    autoComplete,
    className,
    disabled,
    ...selectProps
}: LogikSelectProps): JSX.Element => {
    useCustomFormItemErrorsAndWarnings();
    const logikFormItemContext = useContext(LogikFormItemContext);
    const isEditable = useFieldProperty()(
        (f) => f.editable,
        logikFormItemContext?.logikName || fieldName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );
    const fieldOptions = useFieldProperty<FieldOptionSet>()(
        (f) => f.optionSet.options,
        logikFormItemContext?.logikName || fieldName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    const options = useMemo(() => {
        const q = fieldOptions
            ?.map((o) => {
                return {
                    label: o.label,
                    value: o.value,
                    data: o,
                    disabled: o.state === 'disabled',
                } as SelectOption;
            })
            ?.filter((o) => o.data.state === 'visible' || o.data.state === 'disabled')
            ?.filter((o, i, all) => filter?.(o, i, all) ?? true);

        q?.sort((a, b) => a.data.orderNumber - b.data.orderNumber);

        return q ?? [];
    }, [fieldOptions, filter]);

    const searchByLabelFunc = (input: string, option?: SelectOption) =>
        (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase());

    const searchByValueAndLabelFunc = (input: string, option?: SelectOption) =>
        (option?.value?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
        searchByLabelFunc(input, option);

    useLayoutEffect(() => {
        if (autoComplete) {
            document.querySelectorAll<HTMLInputElement>(`.select-${fieldName} input`).forEach((el) => {
                el.autocomplete = autoComplete;
                el.type = 'text';
            });
        }
    }, [autoComplete, fieldName]);

    const filterFunc = searchByValueAndLabel
        ? searchByValueAndLabelFunc
        : searchByLabel
        ? searchByLabelFunc
        : selectProps?.filterOption;

    const handleSearch = useCallback(
        (value: string) => {
            const match = options
                .filter((f) => (isFunction(filterFunc) ? filterFunc(value, f) : filterFunc))
                .find((o) => o.value == value || o.label == value);

            if (match && match.value) {
                selectProps.onChange?.(match.value.toString(), match);
            }
        },
        [filterFunc, options, selectProps],
    );

    return (
        <>
            <Select
                {...selectProps}
                options={options}
                filterOption={filterFunc}
                className={`${className ?? ''} select-${fieldName}`}
                onSearch={handleSearch}
                showSearch
                disabled={disabled || isEditable === 'false'}
            />
        </>
    );
};
