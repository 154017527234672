import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { RootState } from '../../../app/store';
import { LogikFieldsEnum, SerialTypeEnum } from '../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import { FieldUpdate } from '../../../shared/logik/models/field.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { fetchSets } from '../../widget/thunks/basic/fetchSets';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { setSiteId, setUUID } from '../../widget/Widget.slice';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { Preowned } from '../preowned.model';
import { PreownedState, setPreowned } from '../preowned.slice';
import { setUserProfile } from '../../widget/thunks/init/setUserProfile';

export type createPreownedMasterConfigurationParams = {
    siteId: string;
    preowned: Preowned;
};
export type createPreownedMasterConfigurationResult = {
    configuration: Configuration;
};
export const createPreownedMasterConfiguration = createAsyncThunk<
    createPreownedMasterConfigurationResult,
    createPreownedMasterConfigurationParams,
    {
        state: RootState;
    }
>(
    'preowned/create-master-configuration',
    async ({ siteId, preowned }, thunkAPI): Promise<createPreownedMasterConfigurationResult> => {
        const dispatch = thunkAPI.dispatch;

        if (!preowned.serialNumber) throw new Error("Prebuilt's serial number is missing");
        if (!preowned.productId) throw new Error("Prebuilt's product is missing");

        const updates: FieldUpdate[] = [
            {
                variableName: LogikFieldsEnum.serialType,
                value: SerialTypeEnum.preowned,
            },
            {
                variableName: LogikFieldsEnum.prebuiltIsMasterConfig,
                value: true,
            },
            {
                variableName: LogikFieldsEnum.prebuiltSerialNumber,
                value: preowned.serialNumber,
            },
            {
                variableName: LogikFieldsEnum.prebuiltSiteId,
                value: siteId,
            },
            {
                variableName: LogikFieldsEnum.product,
                value: preowned.productId,
            },
            {
                variableName: LogikFieldsEnum.warehouse,
                value: preowned.storeId,
            },
        ];

        try {
            const configuration = await logikApiService.createConfiguration(updates);
            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) dispatch(fetchTooltips({ layoutUrl }));

            await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
            dispatch(setSiteId(siteId));
            await dispatch(setUserProfile(configuration.uuid));

            const setsToFetch =
                configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [];

            await Promise.all([
                await dispatch(
                    fetchSets({
                        uuid: configuration.uuid,
                        sets: setsToFetch,
                    }),
                ),
                await dispatch(fetchPricingDetails(configuration.uuid)),
            ]);

            dispatch(setPreowned(preowned));
            dispatch(setUUID(configuration.uuid));

            return { configuration };
        } catch (error) {
            if (isAxiosError(error)) {
                throw new Error(error.response?.data.errorMessage);
            } else {
                throw error;
            }
        }
    },
);

export const addCreatePreownedMasterConfigurationCases = (
    builder: ActionReducerMapBuilder<PreownedState>,
): ActionReducerMapBuilder<PreownedState> => {
    return (
        builder
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(createPreownedMasterConfiguration.pending, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(createPreownedMasterConfiguration.fulfilled, (state) => {})
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        // .addCase(createPrebuiltMasterConfiguration.rejected, (state) => {})
    );
};
