import { useEffect } from 'react';

export const IFrameCommunicator = (): JSX.Element => {
    useEffect(() => {
        function callParentFunction(str: string) {
            if (str && str.length > 0 && window.parent && window.parent.parent) {
                window.parent.parent.postMessage(str, '/');
            }
        }

        function receiveMessage(event: MessageEvent<string>) {
            if (event && event.data) {
                if (event.origin.match(/.*\.authorize\.net/)) {
                    callParentFunction(event.data);
                }
            }
        }

        if (window.addEventListener) {
            window.addEventListener('message', receiveMessage, false);
        } else if (window.attachEvent) {
            //window.attachEvent('onmessage', receiveMessage);
        }

        if (window.location.hash && window.location.hash.length > 1) {
            callParentFunction(window.location.hash.substring(1));
        }

        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, []);

    return <></>;
};
