import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { fetchSets } from '../../widget/thunks/basic/fetchSets';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { setUserProfile } from '../../widget/thunks/init/setUserProfile';
import { setMasterConfigurationId, setThreekitId, setUUID } from '../../widget/Widget.slice';
import { Prebuilt } from '../prebuilt.model';
import { PrebuiltState, setPrebuilt } from '../prebuilts.slice';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { LogikFieldsEnum, SerialTypeEnum } from '../../../shared/logik/constants/fields.constant';

export type editPrebuiltMasterConfigurationParams = {
    siteId: string;
    prebuilt: Prebuilt;
};
export type editPrebuiltMasterConfigurationResult = {
    configuration: Configuration;
};
export const editPrebuiltMasterConfiguration = createAsyncThunk<
    editPrebuiltMasterConfigurationResult,
    editPrebuiltMasterConfigurationParams,
    {
        state: RootState;
    }
>(
    'prebuilts/edit-master-configuration',
    async ({ siteId, prebuilt }, thunkAPI): Promise<editPrebuiltMasterConfigurationResult> => {
        if (!prebuilt.masterConfigurationId) throw new Error('Prebuilt has no master configuration');

        const dispatch = thunkAPI.dispatch;

        const c = await ConfiguratorAPI.getConfigurationByMasterId(prebuilt.masterConfigurationId);

        const uuid = c.logikId;

        if (uuid) {
            const configuration = await logikApiService.copyConfiguration(uuid, [
                {
                    variableName: LogikFieldsEnum.serialType,
                    value: SerialTypeEnum.prebuilt,
                },
            ]);
            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
            await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
            await dispatch(setUserProfile(configuration.uuid));

            await Promise.all([
                dispatch(
                    fetchSets({
                        uuid: configuration.uuid,
                        sets: configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
                    }),
                ),
                dispatch(fetchPricingDetails(configuration.uuid)),
            ]);

            dispatch(setUUID(configuration.uuid));
            dispatch(setThreekitId(c.threeKitId));
            dispatch(setMasterConfigurationId(prebuilt.masterConfigurationId));
            dispatch(setPrebuilt(prebuilt));

            return { configuration };
        } else {
            return Promise.reject('Invalid UUID');
        }
    },
    {
        condition: (_, { getState }) => !getState().widget.uuid,
    },
);

export const addEditPrebuiltMasterConfigurationCases = (
    builder: ActionReducerMapBuilder<PrebuiltState>,
): ActionReducerMapBuilder<PrebuiltState> => {
    return (
        builder
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(editPrebuiltMasterConfiguration.pending, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(editPrebuiltMasterConfiguration.fulfilled, (state) => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(editPrebuiltMasterConfiguration.rejected, (state) => {})
    );
};
