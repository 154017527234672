import { toLower } from 'lodash';
import { Environment } from '../../app/environment';
import { PermissionsClaim, RoleClaim, UserWithCustomClaims } from './user.model';

export const userHasPermissions = (user: UserWithCustomClaims, siteId: string, permissions: string[]): boolean => {
    const userPermissions =
        user
            .getCustomClaimWithNamespace<PermissionsClaim[]>('permissions', Environment.auth0OOMSNamespace ?? '')
            ?.filter((up) => up.sites.includes(siteId))
            .flatMap((up) => up.operations) ?? [];

    for (const p of permissions) {
        if (!userPermissions.includes(p)) {
            return false;
        }
    }

    return true;
};

export const userHasRoles = (user: UserWithCustomClaims, siteId: string, role: string): boolean => {
    const roleClaim = user
        .getCustomClaimWithNamespace<RoleClaim[]>('roles', Environment.auth0OOMSNamespace ?? '')
        ?.find((r) => r.site === siteId)?.name;

    return toLower(roleClaim) === toLower(role);
};
