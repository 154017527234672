import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';

export enum PreownedActionTypeEnum {
    PreownedMasterNew = 'PreownedMasterNew',
    PreownedMasterEdit = 'PreownedMasterEdit',
}
export type PreownedActionType = keyof typeof PreownedActionTypeEnum;
export const PreownedMasterNewEvent = `${PreownedActionTypeEnum.PreownedMasterNew}Event`;
export const PreownedMasterEditEvent = `${PreownedActionTypeEnum.PreownedMasterEdit}Event`;
export type PreownedMasterNewEventParams = IFrameEventParams & {
    serial: string;
    launchObjectId?: string;
    siteId?: string;
};
export type PreownedMasterEditEventParams = PreownedMasterNewEventParams;
