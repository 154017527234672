import { Page } from '../../shared/components/layout/Page';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import variables from '../../styles/variables.module.scss';
import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';

export const SaveOrderAndRedirectEvent = 'SaveOrderAndRedirectEvent';
export type SaveOrderAndRedirectEventParams = IFrameEventParams & {
    masterId: string;
    siteId: string;
    logikId?: string;
    newOrder: boolean;
    newCR?: boolean;
    logikIdCR?: string;
    masterIdCR?: string;
    editCR?: boolean;
};
export const OrderCreatedPage = (): JSX.Element => {
    const userEmail = useFieldValue<string>(LogikFieldsEnum.cusEmail);

    return (
        <Page hideNavigationBar>
            <div
                style={{
                    position: 'relative',
                    textAlign: 'center',
                    color: variables.textColor,
                    fontSize: '16px',
                    fontWeight: 500,
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}>
                <p>Order submitted successfully!</p>
                <p>Documents for signature will be delivered to: {userEmail}</p>
                <p>We will finalize your order upon receiving signed documents and payment verification.</p>
                <p>For any questions email weborders@ulrichlifestyle.com</p>
                <p>If you are working with sales person please advise them you have submitted a web order</p>

                {/* <Button
                    type='primary'
                    onClick={() => {
                        navigate(ROUTE_REVIEW, {
                            absolute: true,
                            preserveSearch: true,
                            replace: true,
                            state: {
                                [REVIEW_AND_PAY_STEP_STATE_KEY]: REVIEW_AND_PAY_STEP_PAY,
                            },
                        });
                    }}>
                    Make another payment
                </Button> */}
            </div>
        </Page>
    );
};
