import { ConfigurationEventData } from '../../models/configuration-event-data.model';
import { LogikFieldsEnum, RoofMaterialEnum, SerialTypeEnum } from '../constants/fields.constant';
import { Field, getFieldOptions, getFieldValue } from '../models/field.model';
import { WidgetInitState } from '../../../features/widget/Widget.slice';
import { ProductDetail } from '../models/product.details.model';

export const fetchProduct = (fields: Record<string, Field>): string => {
    const product = getFieldValue(fields, LogikFieldsEnum.product);
    return (getFieldOptions(fields, LogikFieldsEnum.product).find((f) => f.value == product)?.label ?? '').toString();
};

export const fetchShipZipCode = (fields: Record<string, Field>): string => {
    return getFieldValue(fields, LogikFieldsEnum.shipping_zip)?.toString() ?? '';
};

export const fetchConfigurationEventDataFromFields = (
    fields: Record<string, Field>,
    productDetails: ProductDetail[],
    email?: string,
    name?: string,
    salesman?: string,
    siteId?: string,
    dealId?: string,
    subTotal?: number,
    createOrder = false,
    sendEmail = false,
    actionType: string | null | WidgetInitState['actionType'] = null,
    changedDescriptionId: string | null = null,
    launchObjectId: string | null = null,
): ConfigurationEventData => {
    return {
        width: getFieldValue(fields, LogikFieldsEnum.width),
        length: getFieldValue(fields, LogikFieldsEnum.length),
        lead: {
            email: email || getFieldValue(fields, LogikFieldsEnum.cusEmail),
            phone: getFieldValue(fields, LogikFieldsEnum.billing_primary_phone),
            fullName: name || getFieldValue(fields, LogikFieldsEnum.cusName),
        },
        billingAddress: {
            address: getFieldValue(fields, LogikFieldsEnum.billing_address),
            city: getFieldValue(fields, LogikFieldsEnum.billing_city),
            state: getFieldValue(fields, LogikFieldsEnum.billing_state),
            zip: getFieldValue(fields, LogikFieldsEnum.billing_zip),
            country: 'USA',
        },
        shippingAddress: {
            address: getFieldValue(fields, LogikFieldsEnum.shipping_address),
            city: getFieldValue(fields, LogikFieldsEnum.shipping_city),
            state: getFieldValue(fields, LogikFieldsEnum.shipping_state),
            zip: getFieldValue(fields, LogikFieldsEnum.shipping_zip),
            country: 'USA',
        },
        shipCode: getFieldValue(fields, LogikFieldsEnum.shipVia),
        siteId: getFieldValue(fields, LogikFieldsEnum.siteId) || siteId,
        prebuiltSerialNum: getFieldValue(fields, LogikFieldsEnum.prebuiltSerialNumber),
        prebuiltIsMasterConfig: getFieldValue(fields, LogikFieldsEnum.prebuiltIsMasterConfig),
        salesman,
        createOrder,
        dealId,
        sendEmail,
        actionType,
        changedDescriptionId,
        launchObjectId,
        serialType: getFieldValue<SerialTypeEnum>(fields, LogikFieldsEnum.serialType) || null,
        wallHeight: getFieldValue<number>(fields, LogikFieldsEnum.wallHeight),
        subTotal: subTotal ?? productDetails.find((p) => p.id === 'Subtotal')?.extended?.extPrice,
        total: productDetails.find((p) => p.id === 'TotalOrder')?.extended?.extPrice,
        appliedDiscounts: productDetails
            .filter((d) => d.id.startsWith('-Disc_'))
            .map((d) => ({
                id: d.id,
                name: d.description,
                amount: d.extended?.extPrice ?? 0,
            })),
        roofMaterial: getFieldValue<RoofMaterialEnum>(fields, LogikFieldsEnum.roofMaterial) || null,
    } as ConfigurationEventData;
};
