import { AxiosHeaders, AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from '../../app/store';

export const auth0AxiosInterceptor = (axios: AxiosInstance): number =>
    axios.interceptors.request.use(
        (request: AxiosRequestConfig) => {
            const token = store.getState().auth.authToken;
            if (token) {
                if (!request.headers) {
                    request.headers = new AxiosHeaders();
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                request.headers['Authorization'] = `Bearer ${token}`;
            }
            return request;
        },
        (error: unknown) => Promise.reject(error),
    );
