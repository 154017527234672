import { useRef } from 'react';
import { getMoneyInputValidators, MoneyInput, MoneyInputRef } from '../../../shared/antd/components/MoneyInput';
import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikSelect } from '../../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';

export function SummaryFinanceOptions(): JSX.Element {
    const approvedAmountRef = useRef<MoneyInputRef>(null);
    const downpaymentRef = useRef<MoneyInputRef>(null);

    return (
        <>
            <LogikFormItem
                logikName={LogikFieldsEnum.bankName}
                label='Bank Name'
                rules={[
                    {
                        required: true,
                        message: 'Bank name is required',
                    },
                ]}
                hasFeedback={false}>
                <LogikSelect fieldName={LogikFieldsEnum.bankName} showSearch searchByLabel placeholder='Select Bank' />
            </LogikFormItem>

            <LogikFormItem
                label='Finance Option'
                logikName={LogikFieldsEnum.financeOption}
                rules={[
                    {
                        required: true,
                        message: 'Finance option is required',
                    },
                ]}
                hasFeedback={false}>
                <LogikSelect fieldName={LogikFieldsEnum.financeOption} showSearch searchByLabel />
            </LogikFormItem>

            <LogikFormItem
                label='Approved Amount'
                logikName={LogikFieldsEnum.approvedAmount}
                rules={[
                    {
                        required: true,
                        message: 'Additional downpayment is required',
                    },
                    ...getMoneyInputValidators(approvedAmountRef),
                    {
                        validator: () => {
                            return Number(approvedAmountRef.current?.getUnMaskedValue()) > 0
                                ? Promise.resolve()
                                : Promise.reject();
                        },
                        message: 'Approved amount must be a positive number',
                    },
                ]}
                withDebounce={true}
                validateFirst={true}>
                <MoneyInput placeholder='Enter Amount' ref={approvedAmountRef} />
            </LogikFormItem>

            <LogikFormItem
                label='Additional Downpayment'
                logikName={LogikFieldsEnum.additionalDownpayment}
                rules={[
                    {
                        required: true,
                        message: 'Additional downpayment is required',
                    },
                    ...getMoneyInputValidators(downpaymentRef),
                ]}
                withDebounce={true}>
                <MoneyInput placeholder='Enter Downpayment' ref={downpaymentRef} />
            </LogikFormItem>
        </>
    );
}
