import { Navigate, RouteObject, defer, LoaderFunctionArgs } from 'react-router-dom';
import { ROUTE_CONFIGURATOR, ROUTE_SUMMARY } from '../wizard/wizard.router';
import { WidgetAsyncError } from '../widget/WidgetAsyncError';
import { StockRequestWizard } from './StockRequestWizard';
import { isAxiosError } from 'axios';
import { StockRequestsAPI } from './stock-requests.service';
import { StockRequestConfiguratorPage } from './StockRequestConfiguratorPage';
import { StockRequestSummaryPage } from './StockRequestSummaryPage';
import { WidgetAsyncLoader } from '../widget/WidgetAsyncLoader';
import { RouteProtected } from '../../shared/models/route-protected.model';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { delayUntilAuthorized } from '../../shared/utils/delayUntilAuthorized';

const routes: RouteObject[] = [
    {
        index: true,
        element: <Navigate to={ROUTE_CONFIGURATOR} replace={true} />,
    },
    {
        path: ROUTE_CONFIGURATOR,
        element: <StockRequestConfiguratorPage />,
    },
    {
        path: ROUTE_SUMMARY,
        element: <StockRequestSummaryPage />,
    },
];

export type StockRequestsLoadResult = {
    id?: string;
    launchObjectId?: string;
    productMasterConfigurationId: string;
    zip?: string;
    site: string;
    allowVariants: boolean;
};

export const stockRequestsRoutes: RouteProtected = {
    path: 'stock-requests',
    errorElement: <WidgetAsyncError />,
    children: [
        {
            path: '',
            loader: delayUntilAuthorized(async ({ params, request }: LoaderFunctionArgs): Promise<unknown> => {
                const id = params['id'];

                const url = new URL(request.url);
                const masterConfigurationId = url.searchParams.get('masterConfigurationId');
                if (!masterConfigurationId && !id) throw new Response('Product not found', { status: 404 });
                const site = url.searchParams.get('site');
                if (!site) throw new Response('Site is missing', { status: 500 });
                const allowVariantsParam = url.searchParams.get('allowVariants');
                const allowVariants = !!allowVariantsParam
                    ? allowVariantsParam.toLowerCase() === String(true)
                    : undefined;

                if (id) {
                    const stockRequest = StockRequestsAPI.get(site, id)
                        .then((p) => {
                            if (!p) throw new Error(`Building package with id '${id}' was not found`);
                            return {
                                launchObjectId: p.id,
                                productMasterConfigurationId: p.masterId,
                                id: p.id,
                                zip: undefined,
                                site,
                                allowVariants,
                            } as StockRequestsLoadResult;
                        })
                        .catch((r) => {
                            if (isAxiosError(r)) {
                                if (r.response?.status === 404) {
                                    throw new Response(`Building package with id '${id}' was not found`, {
                                        status: 404,
                                    });
                                }
                            }
                            throw r;
                        });

                    return defer({
                        stockRequest,
                    });
                } else {
                    const zip = url.searchParams.get('zipcode');
                    if (!zip) throw new Response('Zip code is missing', { status: 500 });

                    return defer({
                        stockRequest: {
                            launchObjectId: url.searchParams.get('launchObjectId'),
                            productMasterConfigurationId: masterConfigurationId,
                            zip,
                            site,
                            allowVariants,
                        } as StockRequestsLoadResult,
                    });
                }
            }),
            shouldRevalidate: () => false,
            element: (
                <WidgetAsyncLoader<{ [key: string]: unknown }> resolverSelector={(data) => data.stockRequest}>
                    <ErrorBoundary>
                        <StockRequestWizard />
                    </ErrorBoundary>
                </WidgetAsyncLoader>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={'new'} replace={true} />,
                },
                {
                    path: 'new',
                    children: [...routes],
                },
                {
                    path: ':id',
                    children: [
                        {
                            index: true,
                            element: <Navigate to={'edit'} replace={true} />,
                        },
                        {
                            path: 'edit',
                            children: [...routes],
                        },
                    ],
                },
            ],
        },
    ],
};
