import { useRef } from 'react';
import { getPhoneInputValidators, PhoneInputRef } from '../../../shared/antd/components/PhoneInput';
import { PhoneInput } from '../../../shared/antd/components/PhoneInput';
import { useScreenChange } from '../../../shared/hooks/screen';
import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikFields } from '../../../shared/logik/constants/fields.constant';
import { LogikInput } from '../../../shared/logik/components/LogikInput';

type SummaryRTOReferencesProps = {
    referenceNumber: number;
};
export function SummaryRTOReferences({ referenceNumber }: SummaryRTOReferencesProps): JSX.Element {
    const i = referenceNumber;
    const { size } = useScreenChange();
    const labelColSpan = size === 'small' ? 12 : 10;
    const phoneInputRef = useRef<PhoneInputRef>(null);

    return (
        <>
            <LogikFormItem
                label={<span style={{ whiteSpace: 'normal' }}>Reference Name</span>}
                labelCol={{ span: labelColSpan }}
                logikName={LogikFields.getLogikFieldName(`rtoRef${i}Name`) ?? ''}
                rules={[
                    {
                        required: true,
                        message: 'Name is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Reference Name' />
            </LogikFormItem>

            <LogikFormItem
                label={<span style={{ whiteSpace: 'normal' }}>Reference Phone</span>}
                labelCol={{ span: labelColSpan }}
                logikName={LogikFields.getLogikFieldName(`rtoRef${i}Phone`) ?? ''}
                updateOnBlur
                updateOnMaskComplete
                rules={[
                    {
                        required: true,
                        message: 'Reference Phone is required',
                    },
                    ...getPhoneInputValidators(phoneInputRef),
                ]}>
                <PhoneInput ref={phoneInputRef} />
            </LogikFormItem>

            <LogikFormItem
                label={<span style={{ whiteSpace: 'normal' }}>Reference Relation to Buyer</span>}
                labelCol={{ span: labelColSpan }}
                logikName={LogikFields.getLogikFieldName(`rtoRef${i}Relation`) ?? ''}
                rules={[
                    {
                        required: true,
                        message: 'Relation is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput placeholder='Enter Relation' />
            </LogikFormItem>
        </>
    );
}
