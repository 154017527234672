import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { setFromConfiguration, WidgetState } from '../../Widget.slice';
import { fetchPricingDetails } from './fetchPricingDetails';
import { fetchSets } from './fetchSets';

export interface PostProcessConfigurationArgs {
    configuration: Configuration;
    skipRelatedPulls?: boolean;
}
export const postProcessConfiguration = createAsyncThunk<
    Configuration,
    PostProcessConfigurationArgs,
    {
        state: RootState;
    }
>('configuration/postProcess', async ({ configuration, skipRelatedPulls }, thunkAPI): Promise<Configuration> => {
    const dispatch = thunkAPI.dispatch;

    const tasks = [];

    if (!skipRelatedPulls) {
        tasks.push(
            dispatch(
                fetchSets({
                    uuid: configuration.uuid,
                    sets: configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
                }),
            ),
        );

        if (configuration.relatedChanges?.find((rc) => rc.type === 'PRODUCT')) {
            tasks.push(dispatch(fetchPricingDetails(configuration.uuid)));
        }
    }

    await Promise.all(tasks);
    dispatch(setFromConfiguration(configuration));
    return configuration;
});

export const addPostProcessConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder;
};
