import { Form } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useCallback, useImperativeHandle, useRef } from 'react';
import { FilesAPI } from '../../shared/services/files.service';
import { RcFile, UploadFile } from 'antd/es/upload';
import { FileUploadOptions } from '../../shared/models/file-upload.model';
import React from 'react';
import { UploadRef } from 'antd/es/upload/Upload';
import { isArray } from 'lodash';

export type ReceiptUploadProps = Record<string, unknown>;
export type ReceiptUploadRef = {
    initiateUpload: () => Promise<boolean>;
    getFiles: () => File[];
};
export const ReceiptUpload = React.forwardRef<ReceiptUploadRef, ReceiptUploadProps>(function ReceiptUpload(
    { masterConfigurationId, ...props }: ReceiptUploadProps,
    ref,
): JSX.Element {
    const uploadRef = useRef<UploadRef>(null);

    const getFiles = useCallback(() => {
        return uploadRef.current?.fileList.map((f) => f.originFileObj).filter<RcFile>((f): f is RcFile => !!f) ?? [];
    }, []);

    const getValueFromUploadEvent = useCallback((e: Record<string, unknown>) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }, []);

    const uploadFile = useCallback(
        async (file: RcFile | RcFile[]): Promise<string> => {
            const uploaded = await FilesAPI.upload({
                files: isArray(file) ? [...file] : [file],
                isPublic: false,
                keepFileNames: true,
                fileUploadOptions: FileUploadOptions.onDuplicateRename,
                path: `cnf/${masterConfigurationId}/receipts`,
            });

            return uploaded[0].id;
        },
        [masterConfigurationId],
    );

    useImperativeHandle(
        ref,
        () => ({
            initiateUpload: async () => {
                if (uploadRef.current?.fileList) {
                    await uploadFile(getFiles());
                    return true;
                } else {
                    return false;
                }
            },
            getFiles,
        }),
        [getFiles, uploadFile],
    );

    return (
        <Form.Item
            name='receipts'
            valuePropName='fileList'
            getValueFromEvent={getValueFromUploadEvent}
            rules={[
                {
                    required: true,
                },
            ]}>
            <Dragger
                ref={uploadRef}
                name='receipts'
                beforeUpload={() => false}
                // onChange={(info) => {
                //     const { status } = info.file;
                //     if (status !== 'uploading') {
                //         console.log(info.file, info.fileList);
                //     }
                //     if (status === 'done') {
                //         //message.success(`${info.file.name} file uploaded successfully.`);
                //     } else if (status === 'error') {
                //         //message.error(`${info.file.name} file upload failed.`);
                //     }
                // }}
                // onDrop={(e) => {
                //     console.log('Dropped files', e.dataTransfer.files);
                // }}

                multiple
                showUploadList={{
                    showDownloadIcon: true,
                    downloadIcon: 'Download',
                    showRemoveIcon: true,
                }}>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined style={{ color: '#B77259' }} />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Proof of payment</p>
            </Dragger>
        </Form.Item>
    );
});

export const getFilesFromUploads = (uploads: UploadFile[]): File[] => {
    return uploads.map((f) => f.originFileObj).filter<RcFile>((f): f is RcFile => !!f) ?? [];
};
