import { useRef } from 'react';
import { getMoneyInputValidators, MoneyInput, MoneyInputRef } from '../../../shared/antd/components/MoneyInput';
import { LogikFormItem } from '../../../shared/logik/components/LogikFormItem';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { LogikSwitch } from '../../../shared/logik/components/LogikSwitch';

export function SummaryMiscellaneous(): JSX.Element {
    const moneyRef = useRef<MoneyInputRef>(null);

    return (
        <>
            {/* <SummarySectionHeader>Miscellaneous</SummarySectionHeader> */}
            <LogikFormItem
                label='Additional Charges'
                direction='horizontal'
                logikName={LogikFieldsEnum.additionalCharges}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>

            <LogikFormItem
                label='Amount'
                direction='horizontal'
                logikName={LogikFieldsEnum.additionalChargesAmount}
                withDebounce={true}
                rules={getMoneyInputValidators(moneyRef)}>
                <MoneyInput placeholder='Enter Amount' ref={moneyRef} />
            </LogikFormItem>

            <LogikFormItem
                label='Rush Order Fee'
                direction='horizontal'
                logikName={LogikFieldsEnum.upgRushOrder}
                valuePropName='checked'
                hasFeedback={false}>
                <LogikSwitch />
            </LogikFormItem>
        </>
    );
}
