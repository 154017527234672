export type EnvMode = 'development' | 'production' | 'test' | 'testlocal' | 'pilot';

const {
    REACT_APP_MODE,
    REACT_APP_LOGIK_API_URL,
    REACT_APP_LOGIK_API_TOKEN,
    REACT_APP_LOGIK_PRODUCT_ID,
    REACT_APP_THREEKIT_URL,
    REACT_APP_THREEKIT_TOKEN,
    REACT_APP_THREEKIT_ORG_ID,
    REACT_APP_THREEKIT_ASSET_ID,
    REACT_APP_EIGHTH_WALL_API_KEY,
    REACT_APP_AUTHORIZENET_HOSTED_FORM_SANDBOX_URL,
    REACT_APP_AUTHORIZENET_HOSTED_FORM_PROD_URL,
    REACT_APP_AUTHORIZENET_ACCEPT_JS_SANDBOX_URL,
    REACT_APP_AUTHORIZENET_ACCEPT_JS_PROD_URL,
    REACT_APP_GOOGLE_MAPS_API_KEY,
    REACT_APP_AUTH0_OOMS_NAMESPACE,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_SALES_QUOTE_TEMPLATE_ID,
    REACT_APP_FRAME_ANCESTORES,
    REACT_APP_OMS_ORIGIN,
    REACT_APP_GA_TRACKING_ID,
} = process.env;

const error = (message: string): never => {
    throw new Error(message);
};

export class Environment {
    public static get mode(): EnvMode {
        return (REACT_APP_MODE as EnvMode) ?? error("'REACT_APP_MODE' is not set");
    }
    public static get isDevelopment(): boolean {
        return Environment.inMode('development') || Environment.inMode('test') || Environment.inMode('testlocal');
    }
    public static get isProduction(): boolean {
        return Environment.inMode('production');
    }
    public static get isPilot(): boolean {
        return Environment.inMode('pilot');
    }
    public static inMode = (mode: EnvMode): boolean => {
        return Environment.mode === mode;
    };
    public static get logikApiToken(): string {
        return REACT_APP_LOGIK_API_TOKEN ?? error(`'REACT_APP_LOGIK_API_TOKEN' is not set`);
    }
    public static get logikApiUrl(): string {
        return REACT_APP_LOGIK_API_URL ?? error(`'REACT_APP_LOGIK_API_URL' is not set`);
    }
    public static get logikProductId(): string {
        return REACT_APP_LOGIK_PRODUCT_ID ?? error(`'REACT_APP_LOGIK_PRODUCT_ID' is not set`);
    }
    public static get threekitUrl(): string {
        return REACT_APP_THREEKIT_URL ?? error(`'REACT_APP_THREEKIT_URL' is not set`);
    }
    public static get threekitApiToken(): string {
        return REACT_APP_THREEKIT_TOKEN ?? error(`'REACT_APP_THREEKIT_TOKEN' is not set`);
    }
    public static get threekitOrgId(): string {
        return REACT_APP_THREEKIT_ORG_ID ?? error(`'REACT_APP_THREEKIT_ORG_ID' is not set`);
    }
    public static get threekitAssetId(): string {
        return REACT_APP_THREEKIT_ASSET_ID ?? error("'REACT_APP_THREEKIT_ASSET_ID' is not set");
    }
    public static get appEighthWallApiKey(): string {
        return REACT_APP_EIGHTH_WALL_API_KEY ?? error("'REACT_APP_EIGHTH_WALL_API_KEY' is not set");
    }
    public static get authorizeNetHostedFormSandboxUrl(): string {
        return (
            REACT_APP_AUTHORIZENET_HOSTED_FORM_SANDBOX_URL ??
            error("'REACT_APP_AUTHORIZENET_HOSTED_FORM_SANDBOX_URL' is not set")
        );
    }
    public static get authorizeNetHostedFormProdUrl(): string {
        return (
            REACT_APP_AUTHORIZENET_HOSTED_FORM_PROD_URL ??
            error("'REACT_APP_AUTHORIZENET_HOSTED_FORM_PROD_URL' is not set")
        );
    }
    public static get authorizeNetAcceptJSSandboxUrl(): string {
        return (
            REACT_APP_AUTHORIZENET_ACCEPT_JS_SANDBOX_URL ??
            error("'REACT_APP_AUTHORIZENET_ACCEPT_JS_SANDBOX_URL' is not set")
        );
    }
    public static get authorizeNetAcceptJSProdUrl(): string {
        return (
            REACT_APP_AUTHORIZENET_ACCEPT_JS_PROD_URL ?? error("'REACT_APP_AUTHORIZENET_ACCEPT_JS_PROD_URL' is not set")
        );
    }
    public static get googleMapsApiKey(): string {
        return REACT_APP_GOOGLE_MAPS_API_KEY ?? error("'REACT_APP_GOOGLE_MAPS_API_KEY' is not set");
    }
    public static get auth0Domain(): string {
        return REACT_APP_AUTH0_DOMAIN ?? error("'REACT_APP_AUTH0_DOMAIN' is not set");
    }
    public static get auth0ClientId(): string {
        return REACT_APP_AUTH0_CLIENT_ID ?? error("'REACT_APP_AUTH0_CLIENT_ID' is not set");
    }
    public static get auth0OOMSNamespace(): string {
        return REACT_APP_AUTH0_OOMS_NAMESPACE ?? error("'REACT_APP_AUTH0_OOMS_NAMESPACE' is not set");
    }
    public static get auth0Audience(): string {
        return REACT_APP_AUTH0_AUDIENCE ?? error("'REACT_APP_AUTH0_AUDIENCE' is not set");
    }
    public static get apiGatewayUrl(): string {
        return REACT_APP_API_GATEWAY_URL ?? error("'REACT_APP_API_GATEWAY_URL' is not set");
    }
    public static get salesQuoteTemplateId(): string | undefined {
        return REACT_APP_SALES_QUOTE_TEMPLATE_ID;
    }
    public static get frameAncestors(): string {
        return REACT_APP_FRAME_ANCESTORES ?? error("'REACT_APP_FRAME_ANCESTORES' is not set");
    }
    public static get omsOrigin(): string {
        return REACT_APP_OMS_ORIGIN ?? error("'REACT_APP_OMS_ORIGIN' is not set");
    }

    public static get gaTrackingId(): string | undefined {
        return REACT_APP_GA_TRACKING_ID;
    }
}
