import { Button } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { GMap } from '../../shared/google/components/GMap';
import logger from '../../app/logger';
import { LogEntry } from '../../shared/models/logentry';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './AddressMapModal.module.scss';
import AddressAutocomplete, { AddressAutocompleteRefType } from '../../shared/google/components/AddressAutocomplete';
import { Environment } from '../../app/environment';
import { GMarker } from '../../shared/google/components/GMarker';
import { formatGAddress, GAddress } from '../../shared/google/models/GAddress';
import { geocodeResultsToAddress, placeResultToAddress } from '../../shared/google/utils/address.utils';
import { SearchOutlined } from '@ant-design/icons';

export type AddressMapProps = {
    onOk?: (address: GAddress) => void;
    onCancel?: () => void;
    defaultAddress?: GAddress;
};

export const AddressMapModal = ({ onOk, onCancel, defaultAddress }: AddressMapProps): JSX.Element => {
    const ref = useRef<AddressAutocompleteRefType>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null);
    const [geocoderService, setGeocoderService] = useState<google.maps.Geocoder | null>(null);
    const [center, setCenter] = useState<google.maps.LatLng | null>(null);
    const [address, setAddress] = useState<GAddress>();

    const isAddressValid = (): boolean => {
        return !!address && !!address.address && !!address.city && !!address.state && !!address.zip;
    };

    const onGLibraryInit = (status: Status, loader: Loader) => {
        if (status === Status.SUCCESS) {
            const geocoder = new google.maps.Geocoder();
            setGeocoderService(geocoder);

            if (defaultAddress?.address) {
                let request: google.maps.GeocoderRequest;

                if (defaultAddress?.address?.match(/-?\d+\.\d+,-?\d+\.\d+/g)) {
                    const parts = defaultAddress.address.split(',');

                    request = {
                        location: new google.maps.LatLng(parseFloat(parts[0]), parseFloat(parts[1])),
                    };
                } else {
                    request = {
                        address: `${defaultAddress.address}, ${defaultAddress.city}, ${defaultAddress.state} ${defaultAddress.zip}`,
                    };
                }
                geocoder?.geocode(request).then((addressReposne) => {
                    setCenter(addressReposne.results[0].geometry.location);
                    setMarkerPosition(addressReposne.results[0].geometry.location);
                    const addr = geocodeResultsToAddress(addressReposne?.results);
                    setAddress(addr);
                    ref.current?.setAutocompleteValue(formatGAddress(addr, true));
                });
            } else if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        if (center === null) {
                            setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
                        }
                    },
                    (error: GeolocationPositionError) => {
                        logger.error(new LogEntry(error.message, error));
                    },
                );
            }
        }
    };

    const handleOk = useCallback((): void => {
        if (!!address) {
            if (markerPosition) {
                address.coordinates = {
                    lat: markerPosition.lat(),
                    lng: markerPosition.lng(),
                };
            }
            if (!!onOk) onOk(address);
        }
    }, [address, markerPosition, onOk]);

    const handleCoordinatesOk = useCallback(async (): Promise<void> => {
        if (markerPosition && !!address) {
            address.address = markerPosition.toUrlValue();
            address.coordinates = {
                lat: markerPosition.lat(),
                lng: markerPosition.lng(),
            };
            if (!!onOk) onOk(address);
        }
    }, [address, markerPosition, onOk]);

    const handleCancel = (): void => {
        if (!!onCancel) onCancel();
    };

    const onMapIdle = (idle: google.maps.Map) => {
        if (map == null && !!idle) {
            setMap(idle);
        }
    };

    const onMapClick = async (click: google.maps.MapMouseEvent) => {
        setMarkerPosition(click.latLng);
        const geo = await geocoderService?.geocode({
            location: click.latLng,
        });

        if (geo && click.latLng) {
            const addr = geocodeResultsToAddress(geo.results, click.latLng);
            setAddress(addr);
            ref.current?.setAutocompleteValue(formatGAddress(addr, true));
        }
    };

    const onPlaceChanged = (place: google.maps.places.PlaceResult): void => {
        if (!!place.address_components) {
            setAddress(placeResultToAddress(place));
        }

        if (!!place.geometry?.location) {
            setCenter(place.geometry.location);
            setMarkerPosition(place.geometry.location);
        }
    };

    useEffect(() => {
        if (center) {
            map?.setCenter(center);
            map?.setZoom(10);
        }
    }, [center, map]);

    return (
        <>
            <>
                <p className={styles['title']}>
                    Select a location on the map
                    <br />
                    or enter in the search
                </p>
                <div className={styles['autocomplete']}>
                    <AddressAutocomplete
                        onPlaceChanged={onPlaceChanged}
                        map={map ?? undefined}
                        ref={ref}
                        inputProps={{
                            suffix: <SearchOutlined />,
                            autoFocus: true,
                        }}
                        standalone={true}
                        placeholder='Enter location'
                    />
                </div>
            </>

            <div className={styles['g-map-container-fixed']}>
                <Wrapper apiKey={Environment.googleMapsApiKey} libraries={['places']} callback={onGLibraryInit}>
                    <GMap
                        // center={center}
                        // zoom={10}
                        onIdle={onMapIdle}
                        onMapClick={onMapClick}
                        className={styles['g-map-container']}>
                        <GMarker position={markerPosition} />
                    </GMap>
                </Wrapper>
            </div>

            <div className={styles['buttons']}>
                <Button htmlType='button' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button htmlType='button' type='primary' onClick={handleOk} disabled={!isAddressValid()}>
                    Confirm Location
                </Button>
                <Button htmlType='button' type='primary' onClick={handleCoordinatesOk} disabled={!markerPosition}>
                    Confirm Location using coordinates
                </Button>
            </div>
        </>
    );
};
