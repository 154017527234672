import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Preowned } from './preowned.model';
import { addCreatePreownedMasterConfigurationCases } from './thunks/createPreownedMasterConfiguration';
import { addEditPreownedMasterConfigurationCases } from './thunks/editPreownedMasterConfiguration';

export interface PreownedState {
    details?: Preowned;
}

export const PreownedInitialState: PreownedState = {};

export const PreownedSlice = createSlice({
    name: 'Preowned',
    initialState: PreownedInitialState,
    reducers: {
        setPreowned: (state, value) => {
            state.details = value.payload;
        },
    },
    extraReducers: (builder) => {
        addCreatePreownedMasterConfigurationCases(builder);
        addEditPreownedMasterConfigurationCases(builder);

        // builder.addMatcher(
        //     (action) => action.type.endsWith('/rejected'),
        //     (_, action) => {
        //         logger.error(action.error);
        //     },
        // );
    },
});

export const { setPreowned } = PreownedSlice.actions;
export const selectPreowned = (state: RootState): PreownedState => state.preowned;
export const selectPreownedDetails = (state: RootState): Preowned | undefined => state.preowned.details;
export default PreownedSlice.reducer;
