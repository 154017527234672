import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { WidgetState, setUUID } from '../../widget/Widget.slice';
import { FieldUpdate } from '../../../shared/logik/models/field.model';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { fetchSets } from '../../widget/thunks/basic/fetchSets';
import { fetchPricingDetails } from '../../widget/thunks/basic/fetchPricingDetails';
import { isAxiosError } from 'axios';

export type createProductTemplateArgs = {
    productId: string;
};
export type createProductTemplateResult = {
    configuration: Configuration;
};
export const createProductTemplate = createAsyncThunk<
    createProductTemplateResult,
    createProductTemplateArgs,
    {
        state: RootState;
    }
>('products-templates/create', async ({ productId }, thunkAPI): Promise<createProductTemplateResult> => {
    const { dispatch } = thunkAPI;

    const updates: FieldUpdate[] = [
        {
            variableName: LogikFieldsEnum.product,
            value: productId,
        },
        {
            variableName: LogikFieldsEnum.purchaseOpt,
            value: 'Cash',
        },
        {
            variableName: LogikFieldsEnum.permits,
            value: false,
        },
        {
            variableName: LogikFieldsEnum.shipVia,
            value: 'TD',
        },
        {
            variableName: LogikFieldsEnum.shipping_zip,
            value: 'DefaultZipCode',
        },
    ];

    try {
        const configuration = await logikApiService.createConfiguration(updates);
        const layoutUrl = getDefaultLayout(configuration)?.url;
        if (layoutUrl) dispatch(fetchTooltips({ layoutUrl }));

        await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
        const setsToFetch = configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [];

        await Promise.all([
            await dispatch(
                fetchSets({
                    uuid: configuration.uuid,
                    sets: setsToFetch,
                }),
            ),
            await dispatch(fetchPricingDetails(configuration.uuid)),
        ]);

        dispatch(setUUID(configuration.uuid));

        return { configuration };
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response?.data.errorMessage);
        } else {
            throw error;
        }
    }
});

export const addCreateProductTemplateCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(createProductTemplate.pending, (state) => {
            state.initialized = false;
            state.initializing = true;
        })
        .addCase(createProductTemplate.fulfilled, (state) => {
            state.initializing = false;
            state.initialized = true;
        })
        .addCase(createProductTemplate.rejected, (state) => {
            state.initializing = false;
            state.initialized = true;
        });
};
