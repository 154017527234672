import { useAppSelector } from '../../app/hooks';
import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';

export const WizardLoader = (): JSX.Element => {
    const saving = useAppSelector((state) => state.widget.saving);
    const loading = useAppSelector((state) => state.widget.loading);
    const initializing = useAppSelector((state) => state.widget.initializing);
    const orderProcessing = useAppSelector((state) => state.checkout.orderProcessing);

    return saving || loading || initializing || orderProcessing ? <WidgetAsyncFallback /> : <></>;
};
