import { FormInstance } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { LogikFormItem } from '../../shared/logik/components/LogikFormItem';
import { LogikSelect } from '../../shared/logik/components/LogikSelect';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { AddressMapModal } from './AddressMapModal';
import { CustomerFormState } from './CustomerPage';
import styles from './AddressForm.module.scss';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { TerritoryChangeModal } from './TerritoryChangeModal';
import { getPhoneInputValidators, PhoneInput, PhoneInputRef } from '../../shared/antd/components/PhoneInput';
import { DeviceType } from '../../shared/hooks/screen';
import { getZipCodeInputValidators, ZipCodeInput, ZipCodeInputRef } from '../../shared/antd/components/ZipCodeInput';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CONFIGURATOR } from '../wizard/wizard.router';
import { LogikForm } from '../../shared/logik/components/LogikForm';
import { LogikInput } from '../../shared/logik/components/LogikInput';

type AddressFormProps = {
    form: FormInstance<CustomerFormState>;
    device: DeviceType;
};
export const AddressFormBilling = ({ form, device }: AddressFormProps): JSX.Element => {
    const navigate = useNavigate();
    const [modalHandle, setModalHandle] = useState<FlexibleModalHandle<typeof AddressMapModal>>();
    const [terrModalHandle, setTerrModalHandle] = useState<FlexibleModalHandle<typeof TerritoryChangeModal>>();
    const territory = useFieldValue<string>(LogikFieldsEnum.territory);
    const [prevTerritory, setPrevTerritory] = useState<string>();

    const phoneInputRef = useRef<PhoneInputRef>(null);
    const zipInputRef = useRef<ZipCodeInputRef>(null);

    const { setFieldValue, validateFields } = form;

    useEffect(() => {
        if (!modalHandle) {
            const modal = FlexibleModal.createModal(AddressMapModal, { destroyOnClose: true });

            setModalHandle(modal);
        }
    }, [modalHandle]);

    useEffect(() => {
        if (!terrModalHandle) {
            const modal = FlexibleModal.createModal(TerritoryChangeModal, { destroyOnClose: false });

            setTerrModalHandle(modal);
        }
    }, [terrModalHandle]);

    useEffect(() => {
        setFieldValue(LogikFieldsEnum.territory, territory);
        validateFields([LogikFieldsEnum.territory]);

        if (!!prevTerritory && !!territory && prevTerritory !== territory) {
            if (terrModalHandle) {
                terrModalHandle.open({
                    onRequestClose: () => {
                        terrModalHandle.close();
                    },
                    onRequestCloseAndRedirect: () => {
                        terrModalHandle.close();
                        navigate('../' + ROUTE_CONFIGURATOR, { replace: true });
                    },
                });
            }
        }

        if (!!territory && !prevTerritory) {
            setPrevTerritory(territory);
        }
    }, [navigate, prevTerritory, setFieldValue, terrModalHandle, territory, validateFields]);

    return (
        <LogikForm
            name={`billing-address-form`}
            form={form}
            layout={device === 'mobile' || device === 'tablet' ? 'vertical' : 'horizontal'}
            labelCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 8 }}
            wrapperCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 16 }}
            labelAlign='left'
            requiredMark={true}
            autoComplete='on'>
            <div className={styles['map-field-container']}>
                <LogikFormItem
                    logikName={LogikFieldsEnum.billing_address}
                    label='Address'
                    rules={[
                        {
                            required: true,
                            message: 'Address is required',
                        },
                    ]}
                    withDebounce={false}>
                    <LogikInput autoComplete='street-address' />
                </LogikFormItem>
            </div>
            <LogikFormItem
                logikName={LogikFieldsEnum.billing_city}
                label='City'
                rules={[
                    {
                        required: true,
                        message: 'City is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput autoComplete='address-level2' />
            </LogikFormItem>

            <LogikFormItem
                logikName={LogikFieldsEnum.billing_state}
                label='State'
                rules={[
                    {
                        required: true,
                        message: 'State is required',
                    },
                ]}>
                <LogikSelect
                    showSearch
                    searchByValueAndLabel
                    filterOption={(input, option) =>
                        (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder='Enter your state'
                    autoComplete='address-level1'
                />
            </LogikFormItem>

            <LogikFormItem
                logikName={LogikFieldsEnum.billing_zip}
                label='Zip Code'
                rules={[
                    {
                        required: true,
                        message: 'Zip Code is required',
                    },
                    ...getZipCodeInputValidators(zipInputRef),
                ]}
                updateOnBlur
                updateOnMaskComplete>
                <ZipCodeInput placeholder='Enter your zip code' ref={zipInputRef} autoComplete='postal-code' />
            </LogikFormItem>

            <LogikFormItem
                label='Phone'
                logikName={LogikFieldsEnum.billing_primary_phone}
                updateOnBlur
                updateOnMaskComplete
                rules={[{ required: true, message: 'Phone is required' }, ...getPhoneInputValidators(phoneInputRef)]}>
                <PhoneInput ref={phoneInputRef} autoComplete='tel' />
            </LogikFormItem>
        </LogikForm>
    );
};
