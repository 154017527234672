import { Form, FormProps } from 'antd';
import { PropsWithChildren, ReactElement, useCallback, useMemo } from 'react';
import { useFocusNextOnEnter } from '../../antd/hooks/form.hooks';
import { useLogikFormDefaultState } from '../hooks/form.hooks';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

export type LogikFormProps<TFormState> = PropsWithChildren<FormProps<TFormState>>;
export const LogikForm = <TFormState,>({
    onFinishFailed,
    onKeyDown,
    initialValues,
    children,
    ...formProps
}: LogikFormProps<TFormState>): ReactElement | null => {
    const defaultState = useLogikFormDefaultState();
    const stateHasValues = useMemo(() => Object.keys(defaultState).length > 0, [defaultState]);
    const onFocusNextOnEnter = useFocusNextOnEnter(formProps.form);

    const onError = useCallback(
        (errorInfo: ValidateErrorEntity<TFormState>) => {
            if (!formProps.form) return;

            if (errorInfo.errorFields?.length > 0) {
                formProps.form.scrollToField(errorInfo.errorFields[0].name[0]);
            }
        },
        [formProps.form],
    );

    const defaultValues = useMemo(() => {
        return {
            ...(initialValues ?? {}),
            ...defaultState,
        };
    }, [defaultState, initialValues]);

    return useMemo(
        () =>
            stateHasValues ? (
                <Form
                    initialValues={defaultValues}
                    onKeyDown={onKeyDown ?? onFocusNextOnEnter}
                    onFinishFailed={onFinishFailed ?? onError}
                    {...formProps}>
                    {children}
                </Form>
            ) : (
                <></>
            ),
        [children, defaultValues, formProps, onError, onFinishFailed, onFocusNextOnEnter, onKeyDown, stateHasValues],
    );
};
