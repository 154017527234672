import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { updateTotalPrice } from '../../configurator/thunks/updateTotalPrice';
import { WidgetState, setMasterConfigurationId } from '../../widget/Widget.slice';
import { fetchConfigurationEventDataFromFields } from '../../../shared/logik/utils/fields.utils';
import { ProductTemplatesActionTypeEnum } from '../product-templates.utils';

export type saveProductTemplateResult = {
    masterId: string;
    configuration: Configuration;
};
export const saveProductTemplate = createAsyncThunk<
    saveProductTemplateResult,
    void,
    {
        state: RootState;
    }
>('products-templates/save', async (_, thunkAPI): Promise<saveProductTemplateResult> => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const widgetState = state.widget;

    const uuid = widgetState.uuid;
    const productId = widgetState.productId;
    const threeKitId = widgetState.threeKitId;

    if (!uuid || !productId) return Promise.reject("Can't save product template: invalid configuration data");

    await dispatch(updateTotalPrice());
    const configuration = await logikApiService.saveConfiguration(uuid);

    const res = await ConfiguratorAPI.saveConfiguration({
        logikId: uuid,
        productId: productId,
        product: '',
        threeKitId: threeKitId ?? '',
        data: {
            ...fetchConfigurationEventDataFromFields(
                widgetState.configurationFields,
                configuration.products ?? [],
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                false,
                false,
                !widgetState.masterConfigurationId
                    ? ProductTemplatesActionTypeEnum.ProductTemplateNew
                    : ProductTemplatesActionTypeEnum.ProductTemplateEdit,
                null,
                widgetState.init?.launchObjectId,
            ),
            images: widgetState.images3D,
        },
        masterConfigurationId: widgetState.masterConfigurationId,
    });

    if (!res.masterConfigurationId) throw new Error('Failed to save master configuration');

    dispatch(setMasterConfigurationId(res.masterConfigurationId));

    return {
        masterId: res.masterConfigurationId,
        configuration: configuration,
    };
});

export const addSaveProductTemplateCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(saveProductTemplate.pending, (state) => {
            state.saving = true;
        })
        .addCase(saveProductTemplate.fulfilled, (state) => {
            state.saving = false;
        })
        .addCase(saveProductTemplate.rejected, (state) => {
            state.saving = false;
        });
};
