import { Modal } from 'antd';
import { useCallback } from 'react';
import { useAppDispatch } from '../../app/hooks';
import logger from '../../app/logger';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { savePrebuiltMasterConfiguration } from './thunks/savePrebuiltMasterConfiguration';

const { success } = Modal;

export const useSavePrebuiltFunc = (): (() => void) => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        try {
            const res = await dispatch(savePrebuiltMasterConfiguration()).unwrap();
            success({
                title: 'Prebuilt has been successfuly saved',
                content: 'Now you can close the window or go back and make some changes',
                okText: 'Go back',
                cancelText: 'Go back',
                onOk: () => dispatch(recreateConfiguration(res.configuration.uuid)),
            });
        } catch (err: unknown) {
            logger.error(err);
        }
    }, [dispatch]);
};
