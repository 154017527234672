import { useEffect, useRef } from 'react';
import { Outlet, useAsyncValue, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import { LogikGlobalErrors } from '../../shared/logik/components/LogikGlobalErrors';
import { AdminOverlay } from '../admin-overlay/AdminOverlay';
import { UnloadGuard } from '../unload-guard/UnloadGuard';
import { Prebuilt } from './prebuilt.model';
import { PrebuiltsAdminSection } from './PrebuiltsAdminSection';
import { createPrebuiltMasterConfiguration } from './thunks/createPrebuiltMasterConfiguration';
import { editPrebuiltMasterConfiguration } from './thunks/editPrebuiltMasterConfiguration';
import { IdleHeartbeat } from '../idle/IdleHeartbeat';
import styles from '../wizard/Wizard.module.scss';

export const PrebuiltsWizard = (): JSX.Element => {
    const { siteId } = useParams();
    const prebuilt = useAsyncValue() as Prebuilt;

    const dispatchingInit = useRef<boolean>(false);
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);

    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (!dispatchingInit.current && prebuilt && !uuid) {
            dispatchingInit.current = true;

            if (!prebuilt.masterConfigurationId) {
                dispatch(createPrebuiltMasterConfiguration({ prebuilt: prebuilt, siteId: prebuilt.siteId ?? '' }))
                    .unwrap()
                    .catch(showBoundary);
            } else {
                dispatch(editPrebuiltMasterConfiguration({ prebuilt: prebuilt, siteId: prebuilt.siteId ?? '' }))
                    .unwrap()
                    .catch(showBoundary);
            }
        }
    }, [dispatch, prebuilt, showBoundary, uuid]);

    return (
        <div className={styles['app-container-no-header']}>
            <AdminOverlay siteId={siteId} role={'admin'}>
                <PrebuiltsAdminSection />
            </AdminOverlay>
            <UnloadGuard>
                <Outlet />
            </UnloadGuard>
            <LogikGlobalErrors />
            <IdleHeartbeat />
        </div>
    );
};
