import { Form, Radio } from 'antd';
import { useCallback, useState } from 'react';
import { CheckPaymentForm } from './CheckPaymentForm';
import { paymentIsCard, PaymentType, TransactionStatus } from './payments.model';
import styles from './CheckoutPage.module.scss';
import { CashPaymentForm } from './CashPaymentForm';
import { CardPaymentForm } from './CardPaymentForm';
import logger from '../../app/logger';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PricingSection } from '../pricing/PricingSection';
import { Section } from '../../shared/components/layout/Section';
import { useScreenChange } from '../../shared/hooks/screen';
import { PaymentsBlock } from './PaymentsBlock';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { saveAndCreateOrder, SaveAndCreateOrderResult } from '../widget/thunks/actions/saveAndCreateOrder';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { MakePaymentArgs, makePayment } from './thunk/makePayment';
import { WirePaymentForm } from './WirePaymentForm';
import { ROUTE_ORDER_CREATED } from '../wizard/wizard.router';
import { useUserAuthenticated } from '../auth0/auth0.hooks';
import { EventBus } from '../../app/eventbus';
import { SaveOrderAndRedirectEvent, SaveOrderAndRedirectEventParams } from '../orders/OrderCreatedPage';
import { inIframe } from '../../shared/utils/document.utils';
import { notifyAboutOrder } from './thunk/notifyAboutOrder';
import { selectIsCheckedOut } from './Checkout.slice';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { PaymentStatusModal } from './PaymentErrorModal';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import paymentModalStyles from './PaymentErrorModal.module.scss';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';

type PaymentFormState = {
    payment_type: PaymentType;
};

export const CheckoutPage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigateExtended();
    const { device } = useScreenChange();
    const totalDue = useFieldValue<number>(LogikFieldsEnum.finalDownPmtDue);
    const totalPaid = useAppSelector((state) => state.checkout.totalPaid);
    const dueLeft = Math.max((totalDue ?? 0) - totalPaid, 0);
    const siteId = useAppSelector((state) => state.widget.siteId);
    const orderProcessing = useAppSelector<boolean>((state) => state.checkout.orderProcessing);
    const user = useAppSelector((state) => state.auth.user);
    const initialFormState: PaymentFormState = {
        payment_type: PaymentType.Card,
    };
    const [paymentType, setPaymentType] = useState<PaymentType>(initialFormState.payment_type);
    const isCheckedOut = useAppSelector(selectIsCheckedOut);

    const userAuthenticated = useUserAuthenticated();

    const errPayment = useCallback((err: unknown) => {
        logger.error(err);
    }, []);

    const showSuccessPaymentModal = useCallback((callback: () => void) => {
        const handle: FlexibleModalHandle<typeof PaymentStatusModal> = FlexibleModal.createModal(
            PaymentStatusModal,
            {
                text: 'Payment has been successful',
                status: 'success',
                close: () => {
                    handle.close();
                    callback();
                },
            },
            {
                closeOnEscape: false,
                closeOnOutsideClick: false,
                showCloseButton: false,
                destroyOnClose: true,
                className: paymentModalStyles['payment-status-modal'],
            },
        );
        handle.open();
    }, []);

    const showPaymentErrorModal = useCallback((errorText?: string) => {
        const handle: FlexibleModalHandle<typeof PaymentStatusModal> = FlexibleModal.createModal(
            PaymentStatusModal,
            {
                text: errorText ?? 'Failed to process payment. Please try again',
                status: 'error',
                close: () => handle.close(),
            },
            {
                closeOnEscape: false,
                closeOnOutsideClick: false,
                showCloseButton: false,
                destroyOnClose: true,
                className: paymentModalStyles['payment-status-modal'],
            },
        );

        handle.open();
    }, []);

    const finishPayment = useCallback(
        async (payload: MakePaymentArgs) => {
            if (isCheckedOut) return;

            let orderNotifData: SaveAndCreateOrderResult | undefined = undefined;
            try {
                orderNotifData = await dispatch(
                    saveAndCreateOrder({
                        notifyAboutNewOrder: false,
                    }),
                ).unwrap();

                if (!orderNotifData) return;
                const { payment, uuidBeforeUpdate } = await dispatch(
                    makePayment({
                        ...payload,
                        masterId: orderNotifData.orderMasterId,
                        logikId: orderNotifData.orderLogikId,
                    }),
                ).unwrap();

                if (
                    paymentIsCard(payment) &&
                    payment.status !== TransactionStatus.Approved &&
                    payment.status !== TransactionStatus.HeldForReview
                ) {
                    showPaymentErrorModal(payment.reason);
                } else if (payment) {
                    await dispatch(notifyAboutOrder(orderNotifData));

                    const successCallback = (orderNotifData: SaveAndCreateOrderResult) => async () => {
                        if (userAuthenticated && siteId) {
                            EventBus.dispatch<SaveOrderAndRedirectEventParams>(SaveOrderAndRedirectEvent, {
                                masterId: orderNotifData.orderMasterId,
                                siteId,
                                logikId: uuidBeforeUpdate,
                                newOrder: true,
                            });

                            if (!inIframe()) {
                                await redirectToTransitionPage({
                                    redirectType: 'NewOrder',
                                    logikId: uuidBeforeUpdate,
                                    site: siteId,
                                    masterId: orderNotifData.orderMasterId,
                                });
                            }
                        } else {
                            navigate('../' + ROUTE_ORDER_CREATED, {
                                replace: true,
                            });
                        }
                    };
                    showSuccessPaymentModal(successCallback(orderNotifData));
                } else {
                    showPaymentErrorModal();
                }
            } catch (err) {
                errPayment?.(err);
                showPaymentErrorModal();
            }
        },
        [
            dispatch,
            errPayment,
            isCheckedOut,
            navigate,
            showPaymentErrorModal,
            showSuccessPaymentModal,
            siteId,
            userAuthenticated,
        ],
    );

    return (
        <>
            <div className={styles['container']}>
                <PricingSection
                    className={styles['pricing']}
                    titleElement='Order Summary'
                    pricingType='pricing'
                    filter={(pd) => !!pd.extended?.OrderSummary}
                    paddingSize='none'
                    titleSize='bigger'
                    layoutType='single-column'
                />

                <Section className={styles['payments']} titleElement='Payments' titleSize='bigger'>
                    <Section paddingSize='small' type='primary' roundedBorders>
                        <PaymentsBlock />
                    </Section>
                </Section>

                <Section
                    className={styles['make-payment']}
                    titleElement='Payment Details'
                    titleSize='bigger'
                    titleAlign={device === 'desktop' ? 'start' : 'center'}>
                    <div className={styles['payment']}>
                        <Form
                            labelAlign='left'
                            layout='horizontal'
                            requiredMark={true}
                            colon={false}
                            initialValues={initialFormState}>
                            <Form.Item
                                className={styles['payment-type']}
                                label='Purchase Method'
                                labelCol={{ span: 24 }}
                                name='payment_type'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select payment type',
                                    },
                                ]}>
                                <Radio.Group
                                    onChange={(e) => {
                                        setPaymentType(e.target.value);
                                    }}>
                                    {user && (
                                        <>
                                            <Radio value={PaymentType.Cash}>Cash</Radio>
                                            <Radio value={PaymentType.Check}>Check</Radio>
                                        </>
                                    )}
                                    <Radio value={PaymentType.Card}>Credit Card</Radio>
                                    {user && (
                                        <>
                                            <Radio value={PaymentType.Wire}>Wire or ACH</Radio>
                                        </>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Form>

                        {siteId && (
                            <>
                                {paymentType === PaymentType.Cash && (
                                    <CashPaymentForm
                                        onFinish={finishPayment}
                                        onError={errPayment}
                                        amountDue={dueLeft}
                                        processing={orderProcessing}
                                    />
                                )}
                                {paymentType === PaymentType.Check && (
                                    <CheckPaymentForm
                                        onFinish={finishPayment}
                                        onError={errPayment}
                                        amountDue={dueLeft}
                                        processing={orderProcessing}
                                    />
                                )}
                                {paymentType === PaymentType.Card && (
                                    <CardPaymentForm
                                        onFinish={finishPayment}
                                        onError={errPayment}
                                        amountDue={dueLeft}
                                        siteId={siteId}
                                        processing={orderProcessing}
                                    />
                                )}
                                {paymentType === PaymentType.Wire && (
                                    <WirePaymentForm
                                        onFinish={finishPayment}
                                        onError={errPayment}
                                        amountDue={dueLeft}
                                        processing={orderProcessing}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </Section>
            </div>
        </>
    );
};
