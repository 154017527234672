import { CSSProperties, useEffect } from 'react';
import { useRef } from 'react';
import { PropsWithChildren } from 'react';
import styles from './ModalBody.module.scss';

interface OwnProps {
    showCloseButton?: boolean;
    closeOnEscape?: boolean;
    closeOnOutsideClick?: boolean;
    onRequestClose: () => void;
    style?: CSSProperties;
    className?: string;
}
export type ModalBodyProps = PropsWithChildren<OwnProps>;
export function ModalBody({
    showCloseButton,
    closeOnEscape,
    closeOnOutsideClick,
    onRequestClose,
    className,
    children,
    style,
}: ModalBodyProps): JSX.Element {
    const bodyRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = function (e: MouseEvent) {
            if (
                !bodyRef.current?.contains(e.target as Node) &&
                closeOnOutsideClick &&
                !Object.entries(e).find(([k]) => k === 'handled')
            ) {
                onRequestClose();
            }
        };

        const handleEsc = function (e: KeyboardEvent) {
            if (e.key === 'Escape' && closeOnEscape) {
                if (!bodyRef.current?.contains(e.target as Node)) {
                    onRequestClose();
                }
            }
        };

        const ref = wrapperRef.current;
        ref?.addEventListener('click', handleClick);
        document.addEventListener('keydown', handleEsc);

        return () => {
            ref?.removeEventListener('click', handleClick);
            document.removeEventListener('keydown', handleEsc);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${styles['modal-wrapper']}`} ref={wrapperRef} style={{ ...style }}>
            <div className={styles['modal-background']}>
                <div className={`${styles['modal-body']} ${className ?? ''}`} ref={bodyRef}>
                    {children}
                    {showCloseButton && (
                        <div className={styles['close-btn']} onClick={onRequestClose}>
                            <svg
                                width='15'
                                height='15'
                                viewBox='0 0 15 15'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M0.293031 1.29296C0.480558 1.10549 0.734866 1.00017 1.00003 1.00017C1.26519 1.00017 1.5195 1.10549 1.70703 1.29296L7.00003 6.58596L12.293 1.29296C12.3853 1.19745 12.4956 1.12127 12.6176 1.06886C12.7396 1.01645 12.8709 0.988862 13.0036 0.987709C13.1364 0.986555 13.2681 1.01186 13.391 1.06214C13.5139 1.11242 13.6255 1.18667 13.7194 1.28056C13.8133 1.37446 13.8876 1.48611 13.9379 1.60901C13.9881 1.7319 14.0134 1.86358 14.0123 1.99636C14.0111 2.12914 13.9835 2.26036 13.9311 2.38236C13.8787 2.50437 13.8025 2.61471 13.707 2.70696L8.41403 7.99996L13.707 13.293C13.8892 13.4816 13.99 13.7342 13.9877 13.9964C13.9854 14.2586 13.8803 14.5094 13.6948 14.6948C13.5094 14.8802 13.2586 14.9854 12.9964 14.9876C12.7342 14.9899 12.4816 14.8891 12.293 14.707L7.00003 9.41396L1.70703 14.707C1.51843 14.8891 1.26583 14.9899 1.00363 14.9876C0.741432 14.9854 0.49062 14.8802 0.305212 14.6948C0.119804 14.5094 0.0146347 14.2586 0.0123563 13.9964C0.0100779 13.7342 0.110873 13.4816 0.293031 13.293L5.58603 7.99996L0.293031 2.70696C0.10556 2.51943 0.000244141 2.26512 0.000244141 1.99996C0.000244141 1.73479 0.10556 1.48049 0.293031 1.29296Z'></path>
                            </svg>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
