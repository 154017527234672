import { Button } from 'antd';

import styles from './PaymentErrorModal.module.scss';
export type PaymentStatusModalProps = {
    text: string;
    status: 'error' | 'success';
    close: () => void;
};
export const PaymentStatusModal = ({ text, close, status, ...props }: PaymentStatusModalProps): React.ReactNode => {
    return (
        <div
            className={
                styles['payment-status-modal-content'] + ' ' + (status == 'error' ? styles['error'] : styles['success'])
            }>
            <p className={styles['title']}>{status == 'error' ? 'Payment failed' : 'Success'}</p>
            <pre>{text}</pre>
            <Button onClick={close} size='small'>
                Close
            </Button>
        </div>
    );
};
