import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { addSaveAndCreateOrderCases } from '../widget/thunks/actions/saveAndCreateOrder';
import { Payment } from './payments.model';
import { addListPaymentsCases } from './thunk/listPayments';
import { addMakePaymentCases } from './thunk/makePayment';
import { addNotifyAboutOrderCases } from './thunk/notifyAboutOrder';
import { addSaveOrderCases } from './thunk/saveOrder';

export interface CheckoutState {
    payments: Partial<Payment>[];
    totalPaid: number;
    orderProcessing: boolean;
}

const initialState: CheckoutState = {
    payments: [],
    totalPaid: 0,
    orderProcessing: false,
};

export const checkoutSlice = createSlice({
    name: 'Checkout',
    initialState,
    reducers: {
        setPayments: (state, action) => {
            state.payments = [...action.payload];
            state.totalPaid = state.payments.map<number>((p) => p.amount ?? 0).reduce((sum, val) => sum + val, 0);
        },
        addPayment: (state, action) => {
            state.payments = [...state.payments, action.payload];
            state.totalPaid += (action.payload as Payment).amount ?? 0;
        },
        setOrderProcessing: (state, action) => {
            state.orderProcessing = action.payload;
        },
    },
    extraReducers: (builder) => {
        addNotifyAboutOrderCases(builder);
        addMakePaymentCases(builder);
        addListPaymentsCases(builder);
        addSaveOrderCases(builder);
        addSaveAndCreateOrderCases(builder);

        // builder.addMatcher(
        //     (action) => action.type.endsWith('/rejected'),
        //     (_, action) => {
        //         logger.error(action.error);
        //     },
        // );
    },
});

export const { setPayments, addPayment, setOrderProcessing } = checkoutSlice.actions;
export const selectIsCheckedOut = (state: RootState): boolean =>
    state.widget.configurationFields[LogikFieldsEnum.checkedOut]?.value === true;
export default checkoutSlice.reducer;
