import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren, useRef } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logger from '../../app/logger';
import { LogEntry } from '../../shared/models/logentry';
import { setAuthFinished, setAuthToken, setUser } from './Auth0.slice';
import { WidgetAsyncFallback } from '../widget/WidgetAsyncFallback';

type Auth0Props = PropsWithChildren;

export const Auth0 = ({ children }: Auth0Props): JSX.Element | null => {
    const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const isAuthenticating = useRef<boolean>(false);
    const authFinished = useAppSelector((state) => state.auth.authFinished);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !isAuthenticating.current) {
            isAuthenticating.current = true;
            getAccessTokenSilently()
                .then((token) => {
                    logger.debug(new LogEntry('Auth0: token', token));
                })
                .catch((err) => {
                    dispatch(setAuthFinished(true));
                });
        }

        if (isAuthenticated && user) {
            getAccessTokenSilently({
                detailedResponse: true,
            })
                .then((t) => {
                    dispatch(setAuthToken(t.access_token));
                    dispatch(setUser(user));
                })
                .finally(() => {
                    dispatch(setAuthFinished(true));
                });

            logger.debug(new LogEntry('Auth0: user', user));
        }
    }, [dispatch, getAccessTokenSilently, isAuthenticated, isLoading, user]);

    return authFinished ? <>{children}</> : <WidgetAsyncFallback />;
};
