import { Section } from '../../shared/components/layout/Section';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import styles from './ReviewPage.module.scss';

export const ReviewPage = (): JSX.Element => {
    const shippind_contact = useFieldValue(LogikFieldsEnum.shipping_contact);
    const shipping_address = useFieldValue(LogikFieldsEnum.shipping_address);
    const shipping_city = useFieldValue(LogikFieldsEnum.shipping_city);
    const shipping_state = useFieldValue(LogikFieldsEnum.shipping_state);
    const shipping_zip = useFieldValue(LogikFieldsEnum.shipping_zip);
    const cus_name = useFieldValue(LogikFieldsEnum.cusName);
    const billing_address = useFieldValue(LogikFieldsEnum.billing_address);
    const billing_city = useFieldValue(LogikFieldsEnum.billing_city);
    const billing_state = useFieldValue(LogikFieldsEnum.billing_state);
    const billing_zip = useFieldValue(LogikFieldsEnum.billing_zip);

    return (
        <>
            <div className={styles.review}>
                <div className={styles['review-col']}>
                    <div className={styles.section} key='customer'>
                        <Section titleElement='Customer Information'>
                            <div className={styles.row}>
                                <div className={styles.col}>Name</div>
                                <div className={styles.col}>{cus_name}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Address</div>
                                <div className={styles.col}>{billing_address}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>City</div>
                                <div className={styles.col}>{billing_city}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>State</div>
                                <div className={styles.col}>{billing_state}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Zip Code</div>
                                <div className={styles.col}>{billing_zip}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Phone Number</div>
                                <div className={styles.col}>{useFieldValue(LogikFieldsEnum.billing_primary_phone)}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Email</div>
                                <div className={styles.col}>{useFieldValue(LogikFieldsEnum.cusEmail)}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>&nbsp;</div>
                                <div className={styles.col}>&nbsp;</div>
                            </div>
                        </Section>
                    </div>
                    <div className={styles.section} key='ship-to'>
                        <Section titleElement='Ship To'>
                            <div className={styles.row}>
                                <div className={styles.col}>Customer Name</div>
                                <div className={styles.col}>{shippind_contact}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Address</div>
                                <div className={styles.col}>{shipping_address}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>City</div>
                                <div className={styles.col}>{shipping_city}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>State</div>
                                <div className={styles.col}>{shipping_state}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Zip Code</div>
                                <div className={styles.col}>{shipping_zip}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>Phone Number</div>
                                <div className={styles.col}>
                                    {useFieldValue(LogikFieldsEnum.shipping_primary_phone)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>&nbsp;</div>
                                <div className={styles.col}>&nbsp;</div>
                            </div>
                        </Section>
                    </div>
                </div>
            </div>
        </>
    );
};
