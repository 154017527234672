import { toLower } from 'lodash';
import moment from 'moment';
import { KeysOfUnion } from '../../utils/types.utils';

export type VisibilityState = 'visible' | 'hidden';
export type OptionState = VisibilityState | 'disabled';

export interface FieldBase<T> {
    dataType: 'text' | 'boolean' | 'number';
    editable: 'true' | 'false';
    value: T;
    variableName: string;
    visibilityState: VisibilityState;
    userEdited?: boolean;
    uniqueName: string;
}

export type FieldText = FieldBase<string>;
export type FieldBoolean = FieldBase<boolean>;
export type FieldNumber = FieldBase<number>;

export interface Option {
    imageUrl: string | null;
    label: string;
    orderNumber: number;
    state: OptionState;
    value: string;
    extended?: OptionExtended;
}

export interface OptionExtended {
    allowType: 'Single' | 'Multiple';
    amtType: 'Input' | 'Auto' | 'TextWLabel' | 'MoneyWLabel';
    sortOrder: string;
    summary?: 'true' | 'false';
    discInputType?: 'money' | 'percent';
    [key: string]: unknown;
}

export interface OptionSet {
    options: Option[];
    selectedOptions: Option[];
}

export type FieldOptionSet = (FieldBase<string> | FieldBase<string[]>) & {
    optionSet: OptionSet;
};

export type SetFieldSize = FieldBase<string[]> & {
    set: string;
};

export type SetFieldVariable = FieldBase<string | boolean | number> & {
    set: string;
    index: number;
};

export type SetField = SetFieldSize | SetFieldVariable;
export type Field = FieldBoolean | FieldNumber | FieldText | FieldOptionSet | SetField;
export type KeysOfField = KeysOfUnion<Field>;
export type FieldValue = Field['value'];
export type FieldUpdate = Pick<Field, 'variableName' | 'value' | 'userEdited'>;
export type SetFieldUpdate = FieldUpdate & {
    set: string;
    index?: number;
};
export function getFieldOptions(fields: Record<string, Field>, logikFieldName: string): Option[] {
    const f = fields[logikFieldName];
    if (f && isFieldOptionSet(f)) {
        return f.optionSet.options;
    } else {
        return [];
    }
}

export function getFieldSelectedOptions(fields: Record<string, Field>, logikFieldName: string): Option[] {
    const f = fields[logikFieldName];
    if (f && isFieldOptionSet(f)) {
        return f.optionSet.selectedOptions;
    } else {
        return [];
    }
}

export function getFieldByName(fields: Field[], logikFieldName: string): Field | undefined {
    return fields.find((f) => f.variableName === logikFieldName);
}

export function getFieldValue<TReturn extends FieldValue = FieldValue>(
    fields: Record<string, Field>,
    logikFieldName: string,
): TReturn | undefined {
    return fields[logikFieldName]?.value as TReturn;
}
export function getFieldValueFromArray<TReturn extends FieldValue = FieldValue>(
    fields: Field[],
    logikFieldName: string,
): TReturn | undefined {
    return getFieldByName(fields, logikFieldName)?.value as TReturn;
}
export function isSetFieldUpdate(u: FieldUpdate): u is SetFieldUpdate {
    return 'set' in u;
}
export function isFieldText(f: Field): f is FieldText {
    return f.dataType === 'text' && !('optionSet' in f);
}

export function isFieldNumber(f: Field): f is FieldNumber {
    return f.dataType === 'number';
}

export function isFieldBoolean(f: Field): f is FieldBoolean {
    return f.dataType === 'boolean';
}

export function isFieldOptionSet(f: Field): f is FieldOptionSet {
    return 'optionSet' in f;
}

export function isSetField(f: Field): f is SetFieldSize | SetFieldVariable {
    return 'set' in f;
}

export function isSetFieldVariable(f: Field): f is SetFieldVariable {
    return 'set' in f && 'index' in f;
}

export function isSetFieldSize(f: Field): f is SetFieldSize {
    return 'set' in f && !('index' in f);
}

export function convertFieldValue(logikField: Field, value: unknown): FieldValue | undefined {
    let newValue: FieldValue | undefined;

    if (isFieldBoolean(logikField)) {
        newValue = value === true || toLower(String(value)) === 'true';
    }

    if (isFieldNumber(logikField)) {
        newValue = parseFloat(value as string);
    }

    if (isFieldText(logikField)) {
        if (moment.isMoment(value)) {
            newValue = (value as moment.Moment).toISOString();
        } else {
            newValue = value as string;
        }
    }

    if (isFieldOptionSet(logikField)) {
        newValue = value as string;
    }

    return newValue;
}
