import { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import './Section.scss';

export type SectionProps = HTMLAttributes<HTMLDivElement> & {
    titleElement?: ReactElement | string;
    type?: 'primary' | 'secondary' | 'default';
    paddingSize?: 'small' | 'big' | 'none';
    titleAlign?: 'start' | 'center' | 'end';
    titleSize?: 'smaller' | 'bigger' | 'normal';
    roundedBorders?: boolean;
};
export const Section = ({
    titleElement,
    type,
    paddingSize,
    titleAlign,
    titleSize,
    roundedBorders,
    children,
    className,
    ...props
}: PropsWithChildren<SectionProps>): JSX.Element => {
    return (
        <div
            className={
                'section' +
                ` section-${type ?? 'default'}` +
                ` section-padding-size-${paddingSize ?? 'none'}` +
                (roundedBorders ? ' section-round-borders' : '') +
                (className ? ' ' + className : '')
            }
            {...props}>
            {titleElement && (
                <div
                    className={
                        'section-title' +
                        ` section-title-align-${titleAlign ?? 'start'}` +
                        ` section-title-size-${titleSize ?? 'normal'}`
                    }>
                    <>{titleElement}</>
                </div>
            )}
            <div className='section-content'>{children}</div>
        </div>
    );
};
