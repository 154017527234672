import logger from '../../app/logger';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { Page } from '../../shared/components/layout/Page';
import { SummaryPageContent, SummaryFormState } from '../summary/SummaryPageContent';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { recreateConfiguration } from '../widget/thunks/basic/recreateConfiguration';
import { App } from 'antd';
import { useLogikForm } from '../../shared/logik/hooks/form.hooks';
import { saveProductTemplate } from './thunks/saveProductTemplate';
import { publishEventAndRedirect } from '../../shared/utils/location.utils';
import {
    ProductTemplateEditEvent,
    ProductTemplateEditEventParams,
    ProductTemplateNewEvent,
    ProductTemplateNewEventParams,
} from './product-templates.utils';
import { useCallback } from 'react';

export function ProductTemplateSummaryPage(): JSX.Element {
    const navigate = useNavigateExtended();
    const dispatch = useAppDispatch();
    const [form] = useLogikForm<SummaryFormState>();
    const { modal } = App.useApp();

    const launchObjectId = useAppSelector((state) => state.widget.init?.launchObjectId);
    const masterConfigurationId = useAppSelector((state) => state.widget.masterConfigurationId);

    const onFinish = useCallback(
        async (values: unknown) => {
            try {
                const res = await dispatch(saveProductTemplate()).unwrap();
                modal.confirm({
                    title: 'Product template has been successfully saved',
                    content: 'Now you can close the window or go back and make some changes',
                    icon: <></>,
                    okText: 'Save and continue',
                    cancelText: 'Save and close',
                    okButtonProps: { size: 'small' },
                    cancelButtonProps: { size: 'small' },
                    onOk: async () => await dispatch(recreateConfiguration(res.configuration.uuid)),
                    onCancel: async () => {
                        if (launchObjectId) {
                            if (!masterConfigurationId) {
                                await publishEventAndRedirect<ProductTemplateNewEventParams>(ProductTemplateNewEvent, {
                                    launchObjectId,
                                });
                            } else {
                                await publishEventAndRedirect<ProductTemplateEditEventParams>(
                                    ProductTemplateEditEvent,
                                    {
                                        launchObjectId,
                                    },
                                );
                            }
                        }
                    },
                });
            } catch (err: unknown) {
                logger.error(err);
            }
        },
        [dispatch, launchObjectId, masterConfigurationId, modal],
    );

    return (
        <Page
            navProps={{
                onClickBack: () => navigate('..', { preserveSearch: true }),
                onClickNext: () => form.submit(),
                nextButtonTitle: 'Finish',
                hideSaveButton: true,
            }}>
            <SummaryPageContent onFinish={onFinish} form={form} />
        </Page>
    );
}
