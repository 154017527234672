import { createSlice } from '@reduxjs/toolkit';
import { ProductTemplate } from '../products-templates/product-template.model';

export interface ProductDefaultState {
    productsDefaults: Record<string, ProductTemplate>;
}

const initialState: ProductDefaultState = {
    productsDefaults: {},
};

export const productsSlice = createSlice({
    name: 'ProductsTemplates',
    initialState,
    reducers: {
        setProductsTemplates: (state, { payload }: { payload: ProductTemplate[] }) => {
            state.productsDefaults = {};
            for (const pd of payload) {
                if (pd.product?.model && pd.status === 'Active') state.productsDefaults[pd.product.model] = pd;
            }
        },
    },
    extraReducers: (builder) => {},
});

export const { setProductsTemplates } = productsSlice.actions;

export default productsSlice.reducer;
