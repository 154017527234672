import { Form, FormListFieldData } from 'antd';
import { FormListOperation, FormListProps } from 'antd/es/form';
import { isArray } from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { updateConfiguration } from '../../../features/widget/thunks/basic/updateConfiguration';
import { FormState } from '../../models/form.state.model';
import { useFieldSet } from '../hooks/field.hooks';
import { SetFieldItemGroup } from '../models/field-set.details.model';
import { SetFieldUpdate } from '../models/field.model';
import { convertFromLogikValue, getTypeFromLogikField } from '../utils/form.utils';

export type LogikFormListProps = Omit<FormListProps, 'children'> & {
    setName: string;
    children: (
        fields: FormListFieldData[],
        operation: FormListOperation,
        meta: {
            errors: React.ReactNode[];
            warnings: React.ReactNode[];
        },
        isAddingRow: boolean | null,
        isRemovingRow: boolean | null,
        set?: SetFieldItemGroup[],
    ) => React.ReactNode;
};
export const LogikFormList = ({ setName, children, ...props }: LogikFormListProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const form = Form.useFormInstance<FormState>();

    const [isAddingRow, setIsAddingRow] = useState<boolean | null>(null);
    const [isRemovingRow, setIsRemovingRow] = useState<boolean | null>(null);
    const valueIsInited = useRef<boolean>(false);
    const fieldSetValue = useFieldSet(setName);
    //const isChangingSize = useFieldIsUpdating(`set.${setName}.size`);

    useLayoutEffect(() => {
        if (!!fieldSetValue && valueIsInited.current === false) {
            const values = fieldSetValue.map((v) =>
                Object.fromEntries(
                    Object.entries(v.fields).map(([key, field]) => [
                        key,
                        convertFromLogikValue(field.value, getTypeFromLogikField(field.dataType)),
                    ]),
                ),
            );
            form.setFieldValue(setName, values);
            valueIsInited.current = true;
        }
    }, [form, fieldSetValue, setName, isRemovingRow]);

    const addRowCallback = useCallback(
        async (
            fields: FormListFieldData[],
            add: (defaultValue?: unknown, insertIndex?: number | undefined) => void,
            defaultValue?: Record<string, unknown>,
            insertIndex?: number | undefined,
        ) => {
            setIsAddingRow(true);

            await dispatch(
                updateConfiguration({
                    updates: [
                        {
                            set: setName,
                            variableName: `set.${setName}.size`,
                            value: [...fields.map((f) => String(f.name + 1)), String(fields.length + 1)],
                        },
                    ],
                }),
            );

            if (defaultValue) {
                await dispatch(
                    updateConfiguration({
                        updates: Object.entries(defaultValue).map(([k, v]) => {
                            return {
                                set: setName,
                                variableName: k,
                                value: v,
                                index: fields.length,
                            } as SetFieldUpdate;
                        }),
                    }),
                );
            }

            add(undefined, insertIndex);
            setIsAddingRow(false);
        },
        [dispatch, setName],
    );

    useEffect(() => {
        if (isAddingRow === false) {
            console.log('set discounts', fieldSetValue);

            if (fieldSetValue) {
                const updates = Object.fromEntries(
                    Object.entries(fieldSetValue[fieldSetValue.length - 1].fields).map(([key, field]) => [
                        key,
                        convertFromLogikValue(field.value, getTypeFromLogikField(field.dataType)),
                    ]),
                );

                setTimeout(() => {
                    form.setFieldValue([setName, fieldSetValue.length - 1], updates);
                }, 0);
            }

            setIsAddingRow(null);
        }
    }, [fieldSetValue, form, isAddingRow, setName]);
    return (
        <Form.List {...props}>
            {(fields, { add, remove, move }, meta) => {
                // const addRow = async (defaultValue?: Record<string, unknown>, insertIndex?: number | undefined) => {
                //     setIsAddingRow(true);
                //
                //     await dispatch(
                //         updateConfiguration({
                //             updates: [
                //                 {
                //                     set: setName,
                //                     variableName: `set.${setName}.size`,
                //                     value: [...fields.map((f) => String(f.name + 1)), String(fields.length + 1)],
                //                 },
                //             ],
                //         }),
                //     );
                //
                //     if (defaultValue) {
                //         await dispatch(
                //             updateConfiguration({
                //                 updates: Object.entries(defaultValue).map(([k, v]) => {
                //                     return {
                //                         set: setName,
                //                         variableName: k,
                //                         value: v,
                //                         index: fields.length,
                //                     } as SetFieldUpdate;
                //                 }),
                //             }),
                //         );
                //     }
                //
                //     add(undefined, insertIndex);
                //     setIsAddingRow(false);
                //     console.log('set discounts', fieldSetValue);
                // };

                const removeRow = async (index: number | number[]) => {
                    setIsRemovingRow(true);

                    await dispatch(
                        updateConfiguration({
                            updates: [
                                {
                                    set: setName,
                                    variableName: `set.${setName}.size`,
                                    value: [
                                        ...fields
                                            .filter((f) => (isArray(index) ? !index.includes(f.name) : index != f.name))
                                            .map((f) => String(f.name + 1)),
                                    ],
                                },
                            ],
                        }),
                    ).unwrap();

                    remove(index);
                    setIsRemovingRow(false);
                    setIsRemovingRow(null);
                };

                return children(
                    fields,
                    {
                        add: (defaultValue, insertIndex) => addRowCallback(fields, add, defaultValue, insertIndex),
                        remove: removeRow,
                        move,
                    },
                    meta,
                    isAddingRow,
                    isRemovingRow,
                    fieldSetValue,
                );
            }}
        </Form.List>
    );
};
