import React, { ComponentClass, FunctionComponent } from 'react';
import { createRoot } from 'react-dom/client';
import logger from '../../../app/logger';
import { isClassComponent, isFunctionComponent } from '../../utils/component.utils';
import { FlexibleModal, ModalRefType, OpenModalProps } from './FlexibleModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionComponentConstraint = (...args: any) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClassComponentConstraint = abstract new (...args: any) => any;

type CompositeConstraint = ClassComponentConstraint | FunctionComponentConstraint;

interface Q1<T extends FunctionComponentConstraint> {
    open(props?: Parameters<T>[0]): void;
}

interface Q2<T extends ClassComponentConstraint> {
    open(props?: ConstructorParameters<T>): void;
}
export class FlexibleModalHandle<T extends CompositeConstraint>
    implements Q1<Extract<T, FunctionComponent>>, Q2<Extract<T, ComponentClass>>
{
    private readonly _ref = React.createRef<ModalRefType>();
    private readonly _config: OpenModalProps;
    private readonly _modalContent: React.ReactElement;
    public constructor(modalContent: React.ReactElement, config?: OpenModalProps) {
        this._config = config ?? {};
        this._modalContent = modalContent;
    }

    public close = (): Promise<void> => {
        let resolve: (value: void | PromiseLike<void>) => void;
        const promise = new Promise<void>((r) => (resolve = r));

        setTimeout(() => {
            try {
                this._ref.current?.close();
            } catch (err) {
                logger.error(err);
            } finally {
                resolve();
            }
        }, 0);

        return promise;
    };

    public open(
        props?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | Parameters<Extract<T, FunctionComponent<any>>>[0]
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            | ConstructorParameters<Extract<T, ComponentClass<any>>>,
    ): void {
        if (this._ref.current?.isHidden) {
            this._ref.current.show();
            return;
        } else {
            const div = document.createElement('div');
            const root = createRoot(div);

            root.render(
                <FlexibleModal {...this._config} ref={this._ref} root={root}>
                    {React.cloneElement(this._modalContent, props)}
                </FlexibleModal>,
            );
        }
    }
    public static createModal<T = FunctionComponent>(
        Component: T,
        config?: OpenModalProps,
    ): FlexibleModalHandle<Extract<T, FunctionComponent>>;
    public static createModal<T = ComponentClass>(
        Component: T,
        config?: OpenModalProps,
    ): FlexibleModalHandle<Extract<T, ComponentClass>>;
    public static createModal<T extends FunctionComponentConstraint = FunctionComponent>(
        Component: T,
        props: Parameters<T>[0],
        config?: OpenModalProps,
    ): FlexibleModalHandle<T>;
    public static createModal<T extends ClassComponentConstraint = ComponentClass>(
        Component: T,
        props: ConstructorParameters<T>,
        config?: OpenModalProps,
    ): FlexibleModalHandle<T>;
    public static createModal<T extends CompositeConstraint = ComponentClass | FunctionComponent>(
        content: React.ReactNode,
        config?: OpenModalProps,
    ): FlexibleModalHandle<T>;
    public static createModal<T extends CompositeConstraint = ComponentClass | FunctionComponent>(
        content: FunctionComponent | ComponentClass | React.ReactNode,
        componentPropsOrConfig?:
            | Parameters<Extract<T, FunctionComponent>>[0]
            | ConstructorParameters<Extract<T, ComponentClass>>
            | OpenModalProps,
        config?: OpenModalProps,
    ): FlexibleModalHandle<T> {
        let modalConfig: OpenModalProps | undefined;
        let props:
            | Parameters<Extract<T, FunctionComponent>>[0]
            | ConstructorParameters<Extract<T, ComponentClass>>
            | OpenModalProps
            | undefined;

        if (!!componentPropsOrConfig && !!config) {
            props = componentPropsOrConfig;
            modalConfig = { ...config };
        } else if (!!componentPropsOrConfig) {
            type OpenModalPropsKeys = keyof OpenModalProps;

            const isConfig = (
                c:
                    | Parameters<Extract<T, FunctionComponent>>[0]
                    | ConstructorParameters<Extract<T, ComponentClass>>
                    | OpenModalProps,
            ): c is OpenModalProps =>
                Object.keys(c).some((key) => {
                    return key as OpenModalPropsKeys;
                });

            if (isConfig(componentPropsOrConfig)) {
                modalConfig = componentPropsOrConfig;
            } else {
                props = componentPropsOrConfig;
            }
        }

        if (React.isValidElement(content)) {
            return new FlexibleModalHandle<T>(content, modalConfig);
        } else if (isClassComponent(content) || isFunctionComponent(content)) {
            return new FlexibleModalHandle<T>(
                React.createElement<
                    Parameters<Extract<T, FunctionComponent>>[0] | ConstructorParameters<Extract<T, ComponentClass>>
                >(content, props),
                modalConfig,
            );
        } else {
            throw new Error('Invalid modal content');
        }
    }
}
