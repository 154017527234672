import { useEffect } from 'react';
import { EventBus, EventBusMessage } from '../../app/eventbus';

export const useEventBusSubscribe = <T>(event: string): Promise<EventBusMessage<T>> => {
    let resolver: (value: EventBusMessage<T>) => void;

    const promise = new Promise<EventBusMessage<T>>((resolve, reject) => {
        resolver = resolve;
    });

    useEffect(() => {
        const callback = (e: EventBusMessage<T>) => {
            resolver(e);
        };

        const sub = EventBus.subscribe(event, callback);
        return () => {
            sub.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return promise;
};
