import { Radio, RadioGroupProps, RadioProps } from 'antd';
import { useFieldProperty } from '../hooks/field.hooks';
import { useContext } from 'react';
import { LogikFormItemContext } from './LogikFormItemBase';
import { FieldOptionSet } from '../models/field.model';
import { useCustomFormItemErrorsAndWarnings } from '../hooks/form.hooks';

interface LogikRadioGroupProps extends RadioGroupProps {
    fieldName: string;
    radioProps?: RadioProps & React.RefAttributes<HTMLElement>;
}

export const LogikRadioGroup = ({
    radioProps,
    fieldName,
    disabled,
    ...radioGroupProps
}: LogikRadioGroupProps): JSX.Element => {
    useCustomFormItemErrorsAndWarnings();
    const logikFormItemContext = useContext(LogikFormItemContext);

    const isEditable = useFieldProperty()(
        (f) => f.editable,
        logikFormItemContext?.logikName || fieldName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );
    const options = useFieldProperty<FieldOptionSet>()(
        (f) => f.optionSet.options,
        logikFormItemContext?.logikName || fieldName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    return (
        <Radio.Group {...radioGroupProps} disabled={disabled || isEditable === 'false'}>
            {options &&
                options
                    .filter((o) => (o.extended?.summary !== undefined ? o.extended.summary === 'true' : true))
                    .map((u) => (
                        <Radio {...radioProps} value={u.value} key={u.value} disabled={u.state === 'disabled'}>
                            {u.label}
                        </Radio>
                    ))}
        </Radio.Group>
    );
};
