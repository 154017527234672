import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Spin } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { useCallback, useState } from 'react';
import { Environment } from '../../app/environment';
import { useAppDispatch } from '../../app/hooks';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { Configuration } from '../../shared/logik/models/configuration.model';
import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';
import { saveAndSendQuoteAndRecreateConfiguration } from '../widget/thunks/actions/saveAndSendQuoteAndRecreateConfiguration';
import styles from './SaveQuoteDialog.module.scss';
import { useUserAuthenticated } from '../auth0/auth0.hooks';
import { getFieldValueFromArray } from '../../shared/logik/models/field.model';
import { EventBus } from '../../app/eventbus';
import { inIframe } from '../../shared/utils/document.utils';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';
import { setActionType } from '../widget/Widget.slice';

export const SaveQuoteEvent = 'SaveQuoteEvent';
export const DownloadPDFEvent = 'DownloadPDFEvent';
export const SaveQuoteAndRedirectEvent = 'SaveQuoteAndRedirectEvent';

export type SaveQuoteEventParams = IFrameEventParams & {
    email: string;
    name: string;
};

export type DownloadPDFEventParams = IFrameEventParams;
export type SaveQuoteAndRedirectEventParams = IFrameEventParams & {
    masterId: string;
    logikId: string;
    siteId: string;
    newQuote: boolean;
};

export interface SaveQuoteFormModel {
    fullName: string;
    email: string;
}

export interface SaveQuoteDialogResult {
    data: SaveQuoteFormModel;
    newConfiguration: Configuration;
}

export interface SaveQuoteDialogProps {
    onPreSave?: () => void | Promise<void>;
    onSave?: (data: SaveQuoteDialogResult) => void;
    onCancel?: () => void;
    productId?: string;
    uuid?: string;
    threeKitId?: string;
}

export const SaveQuoteDialog = (props: SaveQuoteDialogProps): JSX.Element => {
    const [form] = Form.useForm<SaveQuoteFormModel>();
    const [sending, setSending] = useState<string>();
    const defaultEmail = useFieldValue<string>(LogikFieldsEnum.cusEmail);
    const defaultName = useFieldValue<string>(LogikFieldsEnum.cusName);
    const authenticatedUser = useUserAuthenticated();
    const dispatch = useAppDispatch();

    const notificationDuration = 5;
    const notificationPlacement: NotificationPlacement = 'bottomLeft';
    const errorMessage = 'An unexpected error occurred';
    const notificationKey = 'wrd-btn-notification';

    const validateMessages = {
        required: '${displayName} is required',
        types: {
            email: '${displayName} is not a valid email',
        },
    };

    const saveQuote = useCallback(
        async (type: 'saveAndContinue' | 'saveAndClose' | 'saveAndEmail') => {
            try {
                setSending(type);

                const data = await form.validateFields();

                if (props.onPreSave) {
                    await props.onPreSave();
                }
                const newConfiguration = await dispatch(
                    saveAndSendQuoteAndRecreateConfiguration({
                        email: data.email,
                        name: data.fullName,
                        uuid: props.uuid,
                        productId: props.productId,
                        threeKitId: props.threeKitId,
                        sendEmail: type === 'saveAndEmail',
                    }),
                ).unwrap();

                EventBus.dispatch<SaveQuoteEventParams>(SaveQuoteEvent, {
                    email: data.email,
                    name: data.fullName,
                });

                switch (type) {
                    case 'saveAndClose':
                        const siteId =
                            getFieldValueFromArray<string>(
                                newConfiguration.configuration.fields,
                                LogikFieldsEnum.siteId,
                            ) ?? '';

                        EventBus.dispatch<SaveQuoteAndRedirectEventParams>(SaveQuoteAndRedirectEvent, {
                            masterId: newConfiguration.masterId,
                            siteId,
                            logikId: newConfiguration.oldLogikId,
                            newQuote: !newConfiguration.oldMasterId,
                        });

                        if (!inIframe()) {
                            await redirectToTransitionPage({
                                site: siteId,
                                redirectType: !newConfiguration.oldMasterId ? 'NewQuote' : 'EditQuote',
                                masterId: newConfiguration.masterId,
                                logikId: newConfiguration.oldLogikId,
                            });
                        }
                        break;
                    case 'saveAndEmail':
                        notification.success({
                            message: `Your building configuration will be delivered to ${data.email} soon`,
                            duration: notificationDuration,
                            placement: notificationPlacement,
                            key: notificationKey,
                        });
                        break;
                }

                props.onSave?.({
                    data,
                    newConfiguration: newConfiguration.configuration,
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                notification.error({
                    message: errorMessage,
                    description: Environment.isDevelopment ? err.message : undefined,
                    duration: notificationDuration,
                    placement: notificationPlacement,
                    key: notificationKey,
                });
            } finally {
                setSending(undefined);
                dispatch(setActionType('QuoteEdit'));
            }
        },
        [dispatch, form, props],
    );

    return (
        <div className={styles['content']}>
            <div className={styles['title']}>Save configuration link</div>
            <div className={styles['subtitle']}>
                We will automatically generate a link to your configuration and send it to the provided email address
            </div>
            <Form
                form={form}
                className={`${styles['form']} pure`}
                validateMessages={validateMessages}
                initialValues={{
                    email: defaultEmail,
                    fullName: defaultName,
                }}>
                <Form.Item name='fullName' rules={[{ required: true }]} messageVariables={{ displayName: 'Name' }}>
                    <Input placeholder='Name' className={styles['form-input']} />
                </Form.Item>

                <Form.Item
                    name='email'
                    rules={[{ required: true, type: 'email' }]}
                    messageVariables={{ displayName: 'Email' }}>
                    <Input placeholder='Email' className={styles['form-input']} />
                </Form.Item>

                {authenticatedUser && (
                    <>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                className={styles['send-btn']}
                                disabled={!!sending}
                                onClick={() => saveQuote('saveAndContinue')}>
                                Save And Continue
                                {sending === 'saveAndContinue' && (
                                    <Spin
                                        className={styles['loading-indicator']}
                                        indicator={<LoadingOutlined style={{ fontSize: 16, color: '#425d76' }} spin />}
                                    />
                                )}
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='button'
                                className={styles['send-btn']}
                                disabled={!!sending}
                                onClick={() => saveQuote('saveAndClose')}>
                                Save And Close
                                {sending === 'saveAndClose' && (
                                    <Spin
                                        className={styles['loading-indicator']}
                                        indicator={<LoadingOutlined style={{ fontSize: 16, color: '#425d76' }} spin />}
                                    />
                                )}
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='button'
                                className={styles['send-btn']}
                                disabled={!!sending}
                                onClick={() => saveQuote('saveAndEmail')}>
                                Save And Email
                                {sending === 'saveAndEmail' && (
                                    <Spin
                                        className={styles['loading-indicator']}
                                        indicator={<LoadingOutlined style={{ fontSize: 16, color: '#425d76' }} spin />}
                                    />
                                )}
                            </Button>
                        </Form.Item>
                    </>
                )}

                {!authenticatedUser && (
                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='button'
                            className={styles['send-btn']}
                            disabled={!!sending}
                            onClick={() => saveQuote('saveAndEmail')}>
                            Send
                            {sending && (
                                <Spin
                                    className={styles['loading-indicator']}
                                    indicator={<LoadingOutlined style={{ fontSize: 16, color: '#425d76' }} spin />}
                                />
                            )}
                        </Button>
                    </Form.Item>
                )}
            </Form>
        </div>
    );
};
