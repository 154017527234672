import { isArray } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectSetField, selectSetFieldVisibility } from '../../store/selectors';
import { SetFieldUpdate } from '../models/field.model';
import { FieldMessage, isSetFieldMessage } from '../models/message.model';
import { getLogikFormItemBase, LogikFormItemBaseProps } from './LogikFormItemBase';
interface LogikFormItemProps extends Omit<LogikFormItemBaseProps, 'fieldType'> {
    index: number;
    setName: string;
}

const LogikFormItemBase = getLogikFormItemBase();
export const LogikFormSetItem = ({ logikName, index, setName, name, ...props }: LogikFormItemProps): JSX.Element => {
    const fieldVisibilityState = useAppSelector((state) => selectSetFieldVisibility(logikName, index, setName)(state));
    const fieldDataType = useAppSelector((state) => selectSetField(logikName, index, setName)(state)?.dataType);
    const fieldName = useMemo(() => name ?? [index, logikName], [index, logikName, name]);

    const fullName = useMemo(
        () => (name && isArray(name) ? [setName, ...name] : name ? [setName, name] : [setName, index, logikName]),
        [index, logikName, name, setName],
    );

    const getFieldUpdate = useCallback(
        (value: unknown) => {
            return {
                variableName: logikName,
                set: setName,
                index: index,
                value,
            } as SetFieldUpdate;
        },
        [index, logikName, setName],
    );

    const getMessageSelector = useCallback(
        (message: FieldMessage) =>
            isSetFieldMessage(message) &&
            message.set === setName &&
            message.field === logikName &&
            message.index === index,
        [index, logikName, setName],
    );

    return fieldVisibilityState !== 'hidden' ? (
        <LogikFormItemBase
            fieldType='set'
            name={fieldName}
            fullName={fullName}
            index={index}
            setName={setName}
            logikName={logikName}
            logikDataType={fieldDataType}
            fieldUpdateConverter={getFieldUpdate}
            getMessageSelector={getMessageSelector}
            {...props}
        />
    ) : (
        <></>
    );
};
