import { isEqual } from 'lodash';
import React from 'react';
import { useSetFieldValue } from '../../../shared/logik/hooks/field.hooks';

type ReadOnlyAmtFieldProps = {
    logikName: string;
    index: number;
    setName: string;
    prefix?: React.ReactNode;
};
const ReadOnlyAmtFieldImpl = ({ logikName, index, setName, prefix }: ReadOnlyAmtFieldProps): JSX.Element => {
    const value = useSetFieldValue(logikName, index, setName);

    return (
        <span>
            {prefix}$&nbsp;
            {value?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}
        </span>
    );
};

export const ReadOnlyAmtField = React.memo(ReadOnlyAmtFieldImpl, (prev, next) => isEqual(prev, next));
