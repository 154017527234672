import { Popover } from 'antd';
import { useFieldTooltip } from '../hooks/layouts.hooks';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { marked } from 'marked';
import { isFieldOptionSet } from '../models/field.model';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { useScreenChange } from '../../hooks/screen';

export type LogikTooltipProps = {
    fieldName: string;
    position?: 'default' | 'toTheRight';
    helpContent?: string;
};
const tokenRegex = /({{(?<token>[a-zA-Z_]\w*)}})/gm;
export const LogikTooltip = ({ fieldName, position, helpContent }: LogikTooltipProps): JSX.Element | null => {
    const screen = useScreenChange();
    const tooltipContent = useFieldTooltip(fieldName);
    const tooltipTokens = useMemo(
        () => (tooltipContent ? Array.from(tooltipContent.matchAll(tokenRegex), (m) => m.groups?.['token']) : []),
        [tooltipContent],
    );
    const replacements = useAppSelector(
        createSelector(
            (state: RootState) => state.widget.configurationFields,
            (fields) => Object.entries(fields).filter(([key]) => tooltipTokens.includes(key)),
        ),
    );
    const tooltipContentModified = useMemo(() => {
        let modified = tooltipContent;
        for (const [key, f] of replacements) {
            let value: string | undefined = String(f.value);
            if (isFieldOptionSet(f)) {
                value = f.optionSet.options.find((o) => o.value === f.value)?.label;
            }

            if (value) modified = tooltipContent?.replace(`{{${key}}}`, value);
        }
        return modified;
    }, [replacements, tooltipContent]);

    const styles: React.CSSProperties = useMemo(() => {
        let css: React.CSSProperties = {
            fontSize: '21px',
            marginLeft: '9px',
            order: 10,
            color: 'rgb(66, 93, 118)',
            cursor: 'pointer',
            pointerEvents: 'auto',
        };
        if (position === 'toTheRight') {
            css = {
                ...css,
                position: 'absolute',
                left: '100%',
                marginLeft: '15px',
                top: '50%',
                transform: 'translateY(-50%)',
            };
        }

        return css;
    }, [position]);

    const parsedHtml = useMemo(() => {
        if (!tooltipContentModified) return;
        const domParser = new DOMParser();
        const sanitized = domParser.parseFromString(tooltipContentModified, 'text/html');
        return marked.parse(sanitized.body.innerHTML);
    }, [tooltipContentModified]);

    return parsedHtml ? (
        <Popover
            content={
                <div
                    style={{ maxWidth: screen.device === 'mobile' ? '300px' : '512px' }}
                    dangerouslySetInnerHTML={{
                        __html: parsedHtml,
                    }}></div>
            }
            trigger='click'
            placement={screen.device === 'desktop' ? 'right' : 'bottom'}
            arrow={{ pointAtCenter: true }}
            autoAdjustOverflow={true}>
            <InfoCircleOutlined
                style={styles}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }}
                onPointerDownCapture={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }}
            />
        </Popover>
    ) : null;
};
