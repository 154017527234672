import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { WidgetState, setTooltips } from '../../Widget.slice';

export type FetchTooltipsParams = {
    layoutUrl: string;
};

export const fetchTooltips = createAsyncThunk<
    WidgetState['tooltips'],
    FetchTooltipsParams,
    {
        state: RootState;
    }
>('configuration/fetchTooltips', async ({ layoutUrl }, thunkApi): Promise<WidgetState['tooltips']> => {
    const layout = await logikApiService.fetchLayoutDetails(layoutUrl);

    const fields = layout.layout.tiers
        ?.flatMap((t) => t?.tiers)
        .flatMap((t) => t?.columnSets)
        .flatMap((cs) => cs?.elements);

    const tooltips = Object.fromEntries(
        fields.filter((f) => !!f?.help?.content).map((f) => [f?.variableName, f?.help?.content]),
    );

    thunkApi.dispatch(setTooltips(tooltips));

    return tooltips;
});

export const addFetchTooltipsCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(fetchTooltips.pending, (state) => {
            state.loadingTooltips = true;
        })
        .addCase(fetchTooltips.fulfilled, (state) => {
            state.loadingTooltips = false;
        })
        .addCase(fetchTooltips.rejected, (state) => {
            state.loadingTooltips = false;
        });
};
