export const TYPE_GLOBAL_MESSAGE = 'GlobalMessage';

export interface FieldMessage {
    error: boolean;
    field: string;
    message: string;
    target: string;
    targetType: 'field' | 'layout_element';
    type: 'error' | 'warning' | 'info' | 'validation';
}

export interface SetFieldMessage extends FieldMessage {
    set: string;
    index: number;
}

export type ConfigurationMessage = FieldMessage | SetFieldMessage;
export type GlobalMessage = ConfigurationMessage & {
    field: typeof TYPE_GLOBAL_MESSAGE;
    target: typeof TYPE_GLOBAL_MESSAGE;
};

export const isFieldMessage = (message: FieldMessage): message is FieldMessage => {
    return !('set' in message) && !('index' in message);
};

export const isSetFieldMessage = (message: FieldMessage): message is SetFieldMessage => {
    return 'set' in message && 'index' in message;
};

export const isNotGlobalMessage = (message: FieldMessage): message is ConfigurationMessage => {
    return message.targetType === 'field' && message.field !== TYPE_GLOBAL_MESSAGE;
};

export const isGlobalMessage = (message: FieldMessage): message is GlobalMessage => {
    return message.targetType === 'field' && message.field === TYPE_GLOBAL_MESSAGE;
};
