import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum } from '../../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import {
    FieldUpdate,
    getFieldValue,
    isFieldOptionSet,
    isSetFieldUpdate,
    SetFieldUpdate,
} from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import {
    selectFields,
    selectSets,
    setFieldsUpdating,
    setFieldsValueDuringUpdate,
    WidgetState,
} from '../../Widget.slice';
import { UpdateSetThunkResult } from './fetchSets';
import { postProcessConfiguration } from './postProcessConfiguration';
import { isScalarArrayEqual } from '../../../../shared/utils/arrays.utils';

export type UpdateConfigurationThunkRequest = {
    uuid?: string;
    updates: (FieldUpdate | SetFieldUpdate)[];
    skipRelatedUpdates?: boolean;
    forceUpdate?: boolean;
};
export type UpdateConfigurationThunkResponse = {
    configuration?: Configuration;
    sets?: UpdateSetThunkResult[];
};

export const updateConfiguration = createAsyncThunk<
    UpdateConfigurationThunkResponse,
    UpdateConfigurationThunkRequest,
    {
        state: RootState;
    }
>(
    'configuration/update',
    async (arg: UpdateConfigurationThunkRequest, thunkAPI): Promise<UpdateConfigurationThunkResponse> => {
        const dispatch = thunkAPI.dispatch;
        const widgetState = thunkAPI.getState().widget;
        const uuid = arg.uuid ?? widgetState.uuid;

        const configurationSets = selectSets(thunkAPI.getState());
        const fields = selectFields(thunkAPI.getState());

        const updates = arg.updates.filter((fu) => {
            if (isSetFieldUpdate(fu)) {
                return !(
                    configurationSets &&
                    fu.index !== undefined &&
                    isEqual(configurationSets[fu.set]?.[fu.index]?.fields[fu.variableName].value, fu.value)
                );
            } else {
                return !isEqual(fields?.[fu.variableName]?.value, fu.value);
            }
        });

        if (updates.length === 0 && !arg.forceUpdate) {
            return {};
        }

        if (!uuid) return Promise.reject('Invalid UUID');

        dispatch(setFieldsValueDuringUpdate(updates));
        dispatch(setFieldsUpdating(updates));

        const configuration = await logikApiService.updateConfiguration(uuid, updates);
        const discountsField = configuration.fields.find(
            (f) => f.variableName === LogikFieldsEnum.availableDiscountTypes,
        );
        const disountsSet = widgetState.configurationSets[LogikFieldsEnum.set_of_discounts];
        await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: arg.skipRelatedUpdates }));

        if (discountsField && isFieldOptionSet(discountsField) && disountsSet) {
            const discounts = disountsSet.map((s) => s.fields[LogikFieldsEnum.set_of_discounts_discount_type]);

            const discountIndices = [];
            for (const d of discounts) {
                const discountIndex = discountsField.optionSet.options.findIndex((dt) => dt.value === d.value);

                if (discountIndex >= 0) {
                    discountIndices.push(String(d.index + 1));
                }
            }

            const upd = [];
            const siteFieldVariableName = `set.${LogikFieldsEnum.set_of_discounts}.size`;
            const oldSizeValue = getFieldValue<string[]>(widgetState.configurationFields, siteFieldVariableName);
            if (!!oldSizeValue && !isScalarArrayEqual(oldSizeValue, discountIndices)) {
                upd.push({
                    set: LogikFieldsEnum.set_of_discounts,
                    variableName: siteFieldVariableName,
                    value: discountIndices,
                });
            }
            dispatch(setFieldsValueDuringUpdate(upd));
            dispatch(setFieldsUpdating(upd));

            if (upd.length > 0) {
                const c = await logikApiService.updateConfiguration(uuid, upd);

                await dispatch(postProcessConfiguration({ configuration: c, skipRelatedPulls: false })).unwrap();
            }
        }

        return {
            configuration,
            sets: [],
        };
    },
);

export const addUpdateConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(updateConfiguration.pending, (state, payload) => {
            state.updating = true;
            state.pricingUpdating = true;

            state.configurationMessages = [
                ...state.configurationMessages.filter(
                    (cm) => !payload.meta.arg.updates.map((u) => u.variableName).includes(cm.field),
                ),
            ];
        })
        .addCase(updateConfiguration.fulfilled, (state) => {
            state.updating = false;
            state.pricingUpdating = false;
            state.fieldsUpdating = [];
        })
        .addCase(updateConfiguration.rejected, (state) => {
            state.updating = false;
            state.pricingUpdating = false;
            state.fieldsUpdating = [];
        });
};
