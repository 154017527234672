import { Input } from 'antd';
import { useContext } from 'react';
import { useFieldProperty } from '../hooks/field.hooks';
import { LogikFormItemContext } from './LogikFormItemBase';
import { InputRef, PasswordProps } from 'antd/es/input';
import React from 'react';
import { useCustomFormItemErrorsAndWarnings } from '../hooks/form.hooks';

export type LogikInputPasswordProps = PasswordProps;
export const LogikInputPassword = React.forwardRef<InputRef, LogikInputPasswordProps>(function LogikInputPassword(
    { disabled, ...props }: LogikInputPasswordProps & React.RefAttributes<InputRef>,
    ref,
): JSX.Element {
    useCustomFormItemErrorsAndWarnings();
    const logikFormItemContext = useContext(LogikFormItemContext);
    const isEditable = useFieldProperty()(
        (f) => f.editable,
        logikFormItemContext?.logikName,
        logikFormItemContext?.index,
        logikFormItemContext?.setName,
    );

    return <Input.Password {...props} disabled={disabled || isEditable === 'false'} ref={ref} />;
});
