export const LogikFormItemErrors = ({ errors }: { errors: React.ReactNode[] }): JSX.Element => {
    return (
        <div className='antd-custom-form-item-error-container'>
            {errors.map((e, i) => (
                <div className='antd-custom-form-item-error-msg' key={i}>
                    {e}
                </div>
            ))}
        </div>
    );
};
