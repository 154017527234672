import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { fetchConfigurationEventDataFromFields } from '../../../shared/logik/utils/fields.utils';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { SaveAndCreateOrderResult } from '../../widget/thunks/actions/saveAndCreateOrder';
import { CheckoutState } from '../Checkout.slice';

export const notifyAboutOrder = createAsyncThunk<
    void,
    SaveAndCreateOrderResult,
    {
        state: RootState;
    }
>('orders/notify', async (args, thunkAPI): Promise<void> => {
    const widgetState = thunkAPI.getState().widget;

    if (!widgetState.uuid) {
        return Promise.reject('UUID is invalid');
    }

    const salesman = thunkAPI.getState().auth.user?.email;

    const eventData = {
        ...fetchConfigurationEventDataFromFields(
            widgetState.configurationFields,
            [],
            undefined,
            undefined,
            salesman,
            widgetState.siteId,
            widgetState.init?.dealId,
            widgetState.subTotal,
            true,
        ),
        images: widgetState.images3D,
    };

    await ConfiguratorAPI.notifyAboutOrder(args.orderMasterId, {
        data: eventData,
        sourceMasterConfigurationId: args.sourceMasterId,
    });
});

export const addNotifyAboutOrderCases = (
    builder: ActionReducerMapBuilder<CheckoutState>,
): ActionReducerMapBuilder<CheckoutState> => {
    return builder;
    // .addCase(notifyAboutOrder.pending, (state) => {

    // })
    // .addCase(notifyAboutOrder.fulfilled, (state) => {

    // })
    // .addCase(notifyAboutOrder.rejected, (state) => {

    // });
};
