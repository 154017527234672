import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { WidgetState, setUUID, setThreekitId } from '../../widget/Widget.slice';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { isAxiosError } from 'axios';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { LogikFieldsEnum } from '../../../shared/logik/constants/fields.constant';

export type createBuildingPackageArgs = {
    productMasterConfigurationId?: string;
    productId?: string;
};
export type createBuildingPackageResult = {
    configuration: Configuration;
};
export const createBuildingPackage = createAsyncThunk<
    createBuildingPackageResult,
    createBuildingPackageArgs,
    {
        state: RootState;
    }
>(
    'building-packages/create',
    async ({ productMasterConfigurationId, productId }, thunkAPI): Promise<createBuildingPackageResult> => {
        try {
            let configuration: Configuration;
            if (productMasterConfigurationId) {
                const pd = await ConfiguratorAPI.getConfigurationByMasterId(productMasterConfigurationId);
                thunkAPI.dispatch(setThreekitId(pd.threeKitId));

                configuration = await logikApiService.copyConfiguration(pd.logikId);
            } else if (productId) {
                configuration = await logikApiService.createConfiguration([
                    {
                        variableName: LogikFieldsEnum.product,
                        value: productId,
                    },
                    {
                        variableName: LogikFieldsEnum.purchaseOpt,
                        value: 'Cash',
                    },
                    {
                        variableName: LogikFieldsEnum.permits,
                        value: false,
                    },
                    {
                        variableName: LogikFieldsEnum.shipVia,
                        value: 'TD',
                    },
                    {
                        variableName: LogikFieldsEnum.shipping_zip,
                        value: 'DefaultZipCode',
                    },
                ]);
            } else {
                return Promise.reject('Master config id or product id parameter is missing');
            }

            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));

            await thunkAPI.dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));

            thunkAPI.dispatch(setUUID(configuration.uuid));

            return { configuration };
        } catch (error) {
            if (isAxiosError(error)) {
                throw new Error(error.response?.data.errorMessage);
            } else {
                throw error;
            }
        }
    },
);

export const addCreateBuildingPackageCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(createBuildingPackage.pending, (state) => {
            state.initialized = false;
            state.initializing = true;
        })
        .addCase(createBuildingPackage.fulfilled, (state) => {
            state.initializing = false;
            state.initialized = true;
        })
        .addCase(createBuildingPackage.rejected, (state) => {
            state.initializing = false;
            state.initialized = true;
        });
};
