import { Button, Form, FormInstance } from 'antd';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logger from '../../app/logger';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { selectIsCheckedOut } from '../checkout/Checkout.slice';
import { saveOrder } from '../checkout/thunk/saveOrder';
import { SaveQuoteDialog } from './SaveQuoteDialog';
import { EventBus } from '../../app/eventbus';
import { inIframe } from '../../shared/utils/document.utils';
import { SaveOrderAndRedirectEventParams, SaveOrderAndRedirectEvent } from '../orders/OrderCreatedPage';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { saveConfiguration } from '../widget/thunks/actions/saveConfiguration';
import { redirectToTransitionPage } from '../../shared/utils/location.utils';
import { isGlobalMessage } from '../../shared/logik/models/message.model';

export interface WizardButtonsProps {
    onClickBack?: () => void | Promise<void>;
    onClickSave?: () => void | Promise<void>;
    onClickNext?: () => void | Promise<void>;
    backButtonTitle?: string;
    saveButtonTitle?: string;
    nextButtonTitle?: string;
    relatedForms?: FormInstance[];
    hideBackButton?: boolean;
    hideSaveButton?: boolean;
    hideNextButton?: boolean;
    disableNext?: boolean;
    formsInvalid?: boolean;
}

export function WizardButtons({
    onClickSave: onClickSaveQuote,
    onClickBack,
    onClickNext,
    backButtonTitle,
    saveButtonTitle: saveQuoteButtonTitle,
    nextButtonTitle,
    hideBackButton,
    hideSaveButton: hideSaveQuoteButton,
    hideNextButton,
    disableNext,
    children,
    relatedForms,
    formsInvalid,
}: PropsWithChildren<WizardButtonsProps>): JSX.Element {
    const dispatch = useAppDispatch();
    const [modalHandle, setModalHandle] = useState<FlexibleModalHandle<typeof SaveQuoteDialog>>();
    const isSaving = useAppSelector((state) => state.widget.saving);
    const isLoading = useAppSelector((state) => state.widget.loading);
    const isUpdating = useAppSelector((state) => state.widget.updating);
    const isCheckedOut = useAppSelector(selectIsCheckedOut);
    const messages = useAppSelector((state) => state.widget.configurationMessages);
    const hasGlobalErrors = useMemo(() => messages.some((e) => e.error && isGlobalMessage(e)), [messages]);
    const siteId = useFieldValue<string>(LogikFieldsEnum.siteId);
    const masterId = useAppSelector((state) => state.widget.masterConfigurationId);
    const actionType = useAppSelector((state) => state.widget.init?.actionType);
    const sourceMasterConfigurationId = useAppSelector((state) => state.widget.init?.sourceMasterConfigId);
    // const [disabledSave, setDisabledSave] = useState(
    //     relatedForms?.[0].getFieldsError().some(({ errors }) => errors.length > 0),
    // );

    const saveButtonTitle = useMemo(
        () => (actionType === 'NewCR' || actionType === 'EditCR' ? 'Save' : isCheckedOut ? 'Save Order' : 'Save Quote'),
        [actionType, isCheckedOut],
    );

    // const handleFormChange = useCallback(() => {
    //     const hasErrors = relatedForms?.[0].getFieldsError().some(({ errors }) => errors.length);
    //     setDisabledSave(hasErrors === true);
    // }, [relatedForms]);

    const goBack = async () => {
        try {
            onClickBack?.();
        } catch (ex) {
            logger.error(ex);
        }
    };

    const saveConfig = useCallback(async () => {
        const conf = await dispatch(saveConfiguration()).unwrap();

        if (siteId && sourceMasterConfigurationId) {
            EventBus.dispatch<SaveOrderAndRedirectEventParams>(SaveOrderAndRedirectEvent, {
                masterId: sourceMasterConfigurationId,
                siteId,
                logikIdCR: conf.configuration.uuid,
                masterIdCR: conf.masterId,
                newOrder: actionType === 'NewCR',
                newCR: actionType === 'NewCR',
                editCR: actionType === 'EditCR',
            });

            if (!inIframe()) {
                await redirectToTransitionPage({
                    site: siteId,
                    crLogikId: conf.configuration.uuid,
                    crMasterId: conf.masterId,
                    redirectType: actionType ?? 'NewCR',
                });
            }
        }
    }, [actionType, dispatch, siteId, sourceMasterConfigurationId]);

    const updateOrder = useCallback(async () => {
        const orderConfig = await dispatch(saveOrder()).unwrap();

        if (siteId && masterId) {
            EventBus.dispatch<SaveOrderAndRedirectEventParams>(SaveOrderAndRedirectEvent, {
                masterId: masterId,
                siteId,
                logikId: orderConfig.uuid,
                newOrder: false,
            });

            if (!inIframe()) {
                await redirectToTransitionPage({
                    site: siteId,
                    redirectType: 'EditOrder',
                    masterId,
                    logikId: orderConfig.uuid,
                });
            }
        }
    }, [dispatch, masterId, siteId]);

    const saveQuote = useCallback(() => {
        modalHandle?.open();
    }, [modalHandle]);

    const save = useCallback(async () => {
        if (onClickSaveQuote) {
            onClickSaveQuote();
        } else {
            if (actionType === 'NewCR' || actionType === 'EditCR') {
                await saveConfig();
            } else if (isCheckedOut) {
                await updateOrder();
            } else {
                saveQuote();
            }
        }
    }, [actionType, isCheckedOut, onClickSaveQuote, saveConfig, saveQuote, updateOrder]);

    const next = async () => {
        onClickNext?.();
    };

    useEffect(() => {
        if (!modalHandle) {
            const modal: FlexibleModalHandle<typeof SaveQuoteDialog> = FlexibleModal.createModal(
                SaveQuoteDialog,
                {
                    onCancel: () => modal.close(),
                    onSave: () => modal.close(),
                },
                { showCloseButton: true, destroyOnClose: true },
            );

            setModalHandle(modal);
        }
    }, [modalHandle, setModalHandle]);

    return (
        <>
            <Form component={false} name={'wizard-buttons'}>
                {!hideBackButton && <Button onClick={goBack}>{backButtonTitle ?? 'Back'}</Button>}

                {!hideSaveQuoteButton && (
                    <Button
                        htmlType='button'
                        onClick={save}
                        disabled={isLoading || isSaving || isUpdating || hasGlobalErrors || formsInvalid}>
                        {saveQuoteButtonTitle ?? saveButtonTitle}
                    </Button>
                )}

                {!hideNextButton && (
                    <Button
                        type='primary'
                        htmlType='button'
                        onClick={next}
                        disabled={
                            isLoading || isSaving || isUpdating || hasGlobalErrors || disableNext || formsInvalid
                            //relatedForms?.some((f) => f.getFieldsError().flatMap((f) => f.errors).length > 0)
                        }>
                        {nextButtonTitle ?? 'Next'}
                    </Button>
                )}

                {children}
            </Form>
        </>
    );
}
