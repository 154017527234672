import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Configuration } from '../../../shared/logik/models/configuration.model';
import logikApiService from '../../../shared/logik/services/logik-api.service';
import { WidgetState, setThreekitId, setUUID } from '../../widget/Widget.slice';
import { getDefaultLayout } from '../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../../widget/thunks/basic/fetchTooltips';
import { postProcessConfiguration } from '../../widget/thunks/basic/postProcessConfiguration';
import { ConfiguratorAPI } from '../../../shared/services/configurator.service';
import { setUserProfile } from '../../widget/thunks/init/setUserProfile';

export type duplicateBuildingPackageArgs = {
    productMasterConfigurationId: string;
};
export type duplicateBuildingPackageResult = {
    configuration: Configuration;
};
export const duplicateBuildingPackage = createAsyncThunk<
    duplicateBuildingPackageResult,
    duplicateBuildingPackageArgs,
    {
        state: RootState;
    }
>(
    'building-packages/duplicate',
    async ({ productMasterConfigurationId }, thunkAPI): Promise<duplicateBuildingPackageResult> => {
        const dispatch = thunkAPI.dispatch;

        const c = await ConfiguratorAPI.getConfigurationByMasterId(productMasterConfigurationId);

        const uuid = c.logikId;

        if (uuid) {
            const configuration = await logikApiService.copyConfiguration(uuid);
            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
            await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
            await dispatch(setUserProfile(configuration.uuid));

            // await Promise.all([
            //     dispatch(
            //         fetchSets({
            //             uuid: configuration.uuid,
            //             sets: configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
            //         }),
            //     ),
            //     dispatch(fetchPricingDetails(configuration.uuid)),
            // ]);

            dispatch(setUUID(configuration.uuid));
            dispatch(setThreekitId(c.threeKitId));

            return { configuration };
        } else {
            return Promise.reject('Invalid UUID');
        }
    },
);

export const addDuplicateBuildingPackageCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(duplicateBuildingPackage.pending, (state) => {
            state.initialized = false;
            state.initializing = true;
        })
        .addCase(duplicateBuildingPackage.fulfilled, (state) => {
            state.initializing = false;
            state.initialized = true;
        })
        .addCase(duplicateBuildingPackage.rejected, (state) => {
            state.initializing = false;
            state.initialized = true;
        });
};
