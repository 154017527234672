import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { selectMessages } from '../../../features/widget/Widget.slice';
import { ConfigurationMessage, GlobalMessage, isGlobalMessage } from '../models/message.model';

export const useConfigurationGlobalMessages = (): GlobalMessage[] | undefined =>
    useAppSelector((state) => selectMessages(state)?.filter(isGlobalMessage), shallowEqual);

export const useHasBlockingErrors = (): boolean =>
    useAppSelector((state) => selectMessages(state)?.some((msg) => msg.error), shallowEqual);

export const useBlockingErrors = (): { hasBlockingErrors: boolean; errors: ConfigurationMessage[] } => {
    const errors = useAppSelector((state) => selectMessages(state)?.filter((msg) => msg.error) ?? [], shallowEqual);

    return {
        errors,
        hasBlockingErrors: errors.length > 0,
    };
};
